.subMenu {
  background-color: #3E3E3E;
  border-top: 1px solid #666;

  &__inner {
    display: flex;
    overflow-x: auto;
    scrollbar-width: none;
  }

  &__item {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
    height: 42px;
    font-size: 14px;
    white-space: nowrap;
    text-decoration: none;
    box-sizing: border-box;
    border-bottom: 2px solid transparent;

    &__default {
      color: inherit !important;
    }

    &__active {
      color: #FFB80C;
      border-bottom: 2px solid #FFB80C;
    }

    &:active {
      background-color: #666666;
    }
  }
}
