.container {
  padding: 16px 0;
  border-bottom: 1px dashed #dddddd;
}

.descriptionField {
  position: relative;
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  font-size: 16px;

  &__back,
  &__lay {
    &:before {
      position: absolute;
      content: '';
      width: 4px;
      height: 100%;
      border-radius: 4px;
      top: 0;
      left: 0;
    }
  }

  &__back:before {
    background-color: #a6d8ff;
  }

  &__lay:before {
    background-color: #fac9d1;
  }
}

.marketInfo {
  color: #666;
}

.infoWrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;

  > * {
    line-height: 24px;
  }
}

.selectionName {
  font-size: 16px;
  font-weight: bold !important;
  color: #303030;
}

.icon {
  font-size: 24px;
}

.iconBg:before {
  color: inherit;
}

.oddsWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 4px;
  font-size: 14px;
  color: #666666;
}

.odds {
  display: flex;
  align-items: center;
  gap: 9px;
  line-height: 24px;
  color: #303030;
  font-size: 16px;
  font-weight: bold !important;
}

.betSide {
  line-height: 20px;
}

.error {
  display: flex;
  align-items: center;
  column-gap: 10px;
  border: 1px solid #fe3629;
  border-radius: 4px;
  padding: 13px 12px;
  min-height: 40px;
  box-sizing: border-box;
  margin-top: 10px;

  &__msg {
    font-size: 14px;
    line-height: 20px;
    color: inherit;
    margin: 0;
  }

  &__icon {
    font-size: 16px;
  }
}

.success {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 13px 12px;
  border-radius: 4px;
  border: 1px solid #0072ac;
  margin-top: 10px;

  &__icon {
    font-size: 16px;
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
    color: inherit;
    margin: 0;

    &__bold {
      font-weight: 700 !important;
    }
  }
}

.terms {
  color: #666;
  font-size: 16px;
}

.cashOutLabel {
  align-self: flex-start;
  padding: 0 8px;
  font-size: 14px;
  font-weight: bold !important;
  color: #303030;
  background-color: #ffb80c;
  border-radius: 4px;
  box-sizing: border-box;
}

.tooltip {
  font-size: 16px !important;
}