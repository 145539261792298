.indicator {
  width: 2px;
  max-width: 2px;
  min-width: 2px;
  flex: 1;

  &__back {
    background-color: #0072AC;
  }

  &__lay {
    background-color: #D70022;
  }

  &__height {
    height: 56px;
  }

  &__double {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: fit-content;
  }
}
