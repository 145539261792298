.openedBetsBySideHeader {
  display: flex;
  padding: 5px 10px;
  align-items: center;
  box-sizing: border-box;
  column-gap: 5px;

  &__label {
    margin: 0;
    font-size: 10px;
    color: inherit;
    line-height: 1.5;
    flex: 1;
    text-align: center;

    &__left {
      text-align: left;
      flex: 2;
    }
  }

  &__right {
    display: flex;
    flex: 3;
    align-items: center;

    i {
      color: #0072ac;
      line-height: 1;
    }

    &__unmatched {
      column-gap: 5px;
    }
  }

  &__back {
    background-color: #a6d8ff;
    color: #303030;
  }

  &__lay {
    background-color: #fac9d4;
    color: #303030;
  }
}

.odds {
  min-width: 60px;
}

.stake,
.profit {
  min-width: 62.5px;
}
