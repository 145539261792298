@import "styles/variables/variables";

.headerNavigation {
  background-color: #1e1e1e;
  text-align: left;
  line-height: 30px;
  height: 30px;

  &__mobile {
    background-color: $dark-gray;
    height: auto;
  }

  &__sticky {
    position: sticky;
    top: 0;
    z-index: 1000;
  }
}
