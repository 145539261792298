@import "/src/styles/variables/variables";
@import "/src/styles/mixins/breakpoints";

.marketBackLay {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1;

  @include md {
    width: auto;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    column-gap: 2px;

    &__mobile {
      align-self: end;
    }

    &__withoutLay {
      justify-content: flex-end;
    }

    &__mobileDepth {
      justify-content: center;
    }
  }

  &__overRounds {
    margin-right: auto;
    text-align: left;

    @include xs {
      display: none;
    }
  }

  &__underRounds {
    margin-left: auto;
    text-align: right;

    & > div {
      justify-content: flex-end;
    }

    @include xs {
      display: none;
    }
  }

  &__smallItem {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    &__title {
      min-width: 45px;
      padding: 1px 5px;
      text-align: center;
      font-size: $smaller;
      font-weight: 700;
      color: $dark-gray;
      box-sizing: border-box;
      cursor: pointer;

      @include md {
        padding: 1px 0;
      }
    }
  }

  &__back {
    margin-left: auto;

    & > span {
      background-color: #a6d8ff;
    }
  }

  &__back span {
    background-color: #a6d8ff;
  }

  &__backTooltip span:last-of-type {
    border-right: 0;
  }

  &__lay > span {
    background-color: #fac9d4;
  }

  &__layTooltip span:last-of-type {
    border-left: 0;
  }

  &__hideBackLayDepth {
    align-items: center;
    padding: 2px;

    span {
      background-color: transparent !important;
      text-align: center;
      color: #7f7f7f;
      word-break: break-word;
      font-size: 11px;
    }
  }
}
