.inlinePlacedBet {
  position: relative;
  padding: 5px;

  &__remove {
    position: absolute;
    top: 10px;
    right: 10px;

    &__middle {
      top: 50%;
      transform: translateY(-50%);
    }
    &:hover {
      min-width: 25px;
      min-height: 25px;
      border-radius: 50%;
      background-color: #ececec;
    }
  }

  &__block {
    display: flex;
    align-items: center;
    min-height: 90px;
    background-color: #ffffff;
    border-radius: 4px;
    box-sizing: border-box;

    &__unmatched {
      border: 1px solid #fe3629;
    }

    &__matched {
      border: 1px solid #04a04a;
    }

    &__cancelled {
      border: 1px solid #0072ac;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    flex: 1 1 auto;
    align-items: center;
    gap: 10px;
    padding: 15px;
    padding-right: 10px;
  }

  &__shortView {
    flex-direction: column;
    align-items: flex-start;
  }

  &__icon {
    font-size: 16px;

    &__danger {
      color: #fe3629;
    }
  }

  &__infoWrap {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    width: 100%;
  }

  &__actions {
    margin-left: auto;

    &__shortView {
      width: 100%;
    }
  }
}
