.placeholder {
  height: 26px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
  padding: 0 25px 0 5px;
  background-color: #ececec;
  border: 1px solid #ececec;
  border-radius: 3px;
  font-size: 10px;
  font-style: normal;
  color: #3e3e3e;
  box-sizing: border-box;
  cursor: pointer;

  &::after {
    font-family: "fa2", serif !important;
    font-weight: normal !important;
    font-style: normal !important;
    content: '\e94d';
    width: 7px;
    font-size: 6px !important;
    transform: translateY(-50%);
    position: absolute;
    right: 10px;
    top: 50%;
  }

  &__active {
    &:after {
      transform: translateY(-50%) rotateX(180deg);
    }
  }
}

.placeholder:hover {
  border-color: #ddd;
  background-color: #ddd;
}

.mobilePlaceholder {
  min-width: 42px;
  height: 42px;
  border-radius: 4px;
  border: 1px solid #666666;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #FFFFFF;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 16px;

  &__active {
    background-color: #FFB80C;
    color: #3E3E3E;
  }

  span {
    white-space: nowrap;
  }
}

.competitionIcon {
  font-size: 16px !important;
  &__active {
    color: #303030;
  }
}
