@import "/src/styles/mixins/breakpoints";

.cardsSection {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__roundsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
