.marketHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  color: #1e1e1e;
  font-size: 12px;
  font-weight: bold;

  span {
    line-height: 1.2em;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}

.marketHeaderName {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35px;

  &__icon {
    margin-right: 4px;
    line-height: 35px;
    top: 2px
  }
}

.title {
  display: flex;
}

.name {
  font-weight: bold !important;
}

.score {
  font-weight: bold !important;
  margin: 0 4px;
}

.link {
  padding: 4px 8px;
  display: flex;
  border-bottom: 1px solid;
}

.time {
  font-weight: bold !important;
  padding: 3px 8px;
  border-radius: 2px;
  margin: 0 4px;
}

.wrapper {
  width: 100%
}
