.contentContainer {
  text-align: center;
  margin: 10px 0;
}

.mobileTeamName {
  text-align: start;
  font-weight: bold !important;
}

.mobileCol {
  padding: 4px 2px;
  vertical-align: middle;
  border: solid 1px #e6e6e6;
  text-align: center;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  &__col {
    padding: 4px 2px;
    vertical-align: middle;
    border: solid 1px #e6e6e6;
  }

  &__colBackground {
    padding: 4px 2px;
    border: solid 1px #e6e6e6;
    vertical-align: middle;
    border-left: solid 1px #fff;
    background-color: #d7d7d7;
  }

  &__normalText {
    vertical-align: bottom;
    font-weight: 400 !important;
  }

  &__mobileColBackground {
    padding: 4px 2px;
    border: solid 1px #e6e6e6;
    vertical-align: middle;
    border-left: solid 1px #fff;
    background-color: #f0f0f0;
  }
}
