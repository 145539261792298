.settings {
  &__wrapper {
    position: relative;
    cursor: pointer;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    gap: 8px;
    background: none;
    font-size: 16px;
    color: #ffffff;
  }

  &__icon {
    font-size: 16px !important;
  }

  &__label {
    font-size: inherit;
    white-space: nowrap;
  }

  &__modalBody {
    padding: 0;
    border-radius: 4px;
    border: none;
  }

  &__modalContent {
    min-width: fit-content;
    max-width: fit-content;
    padding: 0;
  }

  &__modalDialog {
    height: auto;
    min-height: auto;

    &__hidden {
      opacity: 0;
    }
  }
}
