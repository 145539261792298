@import "../../../../styles/variables/variables";

.marketHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  background-color: #ebebeb;
  color: #1e1e1e;
  font-size: $small;
  font-weight: bold;

  i {
    font-size: 15px;
    margin-right: 4px;
  }

  span {
    color: #1e1e1e;
    font-size: $small;
    font-weight: 400;
  }

  &__name {
    display: flex;
    align-items: center;
  }

  &__title {
    display: flex;
  }

  &__inPlayName {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__marketName {
    color: #1e1e1e;
    font-size: $small;
    font-weight: 400;

    &__live {
      margin-left: 5px;
    }
  }

  &__time {
    text-align: end;
    font-weight: 400;
  }
}

.marketHeaderSingle {
  align-items: self-start;

  div {
    line-height: 15px;
  }
}

.time {
  padding: 2px 4px;
  border-radius: 2px;
  margin: 0 4px;
}

.name {
  font-weight: bold !important;
}

.score {
  font-weight: bold !important;
  margin: 0 4px;
}

