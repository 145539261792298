.bet {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;

  input {
    font-size: 12px;
  }
}
