@import "/src/styles/mixins/breakpoints";

.hiloMain {
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  border-radius: 2px;
  box-shadow: 4px 4px 10px -2px #1e1e1e;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 20px;
  @include md {
    gap: 10px;
    margin: 0 5px;
    padding: 4px;
  }
}

.description {
  text-align: center;
}

.cards {
  margin: 0 auto;
  width: 352px;
  @include md {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


.always100 {
  @include md {
    width: 300px !important;
  }
}

.startDate {
  margin-bottom: 10px;
}

.resultsWrapper {
  display: flex;
  justify-content: center;
  gap: 5px;
  > div {
    margin: 0;
  }
}

.myBetsTopSection {
  display: table;
  margin: 0 auto;
}
