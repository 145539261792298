.resultsTablesWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  div {
    margin: 0 5px;
  }
}

hr {
  border: none;
  background-color: #e3e8e8;
  height: 1px;
  margin: 20px 0 28px 0;
  width: 100%;
}
