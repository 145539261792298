.openBet {
  position: relative;
  padding: 8px 12px 12px;
  border-bottom: 1px solid #ddd;

  &__topBorder {
    &:first-of-type {
      border-top: 1px solid #ddd;
    }
  }

  &__header {
    display: flex;
    column-gap: 12px;
    align-items: center;
  }

  &__icon {
    font-size: 16px;

    &__unmatched:before {
      color: #fe3629;
    }
  }

  &__title {
    margin: 0;

    &:last-of-type {
      margin-top: 6px;
    }

    span {
      font-weight: 700 !important;
    }
  }
}

.closeIcon {
  cursor: pointer;
  margin-left: auto;
  font-weight: 700 !important;
  font-size: 16px;
}
