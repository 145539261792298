.wrap {
  &__header {
    position: sticky;
    top: 0;
    padding-top: 20px;
    background-color: #FFFFFF;
    z-index: 1;
  }
}

.row {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  gap: 20px;
  min-height: 100px;
  padding: 5px 20px;
  border-bottom: 1px solid #DDDDDD;
  box-sizing: border-box;

  &__header {
    min-height: 50px;
    color: #666666;
    background-color: #F5F5F5;
    border-radius: 4px;
    border: 0;
  }

  &:hover {
    background-color: #F5F5F5;
  }
}

.col {
  padding: 0;

  &__event {
    flex: 5 0 180px;
  }

  &__market {
    flex: 4 0 180px;
  }

  &__date {
    width: 140px;
  }

  &__pl {
    width: 120px;
  }

  &__right {
    text-align: right;
  }

  &__center {
    text-align: center;
  }
}
