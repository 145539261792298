.quickBet {
  position: absolute;
  z-index: 12;
  width: 230px;
  background-color: #FFFFFF;
  border-radius: 4px;
  left: 50%;
  transform: translate(-50%, 0);
  top: 5px;
  bottom: auto;

  &__wrap {
    position: relative;
  }

  &__back {
    border: 2px solid #0072AC;
  }

  &__lay {
    border: 2px solid #FF7289;
  }

  &__disabled {
    border: 2px solid #C0C0C0;
    background-color: #ECECEC;
  }

  &__left {
    left: 0;
    right: auto;
    transform: none;
  }

  &__right {
    right: 0;
    left: auto;
    transform: none;
  }

  &__bottom {
    top: auto;
    bottom: 25px;
  }

  &__hidden {
    display: none;
  }
}
