@import "/src/styles/mixins/breakpoints";

.progress {
  &__wrapper {
    width: 300px;
    height: 5px;
    background-color: lightgray;
    border-radius: 2px;
    box-shadow: 4px 4px 10px -2px #1e1e1e;
    @include md {
      width: 150px;
    }
  }
  &__bar {
    width: 0;
    height: 100%;
    background-color: #ffa217;
    border-radius: 2px;
  }
}
