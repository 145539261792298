@import 'src/styles/mixins/breakpoints';
@import 'src/styles/variables/variables';

.tourModal {
  max-width: 995px;
}

.tourModalHeader {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 48px;
}

.tourModalBody {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

@keyframes modalFadeOut {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes modalFadeIn {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.5;
  z-index: 31;
  @include md {
    z-index: 10001;
  }
}

.withAnimation {
  animation-duration: 1s;
  animation-name: modalFadeOut;
  animation-timing-function: linear;
}

.overlapMobileMenu {
  z-index: 301;
}

.overlapHowTo {
  z-index: 40;
}

.backdropFadein {
  z-index: 999;
  animation: fadeIn 1.5s;
  @include md {
    z-index: 10001;
  }
}

.welcomeModalFadeout {
  animation: fadeOut 0.5s;
}

.welcomeModal {
  animation: modalFadeIn 0.7s;
  padding-top: 30vh;
  @include md {
    padding-top: 20vh;
  }
}

.welcomeModalBody {
  padding: 16px 0 12px 0;
  @include md {
    padding: 10px 0 12px 0;
  }
}

.welcomeModalHeader {
  column-gap: normal;
  .welcomeModalHeaderContent {
    width: calc(100% - 32px);
    margin-left: 16px;
    h4 {
      text-align: center;
    }
  }
}

.hideModalBackdrop {
  visibility: hidden;
}
