@import 'styles/mixins/breakpoints';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  padding: 0 10px 0 0;

  margin: 10px 0;
  @include md {
    margin-top: 0;
  }
}

.nameContainer {
  display: flex;
  align-items: center;
  min-height: 40px;
}

.inPlay {
  position: relative;
  flex: 1;
  align-self: stretch;
}

.teamsName {
  margin-left: 10px;
}

.teamName {
  display: flex;
  align-items: center;
}
