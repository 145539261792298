.btn {
  all: unset;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  box-sizing: border-box;

  i {
    font-size: 24px;
  }
}
