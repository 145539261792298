@import "../../styles/variables/variables";
@import "../../styles/mixins/breakpoints";

.collapse {
  position: relative;
  background-color: $dark-gray;
  color: #ffffff;
  padding: 0 10px;
  line-height: 20px;
  cursor: pointer;
  margin: 0 0 1px;
  @include md {
    height: 45px;
    line-height: 45px;
    margin: 0;
  }

  &.open {
    display: block;

    .collapse__icon {
      transform: rotate(180deg) translate(0, 50%);
      top: 50%;
    }
  }

  &__icon {
    position: absolute;
    right: 4px;
    @include md {
      right: 15px;
    }

    top: 50%;
    transform: translate(0, -50%);

    &__desktopPS {
      font-size: 10px;
    }

    &__desktopDefault {
      font-size: 6px;
    }

    &__ps {
      right: 4px;
    }

    &__ps, &__default {
      &:before {
        font-size: inherit !important;
      }
    }
  }
}

.noMargin {
  margin: 0;
}