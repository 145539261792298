@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

.skeleton {
  padding: 10px;
  animation: 1s fadeIn;
  animation-fill-mode: forwards;

  &__lineMobile {
    height: 28px;
  }

  &__title {
    width: 100px;
    height: 25px;
    float: left;
    margin-bottom: 13px;
  }

  &__filter {
    float: right;
    margin-left: 10px;
    width: 80px;
    height: 25px;
    margin-bottom: 13px;
  }

  &__competition {
    height: 15px;
    margin-bottom: 35px;
    &__mobile {
      height: 28px;
      margin-bottom: 22px;
    }
  }

  &__oddsMobile {
    flex: 1;
    height: 42px;
  }

  &__score {
    width: 30px;
    height: 45px;
    margin-right: 24px;
    float: left;
  }

  &__selections {
    float: left;
    &__mobile {
      display: flex;
      gap: 12px;
      margin-top: 56px;
    }
  }

  &__selection {
    width: 150px;
    height: 15px;
    margin-bottom: 15px;
  }

  &__markets {
    margin-left: 320px;
    &__mobile {
      display: flex;
      gap: 9px;
      margin-top: 18px;
      & > div {
        flex: 1;
      }
    }
  }

  &__market {
    width: 100%;
    height: 15px;
    margin-bottom: 15px;
  }
}

.totalGoals {
  background: #ffffff;

  &__firstLine {
    display: flex;
    gap: 20px;
    margin: 15px 4px 20px;
  }

  &__firstLineItem1 {
    height: 15px;
    width: 45px;
  }

  &__firstLineItem2 {
    height: 15px;
    width: 180px;
  }

  &__secondLine {
    display: flex;
    gap: 20px;
    margin: 15px 4px;
  }

  &__secondItem {
    flex: 1;
    height: 15px;
  }
}

.oddEven {
  background: #ffffff;

  &__line {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
  }

  &__first {
    height: 15px;
    width: 35%;
    max-width: 350px;
    margin-bottom: 14px;
  }

  &__secondContainer {
    display: flex;
    gap: 10px;
  }

  &__second {
    width: 120px;
    height: 15px;
  }
}

.outRight {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  &__mobile {
    grid-template-columns: repeat(1, 1fr);
  }

  &__line {
    display: flex;
    justify-content: space-between;
    height: 15px;
  }

  &__lineMobile {
    height: 28px;
    margin-bottom: 10px;
  }

  &__name {
    max-width: 40%;
    width: 250%;
  }

  &__lay {
    width: 64px;
  }

  &__mobileWrapLay {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  &__layMobile {
    width: 64px;
    height: 42px;
  }
}

