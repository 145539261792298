.marketCell {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__market {
    padding: 5px 10px 0 0;
    margin-bottom: 5px;
    &__main {
      flex-grow: 1;
      min-height: 81px;
    }
    &__hasBackLay {
      min-height: 110px;
    }
    &__empty,
    &__additional {
      border-top: solid 1px #c0c0c0;
      padding-bottom: 5px;
    }
    &__empty {
      height: 64px;
    }
    &__additional {
      min-height: 54px;
    }
    &__backLay__wrapper,
    &__singleLine {
      min-height: auto;
      padding: 0;
      margin: 0;
      border: none;
      display: flex;
      justify-content: flex-end;
      & > div {
        padding: 5px;
        margin: 0;
      }
      & > div:last-child {
        border-left: solid 1px #c0c0c0;
        min-width: 72px;
      }
    }
    &__backLay__wrapper > div {
      padding-bottom: 0 !important;
    }
    &__additional.marketCell__market__singleLine {
      & > div {
        padding-top: 0;
      }
    }
  }
  &__backLay {
    justify-content: flex-end;
  }

  &__skeletons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
