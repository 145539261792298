@import 'styles/variables/variables';

.dropdownContainer {
  &__itemsContainer {
    position: relative;
  }

  &__items {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 111;
    width: 100%;
    padding: 5px 0;
    margin: 4px 0 0 0;
    border: 1px solid $border-gray;
    border-radius: $radius-main;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(#181e5c, 15%);

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;
      background-color: #fff;
      font-size: 12px;
      text-decoration: none;
      line-height: 20px;
      color: $text-black;

      &:hover {
        background-color: $bg-gray;
        cursor: pointer;
      }
    }
  }
}
