@import 'styles/variables/variables';

.featureMarket {
  &__headerContainer {
    display: flex;
    align-items: center;
    padding: 0 10px;
    min-height: 30px;
    border-bottom: 1px solid;
  }

  &__title {
    margin: 0;
  }

  &__headerMobile {
    width: 100%;
    font-weight: bold !important;
  }
}
