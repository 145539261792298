@import "../../../../styles/variables/variables";
@import "../../../../styles/mixins/breakpoints";

.filters {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  padding: 10px;

  @include md {
    padding: 0;
    background-color: #f0f1f5 !important;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 400;
    color: $white;
    line-height: 30px;
    margin: 0;
    white-space: nowrap;
  }

  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 10px;

    @include md {
      justify-content: flex-start;
      flex-wrap: nowrap;
      gap: 0;
    }
  }

  &__filter {
    padding: 8px 15px;
    border-radius: 2px;
    font-weight: bold;
    text-decoration: none;

    @include md {
      position: relative;
      min-width: 60px;
      text-align: center;
      border-radius: 1px;
      font-weight: normal;
      border-top: 2px solid;
      line-height: 14px;
      white-space: nowrap;

      &:after {
        content: ' ';
        position: absolute;
        right: 0;
        width: 1px;
        top: -2px;
        height: calc(100% + 2px);
        background: #dfdfdf;
      }
    }

    &__active {
      @include md {
        font-weight: bold !important;
      }
    }
  }
}
