.betMin {
  display: flex;
  gap: 5px;

  &__col {
    flex-direction: column;
    gap: 0;
  }

  &__label, &__value {
    line-height: 15px;
  }

  &__value {
    font-weight: 700;

    &__mobile {
      font-weight: bold !important;
    }
  }
}
