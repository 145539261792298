.notification {
  margin: 15px 10px 5px 10px;
  border: solid 1px #0072ac;
  border-radius: 4px;
  font-size: 12px;
  overflow: hidden;
  line-height: 20px;
  background-color: #fff;
  &__success {
    border-color: #04a04a;
  }
  &__error {
    border-color: #fe3629;
  }
  &__icon {
    font-size: 16px;
    &__loading {
      color: #0072AC;
    }
    &__unmatched:before {
      color: #fe3629;
    }
  }
  &__close {
    margin-left: auto;
    display: flex;
    min-width: 25px;
    min-height: 25px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    &__icon {
      cursor: pointer;
      font-size: initial;
      color: inherit;
    }
  }
  &__body {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 7px 10px 7px 15px;
    .close:hover {
      background: #ececec;
    }
  }
}

.timeMsg {
  background-color: #f5f5f5;
  padding: 5px 15px 5px 15px;
}

.notPlacedMessage {
  color: #666;
  font-size: 14px;
  line-height: 1.42;
  margin: 0;
}
