.popularLinksHeader {
  display: flex;
  position: relative;
  padding: 0 10px;
  line-height: 35px;
  font-weight: 400 !important;
  background-color: #303030;
  color: #ffffff;
  font-size: 14px;
}

.popularLinksTitle {
  flex: 1 1 0;
}
