.marketTabsWrapper {
  margin: 5px 0 10px;
  border-bottom: 2px solid #2f9ed3;
  .marketTab {
    display: inline-block;
    padding: 5px 10px;
    color: #273a47;
    border: 1px solid #dfdfdf;
    border-bottom: 0;
    cursor: pointer;
  }
}
