.openedBet {
  position: relative;
  min-height: 40px;
  margin-bottom: 5px;
  font-size: 12px;
  box-sizing: border-box;
  border-bottom: 1px solid #c0c0c0;
  padding: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &__back {
    background-color: #dbefff;
    color: #303030;
  }

  &__lay {
    background-color: #fee9ee;
    color: #303030;
  }

  &__header {
    color: #ffffff;
    padding: 5px;

    &__matched {
      background: #303030;
    }

    &__unmatched {
      background: #ff6666;
    }
  }

  .eventName,
  .marketName {
    font-size: 10px;
    line-height: 1.5;
    color: #666666;
  }

  a.eventName,
  a.marketName {
    &:hover {
      text-decoration: none;
    }
  }

  &__betForm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    margin: 5px 0;
    column-gap: 5px;

    > * {
      &:first-child {
        flex: 2;
        text-align: left;
      }

      &:last-child {
        flex: 3;
        text-align: center;
      }
    }
  }

  &__marketLinkWithButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__consolidatedLabel {
    color: #666;
    font-size: 10px;
  }

  &__plBtnAndCashOut {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  &__selectionName {
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    margin: 0;
  }

  &__combined {
    border-top-width: 0;

    &__first {
      border-top-width: 2px;
    }
  }

  &__labels {
    > * {
      padding: 10px 0;
    }
  }

  &__eachWayLabel {
    font-size: 10px;
    line-height: 1.5;
    color: #666666;
    margin: 0;
  }

  &__disabled {
    background-color: #f5f5f5;
  }
}

.betLineInfo {
  padding-top: 5px;
  font-size: 10px;
  margin: 0;
  color: #666666;
}

.progress {
  margin-top: 10px;
}

.notification {
  margin-top: 10px;
}
