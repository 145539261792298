.loginForm {
  &__inputs {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  &__button {
    font-weight: 700 !important;
    padding: 8px 12px 8px 14px;
    font-size: 18px;
    line-height: 28px;
    border-radius: 4px;
    background-color: #FFB80C;
    color: #303030;
    width: 100%;
    text-align: center;
    margin-top: 8px;
    border: none;
    height: 54px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    &:disabled {
      background-color: #FAD06B;
      color: #666;
    }
  }

  &__error {
    font-size: 14px;
    line-height: 20px;
    margin: 0;

    &__icon {
      font-size: 16px;
    }

    &__container {
      padding: 13px 12px;
      border: 1px solid #FE3629;
      border-radius: 4px;
      display: flex;
      column-gap: 12px;
      align-items: center;
    }
  }

  &__loading {
    transform: scale(0.2);
    margin: 0;
  }
}
