.main {
  background-color: #C0C0C0;
  padding: 3px;  // if the top and bottom padding are changed -> need to change TOP_AND_BOTTOM_PADDINGS value in component
  border-bottom: 1px solid;

  .content {
    background-color: #ECECEC !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 11px;

    p {
      margin: 0;
      line-height: 1.5;
    }
  }
}
