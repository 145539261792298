@import "./fonts.scss";
@import "./variables.scss";

body {
  background: white !important;
}

.biab_sport-icon {
  font-family: "PokerStars-Sports" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ps-icon-alpine-skiing {
  &:before {
    content: $ps-icon-alpine-skiing;
  }
}
.ps-icon-american-football {
  &:before {
    content: $ps-icon-american-football;
  }
}
.ps-icon-archery {
  &:before {
    content: $ps-icon-archery;
  }
}
.ps-icon-athletics {
  &:before {
    content: $ps-icon-athletics;
  }
}
.ps-icon-australian-rules {
  &:before {
    content: $ps-icon-australian-rules;
  }
}
.ps-icon-badminton {
  &:before {
    content: $ps-icon-badminton;
  }
}
.ps-icon-baseball {
  &:before {
    content: $ps-icon-baseball;
  }
}
.ps-icon-basketball {
  &:before {
    content: $ps-icon-basketball;
  }
}
.ps-icon-beach-volleyball {
  &:before {
    content: $ps-icon-beach-volleyball;
  }
}
.ps-icon-biathlon {
  &:before {
    content: $ps-icon-biathlon;
  }
}
.ps-icon-bobsleigh {
  &:before {
    content: $ps-icon-bobsleigh;
  }
}
.ps-icon-bowls {
  &:before {
    content: $ps-icon-bowls;
  }
}
.ps-icon-boxing {
  &:before {
    content: $ps-icon-boxing;
  }
}
.ps-icon-canoeing {
  &:before {
    content: $ps-icon-canoeing;
  }
}
.ps-icon-cricket {
  &:before {
    content: $ps-icon-cricket;
  }
}
.ps-icon-cross-country-ski {
  &:before {
    content: $ps-icon-cross-country-ski;
  }
}
.ps-icon-curling {
  &:before {
    content: $ps-icon-curling;
  }
}
.ps-icon-cycling {
  &:before {
    content: $ps-icon-cycling;
  }
}
.ps-icon-darts {
  &:before {
    content: $ps-icon-darts;
  }
}
.ps-icon-diving {
  &:before {
    content: $ps-icon-diving;
  }
}
.ps-icon-equestrian {
  &:before {
    content: $ps-icon-equestrian;
  }
}
.ps-icon-esports {
  &:before {
    content: $ps-icon-esports;
  }
}
.ps-icon-fencing {
  &:before {
    content: $ps-icon-fencing;
  }
}
.ps-icon-figure-skating {
  &:before {
    content: $ps-icon-figure-skating;
  }
}
.ps-icon-soccer {
  &:before {
    content: $ps-icon-soccer;
  }
}
.ps-icon-football {
  &:before {
    content: $ps-icon-football;
  }
}
.ps-icon-freestyle-skiing {
  &:before {
    content: $ps-icon-freestyle-skiing;
  }
}
.ps-icon-gaa-football {
  &:before {
    content: $ps-icon-gaa-football;
  }
}
.ps-icon-gaa-hurling {
  &:before {
    content: $ps-icon-gaa-hurling;
  }
}
.ps-icon-golf {
  &:before {
    content: $ps-icon-golf;
  }
}
.ps-icon-gymnastics {
  &:before {
    content: $ps-icon-gymnastics;
  }
}
.ps-icon-handball {
  &:before {
    content: $ps-icon-handball;
  }
}
.ps-icon-hockey {
  &:before {
    content: $ps-icon-hockey;
  }
}
.ps-icon-horse {
  &:before {
    content: $ps-icon-horse;
  }
}
.ps-icon-ice-hockey {
  &:before {
    content: $ps-icon-ice-hockey;
  }
}
.ps-icon-judo {
  &:before {
    content: $ps-icon-judo;
  }
}
.ps-icon-karate {
  &:before {
    content: $ps-icon-karate;
  }
}
.ps-icon-kickboxing {
  &:before {
    content: $ps-icon-kickboxing;
  }
}
.ps-icon-luge {
  &:before {
    content: $ps-icon-luge;
  }
}
.ps-icon-mixed-martial-arts {
  &:before {
    content: $ps-icon-mixed-martial-arts;
  }
}
.ps-icon-motor-sports {
  &:before {
    content: $ps-icon-motor-sports;
  }
}
.ps-icon-netball {
  &:before {
    content: $ps-icon-netball;
  }
}
.ps-icon-olympics {
  &:before {
    content: $ps-icon-olympics;
  }
}
.ps-icon-pentathlon {
  &:before {
    content: $ps-icon-pentathlon;
  }
}
.ps-icon-rowing {
  &:before {
    content: $ps-icon-rowing;
  }
}
.ps-icon-rugby-league {
  &:before {
    content: $ps-icon-rugby-league;
  }
}
.ps-icon-rugby-union {
  &:before {
    content: $ps-icon-rugby-union;
  }
}
.ps-icon-sailing {
  &:before {
    content: $ps-icon-sailing;
  }
}
.ps-icon-shooting {
  &:before {
    content: $ps-icon-shooting;
  }
}
.ps-icon-skeleton {
  &:before {
    content: $ps-icon-skeleton;
  }
}
.ps-icon-ski-jumping {
  &:before {
    content: $ps-icon-ski-jumping;
  }
}
.ps-icon-snooker {
  &:before {
    content: $ps-icon-snooker;
  }
}
.ps-icon-snowboarding {
  &:before {
    content: $ps-icon-snowboarding;
  }
}
.ps-icon-surfing {
  &:before {
    content: $ps-icon-surfing;
  }
}
.ps-icon-swimming {
  &:before {
    content: $ps-icon-swimming;
  }
}
.ps-icon-table-tennis {
  &:before {
    content: $ps-icon-table-tennis;
  }
}
.ps-icon-taekwondo {
  &:before {
    content: $ps-icon-taekwondo;
  }
}
.ps-icon-tennis {
  &:before {
    content: $ps-icon-tennis;
  }
}
.ps-icon-triathlon {
  &:before {
    content: $ps-icon-triathlon;
  }
}
.ps-icon-volleyball {
  &:before {
    content: $ps-icon-volleyball;
  }
}
.ps-icon-water-polo {
  &:before {
    content: $ps-icon-water-polo;
  }
}
.ps-icon-weightlifting {
  &:before {
    content: $ps-icon-weightlifting;
  }
}
.ps-icon-winter-olympics {
  &:before {
    content: $ps-icon-winter-olympics;
  }
}
.ps-icon-winter-sports {
  &:before {
    content: $ps-icon-winter-sports;
  }
}
.ps-icon-wrestling {
  &:before {
    content: $ps-icon-wrestling;
  }
}

.ps-icon-up-chevron {
  &:before {
    content: $ps-icon-up-chevron;
  }
}
.ps-icon-down-chevron {
  &:before {
    content: $ps-icon-down-chevron;
  }
}

.biab_general-icon {
  font-family: 'PokerStars-General' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ps-icon-up-chevron {
  &:before {
    content: $ps-icon-up-chevron;
  }
}
.ps-icon-down-chevron {
  &:before {
    content: $ps-icon-down-chevron;
  }
}
.ps-icon-left {
  &:before {
    content: $ps-icon-left;
  }
}
.ps-icon-home {
  &:before {
    content: $ps-icon-home;
  }
}
.ps-icon-rg {
  &:before {
    content: $ps-icon-rg;
  }
}
.ps-icon-a-z {
  &:before {
    content: $ps-icon-a-z;
  }
}
.ps-icon-cashout {
  &:before {
    content: $ps-icon-cashout;
  }
}
.ps-icon-faq {
  &:before {
    content: $ps-icon-faq;
  }
}
.ps-icon-guides {
  &:before {
    content: $ps-icon-guides;
  }
}
.ps-icon-in-play {
  &:before {
    content: $ps-icon-in-play;
  }
}
.ps-icon-account {
  &:before {
    content: $ps-icon-account;
  }
}
.ps-icon-my-bets {
  &:before {
    content: $ps-icon-my-bets;
  }
}
.ps-icon-rules {
  &:before {
    content: $ps-icon-rules;
  }
}
.ps-icon-search {
  &:before {
    content: $ps-icon-search;
  }
}
.ps-icon-settings {
  &:before {
    content: $ps-icon-settings;
  }
}

