@import "../../../../styles/mixins/breakpoints";
@import "../../../../styles/variables/variables";

.marketDetailInfo {
  &__top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: 10px;
    margin-bottom: 5px;
    column-gap: 15px;
  }

  &__favourite {
    i {
      display: inline-block;
      height: 32px;
      font-size: 20px;
      line-height: 32px;
      color: #818181;
      text-decoration: none;
    }
  }

  &__rules {
    padding: 0;
    border: 0;
    background-color: transparent;

    i {
      display: inline-block;
      height: 32px;
      cursor: pointer;
      font-size: 20px;
      line-height: 32px;
      color: #1D1D1B;
      text-decoration: none;
      box-sizing: content-box;
    }
  }

  &__refresh {
    border: 0;
    display: inline-block;
    line-height: 16px;
    font-size: $smaller;
    padding: 0 10px;
    background-color: #289fd3;
    color: $white;
    border-radius: 2px;
    vertical-align: top;
    cursor: pointer;

    @include md {
      padding: 2px 7px;
      border-radius: 3px;
    }
  }

  &__matched {
    margin: 3px 11px 0 auto;
    line-height: 16px;
    color: #273a47;
    font-size: 12px;
    text-align: right;

    span {
      display: block;
      font-weight: 700 !important;
    }
  }

  &__rightCol {
    display: flex;
    align-items: center;
    flex: auto;
    column-gap: 15px;
  }
}
