.icon {
    color: #0072AC !important;
    font-size: 24px !important;
}

.messageContainer {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 5px;
    &__mobile {
        align-items: flex-start;
        flex-direction: column;
        gap: 26px;
    }
}

.content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.message {
    color: #303030;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
}

.time {
    color: #303030;
    font-size: 14px;
    font-weight: 700 !important;
    line-height: 20px;
}

.editLink {
    font-size: 12px;
    line-height: 20px;
    text-decoration: underline;
    cursor: pointer;
    &__mobile {
        margin: 0 auto 10px;
    }
}