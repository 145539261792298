@mixin defaultStyles() {
  a:link {
    color: #0075B2;
    text-decoration: underline;
    cursor: pointer;
  }

  a:visited {
    color: #0075B2;
    text-decoration: underline;
    cursor: auto;
  }

  a:link:active {
    color: #0075B2;
  }

  a:visited:active {
    color: #0075B2;
  }

  address {
    display: block;
    font-style: italic;
  }

  area {
    display: none;
  }

  article {
    display: block;
  }

  aside {
    display: block;
  }

  b {
    font-weight: bold;
  }

  bdo {
    unicode-bidi: bidi-override;
  }

  blockquote {
    display: block;
    margin: 1em 40px;
  }

  caption {
    display: table-caption;
    text-align: center;
  }

  cite {
    font-style: italic;
  }

  code {
    font-family: monospace;
  }

  col {
    display: table-column;
  }

  colgroup {
    display: table-column-group;
  }

  datalist {
    display: none;
  }

  dd {
    display: block;
    margin-left: 40px;
  }

  del {
    text-decoration: line-through;
  }

  details {
    display: block;
  }

  dfn {
    font-style: italic;
  }

  div {
    display: block;
  }

  dl {
    display: block;
    margin: 1em 0;
  }

  dt {
    display: block;
  }

  em {
    font-style: italic;
  }

  embed:focus {
    outline: none;
  }

  fieldset {
    display: block;
    margin-left: 2px;
    margin-right: 2px;
    padding: 0.35em 0.75em 0.625em;
    border: 2px groove #303030;
  }

  form {
    display: block;
    margin-top: 0;
  }

  h1 {
    display: block;
    font-size: 2em;
    margin: 0.67em 0;
    font-weight: bold;
  }

  h2 {
    display: block;
    font-size: 1.5em;
    margin: 0.83em 0;
    font-weight: bold;
  }

  h3 {
    display: block;
    font-size: 1.17em;
    margin: 1em 0;
    font-weight: bold;
  }

  h4 {
    display: block;
    margin: 1.33em 0;
    font-weight: bold;
  }

  h5 {
    display: block;
    font-size: .83em;
    margin: 1.67em 0;
    font-weight: bold;
  }

  h6 {
    display: block;
    font-size: .67em;
    margin: 2.33em 0;
    font-weight: bold;
  }

  hr {
    display: block;
    margin: 0.5em auto;
    border-style: inset;
    border-width: 1px;
  }

  i {
    font-style: italic;
  }

  li {
    display: list-item;
  }

  nav {
    display: block;
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin: 1em 0;
    padding-left: 40px;
  }

  p {
    display: block;
    margin: 1em 0;
  }

  q {
    display: inline;
  }

  q::before {
    content: open-quote;
  }

  q::after {
    content: close-quote;
  }

  section {
    display: block;
  }

  small {
    font-size: smaller;
  }

  strike {
    text-decoration: line-through;
  }

  strong {
    font-weight: bold;
  }

  table {
    display: table;
    border-collapse: separate;
    border-spacing: 2px;
    border-color: gray;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  td {
    display: table-cell;
    vertical-align: inherit;
  }

  tfoot {
    display: table-footer-group;
    vertical-align: middle;
    border-color: inherit;
  }

  th {
    display: table-cell;
    vertical-align: inherit;
    font-weight: bold;
    text-align: center;
  }

  thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
  }

  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin: 1em 0;
    padding-left: 40px;
  }
}
