@import "../../styles/variables/variables";
@import "../../styles/mixins/breakpoints";

.marketRunner {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #f2f5f5;

  @include md {
    padding: 3px 0;
  }

  &__tooltip {
    align-self: flex-start;
    text-align: right;
    height: 100%;
    margin-right: 5px;
    padding-top: 5px;
    font-size: 10px;
    z-index: 0;
  }

  &__betContent {
    position: relative;
    width: 100%;
    display: flex;
    max-height: 40px;

    @include md {
      column-gap: 2px;

      .betCellTooltip{
        display: block !important;
      }
    }

    &__withoutLay {
      justify-content: flex-end;
    }
  }

  .cell {
    min-height: 33px;
  }

  .cellContent {
    height: auto;
    min-height: initial;
  }

  .cellOdds {
    padding-top: 4px !important;
  }

  &__betGroup {
    column-gap: 2px;
  }

  @include md {
    border-bottom: 1px solid #f2f5f5;
  }
}
