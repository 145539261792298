.oddEvenViewEvent {
  margin-bottom: 4px;

  &__content {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-bottom: solid 1px #c0c0c0;
  }

  &__nameConteiner {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
  }

  &__firstBlock {
    display: flex;
    flex: 1;
    gap: 10px;
    padding: 0 10px;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    min-height: 60px;
  }

  &__name {
    display: flex;
    font-weight: bold;
    font-size: 14px;
    gap: 10px;
  }

  &__inPlay {
    background-color: #CBECD8;
  }

  &__marketContent {
    display: flex;
    align-items: center;
  }

  &__streaming {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 49px;
    gap: 6px;
    height: 61px;
    border-left: solid 1px #c0c0c0;
  }

  &__wrapper {
    height: 100%;
    border-left: solid 1px #c0c0c0;
  }

  &__cells {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__cell {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 99px;
    min-width: 99px;
    border-left: solid 1px #c0c0c0;
    height: 61px;
  }

  &__cashout {
    column-gap: 0 !important;
  }
  &__cashoutIcon {
    cursor: pointer !important;
  }

  &__rightSection {
    display: flex;
    column-gap: 10px;
    align-items: center;
  }
}
