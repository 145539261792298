@import 'styles/variables/variables.scss';

.alert {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6.5px 10px 6.5px 15px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;

  &__inline {
    border-radius: 0;
  }

  &__mobile {
    gap: 12px;
    padding: 13px 12px;
  }

  &__info {
    border-color: $blue;
  }

  &__warning {
    border-color: $red;
  }

  &__icon {
    font-size: 16px;
  }

  &__message {
    flex: 1 1 auto;
    margin: 0;
    font-size: 12px;
    line-height: 20px;

    &__mobile {
      font-size: 14px;
    }

    span {
      font-weight: 600 !important;
    }
  }

  &__closeBtn {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 25px;
    min-height: 25px;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: #ececec;
    }
  }
}
