@import "styles/variables/variables";
@import "styles/mixins/breakpoints";

.accountButtonsList {
  padding: 0;
  margin: 0;

  li {
    list-style-type: none;
    position: relative;
  }

  a {
    text-decoration: none;
  }

  &__item {
    border-bottom: 1px solid #d8d8d8;
    font-weight: 700 !important;
  }

  &__linkIcon {
    font-size: 15px !important;
  }

  &__labelBlock{
    display: flex;
  }

  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    padding: 0 4px 0 13px;

    label {
      font-weight: bold;
      margin-right: 10px;
    }
  }

  button {
    width: 100%;
    min-height: 40px;
    line-height: 40px;
    text-align: left;
    border-width: 0;
    background-color: inherit;
    padding: 0 0 0 13px;
    color: inherit;
    font-size: inherit;
    font-weight: bold;
    cursor: pointer;
  }
}


.account {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 16px;
    background-color: #3e3e3e;

    &__home {
      margin: 0;
      width: 42px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      box-sizing: border-box;
      color: inherit;

      &__icon {
        font-size: 24px;
        margin: 0;
        color: inherit;
      }

      &:active {
        background-color: #666666 !important;
      }
    }

    &__title {
      font-size: 16px;
      line-height: 1.5;
      font-weight: 700 !important;
      color: inherit;
    }

    &__depositLink {
      color: #fff !important;
      font-size: 14px;
      line-height: 20px;
      text-decoration: underline;
    }

    &__empty {
      width: 42px;
      visibility: hidden;
    }
  }

  &__container {
    padding: 8px 16px 24px;
    background-color: #ececec;
    position: relative;

    &__topBg {
      position: absolute;
      height: 60px;
      top: 0;
      left: 0;
      background-color: #3e3e3e;
      border-radius: 0 0 3px 3px;
      width: 100%;
      z-index: 10;
    }
  }

  &__content {
    position: relative;
    z-index: 20;
    display: flex;
    flex-direction: column;
    gap: 9px;
  }
}

:global {
  .biab_accountPage {
    #header-resize-container {
      position: sticky;
      top: 0;
      z-index: 100;
    }

    &#biab_body {
      overflow: visible;
    }
  }
}
