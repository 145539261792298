.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 50px 0 60px;
  border-bottom: 1px solid #DDDDDD;

  &__mobile {
    padding: 10px 0 50px;
  }
}

.title {
  font-size: 20px;
  font-weight: 700 !important;
  line-height: 30px;
  text-align: center;
  color: #303030;
  padding: 0;
  margin-bottom: 15px;
}

.description {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #303030;
  max-width: 500px;
  padding: 0;
  margin-bottom: 25px;
}

.button {
  width: 300px;
  padding: 9px 20px 11px 20px;
  border-radius: 5px;
  background: #FFB80C;
  border: none;
  font-size: 12px;
  font-weight: 700 !important;
  line-height: 20px;
  text-align: center;
  color: #303030;
  cursor: pointer;

  &:focus {
    border: solid 2px #FF8624;
  }

  &:hover {
    background-color: #ECA803;
  }
}
