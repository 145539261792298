.openBetButtonsWrap {
  display: flex;
  column-gap: 5px;
  margin-top: 15px;
}

.openBetButton {
  padding: 3px 10px;
  font-weight: bold;
  border-radius: 4px;
  border: 0;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 12px;
  line-height: 20px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    border: solid 2px #FF8624;
  }

  &__cancel {
    font-weight: 400;
    background-color: #FFFFFF;
    color: #FF3628;

    &:focus {
      border: solid 2px #C0C0C0;
    }

    &:hover {
      background-color: #F5F5F5;
    }

    &__disabled {
      color: #666666;
      cursor: initial;

      &:hover {
        background-color: #FFFFFF;
      }
    }
  }

  &__take, &__update {
    display: flex;
    flex-direction: column;
    background-color: #ffb900;
    color: #303030;

    &:hover {
      background-color: #ECA803;
    }
  }

  &__update {
    border: 2px solid #FF8624;
    padding-left: 8px;
    padding-right: 8px;

    &__disabled {
      border: 2px solid #FAD06B;
    }
  }

  &__disabled {
    background-color: #FAD06B;
    color: #666666;
    cursor: initial;

    &:hover {
      background-color: #FAD06B;
    }
  }

  &__profit {
    margin-top: -1px;
    font-weight: 400;
    font-size: 10px;
    line-height: 1.5;
  }
}
