@import "styles/variables/variables";
@import "styles/mixins/breakpoints";

.betContentCell {
  min-height: 40px;
  min-width: 40px;
  width: 50%;
  border-width: 0;
  border-left: 1px solid $white;
  padding: 0;
  cursor: pointer;
  position: relative;
  @include md {
    border-radius: 3px;
    border-left: none;
  }

  &:disabled {
    cursor: initial;
  }

  div:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid transparent;
    z-index: 2;
  }

  &.lockIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
  }

  &.doubleWidth {
    width: 100%;
  }

  .betContent {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    min-height: 40px;

    &.hyphen {
      justify-content: center;

      .betOdds {
        padding-top: 0;
      }
    }

    .betOdds {
      padding-top: 7px;
      line-height: 12px;
      font-weight: 600;
    }

    .betAmount {
      padding-top: 2px;
      padding-bottom: 3px;
      white-space: nowrap;
      line-height: 12px;
      font-weight: normal !important;
    }
  }

  @include md {
    width: auto;
  }
}

.deepPriceCell {
  border-left: 1px solid #f2f5f5;
}

.overlayBackground {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  opacity: 0.5;
  z-index: 9;
}

.contents {
  display: contents;
}

.mobileBetContentCellWrapper {
  padding: 3px 1px;
  border-left: 1px solid;

  .betContentCell {
    border-left-width: 0;
  }
}

.makeOffer {
  padding: 2px 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 36px;

  @include md {
    padding: 4px 3px !important;
    box-sizing: border-box;
    min-height: 40px;
    border-radius: 3px;
  }
}

.mobileCellContainer {
  flex: 1 1;

  .betContentCell {
    width: 100%;
  }
}


.tooltip {
  width: inherit;
}

@-webkit-keyframes highlight {
  100% {
    border-color: transparent;
  }
}

@keyframes highlight {
  100% {
    border-color: transparent;
  }
}


.highlighted {
  div:after {
    animation: highlight 600ms;
    animation-fill-mode: forwards;
  }
}

.inlineSelected {
  &:not(.deepPriceCell):after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    transform: translateX(-5px);
    bottom: -5px;
  }

  &__back {
    &:after {
      border-top: 5px solid #75C2FD;
    }
  }

  &__lay {
    &:after {
      border-top: 5px solid #F694AA;
    }
  }
}
