.details {
  &__infoMsg {
    border: 1px solid #0072AC;
    border-radius: 4px;
    padding: 13px 12px;
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 8px;

    &__label {
      color: #303030;
      font-size: 14px;
      line-height: 20px;
      margin: 0;
    }

    &__icon {
      font-size: 16px;
    }
  }

  &__topBlock {
    background-color: #f5f5f5;
    padding: 15px 16px 17px 16px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__name {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 700 !important;
    color: #303030;

    &__bottom {
      font-size: 14px;
      line-height: 20px;
      color: #666666;
      margin: 0;
    }
  }

  &__selectionBlock {
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px 0 16px 16px;
    column-gap: 12px;
    border-bottom: 1px dashed #dddddd;
    justify-content: space-between;

    &__mt {
      margin-top: 8px;
    }

    &__back, &__lay {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 16px;
        width: 4px;
        height: calc(100% - 32px);
        border-radius: 360px;
      }
    }

    &__back:before {
      background-color: #A6D8FF;
    }

    &__lay:before {
      background-color: #FAC9D1;
    }

    &__left, &__right {
      display: flex;
      flex-direction: column;
    }

    &__left {
      gap: 8px;
    }

    &__right {
      gap: 4px;
      align-items: flex-end;
    }

    &__name, &__price, &__marketName {
      margin: 0;
      font-size: 16px;
      line-height: 1.5;
      color: #303030;
    }

    &__name, &__price {
      font-weight: 700 !important;
    }

    &__price {
      display: flex;
      column-gap: 12px;

      &__icon {
        font-size: 20px;
      }
    }

    &__origin {
      padding: 1px 8px 3px 8px;
      border-radius: 4px;
      font-size: 14px;
      line-height: 20px;
      background-color: #ececec;
      color: #303030;
      margin: 0;
      width: fit-content;
    }

    &__status {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 6px;
      font-size: 16px;
      line-height: 1.5;
      color: #303030;
      margin: 0;

      &__dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #666666;
      }

      &__label {
        &__won {
          color: #04A04A;

          &__dot {
            background-color: #04A04A;
          }
        }

        &__lost {
          color: #FE3629;

          &__dot {
            background-color: #FE3629;
          }
        }
      }
    }

    &__cashOut {
      background-color: #FFB80C;
      padding: 1px 8px 3px 8px;
      color: #303030;
      font-weight: 700 !important;
      border-radius: 4px;
      font-size: 14px;
      line-height: 20px;
      width: fit-content;
      margin: 0;
    }

    &__side {
      color: #666666;
      margin: 0;
      font-size: 14px;
      line-height: 20px;
    }

    &__eachWay {
      margin: 0;
      font-size: 16px;
      line-height: 1.5;
      color: #666666;
    }

    &__depositWithdrawal {
      padding-left: 0;
      padding-top: 0;
    }

    &__commission {
      padding-left: 0;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 4px;
    box-sizing: border-box;
    padding: 16px 0;
    max-height: 56px;

    &__border {
      border-bottom: 1px solid #dddddd;
    }

    &__label, &__value {
      margin: 0;
      font-size: 16px;
      line-height: 1.5;
    }

    &__label {
      color: #666666;
    }

    &__value {
      color: #303030;
      display: flex;
      align-items: center;

      &__green {
        color: #04A04A;
      }

      &__red {
        color: #FE3629;
      }

      &__icon {
        color: #303030;
        font-size: 24px;

        &__copyWrapper {
          all: unset;
          padding: 9px;
          cursor: pointer;

          &:active {
            background-color: #ececec;
            border-radius: 50%;
          }
        }
      }
    }
  }

  &__tooltipTextCopied {
    font-size: 16px !important;
  }
}