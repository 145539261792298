.keyboard {
  display: flex;
  gap: 6px;

  &__digitsAndDot, &__firstRow, &__secondRow {
    display: flex;
    gap: 6px;
  }

  &__digitsAndDot {
    flex-direction: column;
    flex: 1;

    &__landscape {
      flex-direction: row;
    }
  }

  &__key {
    all: unset;
    border: 1px solid #C0C0C0;
    background-color: #F5F5F5;
    color: #303030;
    font-size: 14px;
    line-height: 1.42;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    flex: 1;
    padding: 7px 0;
    transition: 0.3s all ease;

    &:disabled {
      border-color: #c0c0c0;
      background-color: #f5f5f5;
      color: #999;
    }
  }

  &__clearBtn {
    max-width: 14%;
    font-size: 20px;
    padding: 0;

    &__landscape {
      max-width: none;
    }
  }
}