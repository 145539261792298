//is Ios bundle
$isIosBundle: false;

// colors
$yellow: #ffd235;
$red: #fe3629;
$green: #4db375;
$black: #000000;
$black-light: #1e1e1e;
$white: #ffffff;
$blue-dark: #005f8f;
$blue: #0072ac;
$blue-light: #1d98d3;
$dark-gray: #303030;
$light-gray: #7f7f7f;
// font-sizes
$smaller: 10px;
$small: 12px;
$regular: 14px;
$middle: 18px;

// bg colors
$bg-gray: #ececec;
$yellow-bg: #ffb80c;
$blue-bg: #29a7e1;
$black-middle-bg: #2b2b2b;
$gray-bg: #424242;
$light-red: #ff7289;
$bg-dark: #3e3e3e;
$bg-dark-light: #666;
$bg-white: #fff;

// borders
$border-black: #3e3e3e;
$border-dark-gray: #c0c0c0;
$border-gray: #ddd;

// icons
$icon-white: #fff;
$icon-gray: #999;
$icon-black: #303030;
$icon-black-disabled: #c0c0c0;

// text
$text-gray-light: #999;
$text-black: #303030;
$text-gray-dark: #666;

// radius
$radius-main: 4px;
$radius-secondary: 2px;

// asian view
$asian-view-pop-up-max-width: 744px;
// For next pop-ups: bet max, betting day, auto cash out, partial cash out, login, video/statistics, favourites
$pop-up-max-width: 600px;
