.footer {
  height: 50px;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ECECEC;
  box-sizing: border-box;
  font-size: 12px;
  border-radius: 0 0 4px 4px;

  &__mobile {
    height: auto;
    padding: 12px 20px;
    font-size: 14px;
  }
}

.buttonsGroup {
  display: flex;
  gap: 10px;

  &__mobile {
    width: 100%;
  }
}

.button {
  width: 100px;
  height: 25px;
  border: 0;
  border-radius: 2px;
  font-size: 10px;
  font-weight: bold;
  color: #212121;
  cursor: pointer;

  &__disabled {
    opacity: 0.5;
  }

  &__apply {
    background-color: #FFB80C;
  }

  &__cancel {
    background-color: #c0c0c0;
  }

  &__mobile {
    width: 50%;
    height: 48px;
    font-size: 12px;
    font-weight: bold !important;
  }
}

.selectAll {
  label {
    cursor: pointer;
  }

  input {
    vertical-align: middle;
    cursor: pointer;
  }

  span {
    margin-left: 5px;
  }
}
