.defaultStake {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;

  &__button, &__input {
    all: unset;
    height: 30px;
    border-radius: 4px;
    flex: 1;
    box-sizing: border-box;
    text-align: center;
    color: #303030;

    &__mobile {
      height: 42px;
      font-size: 16px;
      line-height: 1.5;
    }
  }

  &__button {
    background-color: #ececec;
    cursor: pointer;

    &__active {
      background-color: #0072ac;
      color: #fff;
    }

    &__inactive {
      &:hover {
        background-color: #ddd;
      }
    }

    &__mobile {
      &__hint {
        color: #999;
      }
    }
  }

  &__input {
    border: 1px solid #ddd;
    min-width: 0;

    &:hover, &:focus {
      border-color: #3e3e3e;
    }

    &__error {
      border-color: #FE3629;

      &:hover {
        border-color: #FE3629;
      }
    }
  }

  &__error {
    margin-top: 10px;
  }

  &__notSavedError {
    margin-top: 15px;
  }
}
