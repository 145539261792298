@import 'styles/variables/variables';

.headerNavigationMobileWrapper {
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.headerNavigationMobileLinks {
  display: flex;
  align-items: center;
  overflow-x: auto;
  padding: 5px;
  column-gap: 5px;
  width: fit-content;
  list-style: none;
  margin: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
