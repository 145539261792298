.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.navigationContainer {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 35px;
  height: 30px;
}

.icon {
  font-size: 16px;
  color: inherit;

  &:before {
    color: inherit !important;
  }
}
