@import 'src/styles/mixins/breakpoints';

.title {
  font-weight: bold;
  padding: 9.5px 15px;
  align-items: center;
  display: flex;
  justify-content: space-between;

  .iconsWrapper {
    display: flex;

    .questionMark {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 5px;
      padding: 5px 10px;
      border: 0;
      border-radius: 2px;
      font-size: 14px;
    }
  }

  @include md {
    i {
      margin-right: 10px;
    }
  }
}

.cashOutList {
  width: 50%;
  padding: 0 10px 10px 10px;
  @include md {
    padding: 0;
    width: 100%;
  }
}

.listsMain {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  @include md {
    padding-top: 0;
    flex-direction: column;
  }
}

.empty {
  padding: 20px;
  text-align: center;
}

.loaderOverlay {
  padding: 20px;
}

.showSkeleton {
  height: 800px;
  overflow: hidden;
}
