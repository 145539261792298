@import 'styles/variables/variables.scss';

.table {
  border-spacing: 0;
  width: 100%;
  color: #303030;
  border-collapse: initial;
  table-layout: fixed;

  td,
  th {
    border-color: #dddddd;
    border-style: solid;
    border-width: 0;
    border-top-width: 1px;
    border-left-width: 1px;
    height: 25px;
    text-align: center;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 1.5;
    cursor: default;

    &:last-of-type {
      border-right-width: 1px;
    }

    &:not(:first-of-type) {
      min-width: 70px;
      width: 70px;
      max-width: 70px;
    }
  }

  tbody tr {
    &:last-of-type {
      td {
        border-bottom-width: 1px;

        &:first-of-type {
          border-bottom-left-radius: 4px;
        }
      }

      td:last-of-type {
        border-bottom-right-radius: 4px;
      }
    }

    td:first-of-type,
    &__awayCell {
      width: 60px;
      min-width: 60px;
      max-width: 60px;
    }
  }

  th {
    font-weight: 700 !important;

    &:first-of-type {
      width: 60px;
      min-width: 60px;
      max-width: 60px;
    }
  }

  td {
    &.positive {
      color: $green;
    }

    &.negative {
      color: $red;
    }

    &.currentScore {
      background-color: #fff3d4;
    }
  }

  &__homeGreyCell {
    background-color: #f5f5f5;
  }

  &__awayGreyCell {
    background-color: #ececec;
    font-weight: 700 !important;

    &__mobile {
      position: sticky;
      left: 0;
    }
  }

  &__container {
    margin-bottom: 20px;

    &__mobile {
      overflow: auto;
      max-height: 190px;
      overscroll-behavior: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__header {
      border-radius: 4px 4px 0 0;
      padding: 5px 10px;
      background-color: #dddddd;
      box-sizing: border-box;
      border: 1px solid #dddddd;
      display: flex;
      align-items: center;
      column-gap: 10px;
      outline: none;
      cursor: pointer;
      width: 100%;

      span {
        font-size: 14px;
        line-height: 1.5;
        color: #303030;
        font-weight: 600 !important;
      }

      &__icon {
        font-size: 16px;
      }

      &__collapsed {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  &__stickyCell {
    position: sticky;
  }

  &__homeTitle__cell__mobile,
  &__emptyCell {
    top: 0;
    z-index: 100;
  }

  &__awayTitleCell,
  &__homeGoalNumberCell {
    top: 25px;
    z-index: 50;
  }

  &__awayTitleCell,
  &__emptyCell {
    z-index: 101;
  }

  &__homeTitle {
    position: sticky;
    left: 61px;
    margin: 0;
    padding: 1px;
    max-width: calc(100dvw - 131px);

    &__cell {
      min-width: initial !important;
      width: initial !important;
      max-width: initial !important;

      &__mobile {
        padding: 0;
      }
    }
  }

  &__mobile {
    table-layout: initial;

    td,
    th {
      &:not(:first-of-type) {
        min-width: 68px;
        width: 68px;
        max-width: 68px;
      }
    }
  }
}
