@import "/src/styles/variables/variables";
@import "/src/styles/mixins/breakpoints";

.marketSelections {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  box-sizing: border-box;

  &__title {
    display: flex;
    padding-left: 10px;
    align-items: center;
    font-size: $smaller;
    color: #273a47;
    flex-grow: 1;
  }

  &__divisor {
    margin-left: 8%;
    font-weight: bold;

    @include md {
      margin-left: 10px;
    }
  }

  &__showAll, &__minimize, &__reset {
    margin: 0 0 0 10px;
    padding: 3px 6px;
    border: 0;
    font-size: 11px;
    color: #fff;
    background-color: #0074b1;
    border-radius: 3px;
    cursor: pointer;
    outline: 0;
  }

  &__reset_disabled {
    pointer-events: none;
  }
}

.marketActions__pl {
  width: 100%;
}

.marketActions {
  display: flex;
  align-items: center;
  height: 24px;
  margin: 10px 0;
  padding: 5px 10px;
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee;

  @include md {
    padding-right: 0;
  }

  &__recordsButtons {
    padding: 4px 0;
  }

  &__view {
    font-weight: bold !important;
    color: #2f9ed3;
    padding-right: 5px;
    font-size: 12px;
  }

  &__minimize, &__full {
    display: inline-block;
    padding: 3px 6px;
    height: 16px;
    min-width: 70px;

    border-width: 1px;
    border-style: solid;
    border-color: #2aabe4;
    border-radius: 3px;
    color: #2aabe4;
    background-color: #fff;
    font-size: 11px;
    box-sizing: content-box;
  }

  &__minimize {
    margin-left: 10px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: none;
  }

  &__full {
    background: transparent;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: 0;
  }

  &__rightBtn {
    display: flex;
    margin-left: auto;
  }

  &__PLTable {
    display: inline-block;
    margin-left: 10px;
    font-size: 10px;
    background-color: #2aabe4;
    padding: 5px 10px;
    border-radius: 3px;
    color: #FFFFFF;
    cursor: pointer;
  }

  &__reset {
    display: inline-block;
    height: inherit;
    margin-left: auto;
    padding: 5px 10px;
    font-size: 10px;
    background-color: #2aabe4;
    border-radius: 3px;
    color: #FFFFFF;
    cursor: pointer;
    border: none;

    &__disabled {
      opacity: 0.7;
    }
  }

  &__plTable {
    display: inline-block;
    margin-left: auto;
    padding: 4px 10px;
    height: 16px;
    font-size: 10px;
    background-color: #2aabe4;
    border-radius: 3px;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
    box-sizing: content-box;

    &__combinedView {
      margin-left: 10px;
    }

    &__padding {
      margin-left: 8px;
    }
  }
}
