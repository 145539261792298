@import "styles/variables/variables";

.loader {
  position: relative;
  display: flex;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  transform: scale(0.3);
}

.loader div {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

@keyframes inlarge1 {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

.circle {
  animation: inlarge1 1s infinite ease-in-out both;
}

.circle1 {
  top: 0;
  left: 50px;
}

.circle2 {
  top: 15px;
  left: 85px;
  animation-delay: 0.125s;
}

.circle3 {
  top: 50px;
  left: 100px;
  animation-delay: 0.25s;
}

.circle4 {
  top: 85px;
  left: 85px;
  animation-delay: 0.375s;
}

.circle5 {
  top: 100px;
  left: 50px;
  animation-delay: 0.5s;
}

.circle6 {
  top: 85px;
  left: 15px;
  animation-delay: 0.625s;
}

.circle7 {
  top: 50px;
  left: 0;
  animation-delay: 0.75s;
}

.circle8 {
  top: 15px;
  left: 15px;
  animation-delay: 0.825s;
}

.circle-white div {
  background-color: $white;
}

.circle-black div {
  background-color: $black;
}

.circle-dark-gray div {
  background-color: $dark-gray;
}

.circle-blue div {
  background-color: $blue;
}

.circle-red div {
  background-color: $light-red;
}

.circle-blue-2 div {
  background-color: #0072AC;
}
