.settings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  padding: 10px;
  line-height: 15px;
  border: solid 1px #dddddd;
  border-radius: 5px;
  border-color: inherit;
}
