.popularMarketSportBetContent {
  .betContentContainer {
    .betContentCellMarket {
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: auto;
    }
  }

  .row {
    &:last-of-type {
      border-bottom: none;
    }
  }

  .tableHeaderMarket {
    .betContentColumnsMarket {
      .betContentColumnMarket {
        min-width: auto;
      }
    }
  }
}

.popularMarketSportMobile {
  .rowMarket {
    border-top: none;

    &:last-of-type {
      border-bottom: none;
    }
  }
}
