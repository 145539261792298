.menuBtn {
  min-width: 42px;
  color: #fff;
  height: 42px;
  border-radius: 4px;
  border: 1px solid #666666;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 16px;
  background: none;
}
