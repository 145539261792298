.selectedBetsSubHeader {
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 25px;
  padding: 0 10px;
  font-size: 10px;
  color: #666666;
  box-sizing: border-box;

  &__back {
    background-color: #a6d8ff;
  }

  &__lay {
    background-color: #fac9d4;
  }

  &__odds {
    min-width: 60px;
  }

  &__size,
  &__profit {
    min-width: 62.5px;
  }

  p {
    margin: 0;
  }

  > * {
    flex: 2;
    text-align: center;
    box-sizing: border-box;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      display: flex;
      flex: 3;
      align-items: center;
      justify-content: space-between;

      > * {
        flex: 1;
        text-align: center;
        padding: 5px 0;
        font-size: 10px;
        line-height: 12px;
      }
    }
  }
}

.dutching {
  &__wrapper {
    position: relative;
  }

  &__btn {
    all: unset;
    color: #0072ac;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    &__underline {
      text-decoration: underline;
    }
  }
}
