@import "src/styles/mixins/breakpoints";

.eventInfo {
  display: flex;
  align-items: center;
  column-gap: 8px;

  &__title {
    margin: 0 0 6px 0;
    font-weight: 700 !important;
  }

  &__inPlay {
    margin-left: 4px;
    font-weight: bold !important;
  }

  &__date {
    margin: 0;
    font-weight: 100 !important;
    @include md {
      white-space: nowrap;
    }
  }

  &__raceDetails {
    display: flex;
    align-items: center;
  }

  &__backButton {
    padding-left: 5.5px;
    padding-right: 0;
  }
}
