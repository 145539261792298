@import 'styles/variables/variables.scss';

.stepper {
  display: flex;

  &__button {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 42px;
    height: 42px;
    background-color: $bg-gray;
    border: 1px solid $border-dark-gray;

    i {
      font-size: 20px;

      &::before {
        color: $icon-black;
      }
    }

    &__focused {
      border-color: $border-black;
    }

    &__disabled {
      i {
        &::before {
          color: $icon-black-disabled;
        }
      }
    }

    &__invalid {
      border-color: $red;
    }

    &__increase {
      border-radius: 0 4px 4px 0;
      border-left-width: 0;
    }

    &__decrease {
      border-radius: 4px 0 0 4px;
      border-right-width: 0;
    }
  }

  &__input {
    flex: 1;
    padding: 0;
    border: none;
    text-align: center;
    color: $text-black;
    font-size: 14px;
    font-weight: 700 !important;
    line-height: 20px;
    align-self: stretch;

    &::placeholder {
      color: $text-gray-light;
      font-weight: 400;
    }

    &:focus-visible {
      outline: none;
    }

    &__withValue {
      margin-top: -1px;
    }
  }

  &__label {
    color: $text-gray-light;
    font-size: 12px;
    line-height: 20px;
    text-align: center;

    &__empty {
      position: absolute;
      top: 50%;
      left: 5px;
      right: 5px;
      transform: translate(0, -50%);
      font-size: 12px;
      color: #999999;
      word-break: break-all;
      text-align: center;
    }

    &__overflow {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__inputWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    box-sizing: border-box;
    height: 42px;
    background-color: #fff;
    border-top: 1px solid $border-dark-gray;
    border-bottom: 1px solid $border-dark-gray;

    &__invalid {
      border-color: $red;
    }

    &__focused {
      border-color: $border-black;
    }
  }
}
