@import "../../styles/mixins/breakpoints";

.collapseToggle {
  display: flex;
  align-items: center;
  column-gap: 5px;
  box-sizing: content-box;
  line-height: 30px;
  font-size: 11px;
  cursor: pointer;
  padding-inline: 10px;
  @include md {
    font-weight: bold;
  }

  &__right {
    flex-direction: row-reverse;
  }

  &__chevron {
    transform: rotate(180deg);

    &__opened {
      transform: rotate(0deg);
    }
  }
  .title {
    font-weight: bold;
  }
}
