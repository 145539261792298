.asianViewBackLayLabels {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 5px;
  column-gap: 4px;
  cursor: help;

  &__smallGap {
    min-width: 72px;
    column-gap: 2px;
  }

  &__mobileCondtainer {
    margin-bottom: 0;
    margin-top: 0;
  }

  &__lay, &__back {
    border-radius: 2px 2px 0 0;
    background-color: #f5f5f5;
    border: solid 1px #c0c0c0;
    width: 40px;
    max-width: 40px;
    box-sizing: border-box;
    line-height: 17px;
    font-size: 10px;
    text-align: center;
  }

  &__small {
    width: 100%;
    max-width: 35px;
  }

  &__mobile {
    min-width: 46px;
    background: none !important;
    border: none;
    max-width: 100%;
  }
}
