.container {
  position: fixed;
  top: 20px;
  left: 12px;
  right: 12px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.notification {
  border: solid 1px #0072ac;
  border-radius: 4px;
  font-size: 12px;
  overflow: hidden;
  line-height: 20px;
  background-color: #fff;
  opacity: 0;
  transform: translateY(-100%);
  animation: slideIn 0.5s ease forwards;
  box-shadow: 0 2px 8px rgba(#14204b, 25%);

  &__hidden {
    display: none;
  }
  &__success {
    border-color: #04a04a;
  }
  &__error {
    border-color: #fe3629;
  }
  &__info {
    border-color: #0072ac;
  }
  &__body {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 7px 15px;
  }
  &__loading {
    color: #0072ac;
  }
  &__icon {
    font-size: 16px;
    &__unmatched:before {
      color: #fe3629;
    }
  }
  &__closeIcon {
    margin-left: auto;
    cursor: pointer;
    font-size: initial;
    color: inherit;
  }
  &__errorText {
    font-size: 14px;
    line-height: 20px;
    color: inherit;
    margin-top: 8px;
    margin-bottom: 0;
  }
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.timeMsg {
  background-color: #f5f5f5;
  padding: 5px 15px;
  margin: 0;
}

.text {
  margin: 0;
  strong {
    font-weight: 700 !important;
  }
}
.moreInfo {
  color: #0072ac;

  a {
    color: #0072ac;
  }
}

.bold {
  font-weight: 700 !important;
}
