.tab {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  background-color: #3E3E3E;
  color: #FFFFFF;
  border-top: 1px solid #666666;
  border-bottom: 1px solid #666666;
  border-right: 1px solid #666666;
  border-left-width: 0;
  font-size: 12px;
  font-weight: 500;
  padding: 0;
  box-sizing: border-box;
  &.selected {
    background-color: #F5F5F5;
    color: #212121;
    border-top-color: #FFB80C;
    border-top-width: 2px;
    border-bottom-width: 0;
    border-right-width: 0;

    &:hover {
      border-top: 2px solid transparent;
    }
  }
  &:hover {
    border-width: 0;
  }
  &__counter {
    margin-left: 5px;
    height: 14px;
    font-size: 10px;
    line-height: 14px;
    font-weight: bold;
    padding: 0 2px;
    border-radius: 1.4px;
    color: #212121;
    background-color: #FFB80C;
  }
  &:last-child {
    border-right-width: 0;
  }
  &.opened {
    border-bottom-width: 0;
  }
}
