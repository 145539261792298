.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .tab {
      cursor: pointer;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      background-color: #3E3E3E;
      color: #FFFFFF;
      border-top: 1px solid #3E3E3E;
      font-size: 12px;
      font-weight: 500;
      text-decoration: none;
      &.selected {
        background-color: #ECECEC;
        color: #212121;
        border-top-color: #FFB80C;
        .live {
          color: #ECECEC;
        }
      }
      .live {
        background-color: #04A04A;
        border-radius: 1px;
        padding: 2px;
        margin-left: 5px;
        line-height: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .tabContent {
    width: 100%;
    .todayHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 24px;
      background-color: #DDDDDD;
      .todayHeaderLeft {
        margin-left: 5px;
      }
      .todayHeaderRight {
        margin-right: 7px;
      }
    }
  }
  .listItem {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    background-color: #F5F5F5;
    border-bottom: 1px solid #ffffff;
    cursor: pointer;
    font-weight: 400;
    text-decoration: none;
    .listItemLeft {
      margin-left: 25px;
    }
    .listItemRight {
      margin-right: 5px;
    }
    .listItemRight, .listItemLeft {
      margin-top: 0;
      margin-bottom: 0;
    }
    &.active {
      font-weight: 600;
      background-color: #ECECEC;
    }
  }
}

.icon {
  margin-left: 5px;
  margin-right: 4px;
  font-size: 11px;
}

.sports__collapse {
  line-height: 25px;
  border-bottom: 1px solid #3E3E3E;
}
