.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  width: 120px;
  height: 40px;
  font-size: 12px;
  font-weight: 700;
  border: 0;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  vertical-align: middle;
  text-transform: capitalize;
  z-index: 1;

  &:focus {
    border: solid 2px #FF8624;
  }

  &__profit {
    font-size: 10px;
    font-weight: normal;
  }

  &__fullWidth {
    width: 100%;
  }

  &__confirm {
    border: 2px solid #FF8624;
  }

  &__take_offer {
    background-color: #FFB80C;
  }

  &__edit_unmatched, &__update {
    background-color: #FFB80C;
  }

  &__cancel_unmatched {
    background-color: #ECECEC;
    color: #FE3629;
  }

  &__edit {
    all: unset;
    cursor: pointer;
    width: 25px;
    height: 25px;
    text-align: center;
    border: 2px solid transparent;
    box-sizing: border-box;

    &:hover {
      background-color: #FFFFFF;
      border-radius: 50%;
    }

    &:focus {
      border: 2px solid #3E3E3E;
      border-radius: 4px;
    }
  }

  &__disabled {
    background-color: #FAD06B;
    color: #666666;
    cursor: initial;
  }

  &__editIcon {
    font-size: 16px;
  }

  &__acceptOdds {
    border: 2px solid #FF8624;
  }
}
