.netCommission {
  display: inline-flex;
  align-items: center;

  &__container {
    display: flex;
    align-items: center;
    &__mobile {
      align-items: flex-start;
      flex-direction: column;
      gap: 18px;
    }
  }

  &__label {
    line-height: 13px;
  }
}

.title {
  font-size: 16px;
  font-weight: 700 !important;
  line-height: 24px;
}

.settings__item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  &__label {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
}
