@import 'src/styles/variables/variables';
@import 'src/styles/mixins/breakpoints';

.link {
  display: flex;
  justify-content: center;
  flex: 1;
  color: $text-black !important;
  text-decoration: none;

  &:hover {
    text-decoration: underline;

    .image {
      filter: brightness(60%);
    }
  }
}

.card {
  margin: 0;
  padding: 0;
  max-width: 300px;

  &__mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  @media (max-width: $lg) {
    width: 100%;
    max-width: none;
  }
}

.image {
  width: 100%;
  height: 170px;
  aspect-ratio: 30/17;
  border-radius: $radius-main;
  object-fit: cover;

  &__hide {
    display: none;
  }

  &__mobile {
    height: 110px;
    width: 100%;
  }

  &__scroll {
    max-width: none !important;
  }

  @media (max-width: $sm) {
    max-width: 232px;
  }

  @media (max-width: $xs) {
    width: 180px;
  }
}

.title {
  padding: 5px 0;
  font-size: 16px;
  text-align: center;
  font-weight: 700 !important;
  line-height: 25px;
  color: #303030;

  @media (max-width: $md) {
    padding: 10px 0;
    font-size: 18px;
  }

  @media (max-width: $sm) {
    column-gap: 20px;
  }

  &__mobile {
    font-size: 16px;
  }
}
