.refreshButton {
  all: unset;
  padding: 3px;
  color: #000000;
  border-radius: 50%;
  font-weight: bold;
  border: none;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    background-color: #ECECEC;
  }

  &__disabled {
    color: #C0C0C0;
    cursor: initial;

    &:hover {
      background-color: transparent;
    }
  }
}

.icon {
  font-size: 24px;
  color: inherit;
}
