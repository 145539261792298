.inlineSelectedBetWrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
}

.inlineSelectedBet {
  position: relative;
  padding: 10px 15px;
  padding-right: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  min-height: 90px;
  box-sizing: border-box;

  &__shortView {
    padding: 15px;
  }

  &__inner {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    flex: 1 1 auto;

    &__shortView {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: auto;
  }

  &__back {
    background-color: #d4ecff;
  }

  &__lay {
    background-color: #faebed;
  }

  &__topButton {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__placed {
    border: 1px solid #fe3629;
  }

  &__actions {
    &__shortView {
      width: 100%;
    }
  }

  &__disabled {
    background-color: #f5f5f5;
  }
}
