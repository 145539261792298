.notification {
  margin: 0;

  &__price {
    font-weight: 700 !important;

    &__more {
      color: #04A04A;
    }

    &__less {
      color: #FE3629;
    }
  }

  &__icon {
    font-size: 6px;
    margin-left: 4px;
    margin-bottom: 1px;
    line-height: 1;
    vertical-align: middle;

    &__more {
      color: #04A04A;
    }

    &__less {
      color: #FE3629;
    }
  }

  &__oddsValues {
    white-space: nowrap;
  }

  &__arrow {
    font-size: 8px;
    display: inline-block;
    position: relative;
    top: -1px;
    margin: 0 6px;
  }
}