.actionsContainer {
  display: flex;
  margin: 15px 10px;
  gap: 10px;

  &__actions {
    flex: 1 1 auto;
    display: flex;
    gap: 10px;
  }
}

.action {
  flex: 1 1 50%;

  &__button {
    width: 100%;
  }

  &__icon {
    font-size: 14px;
    color: currentColor !important;

    &__sorting {
      font-size: 16px;
    }

    &__chevron {
      transform: rotate(180deg);

      &::before {
        color: currentColor !important;
      }
    }
  }
}

.dropdownItem {
  &__active {
    font-weight: bold;
  }
}
