.container {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background: #303030;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  color: #fff;
  box-sizing: border-box;
  height: 40px;
  padding: 8px 10px;
  border-top: 1px solid #666;
  top: 40px;
  z-index: 11;
  position: sticky;

  &__hidden {
    display: none;
  }
}

.timerIcon {
  color: #dd4f46;
  font-size: 16px;
}

.live {
  padding: 0 4px;
  color: #fff;
  border-radius: 2px;
  background-color: #04a04a;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  margin: 0;
}

.time {
  display: flex;
  height: 25px;
  font-size: 14px;
  padding: 0 4px;
  align-items: center;
  gap: 5px;
  border-radius: 3px;
  background-color: #3e3e3e;
}

.score {
  padding: 0 4px;
  border-radius: 3px;
  border: 1px solid #444;
  background-color: #3e3e3e;
  color: #04a04a;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  margin: 0;
  box-sizing: border-box;
}
