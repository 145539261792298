.separator {
  margin: 12px 12px 6px 12px;
  height: 1px;
  background-color: #c0c0c0;
}

.backLay {
  display: flex;
  padding: 0px 4px;
  &__wrapper {
    display: flex;
    text-align: center;
    width: 100%;
    padding: 5px 6px 0px 6px;
    & > div {
      flex: 1;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      background: none;
    }
  }
}

.selections {
  display: flex;
  padding: 6px 4px 0px 4px;
  &.padding {
    padding-top: 12px;
  }
  & > div {
    flex: 1;
    padding: 0px 6px;
    & > div {
      gap: 4px;
      margin: 0px;
      & > span {
        flex: 1;
        & > button {
          width: 100%;
        }
      }
    }
  }
}
