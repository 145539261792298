.mobileMarketTabs {
  width: 100%;
  padding-bottom: 10px;
  padding-top: 5px;

  &__list {
    display: flex;
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    height: fit-content;
    background-color: #efefef;
  }

  &__link {
    padding: 0 10px;
    min-width: 16.1%;
    border-top: 2px solid #f0f1f5;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    height: fit-content;
    color: inherit !important;
    text-decoration: none;
    font-weight: bold !important;
  }

  &__allMarkets {
    padding-bottom: 0;
  }
}
