@import "styles/variables/variables";

.placeholder {
  position: relative;
  width: 220px;
  height: 30px;
  padding: 10px;
  background-color: $bg-white;
  border: 1px solid $border-gray;
  border-radius: 4px;
  font-size: 12px;
  text-transform: none;
  box-sizing: border-box;

  &::after {
    top: 50%;
    right: 20px;
    color: inherit;
    content: "\e92c";
    font-size: 16px !important;
  }

  &__default {
    color: $text-gray-light;
  }

  &:hover:not(.disabledDropdown) {
    border-color: #3E3E3E;
  }
}

.activePlaceholder {
  border-color: #3E3E3E;
}

.dropdown {
  margin-top: 5px;
  border-radius: 4px;
  background: $bg-white;
  border-color: $border-gray;
  box-sizing: border-box;
  box-shadow: 0 2px 8px 0 rgba(24, 30, 92, 0.15);
}

.selectedOption {
  font-weight: bold;

  &::after {
    font-family: 'custom-icon-font', serif;
    content: '\e922' !important;
    position: absolute;
    right: 10px;
    font-size: 16px;
    font-weight: normal;
  }
}

.disabledDropdown {
  opacity: 0.5;
  cursor: initial;
}

.tooltipIcon {
  color: #303030;
}
