.container {
  display: flex;
  flex-direction: column;
  row-gap: 26px;
  padding: 16px 12px 0;

  &__top {
    height: 20px;
  }

  &__middle {
    display: flex;
    align-items: center;
    column-gap: 24px;

    &__left {
      height: 42px;
      width: 60px;
    }

    &__right {
      flex: 1;
      height: 28px;
    }
  }

  &__bottom {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 48px;
    row-gap: 26px;

    &__item {
      height: 50px
    }
  }
}