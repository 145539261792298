@import 'src/styles/mixins/breakpoints';

.containerWrapper {
  display: flex;
  justify-content: center;
}
.container {
  display: flex;
  width: fit-content;
  min-width: 180px;
  padding: 8px;
  margin: 10px;
  text-align: left;
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  border-radius: 2px;
  box-shadow: 4px 4px 10px -2px #1e1e1e;
  justify-content: center;
  align-items: center;
  .innerWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .cardCounter {
    font-weight: 600;
  }
  .tableContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    border-left: solid 1px #cccccc;
    margin-top: 3px;
    @include md {
      width: 100%;
    }
    .tableRow {
      display: flex;
      flex-direction: column;
      width: 20px;
      @include md {
        width: 7.69%;
      }
      .tableCell {
        height: 20px;
        border-bottom: 1px solid #cccccc;
        border-right: 1px solid #cccccc;
        display: flex;
        justify-content: center;
        align-items: center;
        &:first-of-type {
          border-top: 1px solid #cccccc;
        }
      }
    }
  }
}
