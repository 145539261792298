@import "/src/styles/variables/variables";
@import "/src/styles/mixins/breakpoints";

.marketLineRangeInfo {
  display: flex;
  align-self: start;
  width: 100%;
  padding-top: 5px;
  padding-left: 10px;
  font-weight: bold !important;
  font-size: 10px;
  box-sizing: border-box;
}
