@import "src/styles/variables/variables";
@import "src/styles/mixins/breakpoints";

.searchContent {
  display: flex;
  justify-content: space-between;
  @include md {
    flex-direction: column-reverse;
  }

  &__markets {
    display: flex;
    flex-direction: column;
    width: 50%;
    @include md {
      width: 100%;
    }
  }

  &__market {
    font-size: $small;
    margin-bottom: 3px;
    text-decoration: none;
    transition: 0.1s;
    line-height: 30px;
    margin-left: 10px;
    @include md {
      border-top: solid 1px #eee;
      margin: 0;
      padding: 0 10px;
    }

    &:hover {
      text-decoration: underline;
    }

    &:last-child {
      margin-bottom: 0;
    }

    i {
      margin-left: 2px;
      color: $light-gray;
      font-size: $smaller;
      line-height: 30px;
      @include md {
        float: right;
      }
    }

    @include md {
      &:last-child {
        padding-bottom: 10px;
      }
    }
  }

  &__moreMarkets {
    font-size: $small;
    text-decoration: none;
    line-height: 30px;
    font-weight: bold;
    margin-left: 10px;

    @include md {
      border-bottom: solid 1px #eee;
      border-top: solid 1px #eee;
      padding: 3px 10px;
      margin: 0;
      font-weight: bold !important;
    }

    &:hover {
      text-decoration: underline;
    }

    i {
      margin-left: 2px;
      color: $light-gray;
      font-size: $smaller;
      line-height: 30px;
      @include md {
        float: right;
      }
    }
  }

  &__tableContainer {
    width: 48%;
    margin: 0 11px;
    @include md {
      width: 100%;
      margin: 0;
    }
  }

  &__tableWrapper {
    position: relative;
  }

  &__tableRowHeader {
    display: grid;
    grid-template-columns: 5fr 2fr;
    align-items: center;
    padding-bottom: 8px;
    padding-left: 8px;
    border-bottom: 1px solid #eeeeee;
    @include md {
      grid-template-columns: 5fr 0fr;
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }

  &__matched {
    width: 100%;
    font-size: $smaller;
    color: $light-gray;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include md {
      font-size: $small;
      color: $black;
    }
  }

  &__tableContainerHeader {
    display: flex;
  }

  &__tableContainerHeaderText {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    width: 50%;
    font-size: $smaller;
    color: $light-gray;
    text-align: center;
    @include md {
      width: auto;
      font-size: $small;
      color: $black;
    }
  }

  &__marketWrap {
    position: relative;
    text-align: right;
  }

  &__blurLastMarket {
    z-index: 2;
    position: relative;
    top: -25px;
    width: 100%;
    height: 25px;
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #fff 50%, #fff 100%);
  }

  &__whatIf {
    margin-left: 10px;
  }

  &__showMoreMarket {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    text-align: end;
    font-size: $small;
    cursor: pointer;
    margin-top: 0;
    margin-right: -10px;
    padding: 5px 0;
    transition: 0.1s;

    &_active {
      position: relative;
      margin-top: 10px;
    }

    &:hover {
      text-decoration: underline;
    }

    i {
      margin-left: 3px;
    }

    @include md {
      color: $blue;
      margin-right: 5px;
    }
  }
  &__selectionNameWrapper {
    flex: auto;
    .betsIndicatorWrapper{
      div{
        display: flex;
        flex-direction: row;
        @include lg {
          flex-direction: column;
        }
      }
    }
  }
}
