.raceCard {
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: calc(100% - 24px);
  max-width: calc(100% - 24px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);

  &__carousel {
    contain: paint;
    cursor: grab;
  }

  &__container {
    display: flex;
    column-gap: 6px;
    margin-bottom: 10px;
  }

  &__emptyCard {
    background-color: #FFFFFF;
    height: calc(100% - 50px);
  }
}
