.game-image-blackjack {
  background-position: -164px 0;
}

.game-image-card_racer {
  background-position: -428px 0;
}

.game-image-hilo {
  background-position: -32px -88px;
}

.game-image-new-hold-em {
  background-position: -428px -88px;
}

.game-image-baccarat {
  background-position: -32px 0px;
}

.game-image-bulls_eye_roulette {
  background-position: -296px 0px;
}

.game-image-omaha_poker {
  background-position: -296px -88px;
}

.game-image-poker {
  background-position: -164px -88px;
}

.game-image-hold-em {
  background-position: -164px -88px;
}
