.resultFirstSection {
  margin: auto !important;
  width: auto !important;
  .startDate {
    display: flex;
    justify-content: center;
  }
}
.rounds {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  .round {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    min-width: 90px;

    .roundCards {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
}

.raceTitle {
  display: flex;
  justify-content: center;
  font-weight: bold !important;
  margin-top: 0;
}

.playerBoxes {
  display: flex;
  justify-content: space-between;
  max-width: 536px;
  margin: 10px auto 35px auto;
  gap: 10px;
}
