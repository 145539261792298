.viewByPlaceholder {
  height: 42px;
  border-radius: 4px;
  border: 1px solid #666666;
  padding: 0 25px 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
}
.viewByLabel {
  position: relative;

  &::after {
    font-family: "fa2", serif !important;
    font-weight: normal !important;
    font-style: normal !important;
    content: '\e94d';
    width: 7px;
    font-size: 6px !important;
    transform: translateY(-50%);
    position: absolute;
    right: -15px;
    top: 50%;
  }

  &__active {
    &:after {
      transform: translateY(-50%) rotateX(180deg);
    }
  }
}
