.plProfit {
  font-size: 16px;
  word-wrap: normal;
  text-overflow: ellipsis;
}

.betWon {
  color: #04A04A;
}

.betLost {
  color: #FE3629;
}
