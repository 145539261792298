.notification {
  margin: 0;
  line-height: 1.5;

  &__price {
    font-weight: 600 !important;
  }

  &__icon {
    font-size: 6px;
    margin-left: 4px;
    margin-bottom: 1px;
    line-height: 1;
    vertical-align: middle;
  }

  &__oddsValues {
    white-space: nowrap;
  }
}