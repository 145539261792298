.morePage {
  padding: 12px 12px 65px;
  background-color: #212121;
  box-sizing: border-box;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6px;
  background-color: #3E3E3E;
  color: #fff;
  box-sizing: border-box;
  height: 60px;
  font-size: 16px;

  &__left {
    display: flex;
    align-items: center;
    column-gap: 8px;
    flex: 1 1;
    padding-left: 10px;

    i {
      font-size: 30px;
    }
  }
}

.banner {
  display: flex;
  justify-content: center;
  max-width: 640px;
  margin: 0 auto;

  &__container {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 100%;

    li, img {
      width: 100%;
      max-width: 100%;
    }

    img {
      border-radius: 4px;
    }
  }
}

.title {
  text-transform: uppercase !important;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  font-weight: 500;
  color: #ffffff;
  margin: 15px 0 10px;

  &__noTopMargin {
    margin-top: 0;
  }
}

.switchTo {
  display: flex;
  column-gap: 12px;
  align-items: center;

  &__item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    gap: 8px;
    background-color: #3E3E3E;
    border-radius: 4px;
    text-decoration: none;
    color: #ffffff;

    i {
      font-size: 48px;
      color: #999999;
      line-height: normal;
    }

    span {
      font-size: inherit;
      line-height: 1.5;
    }
  }
}

.popularLinks {
  border-radius: 4px;
  background-color: #3E3E3E;
  padding: 12px 12px 12px 20px;
  color: #FFFFFF;

  &__item {
    background-color: inherit;
    border: none;
    width: 100%;
    box-sizing: border-box;
    padding: 9px 0;
    height: auto;
    color: inherit !important;

    &:hover {
      background-color: #3E3E3E;

      span {
        text-decoration: none;
      }
    }

    &:first-child {
      border-radius: 4px 4px 0 0;
    }

    &:last-child {
      border-radius: 0 0 4px 4px;
    }

    span {
      font-size: inherit;
    }

    i {
      font-size: 16px;
    }

    span {
      line-height: 1.5;
    }

    .arrow:before {
      font-size: 8px;
    }
  }
}
