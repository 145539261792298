.bannerWrapper {
  max-height: 100%;
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      a {
        img {
          width: 100%;
          vertical-align: top;
        }
      }
    }
  }
}
