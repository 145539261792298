.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;

  &__col {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
  }
}

.btn {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  min-height: 48px;
  border-radius: 4px;
  border: none !important;
  outline: none;
  font-size: 16px;
  line-height: 1.5;
  color: #303030;

  &__place {
    background-color: #FFB80C;
    border-width: 1px;
    border-style: solid;
    border-color: #FFB80C;

    &__disabled {
      background-color: #FAD06B;
    }

    &__confirm, &__lowerPrice {
      border-color: #FF8624;
    }
  }

  &__edit {
    background-color: #C0C0C0;
  }

  &__label {
    font-weight: bold !important;
  }

  &__payout {
    font-size: 12px;
  }
}
