@import "src/styles/variables/variables";

.fancyMarketRules {
  display: flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  line-height: 16px;

  i {
    border-radius: 2px 0 0 2px;
    border-right: 1px solid;
    border-color: #56b5de;
    font-size: $small;
    font-style: normal;
    width: 16px;
    height: 16px;
    text-align: center;
    font-weight: bold;
  }

  span {
    font-size: 10px;
    padding: 0 5px;
    background-color: #289fd3;
    border-radius: 0 2px 2px 0;
  }

  &__mobileIcon {
    font-size: 20px;
    cursor: pointer;
  }
}
