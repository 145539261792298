.dropdown {
  line-height: 1.5;

  &_container {
    position: absolute;
    display: block;
    top: 44px;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background: rgba(131, 131, 131, 0.5);
    z-index: 99999;

    ul {
      border: 1px solid #d8d8d8;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    li {
      cursor: pointer;
      text-align: left;
      font-weight: normal !important;
      height: 49px;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  i {
    cursor: pointer;
  }
}
