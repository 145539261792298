.playerStats {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 10px;

  &__main {
    padding: 5px;

    .title {
      font-weight: bold;
      text-align: center;
      padding: 10px;
      display: flex;
      justify-content: center;
    }

    .row {
      display: table-row;
      width: 100%;
      white-space: nowrap;

      .rowName {
        font-size: 12px;
        font-weight: 700;
        display: table-cell;
        padding: 5px 50px 5px 5px;
      }

      .rowStatus {
        font-size: 12px;
        font-weight: 400;
        display: table-cell;
      }
    }
  }
}
