@import 'styles/variables/variables';

.dropdownContainer {
  display: inline-block;
  position: relative;
  user-select: none;
}

.dropdownPlaceholder {
  width: 100%;
  line-height: 14px;
  font-size: $small;
  display: inline-flex !important;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  text-transform: uppercase;
  background-color: transparent;
  padding: 4px 25px 4px 5px;
  color: $dark-gray;
  border: 1px solid;

  &:after,
  &__ps:after {
    font-family: 'fa2', serif !important;
    font-weight: normal !important;
    font-style: normal !important;
    content: '\e94d';
    width: 7px;
    font-size: 6px !important;
    transform: translateY(-50%);
    position: absolute;
    right: 10px;
    top: 55%;
  }

  &__tooltip {
    margin-left: 5px;
  }

  &.hideArrow {
    padding: 0;
    border: none;
    display: flex;
    align-items: center;

    &:after {
      content: '';
    }

    &.dropdownPlaceholder_active {
      background-color: transparent;
    }
  }

  &_active {
    background-color: #d29910;
    border-color: #bb8911;

    &:after {
      transform: translateY(-50%) rotateX(180deg);
    }
  }

  &__mobile {
    height: 32px;
    padding: 9px 35px 9px 9px;
    border-color: #d8d8d8;
    color: $blue;
    font-weight: bold;
    border-radius: 3px;
    &::after {
      content: '\e94d';
      font-size: 7px;
      width: 13px;
      right: 10px;
    }

    &__label {
      margin-right: 3px;
    }
  }
}

.dropdown {
  min-width: 100%;
  max-width: 200px;
  max-height: 240px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  overflow-y: auto;
  border-width: 1px;
  border-style: solid;
  list-style-type: none;
  position: absolute;
  right: 0;
  z-index: 111;
  background-color: #f6f6f6;
  border-color: #cbcbca;
  color: #1e1e1e;
  font-size: $small;
  border-radius: 2px;
  &__mobile {
    max-height: 120px;
  }

  &.primary {
    background-color: #f6f6f6;
    border-color: #cbcbca;
    color: #1e1e1e;
    top: calc(100% + 5px);
  }

  &.secondary {
    background-color: #ffffff;
    border-color: #d8d8d8;
    color: #007dc5;
    top: calc(100% + 3px);
  }

  &__option {
    display: flex;
    text-align: left;
    align-items: center;
    padding: 0.7em 1em;
    cursor: pointer;
    column-gap: 5px;
    white-space: nowrap;

    &:hover {
      background-color: #dfdfdf;
      color: #1e1e1e;
    }

    &__label {
      line-height: 100%;
    }
  }
}

.darkBackground {
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  z-index: 10001;
  width: 100%;
  height: 100%;
  top: 0;

  left: 0;
}

.opened {
  z-index: 10003;
}

.tooltipIcon {
  font-size: 14px;
  color: #0072ac;
}

.divider {
  height: 10px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  hr {
    margin: 0;
    padding: 0;
    height: 0;
    border-top: 1px solid #dddddd;
  }
}
