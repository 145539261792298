.matchOddsHTEvent {
  display: flex;

  &__border {
    border-bottom: 1px solid #c0c0c0;
  }

  &__participants {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px 8px 12px;

    &__name {
      margin: 0;
      line-height: 1.5;
      font-size: 12px;

      &:first-of-type {
        margin-bottom: 7px;
      }
    }

    &__inPlay {
      background-color: #cbecd8;
    }
  }

  &__statsAndStream {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 10px;
    width: 50px;
    min-width: 50px;
    border-left: 1px solid #c0c0c0;
    box-sizing: border-box;

    &__inPlay {
      background-color: #cbecd8;
    }
  }

  &__cols {
    display: flex;
    flex-grow: 1;
    align-items: center;

    &__inPlay {
      background-color: #cbecd8;
    }
  }

  &__prices {
    text-align: center;
    border-left: 1px solid #c0c0c0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    flex-wrap: nowrap;
    box-sizing: border-box;
    width: 103px;

    &__first {
      flex-direction: column;
    }

    &__empty {
      height: 27px;
    }

    &__group {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  &__cashout {
    column-gap: 0 !important;

    &__icon {
      cursor: pointer !important;
    }
  }

  &__rightSection {
    display: flex;
    column-gap: 10px;
  }
}
