.count {
  font-weight: bold !important;

  &__zero {
    color: #303030;
  }

  &__positive {
    color: #060;
  }

  &__negative {
    color: #b30000;
  }

  &__normal {
    font-weight: 400 !important;
  }
}


