@import 'src/styles/mixins/breakpoints';

.collapsibleRow {
  user-select: none;
  background-color: #ffffff;

  &__favoriteWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
  }

  &__link {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 2px 5px 2px 9px;
    box-sizing: border-box;
    text-decoration: none;
    border-bottom: 1px solid #e8e8e8;
    @include md {
      min-height: 28px;
      max-height: 45px;
      padding: 2px 5px 2px 15px;
    }
  }

  &__sportName {
    width: 100%;
    padding: 0 12px 0 5px;
    overflow-wrap: break-word;
    overflow: hidden;
    cursor: pointer;
    line-height: 1.5;

    &:hover {
      text-decoration: underline;
    }
  }

  &__container {
    list-style-type: none;
    padding: 0;
    margin: 0;
    background-color: #ffffff;
  }

  &__icon {
    display: flex !important;
    align-items: center;
    height: 100%;
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);

    &_opened {
      transform: translateY(-50%) rotate(180deg);
    }

    @include md {
      font-size: 12px !important;
      right: 11px;
    }
  }

  &__starIcon {
    z-index: 5;
    text-align: left;
    font-size: 12px !important;
  }

  &__popupLinkContainer {
    padding: 5px 20px 5px 9px;
    border-bottom: 1px solid #e8e8e8;

    .collapsibleRow__starIcon {
      text-align: center;
    }

    .collapsibleRow__icon {
      right: 10px;
    }
  }

  &__popupLink {
    padding: 0 10px;
  }
}
