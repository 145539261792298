.container {
  width: 100%;
  padding-top: 10px;
}

.label {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #303030;
  margin-bottom: 5px;
}

.optionsContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 0;

  &__vertical {
    flex-direction: column;
  }
}

.option {
  width: 28%;
  padding: 8px 16px;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-transform: capitalize !important;
  color: #303030;
  cursor: pointer;
  text-align: left;

  &:active {
    background-color: #c0c0c0;
  }

  &__selected {
    background-color: #fff;
    border: 2px solid #0072AC;
  }

  &__vertical {
    width: 100%;
    margin-bottom: 10px;
  }

  &__last {
    margin-bottom: 0px;
  }

  &__centered {
    text-align: center;
  }
}