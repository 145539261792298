.counterMain {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .lowerCounter {
    margin-right: 20px;
    white-space: nowrap;
  }

  .higherCounter {
    margin-left: 20px;
    white-space: nowrap;
  }

  .content {
    padding: 5px 8px;
    color: #273a47;
    background-color: #e3e8e8;
    border: 1px solid #d3d3d3;
  }

  .active {
    color: #273a47;
    background-color: #db9909;
  }

  .bold {
    font-weight: bold;
  }

  .isPlayed {
    color: #b2b8bd;
  }
}
