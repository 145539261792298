@import 'src/styles/variables/variables';
@import 'src/styles/mixins/breakpoints';

//Desktop
.sportsList {
  margin: 0;
  padding: 0;
  list-style: none;

  ul {
    div:first-child {
      @include min-md {
        li {
          border-top: none !important;
          a {
            border-top: none !important;
          }
        }
      }
    }
    div:last-child {
      div:last-child {
        li:last-child {
          border-bottom: 1px solid;
          @include md{
            border-bottom: none;
          }
        }
      }
    }
  }

  li {
    display: block;
    border-bottom-style: solid;
    border-bottom-width: 1px;

    &.dateItem {
      span {
        justify-content: center;
      }
    }

    a,
    span {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 5px 0 10px;
      @include md {
        text-align: left;
      }
      @include min-md {
        padding: 0 23px 0 10px;
      }
    }

    > a,
    > span {
      @include md {
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  &__allSports {
    justify-content: left !important;
  }

  &__leftContainer {
    display: flex;
    align-items: center;
    column-gap: 5px;

    @include md {
      white-space: pre;
    }
  }

  &__itemName {
    white-space: pre-wrap;
    line-height: 21px;
  }

  &__linkMobile {
    @include md {
      display: flex !important;
      position: relative;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  &__item {
    position: relative;
    line-height: 20px;
    cursor: pointer;
    color: #2789ce;
    transition: 0.1s;

    @include md {
      height: 45px;
      line-height: 45px;
      border-bottom: none;
      border-top: 1px solid;
      border-left: none;
      border-right: none;
      border-bottom: 0 !important;
      &:last-child:not(.filterItem) {
        border-bottom: 1px solid;
      }
    }

    a {
      text-decoration: none;
    }

    &:not(.moreMobileItem):hover {
      .sportsList__favoriteHidden i {
        visibility: visible;
      }
    }

    &_active {
      background-color: $yellow-bg;
      color: $black-light;
      font-weight: bold;
      border: none;
      font-style: italic;
      display: flex;

      @include md {
        border-bottom: none;
        height: 45px;
        line-height: 45px;
      }

      &:hover {
        background-color: $yellow-bg;
      }

      & .sportsList__link {
        color: #1e1e1e;
      }

      a {
        border-bottom: 1px solid #1e1e1e;
        @include md {
          border-top: 1px solid #1e1e1e;
          height: 43px !important;
        }
      }
    }

    a {
      @include md {
        height: 45px;
        line-height: 45px;
      }
    }

    &.sportsList__today,
    &.sportsList__raceItemParent {
      padding: 0;
    }
  }

  &__link {
    color: #2789ce;
    text-decoration: none;
    @include md {
      height: 100% !important;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__favoriteItem {
    position: relative;
    font-size: 15px;
    right: -15px;
    @include md {
      position: relative;
      right: 2px;
      margin-left: 15px;
      margin-right: 5px;
      i {
        left: 10%;
      }
    }
  }

  &__favoriteItemParent {
    @include md {
      margin-right: 5px;
    }
  }

  &__favouriteItemInline {
    font-size: 15px;
  }

  &__favoriteHidden i {
    visibility: hidden;
  }

  &__dateItem {
    background-color: #dee5e4;
    color: $dark-gray;
    font-weight: bold;
    text-align: center;
    @include md {
      padding: 0 10px;
    }
  }

  &__selectedItem {
    .sportsList__link {
      color: $dark-gray;
      font-weight: bold;
    }
  }

  &__iconWrap {
    position: absolute;
    right: 7px;
    top: 3px;
    width: 13px;
    height: 13px;
    text-align: center;
    line-height: 14px;
    font-size: 10px;
    border-radius: 2px;
    border: 1px solid $light-gray;
    background-color: $light-gray;
    color: $white;
  }

  &__arrowIcon {
    position: absolute;
    right: 15px;
    top: 3px;
    width: 13px;
    height: 13px;
    text-align: center;
    line-height: 14px;
    font-size: $smaller;
    @include md {
      top: 13px;
      font-weight: 700 !important;
      line-height: 21px;
      i {
        width: 10px;
        height: 31px;
        font-size: 15px;
      }
    }
  }

  &__todayIcon {
    color: $dark-gray;
    padding: 0 20px 0 10px;

    &::after {
      position: absolute;
      display: inline-block;
      font-family: "fa2", serif !important;
      font-weight: normal !important;
      font-style: normal !important;
      content: "\e94d";
      font-size: 6px;
      right: 5px;
      top: 2px;
    }
  }

  &__raceItem {
    color: $dark-gray;
    padding: 0 20px 0 10px;

    &.showArrows {
      &::after {
        position: absolute;
        display: inline-block;
        font-family: "fa2", serif !important;
        font-weight: normal !important;
        font-style: normal !important;
        content: "\e94d";
        font-size: 6px;
        right: 5px;
        top: 2px;
      }
    }
  }

  &__todayChild,
  &__raceItemChild {
    background-color: #efefef;
    color: #2789ce;
    padding: 0 10px;
    border-bottom: 1px solid $white;

    &:last-child {
      border-bottom: none;
    }

    &.sportsList__selectedItem {
      background-color: #efefef;
      color: $dark-gray;
      font-weight: bold;
    }
  }

  &__openedParent {
    background-color: #dfdfdf;
    font-weight: bold;
  }
}

.navigationCollapse {
  background-color: #dee5e4;
  color: $dark-gray;
  font-weight: bold;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.category__link {
  flex-basis: 100%;

  @include md {
    position: relative;
  }
}

.name {
  position: relative;
  line-height: 20px;
  display: block;
  font-weight: normal;
  &.bold {
    font-weight: bold;
  }
  @include md {
    min-height: 44px;
    height: unset;
    border-bottom: 1px solid;
  }

  a {
    display: inline-block;
    @include md {
      display: block;
      span {
        padding: 0;
        width: calc(100% - 16px);
        display: block;
        overflow: inherit;
      }
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  @include md {
    border-top: 1px solid;
    border-bottom: none;
  }
  > span,
  > a {
    padding: 0 10px;
    @include md {
      font-weight: normal !important;
      border: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 44px;
      line-height: 44px;
      border-top: 1px solid;
      border-bottom: 1px solid;
      i {
        cursor: pointer;
      }
    }
  }

  > a:not(#biab_body_modal) {
    @include md {
      padding: 10px;
      padding-right: 5px;
      height: unset;
      overflow: initial;
      white-space: normal;
      text-overflow: unset;
      line-height: 20px;
    }
  }

  > span {
    display: flex !important;
    @include md {
      padding: 0 !important;
    }
  }
  &.active {
    > span,
    > a {
      font-weight: bold !important;
    }
  }
}

:global {
  .sportsList__raceItemParent {
    &.biab_active {
      font-weight: bold;
    }
  }
}

.competitionItem {
  @include md {
    padding: 0 10px;
  }
  font-weight: bold;
}

.filterItem {
  a {
    line-height: 20px;
    @include md {
      display: inline;
      line-height: 45px;
    }
  }
}

.moreMobileItem {
  padding: 5px 10px;
  font-weight: bold !important;
  @include md {
    height: 44px;
    padding: 0 10px;
    line-height: 44px;
    span {
      padding: 0 !important;
    }
  }
}

.textLeft {
  text-align: left;
}

.dropdownItem {
  border-bottom: 1px solid #d8d8d8;

  &:last-child {
    border-bottom: none;
  }

  @include md {
    height: 44px;
    line-height: 44px;
    position: relative;
  }

  a {
    height: 44px;
    line-height: 44px;
  }
}

.dropdownItem.dropdownItemCompetition {
  display: flex;

  .sportsList {
    &__favoriteItem {
      position: relative;
      top: 47%;
      margin-left: 28px;
      height: 20px;
    }
  }
}

.eventItem {
  a {
    @media (max-width: $md) {
      font-weight: 700 !important;
    }
  }
}

.subNavigationItem {
  a {
    @media (max-width: $md) {
      font-weight: 700 !important;
    }
  }
}

.subNavigationHeader {
  position: relative;
  width: auto;
  margin: 0;
  line-height: 1.5;
  background-color: #2f9ed3;
  font-size: 16px;
  color: #ffffff;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  @include md {
    display: flex !important;
    justify-content: space-between;
    column-gap: 25px;
    height: 45px;
  }

  > span,
  > a {
    @include md {
      height: 100%;
      border: none;
      // TODO extra space for favorite icon
      //width: calc(100% - 40px);
      width: 100%;
      white-space: pre-wrap !important;
      line-height: 25px;
    }
  }
}

.sportIcon {
  margin-right: 4px;
}

.racing:last-child {
  @include md {
    border-bottom-width: 1px !important;
    border-bottom-style: solid !important;
  }
}

.allSport {
  @include md {
    border-bottom: none !important;
  }

  i {
    margin-right: 8px;
  }
}

.nonTennisDropList {
  max-height: 162px;
  overflow-y: scroll;

  li {
    padding: 0 10px;
  }
}

.flexContainer {
  display: flex;

  .favoriteItemInline {
    font-size: 15px;
    margin-left: 10px;
  }
}

.favoriteItemInlineGroup {
  position: relative;
  right: 12px;
  top: 50%;
}

.arrowContainer {
  display: inline-flex;
  margin-right: 8px;

  &.rotate {
    transform: rotate(270deg);
  }

  &__default {
    &:before {
      font-size: inherit !important;
    }
  }
}

.parentItem {
  width: 100%;
  height: 20px;
  line-height: 20px;
  cursor: pointer;

  a {
    display: block;
  }

  &__competition {
    a {
      color: inherit !important;
    }
  }

  @include md {
    height: 45px;
    line-height: 45px;
    border-top-width: 1px;
    border-top-style: solid;
  }
}

.activeParent {
  font-weight: bold !important;
  cursor: initial;
}

@include md {
  .active {
    a {
      font-weight: bold !important;
      border-top: none;
      border-bottom: none;
    }
  }

  .eventItem {
    border-bottom-width: 1px;
  }
}

.highlighted {
  @include min-md {
    a,
    span {
      border-top: 1px solid !important;
      border-bottom: 1px solid !important;
    }
  }
}

.marketItem {
  padding-right: 25px;
  @include md {
    font-weight: 700 !important;
  }
  @include min-md {
    word-break: break-word;
  }
}

.dateItem {
  text-align: center;
  font-weight: 700;
  @include md {
    span,
    a {
      text-align: center !important;
    }
  }
}

.textCenter {
  text-align: center;
}

.categoryName {
  border-bottom: 1px solid;
  @include min-md {
    span {
      justify-content: flex-start !important;
    }
  }
}

.navigationItems {
  border-bottom: solid 1px;
  border-color: #f7f7f7;
}

.event-item {
  a {
    @include md {
      font-weight: 700 !important;
    }
  }
}
