@import "../../../../styles/mixins/breakpoints";

.noSectionsContainer {
  padding-bottom: 15px;
}

.noMarkets {
  padding: 50px 0;
  text-align: center;
  font-size: 15px;
  height: 158px;
  margin: 0;

  @include md {
    padding: 20px 10px;
    height: auto;
    line-height: 30px;
  }
}

.collapse {
  &__container {
    padding-bottom: 15px;
  }

  &__toggle {
    font-weight: bold;
    line-height: 14px !important;
    padding: 5px 10px;

    @include md {
      font-weight: bold !important;
    }

    &__arrow {
      font-weight: inherit;
    }
  }
}

.separator {
  height: 1px;
  background-color: #f7f7f7;
  border: none;
}
