@import "../../styles/variables/variables";
@import "../../styles/mixins/breakpoints";

.searchResult {
  @media (max-width: $md) {
    padding: 0;
  }

  &__item {
    background: #fff;
  }
}

.wrapper {
  display: flex;
}

:global {
  .searchResult {
    .matchedTitle {
      margin-left: 10px;
      @include md {
        font-weight: normal !important;
        font-size: $small;
      }
    }

    .biab_search-result-wrapper.biab_collapsed {
      padding-bottom: 0;

      .biab_search-header {
        border-bottom: solid 1px #f4f5f5;
      }
    }

    .biab_search-result-wrapper {
      background-color: #f4f5f5;
      padding-bottom: 7px;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .marketHeader__header__collapse {
    padding-right: 10px;
    display: flex;
    align-items: center;
  }
}
