.header {
  position: sticky;
  top: 0;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 16px;
  background-color: #F5F5F5;
  box-sizing: border-box;
}

.title {
  font-size: 16px;
  font-weight: 700 !important;
}

.btn {
  all: unset;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  box-sizing: border-box;

  &__changed {
    color: #0072AC;
    border-color: #0072AC;
  }

  &__disabled {
    color: #C0C0C0;
    border-color: #C0C0C0;
  }

  i {
    font-size: 24px;
    color: inherit;
  }
}
