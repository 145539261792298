.cashOut {
  &__container {
    display: flex;
    flex-direction: column;
    padding: 10px 0; // if the top and bottom padding are changed -> need to change TOP_AND_BOTTOM_PADDINGS value in component
    gap: 5px;
    background-color: #F5F5F5;
    overflow-y: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__emptyMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 10px 17px;
    text-align: center;
    min-height: 20px;
    border-radius: 4px;
    border: 1px solid #C0C0C0;
    color: #999999;
    line-height: 1.5;
  }

  &__content {
    padding: 0 10px;
  }

  &__refreshBtn {
    width: fit-content;
  }

  &__reloadBtn {
    width: fit-content;
    margin-bottom: 6px;
    font-size: 10px;

    &:hover {
      background: #C0C0C0;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__pagination {
    padding-left: 15px;
    padding-right: 15px;

    &__chevron {
      font-size: 10px;
      padding: 5px;

      &:before {
        color: inherit !important;
      }
    }
  }
}
