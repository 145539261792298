.defaultStake {
  padding: 12px 16px 24px 20px;
  background-color: #fff;
  border-radius: 4px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 0;
    margin-bottom: 8px;
    cursor: pointer;
    column-gap: 10px;

    &__title {
      font-size: 16px;
      line-height: 1.5;
      font-weight: 700 !important;
      color: #303030;
      margin: 0;
    }

    &__toggle {
      flex-shrink: 0;
    }
  }
}
