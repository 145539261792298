.wrap {
  margin-top: 5px;
}

.inner {
  position: relative;
  overflow: hidden;
  height: 25px;
  padding: 5px 10px;
  background-color: #FFFFFF;
  border-radius: 4px;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.progress {
  position: absolute;
  height: 25px;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #DDDDDD;
  transition: 1000ms all;
  z-index: 0;
}

.text {
  position: relative;
  font-size: 10px;
  z-index: 1;
}
