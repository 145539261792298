.list {
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;

  &__back {
    border-color: #a6d8ff;
  }

  &__lay {
    border-color: #fac9d1;
  }
}
