.tennis {
  display: flex;
  width: 100%;
  height: 100%;

  &__sets {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  &__games {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  &__cell {
    line-height: 20px;
    height: 50%;
  }
}

.tennisCashOut {
  display: table;
  min-width: 65px;
  min-height: 40px;
  height: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  justify-content: center;

  tbody {
    display: table;
    width: 100%;
    height: 100%;
  }

  &__cell {
    line-height: 20px;
    height: 50%;
    min-width: 20px;
    text-align: center;
  }
}
