@import 'src/styles/mixins/breakpoints';

.activeMarkets {
  &__list {
    background-color: #ffffff;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      background-color: inherit;

      a {
        display: inline-flex;
        align-items: center;
        min-height: 40px;
        font-size: 13px;
        font-weight: 700;
        line-height: 1.5;
        color: #0075b2;
        text-decoration: none;
      }
    }
  }

  &__container {
    @include md {
      min-height: 290px;
    }
  }

  &__emptyMessage {
    padding: 10px;
  }
}

.favorites {
  &__tabsWrapper {
    text-align: center;
    padding: 10px;
  }

  &__tabWrapper {
    display: inline-block;
    width: 50%;
    box-sizing: border-box;
    padding: 0 4px;
  }

  &__tab {
    display: inline-block;
    width: 100%;
    padding: 9px 19px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 3px;
    font-weight: bold !important;
    cursor: pointer;
    font-size: 12px;
    border: solid 1px #fff;
    background-color: #fff;
  }

  &__mobileTitle {
    background: #28a7e1;
    color: #fff;
    line-height: 30px;
    font-size: 14px;
    font-weight: 700 !important;
    padding-left: 12px;
  }

  &__noFavorites {
    padding: 0 10px;
  }
}
