.container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 220px;

  &__mobile {
    width: 100%;
  }
  &__input {
    width: auto;
  }
}

.label {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #303030;
  margin-bottom: 5px;
}