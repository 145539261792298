.asianRightBar {
  max-width: inherit;
  height: 100%;
  box-sizing: border-box;

  &__collapsible {
    min-width: 250px;
    max-width: 250px;
  }

  &__wrapper {
    position: relative;
    min-height: 80vh;
    transition: max-width 0.5s ease, min-width 0.5s ease;
    max-width: 250px;
    min-width: 250px;
    border-left: 1px solid #C0C0C0;
    overflow-y: auto;

    &__minimized {
      min-width: 34px;
      max-width: 34px;
    }

    &__collapsible {
      min-width: 35px;
      max-width: 35px;

      &__expanded {
        overflow-y: initial;
      }
    }
  }

  &__minimized {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    min-width: inherit;
    max-width: inherit;
    height: calc(100% - 14px);
    padding: 7px 0;
    background-color: #3E3E3E;
    cursor: pointer;

    &__arrowIcon {
      margin-top: 3px;
      color: #FFFFFF;

      &:before {
        font-size: 7px;
        font-weight: bold !important;
      }
    }

    &__linkIcon {
      font-size: 24px;
      color: #999999;
    }
  }

  &__autoCollapse {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    background-color: #FFFFFF;
    transition: transform 0.5s ease;
    min-width: 250px;
    max-width: 250px;
    border-left: 1px solid #C0C0C0;

    &__contentWrapper {
      overflow-y: auto;
    }
  }

  .hideButton {
    cursor: pointer;
    color: #000000;
    background-color: #C0C0C0;
    padding: 0 5px;
    display: flex;
    align-items: center;
    border: none;
    column-gap: 10px;
    height: 25px;
    width: 100%;
    font-size: inherit;

    i {
      &::before {
        font-size: 4px;
      }
    }
  }

  .bannerSection {
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
    img {
      width: 100%;
    }
  }
}

.transform__show {
  transform: translateX(0);
}

.transform__hide {
  transform: translateX(250px);
}
