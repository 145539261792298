@import "styles/variables/variables";
@import 'styles/mixins/breakpoints';

.sybHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px 10px 5px;
  border-bottom: 1px solid #eee;

  .rightSide {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 5px;

    .inPlay {
      height: 16px;
      width: 16px;
      text-align: center;
      border-radius: 2px;

      i {
        margin-top: 2px;
      }
    }

    .cashOutIcon {
      position: relative;
      width: 12px;
      height: 12px;
      display: flex;
      background: #f2d660;
      border: solid 1px #ffa01f;
      border-radius: 12px;
      text-align: center;
      line-height: 12px;

      i {
        width: 4px;
        height: 4px;
        display: block;
        background-color: #ff8624;
        position: absolute;
        left: 3px;
        top: 3px;
        border: solid 1px #ffa01f;
      }
    }

    .fullRules {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: 5px;
      margin-right: 5px;

      i {
        border-radius: 2px 0 0 2px;
        border-right: 1px solid;
        border-color: #56b5de;
        font-size: $small;
        font-style: normal;
        width: 16px;
        height: 16px;
        text-align: center;
        line-height: 16px;
        font-weight: bold;
      }

      span {
        line-height: 16px;
        font-size: 10px;
        padding: 0 5px;
        background-color: #289fd3;
        border-radius: 0 2px 2px 0;
      }
    }
  }
  .name {
    @include md {
      font-weight: bold !important;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .arrow {
    cursor: pointer;
  }
}
