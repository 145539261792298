.marketName {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 25px;
  background-color: #dddddd;
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
}

.runnersColumns {
  display: grid;
  grid-template-columns: repeat(3, 3fr) 1fr;
  border-bottom: 1px solid #c0c0c0;

  &__full {
    grid-template-columns: 3fr 2fr 3fr 1.5fr;
  }
}
