.skeletonContainerAbsolute {
  position: absolute;
  width: 100%;
  background: white;
  z-index: 100;
  min-height: 100%;
}

.middleSectionSkeletonItemWrapper {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px;
  .middleSectionLeftSideWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    width: 100%;
    .middleSectionSkeletonItem {
      &:first-child {
        height: 44px;
        min-width: 44px;
        max-width: 44px;
        margin-right: 15px;
      }
      &:last-child {
        height: 44px;
      }
    }
  }
  .middleSectionRightSideWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    .middleSectionSkeletonItem {
      height: 44px;
      min-width: 44px;
      margin-left: 25px;
    }
  }
}