@import 'styles/variables/variables';

.modal {
  &__header {
    font-size: 20px;
    color: #303030 !important;
    padding-top: 14.25px;
    padding-bottom: 14.25px;
    border: none;
    border-radius: 4px 4px 0 0;

    h4 {
      line-height: 1.5;
      font-weight: 500;
    }
  }

  &__body {
    padding: 10px 20px;
    border: none;
    max-height: calc(70dvh - 60px);
    overflow-y: auto;
    box-sizing: border-box;
  }

  &__content {
    min-width: auto;
    padding: 0;
    box-sizing: border-box;
    max-width: $asian-view-pop-up-max-width;
    width: calc(100dvw - 24px);
  }

  &__dialog {
    height: auto;
    min-height: auto;
    overflow: unset;
  }
}
