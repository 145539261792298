.mobileFavs {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6px;
    background-color: #3E3E3E;
    box-sizing: border-box;
    height: 60px;

    &__left {
      display: flex;
      align-items: center;
      column-gap: 8px;
      flex: 1;
      padding-left: 10px;
    }

    &__title {
      margin: 0;
      color: inherit;
      font-weight: 500;
      font-size: inherit;
    }

    &__favIcon {
      color: #FFB80C;
      font-size: 16px;
    }
  }

  &__noFavs {
    background-color: #FFFFFF;
    height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 8px;
    padding: 0 16px;

    &__label {
      font-size: 14px;
      text-align: center;
      color: #444444;
      line-height: 24px;
      margin: 0;

      &:first-of-type {
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
      }

      &:last-of-type {
        max-width: 275px;
      }
    }
  }
}
