.content {
  background-color: #F5F5F5;
}

.inner {
  padding: 10px; // if the top and bottom padding are changed -> need to change TOP_AND_BOTTOM_PADDINGS value in component
  overflow-y: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.empty {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  color: inherit;
  border: 1px solid #C0C0C0;
  border-radius: 4px;
  box-sizing: border-box;
}
