.liability {
  font-size: 14px;
  line-height: 1.6666;
  margin: 0;
  flex: 1;
  padding: 12px 0;

  &__value {
    font-weight: 700 !important;
  }

  &__landscape {
    text-align: center;
  }

  &__tooltipContainer {
    justify-content: space-between;
    padding-right: 8px;
  }
}
