@import 'src/styles/mixins/breakpoints';

.mobileExtraTabSingleMarket {
  &__runnersContainer {
    position: relative;
    padding-right: 5px;
    padding-left: 1px;
  }

  &__actions {
    @include md {
      margin-top: 0;
      border-top-width: 0;
    }
  }
}

.marketName {
  margin-left: 8px;
}
