.bet {
  padding: 16px 0 16px 16px;
  font-size: 14px;
  line-height: 1.2;
  border-bottom: 1px solid #dddddd;
  position: relative;

  &:first-of-type {
    border-top: 1px solid #dddddd;
  }

  &:active {
    background-color: #f5f5f5;
  }

  &__back,
  &__lay {
    &:before {
      position: absolute;
      content: '';
      width: 4px;
      height: calc(100% - 30px);
      border-radius: 4px;
      top: 15px;
      left: 0;
    }
  }

  &__back:before {
    background-color: #a6d8ff;
  }

  &__lay:before {
    background-color: #fac9d1;
  }
}

.content {
  display: flex;
  gap: 12px;
  flex: 1;
  color: #666666;
  justify-content: space-between;

  &__left {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    &__odds {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 4px;

      &__value {
        font-size: 16px;
        font-weight: bold !important;
        color: #303030;
        white-space: nowrap;
      }
    }
  }
}

.selectionNameWrap {
  display: flex;
  gap: 5px;

  i {
    font-size: 24px;
  }
}

.selectionName {
  font-size: 16px;
  font-weight: bold !important;
  line-height: 24px;
  color: #303030;
}

.eventName {
  line-height: 20px;
}

.riskToWinValue {
  font-size: 16px;
  color: #303030;
}

.icon {
  &__unmatched {
    color: #fe3629;
  }

  &__matched {
    color: #04a04a;
  }

  &__dot {
    font-size: 8px;
  }

  &__arrow {
    font-size: 24px;
  }
}

.cashOutLabel {
  display: inline-block;
  min-height: 24px;
  padding: 3px 8px;
  font-size: 14px;
  font-weight: bold !important;
  color: #303030;
  background-color: #ffb80c;
  border-radius: 4px;
  box-sizing: border-box;
}

.status {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #303030;

  i {
    color: #666666;
  }

  &__label {
    margin-left: 6px;
    margin-right: 12px;
  }

  &__won {
    color: #04a04a;

    i {
      color: #04a04a;
    }
  }

  &__lost {
    color: #fe3629;

    i {
      color: #fe3629;
    }
  }
}

.row {
  display: flex;
  align-items: center;
  gap: 8px;
}

.unmatchedActions {
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin-top: 10px;

  &__action {
    flex: 1;
    height: 42px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    border-radius: 4px;
    background-color: #ececec;
    align-content: center;
    padding: 9px 8px;
    cursor: pointer;
    color: #303030;
    font-size: 14px;
    line-height: 20px;
  }
}

.error {
  display: flex;
  align-items: center;
  column-gap: 10px;
  border: 1px solid #fe3629;
  border-radius: 4px;
  padding: 19px 12px;
  min-height: 40px;
  box-sizing: border-box;
  margin-top: 10px;

  &__msg {
    font-size: 14px;
    line-height: 20px;
    color: inherit;
    margin: 0;
  }

  &__icon {
    font-size: 20px;
  }
}

.loader {
  transform: scale(0.16675);

  & > div {
    background-color: #0072ac !important;
  }

  &__wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    z-index: 10;
  }
}

.success {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 18px 12px;
  border-radius: 4px;
  border: 1px solid #0072ac;

  &__wrap {
    padding: 16px 0;
    border-bottom: 1px solid #dddddd;

    &:first-of-type {
      border-top: 1px solid #dddddd;
    }
  }

  &__icon {
    font-size: 20px;
  }

  &__textsWrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
    color: inherit;
    margin: 0;

    &__bold {
      font-weight: 700 !important;
    }
  }
}

.capitalize {
  text-transform: capitalize;
}
