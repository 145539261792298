.multiCurrencyMessage {
  display: flex;
  align-items: center;
  gap: 10px;
  min-height: 40px;
  line-height: 1.5em;
  padding: 5px 10px;
  background-color: #FFFFFF;
  border: 1px solid #0072AC;
  box-sizing: border-box;

  &__icon {
    font-size: 16px;
  }

  &__text {
    line-height: 20px;
  }

  &__btnClose {
    position: absolute;
    top: 5px;
    right: 5px;
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    background: none;
    color: inherit;
    cursor: pointer;
  }
}
