@import "../../styles/variables/variables";
@import "../../styles/mixins/breakpoints";

.search {
  &__totalBets {
    color: #ddd;
    border-bottom: solid 1px #eee;
    font-size: 12px;
    line-height: 30px;
    padding-left: 10px;
  }
}
