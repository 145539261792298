@import 'src/styles/mixins/breakpoints';
@import 'src/styles/variables/variables';

.welcomePopup {
  &__body {
    width: 430px;
    padding: 20px 0 20px 0;
    background-color: white;
    position: relative;
    @include md {
      width: auto;
    }
    &__closeBtn {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    &__header {
      text-transform: uppercase;
      font-weight: 600;
      padding: 5px 0 8px 0;
      font-size: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      &::after {
        padding-top: 8px;
        content: '';
        width: 172px;
        border-bottom: 0.5px solid #c0c0c0;
      }
    }
    &__text {
      display: flex;
      justify-content: center;
      padding-bottom: 16px;
    }
    &__buttonContainer {
      display: flex;
      justify-content: center;
      button {
        width: 176px;
        height: 29px;
        border: none;
        border-radius: 4px;
      }
    }
    &__landscapeWarning {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
