.containerLoading {
  padding: 10px 6px;
  background: #FFFFFF;
  box-sizing: border-box;

  &__topLine {
    width: 100%;
    height: 15px;
  }

  &__cards {
    display: flex;
  }

  &__card {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 35px 0;
    gap: 15px
  }

  &__cardFirstLine {
    height: 15px;
    width: 80%;
  }

  &__cardSecondLine {
    height: 30px;
    width: 80%;
  }
}
