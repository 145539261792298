@import 'styles/variables/variables.scss';

.bet {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border-bottom: 1px solid #c0c0c0;

  > * {
    padding: 0 !important;
  }

  &__back {
    background-color: #dbefff;
  }

  &__lay {
    background-color: #fee9ee;
  }

  &__disabled {
    padding: 15px 10px 10px 10px;
    background-color: #f5f5f5;
    gap: 15px;
  }

  &__hidden {
    display: none;
  }

  &__inner {
    display: flex;
    align-items: center;
    font-size: 10px;
    box-sizing: border-box;
    padding: 10px;

    > * {
      &:first-child {
        flex: 2;
      }

      &:last-child {
        flex: 3;
      }
    }
  }

  &__lineInfo {
    font-weight: bold;
  }
}

.betSelectionName {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2px;

  &__label {
    font-weight: 600;
    font-size: 12px;
    line-height: 1.5em;
    margin-bottom: 2px;
  }
}

.betRemoveIcon {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  cursor: pointer;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-sizing: border-box;

  i {
    color: inherit;
  }

  &:hover {
    background-color: #fff;
  }
}

.betRemoveIcon:focus {
  border: #3e3e3e solid 1px;
}

.youAreLabel {
  margin: 0;
  color: #666666;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.pncIndicator {
  position: absolute;
}
