.skeletonContainer {
  position: relative;

  &__active {
    height: 300px;
    overflow-y: hidden;
  }
}

.refreshWrapper {
  float: right;
}

.totalForEvent {
  padding-top: 5px;
  display: inline-block;
}

.hasTotal {
  padding-bottom: 9px !important;
}

.mobileFilters {
  display: flex;
  gap: 12px;
}

.mobileTableHeader {
  padding: 8px 0 16px;
  border-bottom: 1px solid #dddddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;

  &__left {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }

  &__dateRange {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    color: #303030;
    font-weight: 700 !important;
  }

  &__product {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
  }
}