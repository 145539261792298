.tableContainer {
  position: relative;

  &__mobile {
    overflow-y: unset;
    overflow-x: hidden;
  }
}

.refreshWrapper {
  float: right;
}

.rightContainer {
  padding-bottom: 15px;
  height: 65px;
  float: right;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
}
