@import "../../styles/variables/variables";
@import "../../styles/mixins/breakpoints";

.filtersAndSort {
  display: flex;
  flex-direction: column;

  @include md {
    flex-direction: column-reverse;
  }
}

.mobileTitle {
  padding-inline: 11px;
  margin: 0;
  line-height: 34px;
}
