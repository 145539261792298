.asianViewFavourites {
  &__collapse {
    padding-inline: 6px;
    line-height: 25px;
    border-bottom: 1px solid #3E3E3E;
  }

  &__item {
    display: flex;
    column-gap: 4px;
    padding: 6px;
    align-items: center;
    border-top: 1px solid #FFFFFF;

    &:last-of-type {
      margin-bottom: 5px;
    }

    &__name {
      text-decoration: none;
      color: inherit !important;
      font-size: inherit;
      line-height: 1.5;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
