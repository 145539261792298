.totalGoals {
  border-bottom: 1px solid #C0C0C0;

  &__inPlayCell {
    min-height: 40px;
  }

  &__titleHeader {
    display: flex;
    align-items: center;
    gap: 10px
  }

  &__name {
    font-weight: 500;
  }

  &__inPlay {
    background: linear-gradient(0deg, #CBECD8, #CBECD8);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__streming {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 0 10px;
  }

  &__plIcon {
    margin-right: 10px;
  }

  &__content {
    &__header {
      display: flex;
      background-color: #ececec;
      border-bottom: 1px solid #C0C0C0;
    }

    &__headerItem {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid #C0C0C0;
      border-right: 1px solid #C0C0C0;
      height: 30px;
      font-size: 12px;
      font-weight: 500;
      color: #3E3E3E;

      &:last-child {
        border-right-width: 0;
      }
    }

    &__runners {
      display: flex;
    }

    &__runner {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 1;
      height: 60px;
      border-right: 1px solid #C0C0C0;

      &:last-child {
        border-right-width: 0;
      }
    }
  }
}
