.btn {
  all: unset;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
}
