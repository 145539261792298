#biab_footer .col-lg-6 {
  width: 50%;
}

body {
  margin: 0;
}

.biab_body #biab_footer.psFooter {
  background-color: #29a7e1;
  color: #fff;
  font-size: 12px;
  display: table-row;
  height: 1px;
  position: relative;
}

#footer {
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 10px;
  width: 980px;
  margin: auto;
  position: relative;
  z-index: 2;
  color: #777;
  font-size: 9px;
  padding-top: 25px;
}
#footer {
  width: 100% !important;
  height: 100%;
  padding-top: 0px;
}
.footer-grid {
  padding: 20px 0 0;
  color: #6f6f6f;
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 10px;
}
.footer-grid h3 {
  font-size: 11px;
  font-weight: bold;
  margin: 0 0 10px;
  padding: 0;
  color: #666;
}
.footer-grid a {
  color: #666;
  text-decoration: none;
}
.footer-grid a:hover {
  text-decoration: underline;
}

.footer-grid .footerDabs .dabsHolder {
}
.footer-grid .footerDabs .dabsHolder .dabs {
  border: 1px solid #282828;
  width: 100%;
  font-family: Verdana, sans-serif;
  display: table;
  font-size: 11px;
}
.footer-grid .footerDabs .dabsHolder .dabs a {
  display: table-cell;
  background: #111111;
  outline: none;
  padding: 4px 0;
  text-align: center;
  border-right: 1px solid #282828;
  color: #666;
  cursor: pointer;
  text-decoration: none;
}
.footer-grid .footerDabs .dabsHolder .dabs a.last {
  border-right: 0px solid #282828;
}
.footer-grid .footerDabs .dabsHolder .dabs a.active,
.footer-grid .footerDabs .dabsHolder .dabs a:hover {
  color: #4e4e4e;
  background: #282828;
}
.footer-grid .footerDabs .dabsHolder .dabContent {
  min-height: 75px;
  margin: 25px 0 0;
}
.footer-grid .footerDabs .dabsHolder .dabContent ul {
  list-style-type: none;
}
.footer-grid .footerDabs .dabsHolder .dabContent ul li {
  padding: 3px 0 3px 0;
  line-height: 1em;
}
.footer-grid .mobile-button {
  padding-top: 15px;
  padding-bottom: 15px;
  color: #8b8b8b;
  font-size: 12px;
  text-align: center;
  border-bottom: 1px solid #000;
  border-top: 1px solid #222;
  outline: none;
}
.footer-grid .mobile-button.active {
}
.footer-grid .footerMiddle {
  border-bottom: 1px solid #282828;
  border-top: 1px solid #282828;
  padding: 20px 0 10px 0;
  margin: 10px 0;
}
.footer-grid .copyright p {
  padding: 0;
  margin: 0 0 10px;
}
.footer-grid .footerLanguageMenu {
  display: none;
}
.footerLogos {
  display: inline-block;
  float: none;
  margin: auto;
  text-align: center;
}
#footerCombo,
.footerButton {
  font-size: 12px;
  text-align: center;
  color: #8b8b8b;
  display: block;
}
#footerCombo select {
  background: #181818;
  color: #fff;
  border: none;
}
#footerCombo {
  display: none !important;
}

.footer-grid .row:last-child {
  /*border-top:1px solid #282828; padding-top:20px*/
}

.footerLogos {
  display: block;
  float: none;
  margin: auto;
  text-align: center;
  margin: 0 5px;
}
.footerLogo {
  background-image: url('//s4.rationalcdn.com/img/bg/estate/footer-small.png');
  background-repeat: no-repeat;
  height: 38px;
}
/* Color Logo */
.footerLogo.rationalGroup {
  background-position: 0 0;
  width: 80px;
}
.footerLogo.fps {
  background-position: 0 -38px;
  width: 80px;
}
.footerLogo.ept {
  background-position: 0 -76px;
  width: 80px;
}
.footerLogo.arjel {
  background-position: 0 -113px;
  width: 42px;
}
.footerLogo.plus18 {
  background-position: 0 -150px;
  width: 35px;
}
.footerLogo.adictel {
  background-position: 0 -187px;
  width: 88px;
}
.footerLogo.igc {
  background-position: 0 -224px;
  width: 50px;
}
.footerLogo.cigital {
  background-position: 0 -261px;
  width: 61px;
}
.footerLogo.secure {
  background-position: 0 -297px;
  width: 37px;
}
.footerLogo.exclusion {
  background-position: 0 -334px;
  width: 37px;
}
.footerLogo.amaya {
  background-position: 0 -372px;
  width: 80px;
}
.footerLogo.gamcareCert {
  background-position: 0 -409px;
  width: 48px;
}
.footerLogo.gamcare {
  background-position: 0 -444px;
  width: 113px;
}
.footerLogo.estrellas {
  background-position: 0 -484px;
  width: 91px;
}
.footerLogo.fejar {
  background-position: 0 -521px;
  width: 35px;
}
.footerLogo.malta {
  background-position: 0 -560px;
  width: 110px;
}
.footerLogo.gamingLabs {
  background-position: 0 -596px;
  width: 61px;
}
.footerLogo.juegoSeguro {
  background-position: 0 -632px;
  width: 110px;
}
.footerLogo.iom {
  background-position: 0 -670px;
  width: 39px;
}
.footerLogo.plus21 {
  background-position: 0 -705px;
  width: 35px;
}
.footerLogo.aams {
  background-position: 0 -742px;
  width: 45px;
}
.footerLogo.aamsCert {
  background-position: 0 -780px;
  width: 60px;
}
.footerLogo.bps {
  background-position: 0 -816px;
  width: 75px;
}
.footerLogo.casinoAmur {
  background-position: 0 -853px;
  width: 77px;
}
.footerLogo.bgc {
  background-position: 0 -889px;
  width: 30px;
}
.footerLogo.gg {
  background-position: 0 -925px;
  width: 37px;
}
.footerLogo.resorts {
  background-position: 0 -961px;
  width: 77px;
}
.footerLogo.ccgnj {
  background-position: 0 -999px;
  width: 23px;
}
.footerLogo.aaj {
  background-position: 0 -1037px;
  width: 85px;
}
.footerLogo.gokhulp {
  background-position: 0 -1074px;
  width: 85px;
}
.footerLogo.licensedNJ {
  background-position: 0 -1110px;
  width: 37px;
}
.footerLogo.gamblingCommission {
  background-position: 0 -1144px;
  width: 80px;
}
.footerLogo.mga {
  background-position: 0 -1183px;
  width: 75px;
}
.footerLogo.JugarBIEN {
  background-position: 0 -1259px;
  width: 107px;
}
.footerLogo.xe {
  background-position: 0 -1294px;
  width: 45px;
}
.footerLogo.sh {
  background-position: 0 -1331px;
  width: 35px;
}
.footerLogo.trustIt {
  background-position: 0 -1368px;
  width: 60px;
}
.footerLogo.evalujeu-long {
  background-position: 0 -1407px;
  width: 250px;
}
.footerLogo.evalujeu {
  background-position: 0 -1407px;
  width: 95px;
}
.footerLogo.rg {
  background-position: 0 -1480px;
  width: 35px;
}
.footerLogo.affiliates {
  background-position: 0 -1515px;
  width: 70px;
}
.footerLogo.nj-resorts {
  background-position: 0 -1554px;
  width: 43px;
}
.footerLogo.gambler-800 {
  background-position: 0 -1591px;
  width: 92px;
}
.footerLogo.dge {
  background-position: 0 -1628px;
  width: 68px;
}
.footerLogo.gambleaware {
  background-position: 0 -1664px;
  width: 110px;
}
.footerLogo.srij_1 {
  background-position: 0px -1702px;
  width: 35px;
}
.footerLogo.srij_2 {
  background-position: 0 -1739px;
  width: 35px;
}
.footerLogo.srij_3 {
  background-position: 0 -1776px;
  width: 35px;
}
.footerLogo.gamblingtherapy {
  background-position: 0 -1813px;
  width: 40px;
}
.footerLogo.nog {
  background-position: 0px -1850px;
  width: 38px;
}
.footerLogo.mof {
  background-position: 0 -1888px;
  width: 29px;
}
.footerLogo.ESSA {
  background-position: 0 -1918px;
  width: 145px;
}
.footerLogo.pslive {
  background-position: 0 -1963px;
  width: 112px;
}
.footerLogo.helpDe {
  background-position: 0 -1999px;
  width: 79px;
}
.footerLogo.helpEn {
  background-position: 0 -2035px;
  width: 79px;
}
.footerLogo.playsafe {
  background-position: 0 -2072px;
  width: 52px;
}
.footerLogo.safeIOM {
  background-position: 0 -2108px;
  width: 140px;
}
.footerLogo.safeIT {
  background-position: 0 -2145px;
  width: 146px;
}
.footerLogo.safePT {
  background-position: 0 -2182px;
  width: 132px;
}
.footerLogo.safeRO {
  background-position: 0 -2219px;
  width: 134px;
}
.footerLogo.starsGroup {
  background-position: 0 -2255px;
  width: 75px;
}
.footerLogo.facebook {
  background-position: 0 -2293px;
  width: 35px;
}
.footerLogo.twitter {
  background-position: 0 -2330px;
  width: 40px;
}
.footerLogo.responsibleGaming {
  background-position: 0px -2369px;
  width: 35px;
}
.footerLogo.gamestop {
  background-position: 0 -2401px;
  width: 79px;
}
.footerLogo.sachikoGaming {
  background-position: 0 -2442px;
  width: 79px;
}
.footerLogo.ncpg {
  background-position: 0 -2478px;
  width: 77px;
}
.footerLogo.logico {
  background-position: 0 -2517px;
  width: 77px;
}
.footerLogo.kethea {
  background-position: 0 -2551px;
  width: 77px;
}
.footerLogo.AIGF {
  background-position: 0 -2594px;
  width: 36px;
}
.footerLogo.playsafe-fr {
  background-position: 0 -2628px;
  width: 148px;
}
.footerLogo.playsafe-nl {
  background-position: 0 -2666px;
  width: 148px;
}
.footerLogo.playsafe-en {
  background-position: 0 -2702px;
  width: 148px;
}
.footerLogo.spelin {
  background-position: 0 -2738px;
  width: 47px;
}
.footerLogo.pcgblr {
  background-position: 0px -2776px;
  width: 120px;
}
.footerLogo.pgcb {
  background-position: 0 -2812px;
  width: 35px;
}
.footerLogo.davos {
  background-position: 0 -2850px;
  width: 62px;
}
.footerLogo.adm {
  background-position: 0 -2886px;
  width: 85px;
}
.footerLogo.fournier {
  background-position: 0 -2925px;
  width: 77px;
}
.footerLogo.bgsc {
  background-position: 0 -2961px;
  width: 135px;
}
.footerLogo.sicadpt {
  background-position: 0 -2993px;
  width: 78px;
}
.footerLogo.darmstadt {
  background-position: 0 -3035px;
  width: 38px;
}
.footerLogo.sandhausen {
  background-position: 0 -3072px;
  width: 33px;
}
.footerLogo.kiel {
  background-position: 0 -3109px;
  width: 38px;
}
.footerLogo.hannover {
  background-position: 0 -3146px;
  width: 43px;
}
/* Grey Logo */
.footerLogo.grey.rationalGroup {
  background-position: -146px 0;
}
.footerLogo.grey.fps {
  background-position: -146px -38px;
}
.footerLogo.grey.ept {
  background-position: -146px -76px;
}
.footerLogo.grey.arjel {
  background-position: -146px -113px;
}
.footerLogo.grey.plus18 {
  background-position: -146px -150px;
}
.footerLogo.grey.adictel {
  background-position: -146px -187px;
}
.footerLogo.grey.igc {
  background-position: -146px -224px;
}
.footerLogo.grey.cigital {
  background-position: -146px -261px;
}
.footerLogo.grey.secure {
  background-position: -146px -297px;
}
.footerLogo.grey.exclusion {
  background-position: -146px -334px;
}
.footerLogo.grey.amaya {
  background-position: -146px -372px;
}
.footerLogo.grey.gamcareCert {
  background-position: -146px -409px;
}
.footerLogo.grey.gamcare {
  background-position: -146px -444px;
}
.footerLogo.grey.estrellas {
  background-position: -146px -484px;
}
.footerLogo.grey.fejar {
  background-position: -146px -521px;
}
.footerLogo.grey.malta {
  background-position: -146px -560px;
}
.footerLogo.grey.gamingLabs {
  background-position: -146px -596px;
}
.footerLogo.grey.juegoSeguro {
  background-position: -146px -632px;
}
.footerLogo.grey.iom {
  background-position: -146px -670px;
}
.footerLogo.grey.plus21 {
  background-position: -146px -705px;
}
.footerLogo.grey.aams {
  background-position: -146px -742px;
}
.footerLogo.grey.aamsCert {
  background-position: -146px -780px;
}
.footerLogo.grey.bps {
  background-position: -146px -816px;
}
.footerLogo.grey.casinoAmur {
  background-position: -146px -853px;
}
.footerLogo.grey.bgc {
  background-position: -146px -889px;
}
.footerLogo.grey.gg {
  background-position: -146px -925px;
}
.footerLogo.grey.resorts {
  background-position: -146px -961px;
}
.footerLogo.grey.ccgnj {
  background-position: -146px -999px;
}
.footerLogo.grey.aaj {
  background-position: -146px -1037px;
}
.footerLogo.grey.gokhulp {
  background-position: -146px -1074px;
}
.footerLogo.grey.licensedNJ {
  background-position: -146px -1110px;
}
.footerLogo.grey.gamblingCommission {
  background-position: -146px -1144px;
}
.footerLogo.grey.mga {
  background-position: -146px -1183px;
}
.footerLogo.grey.JugarBIEN {
  background-position: -146px -1259px;
}
.footerLogo.grey.xe {
  background-position: -146px -1294px;
}
.footerLogo.grey.sh {
  background-position: -146px -1331px;
}
.footerLogo.grey.trustIt {
  background-position: -146px -1368px;
}
.footerLogo.grey.evalujeu-long {
  background-position: 0 -1443px;
}
.footerLogo.grey.evalujeu {
  background-position: 0 -1443px;
}
.footerLogo.grey.rg {
  background-position: -146px -1480px;
}
.footerLogo.grey.affiliates {
  background-position: -146px -1515px;
}
.footerLogo.grey.nj-resorts {
  background-position: -146px -1554px;
}
.footerLogo.grey.gambler-800 {
  background-position: -146px -1591px;
}
.footerLogo.grey.dge {
  background-position: -146px -1628px;
}
.footerLogo.grey.gambleaware {
  background-position: -146px -1664px;
}
.footerLogo.grey.srij_1 {
  background-position: -146px -1702px;
}
.footerLogo.grey.srij_2 {
  background-position: -146px -1739px;
}
.footerLogo.grey.srij_3 {
  background-position: -146px -1776px;
}
.footerLogo.grey.gamblingtherapy {
  background-position: -146px -1813px;
}
.footerLogo.grey.nog {
  background-position: -146px -1850px;
}
.footerLogo.grey.mof {
  background-position: -146px -1888px;
}
.footerLogo.grey.ESSA {
  background-position: -146px -1918px;
}
.footerLogo.grey.pslive {
  background-position: -146px -1963px;
}
.footerLogo.grey.helpDe {
  background-position: -146px -1999px;
}
.footerLogo.grey.helpEn {
  background-position: -146px -2035px;
}
.footerLogo.grey.playsafe {
  background-position: -146px -2072px;
}
.footerLogo.grey.safeIOM {
  background-position: -146px -2108px;
}
.footerLogo.grey.safeIT {
  background-position: -146px -2145px;
}
.footerLogo.grey.safePT {
  background-position: -146px -2182px;
}
.footerLogo.grey.safeRO {
  background-position: -146px -2219px;
}
.footerLogo.grey.starsGroup {
  background-position: -146px -2255px;
}
.footerLogo.grey.facebook {
  background-position: -146px -2293px;
}
.footerLogo.grey.twitter {
  background-position: -146px -2330px;
}
.footerLogo.grey.responsibleGaming {
  background-position: -146px -2369px;
}
.footerLogo.grey.gamestop {
  background-position: -146px -2401px;
}
.footerLogo.grey.sachikoGaming {
  background-position: -147px -2440px;
}
.footerLogo.grey.ncpg {
  background-position: -147px -2478px;
}
.footerLogo.grey.logico {
  background-position: -147px -2517px;
  width: 77px;
}
.footerLogo.grey.kethea {
  background-position: -147px -2551px;
}
.footerLogo.grey.AIGF {
  background-position: -146px -2594px;
}
.footerLogo.grey.playsafe-fr {
  background-position: -146px -2628px;
}
.footerLogo.grey.playsafe-nl {
  background-position: -146px -2666px;
}
.footerLogo.grey.playsafe-en {
  background-position: -146px -2702px;
}
.footerLogo.grey.spelin {
  background-position: -146px -2738px;
}
.footerLogo.grey.pcgblr {
  background-position: -146px -2776px;
}
.footerLogo.grey.pgcb {
  background-position: -146px -2812px;
}
.footerLogo.grey.davos {
  background-position: -146px -2850px;
}
.footerLogo.grey.adm {
  background-position: -146px -2886px;
}
.footerLogo.grey.fournier {
  background-position: -146px -2925px;
}
.footerLogo.grey.bgsc {
  background-position: -146px -2961px;
}
.footerLogo.grey.sicadpt {
  background-position: -146px -2993px;
}
.footerLogo.darmstadt {
  background-position: -146px -3035px;
}
.footerLogo.sandhausen {
  background-position: -146px -3072px;
}
.footerLogo.kiel {
  background-position: -146px -3109px;
}
.footerLogo.hannover {
  background-position: -146px -3146px;
}

@media (max-width: 767px) {
  .footer-grid .footerDabs .dabsHolder .dabs {
    display: none;
  }
  .footer-grid .footerDabs .dabsHolder .dabContent {
    margin: 10px 0 0;
  }
  .footerLogo {
    float: none;
    display: inline-block;
    height: 38px;
    margin: 0 5px;
  }
  .footerLogos {
    display: table;
    float: none;
    margin: auto;
    text-align: center;
    margin-bottom: 15px;
  }
  .footer-grid .mobile-button:hover {
    color: #4e4e4e;
    background-color: #282828;
    outline: none;
    text-decoration: none;
  }
  .footer-grid .footerLanguageMenu {
    color: #ffffff !important;
    display: none;
    font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
    font-size: 18px;
    margin: auto auto 20px;
    text-align: center;
  }
  .footer-grid .footerLanguageMenu a {
    color: #ffffff !important;
  }
}
@media (min-width: 768px) {
  .footerLogos {
    float: none;
    display: table;
    margin: auto;
  }
  .footerLogo {
    height: 38px;
    display: block;
    float: left;
    padding-bottom: 10px;
    margin: 0 5px;
  }
}
@media (min-width: 992px) {
  .footerLogos {
    float: right;
    display: table;
    margin: auto;
    padding-top: 5px;
  }
  .footerLogo {
    height: 38px;
    display: block;
    float: left;
    margin-left: 10px;
  }
}
@media (min-width: 1200px) {
  .footerLogos {
    float: right;
  }
  .footerLogo {
    height: 38px;
    display: block;
    float: left;
    margin-left: 10px;
  }
}

#biab_footer html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

#biab_footer body {
  margin: 0;
}

#biab_footer article,
#biab_footer aside,
#biab_footer details,
#biab_footer figcaption,
#biab_footer figure,
#biab_footer footer,
#biab_footer header,
#biab_footer hgroup,
#biab_footer main,
#biab_footer menu,
#biab_footer nav,
#biab_footer section,
#biab_footer summary {
  display: block;
}

#biab_footer audio,
#biab_footer canvas,
#biab_footer progress,
#biab_footer video {
  display: inline-block;
  vertical-align: baseline;
}

#biab_footer audio:not([controls]) {
  display: none;
  height: 0;
}

#biab_footer [hidden],
#biab_footer template {
  display: none;
}

#biab_footer a {
  background-color: transparent;
}

#biab_footer a:active,
#biab_footer a:hover {
  outline: 0;
}

#biab_footer abbr[title] {
  border-bottom: 1px dotted;
}

#biab_footer b,
#biab_footer strong {
  font-weight: bold;
}

#biab_footer dfn {
  font-style: italic;
}

#biab_footer h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

#biab_footer mark {
  background: #ff0;
  color: #000;
}

#biab_footer small {
  font-size: 80%;
}

#biab_footer sub,
#biab_footer sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

#biab_footer sup {
  top: -0.5em;
}

#biab_footer sub {
  bottom: -0.25em;
}

#biab_footer img {
  border: 0;
}

#biab_footer svg:not(:root) {
  overflow: hidden;
}

#biab_footer figure {
  margin: 1em 40px;
}

#biab_footer hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

#biab_footer pre {
  overflow: auto;
}

#biab_footer code,
#biab_footer kbd,
#biab_footer pre,
#biab_footer samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

#biab_footer button,
#biab_footer input,
#biab_footer optgroup,
#biab_footer select,
#biab_footer textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

#biab_footer button {
  overflow: visible;
}

#biab_footer button,
#biab_footer select {
  text-transform: none;
}

#biab_footer button,
#biab_footer html input[type='button'],
#biab_footer input[type='reset'],
#biab_footer input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}

#biab_footer button[disabled],
#biab_footer html input[disabled] {
  cursor: default;
}

#biab_footer button::-moz-focus-inner,
#biab_footer input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

#biab_footer input {
  line-height: normal;
}

#biab_footer input[type='checkbox'],
#biab_footer input[type='radio'] {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

#biab_footer input[type='number']::-webkit-inner-spin-button,
#biab_footer input[type='number']::-webkit-outer-spin-button {
  height: auto;
}

#biab_footer input[type='search'] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

#biab_footer input[type='search']::-webkit-search-cancel-button,
#biab_footer input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

#biab_footer fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

#biab_footer legend {
  border: 0;
  padding: 0;
}

#biab_footer textarea {
  overflow: auto;
}

#biab_footer optgroup {
  font-weight: bold;
}

#biab_footer table {
  border-collapse: collapse;
  border-spacing: 0;
}

#biab_footer td,
#biab_footer th {
  padding: 0;
}

#biab_footer * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#biab_footer *:before,
#biab_footer *:after {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#biab_footer html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#biab_footer body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
}

#biab_footer input,
#biab_footer button,
#biab_footer select,
#biab_footer textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

#biab_footer a {
  color: #337ab7;
  text-decoration: none;
}

#biab_footer a:hover,
#biab_footer a:focus {
  color: #23527c;
  text-decoration: underline;
}

#biab_footer a:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

#biab_footer figure {
  margin: 0;
}

#biab_footer img {
  vertical-align: middle;
}

#biab_footer .img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

#biab_footer .img-rounded {
  border-radius: 6px;
}

#biab_footer .img-thumbnail {
  padding: 4px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}

#biab_footer .img-circle {
  border-radius: 50%;
}

#biab_footer hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

#biab_footer .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

#biab_footer .sr-only-focusable:active,
#biab_footer .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

#biab_footer [role='button'] {
  cursor: pointer;
}

#biab_footer .container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  #biab_footer .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  #biab_footer .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  #biab_footer .container {
    width: 1170px;
  }
}

#biab_footer .container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

#biab_footer .row {
  margin-left: -15px;
  margin-right: -15px;
}

#biab_footer .col-xs-1,
#biab_footer .col-sm-1,
#biab_footer .col-md-1,
#biab_footer .col-lg-1,
#biab_footer .col-xs-2,
#biab_footer .col-sm-2,
#biab_footer .col-md-2,
#biab_footer .col-lg-2,
#biab_footer .col-xs-3,
#biab_footer .col-sm-3,
#biab_footer .col-md-3,
#biab_footer .col-lg-3,
#biab_footer .col-xs-4,
#biab_footer .col-sm-4,
#biab_footer .col-md-4,
#biab_footer .col-lg-4,
#biab_footer .col-xs-5,
#biab_footer .col-sm-5,
#biab_footer .col-md-5,
#biab_footer .col-lg-5,
#biab_footer .col-xs-6,
#biab_footer .col-sm-6,
#biab_footer .col-md-6,
#biab_footer .col-lg-6,
#biab_footer .col-xs-7,
#biab_footer .col-sm-7,
#biab_footer .col-md-7,
#biab_footer .col-lg-7,
#biab_footer .col-xs-8,
#biab_footer .col-sm-8,
#biab_footer .col-md-8,
#biab_footer .col-lg-8,
#biab_footer .col-xs-9,
#biab_footer .col-sm-9,
#biab_footer .col-md-9,
#biab_footer .col-lg-9,
#biab_footer .col-xs-10,
#biab_footer .col-sm-10,
#biab_footer .col-md-10,
#biab_footer .col-lg-10,
#biab_footer .col-xs-11,
#biab_footer .col-sm-11,
#biab_footer .col-md-11,
#biab_footer .col-lg-11,
#biab_footer .col-xs-12,
#biab_footer .col-sm-12,
#biab_footer .col-md-12,
#biab_footer .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

#biab_footer .col-xs-1,
#biab_footer .col-xs-2,
#biab_footer .col-xs-3,
#biab_footer .col-xs-4,
#biab_footer .col-xs-5,
#biab_footer .col-xs-6,
#biab_footer .col-xs-7,
#biab_footer .col-xs-8,
#biab_footer .col-xs-9,
#biab_footer .col-xs-10,
#biab_footer .col-xs-11,
#biab_footer .col-xs-12 {
  float: left;
}

#biab_footer .col-xs-12 {
  width: 100%;
}

#biab_footer .col-xs-11 {
  width: 91.66666667%;
}

#biab_footer .col-xs-10 {
  width: 83.33333333%;
}

#biab_footer .col-xs-9 {
  width: 75%;
}

#biab_footer .col-xs-8 {
  width: 66.66666667%;
}

#biab_footer .col-xs-7 {
  width: 58.33333333%;
}

#biab_footer .col-xs-6 {
  width: 50%;
}

#biab_footer .col-xs-5 {
  width: 41.66666667%;
}

#biab_footer .col-xs-4 {
  width: 33.33333333%;
}

#biab_footer .col-xs-3 {
  width: 25%;
}

#biab_footer .col-xs-2 {
  width: 16.66666667%;
}

#biab_footer .col-xs-1 {
  width: 8.33333333%;
}

#biab_footer .col-xs-pull-12 {
  right: 100%;
}

#biab_footer .col-xs-pull-11 {
  right: 91.66666667%;
}

#biab_footer .col-xs-pull-10 {
  right: 83.33333333%;
}

#biab_footer .col-xs-pull-9 {
  right: 75%;
}

#biab_footer .col-xs-pull-8 {
  right: 66.66666667%;
}

#biab_footer .col-xs-pull-7 {
  right: 58.33333333%;
}

#biab_footer .col-xs-pull-6 {
  right: 50%;
}

#biab_footer .col-xs-pull-5 {
  right: 41.66666667%;
}

#biab_footer .col-xs-pull-4 {
  right: 33.33333333%;
}

#biab_footer .col-xs-pull-3 {
  right: 25%;
}

#biab_footer .col-xs-pull-2 {
  right: 16.66666667%;
}

#biab_footer .col-xs-pull-1 {
  right: 8.33333333%;
}

#biab_footer .col-xs-pull-0 {
  right: auto;
}

#biab_footer .col-xs-push-12 {
  left: 100%;
}

#biab_footer .col-xs-push-11 {
  left: 91.66666667%;
}

#biab_footer .col-xs-push-10 {
  left: 83.33333333%;
}

#biab_footer .col-xs-push-9 {
  left: 75%;
}

#biab_footer .col-xs-push-8 {
  left: 66.66666667%;
}

#biab_footer .col-xs-push-7 {
  left: 58.33333333%;
}

#biab_footer .col-xs-push-6 {
  left: 50%;
}

#biab_footer .col-xs-push-5 {
  left: 41.66666667%;
}

#biab_footer .col-xs-push-4 {
  left: 33.33333333%;
}

#biab_footer .col-xs-push-3 {
  left: 25%;
}

#biab_footer .col-xs-push-2 {
  left: 16.66666667%;
}

#biab_footer .col-xs-push-1 {
  left: 8.33333333%;
}

#biab_footer .col-xs-push-0 {
  left: auto;
}

#biab_footer .col-xs-offset-12 {
  margin-left: 100%;
}

#biab_footer .col-xs-offset-11 {
  margin-left: 91.66666667%;
}

#biab_footer .col-xs-offset-10 {
  margin-left: 83.33333333%;
}

#biab_footer .col-xs-offset-9 {
  margin-left: 75%;
}

#biab_footer .col-xs-offset-8 {
  margin-left: 66.66666667%;
}

#biab_footer .col-xs-offset-7 {
  margin-left: 58.33333333%;
}

#biab_footer .col-xs-offset-6 {
  margin-left: 50%;
}

#biab_footer .col-xs-offset-5 {
  margin-left: 41.66666667%;
}

#biab_footer .col-xs-offset-4 {
  margin-left: 33.33333333%;
}

#biab_footer .col-xs-offset-3 {
  margin-left: 25%;
}

#biab_footer .col-xs-offset-2 {
  margin-left: 16.66666667%;
}

#biab_footer .col-xs-offset-1 {
  margin-left: 8.33333333%;
}

#biab_footer .col-xs-offset-0 {
  margin-left: 0;
}

@media (min-width: 768px) {
  #biab_footer .col-sm-1,
  #biab_footer .col-sm-2,
  #biab_footer .col-sm-3,
  #biab_footer .col-sm-4,
  #biab_footer .col-sm-5,
  #biab_footer .col-sm-6,
  #biab_footer .col-sm-7,
  #biab_footer .col-sm-8,
  #biab_footer .col-sm-9,
  #biab_footer .col-sm-10,
  #biab_footer .col-sm-11,
  #biab_footer .col-sm-12 {
    float: left;
  }

  #biab_footer .col-sm-12 {
    width: 100%;
  }

  #biab_footer .col-sm-11 {
    width: 91.66666667%;
  }

  #biab_footer .col-sm-10 {
    width: 83.33333333%;
  }

  #biab_footer .col-sm-9 {
    width: 75%;
  }

  #biab_footer .col-sm-8 {
    width: 66.66666667%;
  }

  #biab_footer .col-sm-7 {
    width: 58.33333333%;
  }

  #biab_footer .col-sm-6 {
    width: 50%;
  }

  #biab_footer .col-sm-5 {
    width: 41.66666667%;
  }

  #biab_footer .col-sm-4 {
    width: 33.33333333%;
  }

  #biab_footer .col-sm-3 {
    width: 25%;
  }

  #biab_footer .col-sm-2 {
    width: 16.66666667%;
  }

  #biab_footer .col-sm-1 {
    width: 8.33333333%;
  }

  #biab_footer .col-sm-pull-12 {
    right: 100%;
  }

  #biab_footer .col-sm-pull-11 {
    right: 91.66666667%;
  }

  #biab_footer .col-sm-pull-10 {
    right: 83.33333333%;
  }

  #biab_footer .col-sm-pull-9 {
    right: 75%;
  }

  #biab_footer .col-sm-pull-8 {
    right: 66.66666667%;
  }

  #biab_footer .col-sm-pull-7 {
    right: 58.33333333%;
  }

  #biab_footer .col-sm-pull-6 {
    right: 50%;
  }

  #biab_footer .col-sm-pull-5 {
    right: 41.66666667%;
  }

  #biab_footer .col-sm-pull-4 {
    right: 33.33333333%;
  }

  #biab_footer .col-sm-pull-3 {
    right: 25%;
  }

  #biab_footer .col-sm-pull-2 {
    right: 16.66666667%;
  }

  #biab_footer .col-sm-pull-1 {
    right: 8.33333333%;
  }

  #biab_footer .col-sm-pull-0 {
    right: auto;
  }

  #biab_footer .col-sm-push-12 {
    left: 100%;
  }

  #biab_footer .col-sm-push-11 {
    left: 91.66666667%;
  }

  #biab_footer .col-sm-push-10 {
    left: 83.33333333%;
  }

  #biab_footer .col-sm-push-9 {
    left: 75%;
  }

  #biab_footer .col-sm-push-8 {
    left: 66.66666667%;
  }

  #biab_footer .col-sm-push-7 {
    left: 58.33333333%;
  }

  #biab_footer .col-sm-push-6 {
    left: 50%;
  }

  #biab_footer .col-sm-push-5 {
    left: 41.66666667%;
  }

  #biab_footer .col-sm-push-4 {
    left: 33.33333333%;
  }

  #biab_footer .col-sm-push-3 {
    left: 25%;
  }

  #biab_footer .col-sm-push-2 {
    left: 16.66666667%;
  }

  #biab_footer .col-sm-push-1 {
    left: 8.33333333%;
  }

  #biab_footer .col-sm-push-0 {
    left: auto;
  }

  #biab_footer .col-sm-offset-12 {
    margin-left: 100%;
  }

  #biab_footer .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  #biab_footer .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  #biab_footer .col-sm-offset-9 {
    margin-left: 75%;
  }

  #biab_footer .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  #biab_footer .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  #biab_footer .col-sm-offset-6 {
    margin-left: 50%;
  }

  #biab_footer .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  #biab_footer .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  #biab_footer .col-sm-offset-3 {
    margin-left: 25%;
  }

  #biab_footer .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  #biab_footer .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  #biab_footer .col-sm-offset-0 {
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  #biab_footer .col-md-1,
  #biab_footer .col-md-2,
  #biab_footer .col-md-3,
  #biab_footer .col-md-4,
  #biab_footer .col-md-5,
  #biab_footer .col-md-6,
  #biab_footer .col-md-7,
  #biab_footer .col-md-8,
  #biab_footer .col-md-9,
  #biab_footer .col-md-10,
  #biab_footer .col-md-11,
  #biab_footer .col-md-12 {
    float: left;
  }

  #biab_footer .col-md-12 {
    width: 100%;
  }

  #biab_footer .col-md-11 {
    width: 91.66666667%;
  }

  #biab_footer .col-md-10 {
    width: 83.33333333%;
  }

  #biab_footer .col-md-9 {
    width: 75%;
  }

  #biab_footer .col-md-8 {
    width: 66.66666667%;
  }

  #biab_footer .col-md-7 {
    width: 58.33333333%;
  }

  #biab_footer .col-md-6 {
    width: 50%;
  }

  #biab_footer .col-md-5 {
    width: 41.66666667%;
  }

  #biab_footer .col-md-4 {
    width: 33.33333333%;
  }

  #biab_footer .col-md-3 {
    width: 25%;
  }

  #biab_footer .col-md-2 {
    width: 16.66666667%;
  }

  #biab_footer .col-md-1 {
    width: 8.33333333%;
  }

  #biab_footer .col-md-pull-12 {
    right: 100%;
  }

  #biab_footer .col-md-pull-11 {
    right: 91.66666667%;
  }

  #biab_footer .col-md-pull-10 {
    right: 83.33333333%;
  }

  #biab_footer .col-md-pull-9 {
    right: 75%;
  }

  #biab_footer .col-md-pull-8 {
    right: 66.66666667%;
  }

  #biab_footer .col-md-pull-7 {
    right: 58.33333333%;
  }

  #biab_footer .col-md-pull-6 {
    right: 50%;
  }

  #biab_footer .col-md-pull-5 {
    right: 41.66666667%;
  }

  #biab_footer .col-md-pull-4 {
    right: 33.33333333%;
  }

  #biab_footer .col-md-pull-3 {
    right: 25%;
  }

  #biab_footer .col-md-pull-2 {
    right: 16.66666667%;
  }

  #biab_footer .col-md-pull-1 {
    right: 8.33333333%;
  }

  #biab_footer .col-md-pull-0 {
    right: auto;
  }

  #biab_footer .col-md-push-12 {
    left: 100%;
  }

  #biab_footer .col-md-push-11 {
    left: 91.66666667%;
  }

  #biab_footer .col-md-push-10 {
    left: 83.33333333%;
  }

  #biab_footer .col-md-push-9 {
    left: 75%;
  }

  #biab_footer .col-md-push-8 {
    left: 66.66666667%;
  }

  #biab_footer .col-md-push-7 {
    left: 58.33333333%;
  }

  #biab_footer .col-md-push-6 {
    left: 50%;
  }

  #biab_footer .col-md-push-5 {
    left: 41.66666667%;
  }

  #biab_footer .col-md-push-4 {
    left: 33.33333333%;
  }

  #biab_footer .col-md-push-3 {
    left: 25%;
  }

  #biab_footer .col-md-push-2 {
    left: 16.66666667%;
  }

  #biab_footer .col-md-push-1 {
    left: 8.33333333%;
  }

  #biab_footer .col-md-push-0 {
    left: auto;
  }

  #biab_footer .col-md-offset-12 {
    margin-left: 100%;
  }

  #biab_footer .col-md-offset-11 {
    margin-left: 91.66666667%;
  }

  #biab_footer .col-md-offset-10 {
    margin-left: 83.33333333%;
  }

  #biab_footer .col-md-offset-9 {
    margin-left: 75%;
  }

  #biab_footer .col-md-offset-8 {
    margin-left: 66.66666667%;
  }

  #biab_footer .col-md-offset-7 {
    margin-left: 58.33333333%;
  }

  #biab_footer .col-md-offset-6 {
    margin-left: 50%;
  }

  #biab_footer .col-md-offset-5 {
    margin-left: 41.66666667%;
  }

  #biab_footer .col-md-offset-4 {
    margin-left: 33.33333333%;
  }

  #biab_footer .col-md-offset-3 {
    margin-left: 25%;
  }

  #biab_footer .col-md-offset-2 {
    margin-left: 16.66666667%;
  }

  #biab_footer .col-md-offset-1 {
    margin-left: 8.33333333%;
  }

  #biab_footer .col-md-offset-0 {
    margin-left: 0;
  }
}

@media (min-width: 1200px) {
  #biab_footer .col-lg-1,
  #biab_footer .col-lg-2,
  #biab_footer .col-lg-3,
  #biab_footer .col-lg-4,
  #biab_footer .col-lg-5,
  #biab_footer .col-lg-6,
  #biab_footer .col-lg-7,
  #biab_footer .col-lg-8,
  #biab_footer .col-lg-9,
  #biab_footer .col-lg-10,
  #biab_footer .col-lg-11,
  #biab_footer .col-lg-12 {
    float: left;
  }

  #biab_footer .col-lg-12 {
    width: 100%;
  }

  #biab_footer .col-lg-11 {
    width: 91.66666667%;
  }

  #biab_footer .col-lg-10 {
    width: 83.33333333%;
  }

  #biab_footer .col-lg-9 {
    width: 75%;
  }

  #biab_footer .col-lg-8 {
    width: 66.66666667%;
  }

  #biab_footer .col-lg-7 {
    width: 58.33333333%;
  }

  #biab_footer .col-lg-6 {
    width: 50%;
  }

  #biab_footer .col-lg-5 {
    width: 41.66666667%;
  }

  #biab_footer .col-lg-4 {
    width: 33.33333333%;
  }

  #biab_footer .col-lg-3 {
    width: 25%;
  }

  #biab_footer .col-lg-2 {
    width: 16.66666667%;
  }

  #biab_footer .col-lg-1 {
    width: 8.33333333%;
  }

  #biab_footer .col-lg-pull-12 {
    right: 100%;
  }

  #biab_footer .col-lg-pull-11 {
    right: 91.66666667%;
  }

  #biab_footer .col-lg-pull-10 {
    right: 83.33333333%;
  }

  #biab_footer .col-lg-pull-9 {
    right: 75%;
  }

  #biab_footer .col-lg-pull-8 {
    right: 66.66666667%;
  }

  #biab_footer .col-lg-pull-7 {
    right: 58.33333333%;
  }

  #biab_footer .col-lg-pull-6 {
    right: 50%;
  }

  #biab_footer .col-lg-pull-5 {
    right: 41.66666667%;
  }

  #biab_footer .col-lg-pull-4 {
    right: 33.33333333%;
  }

  #biab_footer .col-lg-pull-3 {
    right: 25%;
  }

  #biab_footer .col-lg-pull-2 {
    right: 16.66666667%;
  }

  #biab_footer .col-lg-pull-1 {
    right: 8.33333333%;
  }

  #biab_footer .col-lg-pull-0 {
    right: auto;
  }

  #biab_footer .col-lg-push-12 {
    left: 100%;
  }

  #biab_footer .col-lg-push-11 {
    left: 91.66666667%;
  }

  #biab_footer .col-lg-push-10 {
    left: 83.33333333%;
  }

  #biab_footer .col-lg-push-9 {
    left: 75%;
  }

  #biab_footer .col-lg-push-8 {
    left: 66.66666667%;
  }

  #biab_footer .col-lg-push-7 {
    left: 58.33333333%;
  }

  #biab_footer .col-lg-push-6 {
    left: 50%;
  }

  #biab_footer .col-lg-push-5 {
    left: 41.66666667%;
  }

  #biab_footer .col-lg-push-4 {
    left: 33.33333333%;
  }

  #biab_footer .col-lg-push-3 {
    left: 25%;
  }

  #biab_footer .col-lg-push-2 {
    left: 16.66666667%;
  }

  #biab_footer .col-lg-push-1 {
    left: 8.33333333%;
  }

  #biab_footer .col-lg-push-0 {
    left: auto;
  }

  #biab_footer .col-lg-offset-12 {
    margin-left: 100%;
  }

  #biab_footer .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  #biab_footer .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  #biab_footer .col-lg-offset-9 {
    margin-left: 75%;
  }

  #biab_footer .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  #biab_footer .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  #biab_footer .col-lg-offset-6 {
    margin-left: 50%;
  }

  #biab_footer .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  #biab_footer .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  #biab_footer .col-lg-offset-3 {
    margin-left: 25%;
  }

  #biab_footer .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  #biab_footer .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  #biab_footer .col-lg-offset-0 {
    margin-left: 0;
  }
}

#biab_footer table {
  background-color: transparent;
}

#biab_footer caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777;
  text-align: left;
}

#biab_footer th {
  text-align: left;
}

#biab_footer .table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

#biab_footer .table > thead > tr > th,
#biab_footer .table > tbody > tr > th,
#biab_footer .table > tfoot > tr > th,
#biab_footer .table > thead > tr > td,
#biab_footer .table > tbody > tr > td,
#biab_footer .table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

#biab_footer .table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

#biab_footer .table > caption + thead > tr:first-child > th,
#biab_footer .table > colgroup + thead > tr:first-child > th,
#biab_footer .table > thead:first-child > tr:first-child > th,
#biab_footer .table > caption + thead > tr:first-child > td,
#biab_footer .table > colgroup + thead > tr:first-child > td,
#biab_footer .table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

#biab_footer .table > tbody + tbody {
  border-top: 2px solid #ddd;
}

#biab_footer .table .table {
  background-color: #fff;
}

#biab_footer .table-condensed > thead > tr > th,
#biab_footer .table-condensed > tbody > tr > th,
#biab_footer .table-condensed > tfoot > tr > th,
#biab_footer .table-condensed > thead > tr > td,
#biab_footer .table-condensed > tbody > tr > td,
#biab_footer .table-condensed > tfoot > tr > td {
  padding: 5px;
}

#biab_footer .table-bordered {
  border: 1px solid #ddd;
}

#biab_footer .table-bordered > thead > tr > th,
#biab_footer .table-bordered > tbody > tr > th,
#biab_footer .table-bordered > tfoot > tr > th,
#biab_footer .table-bordered > thead > tr > td,
#biab_footer .table-bordered > tbody > tr > td,
#biab_footer .table-bordered > tfoot > tr > td {
  border: 1px solid #ddd;
}

#biab_footer .table-bordered > thead > tr > th,
#biab_footer .table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}

#biab_footer .table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

#biab_footer .table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}

#biab_footer table col[class*='col-'] {
  position: static;
  float: none;
  display: table-column;
}

#biab_footer table td[class*='col-'],
#biab_footer table th[class*='col-'] {
  position: static;
  float: none;
  display: table-cell;
}

#biab_footer .table > thead > tr > td.active,
#biab_footer .table > tbody > tr > td.active,
#biab_footer .table > tfoot > tr > td.active,
#biab_footer .table > thead > tr > th.active,
#biab_footer .table > tbody > tr > th.active,
#biab_footer .table > tfoot > tr > th.active,
#biab_footer .table > thead > tr.active > td,
#biab_footer .table > tbody > tr.active > td,
#biab_footer .table > tfoot > tr.active > td,
#biab_footer .table > thead > tr.active > th,
#biab_footer .table > tbody > tr.active > th,
#biab_footer .table > tfoot > tr.active > th {
  background-color: #f5f5f5;
}

#biab_footer .table-hover > tbody > tr > td.active:hover,
#biab_footer .table-hover > tbody > tr > th.active:hover,
#biab_footer .table-hover > tbody > tr.active:hover > td,
#biab_footer .table-hover > tbody > tr:hover > .active,
#biab_footer .table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8;
}

#biab_footer .table > thead > tr > td.success,
#biab_footer .table > tbody > tr > td.success,
#biab_footer .table > tfoot > tr > td.success,
#biab_footer .table > thead > tr > th.success,
#biab_footer .table > tbody > tr > th.success,
#biab_footer .table > tfoot > tr > th.success,
#biab_footer .table > thead > tr.success > td,
#biab_footer .table > tbody > tr.success > td,
#biab_footer .table > tfoot > tr.success > td,
#biab_footer .table > thead > tr.success > th,
#biab_footer .table > tbody > tr.success > th,
#biab_footer .table > tfoot > tr.success > th {
  background-color: #dff0d8;
}

#biab_footer .table-hover > tbody > tr > td.success:hover,
#biab_footer .table-hover > tbody > tr > th.success:hover,
#biab_footer .table-hover > tbody > tr.success:hover > td,
#biab_footer .table-hover > tbody > tr:hover > .success,
#biab_footer .table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6;
}

#biab_footer .table > thead > tr > td.info,
#biab_footer .table > tbody > tr > td.info,
#biab_footer .table > tfoot > tr > td.info,
#biab_footer .table > thead > tr > th.info,
#biab_footer .table > tbody > tr > th.info,
#biab_footer .table > tfoot > tr > th.info,
#biab_footer .table > thead > tr.info > td,
#biab_footer .table > tbody > tr.info > td,
#biab_footer .table > tfoot > tr.info > td,
#biab_footer .table > thead > tr.info > th,
#biab_footer .table > tbody > tr.info > th,
#biab_footer .table > tfoot > tr.info > th {
  background-color: #d9edf7;
}

#biab_footer .table-hover > tbody > tr > td.info:hover,
#biab_footer .table-hover > tbody > tr > th.info:hover,
#biab_footer .table-hover > tbody > tr.info:hover > td,
#biab_footer .table-hover > tbody > tr:hover > .info,
#biab_footer .table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3;
}

#biab_footer .table > thead > tr > td.warning,
#biab_footer .table > tbody > tr > td.warning,
#biab_footer .table > tfoot > tr > td.warning,
#biab_footer .table > thead > tr > th.warning,
#biab_footer .table > tbody > tr > th.warning,
#biab_footer .table > tfoot > tr > th.warning,
#biab_footer .table > thead > tr.warning > td,
#biab_footer .table > tbody > tr.warning > td,
#biab_footer .table > tfoot > tr.warning > td,
#biab_footer .table > thead > tr.warning > th,
#biab_footer .table > tbody > tr.warning > th,
#biab_footer .table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}

#biab_footer .table-hover > tbody > tr > td.warning:hover,
#biab_footer .table-hover > tbody > tr > th.warning:hover,
#biab_footer .table-hover > tbody > tr.warning:hover > td,
#biab_footer .table-hover > tbody > tr:hover > .warning,
#biab_footer .table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc;
}

#biab_footer .table > thead > tr > td.danger,
#biab_footer .table > tbody > tr > td.danger,
#biab_footer .table > tfoot > tr > td.danger,
#biab_footer .table > thead > tr > th.danger,
#biab_footer .table > tbody > tr > th.danger,
#biab_footer .table > tfoot > tr > th.danger,
#biab_footer .table > thead > tr.danger > td,
#biab_footer .table > tbody > tr.danger > td,
#biab_footer .table > tfoot > tr.danger > td,
#biab_footer .table > thead > tr.danger > th,
#biab_footer .table > tbody > tr.danger > th,
#biab_footer .table > tfoot > tr.danger > th {
  background-color: #f2dede;
}

#biab_footer .table-hover > tbody > tr > td.danger:hover,
#biab_footer .table-hover > tbody > tr > th.danger:hover,
#biab_footer .table-hover > tbody > tr.danger:hover > td,
#biab_footer .table-hover > tbody > tr:hover > .danger,
#biab_footer .table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc;
}

#biab_footer .table-responsive {
  overflow-x: auto;
  min-height: 0.01%;
}

@media screen and (max-width: 767px) {
  #biab_footer .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }

  #biab_footer .table-responsive > .table {
    margin-bottom: 0;
  }

  #biab_footer .table-responsive > .table > thead > tr > th,
  #biab_footer .table-responsive > .table > tbody > tr > th,
  #biab_footer .table-responsive > .table > tfoot > tr > th,
  #biab_footer .table-responsive > .table > thead > tr > td,
  #biab_footer .table-responsive > .table > tbody > tr > td,
  #biab_footer .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }

  #biab_footer .table-responsive > .table-bordered {
    border: 0;
  }

  #biab_footer .table-responsive > .table-bordered > thead > tr > th:first-child,
  #biab_footer .table-responsive > .table-bordered > tbody > tr > th:first-child,
  #biab_footer .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  #biab_footer .table-responsive > .table-bordered > thead > tr > td:first-child,
  #biab_footer .table-responsive > .table-bordered > tbody > tr > td:first-child,
  #biab_footer .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }

  #biab_footer .table-responsive > .table-bordered > thead > tr > th:last-child,
  #biab_footer .table-responsive > .table-bordered > tbody > tr > th:last-child,
  #biab_footer .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  #biab_footer .table-responsive > .table-bordered > thead > tr > td:last-child,
  #biab_footer .table-responsive > .table-bordered > tbody > tr > td:last-child,
  #biab_footer .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }

  #biab_footer .table-responsive > .table-bordered > tbody > tr:last-child > th,
  #biab_footer .table-responsive > .table-bordered > tfoot > tr:last-child > th,
  #biab_footer .table-responsive > .table-bordered > tbody > tr:last-child > td,
  #biab_footer .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}

#biab_footer .clearfix:before,
#biab_footer .clearfix:after,
#biab_footer .container:before,
#biab_footer .container:after,
#biab_footer .container-fluid:before,
#biab_footer .container-fluid:after,
#biab_footer .row:before,
#biab_footer .row:after {
  content: ' ';
  display: table;
}

#biab_footer .clearfix:after,
#biab_footer .container:after,
#biab_footer .container-fluid:after,
#biab_footer .row:after {
  clear: both;
}

#biab_footer .center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#biab_footer .pull-right {
  float: right !important;
}

#biab_footer .pull-left {
  float: left !important;
}

#biab_footer .hide {
  display: none !important;
}

#biab_footer .show {
  display: block !important;
}

#biab_footer .invisible {
  visibility: hidden;
}

#biab_footer .text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

#biab_footer .hidden {
  display: none !important;
}

#biab_footer .affix {
  position: fixed;
}

@-ms-viewport {
  #biab_footer {
    width: device-width;
  }
}

#biab_footer .visible-xs,
#biab_footer .visible-sm,
#biab_footer .visible-md,
#biab_footer .visible-lg {
  display: none !important;
}

#biab_footer .visible-xs-block,
#biab_footer .visible-xs-inline,
#biab_footer .visible-xs-inline-block,
#biab_footer .visible-sm-block,
#biab_footer .visible-sm-inline,
#biab_footer .visible-sm-inline-block,
#biab_footer .visible-md-block,
#biab_footer .visible-md-inline,
#biab_footer .visible-md-inline-block,
#biab_footer .visible-lg-block,
#biab_footer .visible-lg-inline,
#biab_footer .visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  #biab_footer .visible-xs {
    display: block !important;
  }

  #biab_footer table.visible-xs {
    display: table !important;
  }

  #biab_footer tr.visible-xs {
    display: table-row !important;
  }

  #biab_footer th.visible-xs,
  #biab_footer td.visible-xs {
    display: table-cell !important;
  }
}

@media (max-width: 767px) {
  #biab_footer .visible-xs-block {
    display: block !important;
  }
}

@media (max-width: 767px) {
  #biab_footer .visible-xs-inline {
    display: inline !important;
  }
}

@media (max-width: 767px) {
  #biab_footer .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #biab_footer .visible-sm {
    display: block !important;
  }

  #biab_footer table.visible-sm {
    display: table !important;
  }

  #biab_footer tr.visible-sm {
    display: table-row !important;
  }

  #biab_footer th.visible-sm,
  #biab_footer td.visible-sm {
    display: table-cell !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #biab_footer .visible-sm-block {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #biab_footer .visible-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #biab_footer .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  #biab_footer .visible-md {
    display: block !important;
  }

  #biab_footer table.visible-md {
    display: table !important;
  }

  #biab_footer tr.visible-md {
    display: table-row !important;
  }

  #biab_footer th.visible-md,
  #biab_footer td.visible-md {
    display: table-cell !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  #biab_footer .visible-md-block {
    display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  #biab_footer .visible-md-inline {
    display: inline !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  #biab_footer .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  #biab_footer .visible-lg {
    display: block !important;
  }

  #biab_footer table.visible-lg {
    display: table !important;
  }

  #biab_footer tr.visible-lg {
    display: table-row !important;
  }

  #biab_footer th.visible-lg,
  #biab_footer td.visible-lg {
    display: table-cell !important;
  }
}

@media (min-width: 1200px) {
  #biab_footer .visible-lg-block {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  #biab_footer .visible-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1200px) {
  #biab_footer .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  #biab_footer .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #biab_footer .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  #biab_footer .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  #biab_footer .hidden-lg {
    display: none !important;
  }
}

#biab_footer .visible-print {
  display: none !important;
}

@media print {
  #biab_footer .visible-print {
    display: block !important;
  }

  #biab_footer table.visible-print {
    display: table !important;
  }

  #biab_footer tr.visible-print {
    display: table-row !important;
  }

  #biab_footer th.visible-print,
  #biab_footer td.visible-print {
    display: table-cell !important;
  }
}

#biab_footer .visible-print-block {
  display: none !important;
}

@media print {
  #biab_footer .visible-print-block {
    display: block !important;
  }
}

#biab_footer .visible-print-inline {
  display: none !important;
}

@media print {
  #biab_footer .visible-print-inline {
    display: inline !important;
  }
}

#biab_footer .visible-print-inline-block {
  display: none !important;
}

@media print {
  #biab_footer .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  #biab_footer .hidden-print {
    display: none !important;
  }
}

#biab_footer body {
  margin: 0px;
  background-color: #eaeaea;
}

#biab_footer #footer {
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 10px;
  width: 980px;
  margin: auto;
  position: relative;
  z-index: 2;
  color: #777;
  font-size: 9px;
  padding-top: 25px;
}

#biab_footer #footer {
  width: 100% !important;
  height: 100%;
  padding-top: 0px;
}

#biab_footer .footer-grid {
  padding: 20px 0 0;
  color: #6f6f6f;
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 10px;
}

#biab_footer .footer-grid h3 {
  font-size: 11px;
  font-weight: bold;
  margin: 0 0 10px;
  padding: 0;
  color: #666;
}

#biab_footer .footer-grid a {
  color: #666;
  text-decoration: none;
}

#biab_footer .footer-grid a:hover {
  text-decoration: underline;
}

#biab_footer .footer-grid .footerDabs .dabsHolder .dabs {
  border: 1px solid #282828;
  width: 100%;
  font-family: Verdana, sans-serif;
  display: table;
  font-size: 11px;
}

#biab_footer .footer-grid .footerDabs .dabsHolder .dabs a {
  display: table-cell;
  background: #111;
  outline: none;
  padding: 4px 0;
  text-align: center;
  border-right: 1px solid #282828;
  color: #666;
  cursor: pointer;
  text-decoration: none;
}

#biab_footer .footer-grid .footerDabs .dabsHolder .dabs a.last {
  border-right: 0px solid #282828;
}

#biab_footer .footer-grid .footerDabs .dabsHolder .dabs a.active,
#biab_footer .footer-grid .footerDabs .dabsHolder .dabs a:hover {
  color: #4e4e4e;
  background: #282828;
}

#biab_footer .footer-grid .footerDabs .dabsHolder .dabContent {
  min-height: 75px;
  margin: 25px 0 0;
}

#biab_footer .footer-grid .footerDabs .dabsHolder .dabContent ul {
  list-style-type: none;
}

#biab_footer .footer-grid .footerDabs .dabsHolder .dabContent ul li {
  padding: 3px 0 3px 0;
  line-height: 1em;
}

#biab_footer .footer-grid .mobile-button {
  padding-top: 15px;
  padding-bottom: 15px;
  color: #8b8b8b;
  // background: url('/assets/common/images/layout/footer/footerDownArrow.gif') no-repeat scroll 95% 50% #181818;
  font-size: 12px;
  text-align: center;
  border-bottom: 1px solid #000;
  border-top: 1px solid #222;
  outline: none;
}

#biab_footer .footer-grid .footerMiddle {
  border-bottom: 1px solid #282828;
  border-top: 1px solid #282828;
  padding: 20px 0 10px 0;
  margin: 10px 0;
}

#biab_footer .footer-grid .copyright p {
  padding: 0;
  margin: 0 0 10px;
}

#biab_footer .footer-grid .footerLanguageMenu {
  display: none;
}

#biab_footer .footerLogos {
  display: inline-block;
  float: none;
  margin: auto;
  text-align: center;
}

#biab_footer #footerCombo,
#biab_footer .footerButton {
  font-size: 12px;
  text-align: center;
  color: #8b8b8b;
  display: block;
}

#biab_footer #footerCombo select {
  background: #181818;
  color: #fff;
  border: none;
}

#biab_footer #footerCombo {
  display: none !important;
}

#biab_footer .footerLogos {
  display: block;
  float: none;
  margin: auto;
  text-align: center;
  margin: 0 5px;
}

#biab_footer .footerLogo {
  background-image: url('//s4.rationalcdn.com/img/bg/estate/footer-small.png');
  background-repeat: no-repeat;
  height: 38px;
}

#biab_footer .footerLogo.rationalGroup {
  background-position: 0 0;
  width: 80px;
}

#biab_footer .footerLogo.fps {
  background-position: 0 -38px;
  width: 80px;
}

#biab_footer .footerLogo.ept {
  background-position: 0 -76px;
  width: 80px;
}

#biab_footer .footerLogo.arjel {
  background-position: 0 -113px;
  width: 42px;
}

#biab_footer .footerLogo.plus18 {
  background-position: 0 -150px;
  width: 35px;
}

#biab_footer .footerLogo.adictel {
  background-position: 0 -187px;
  width: 88px;
}

#biab_footer .footerLogo.igc {
  background-position: 0 -224px;
  width: 50px;
}

#biab_footer .footerLogo.cigital {
  background-position: 0 -261px;
  width: 61px;
}

#biab_footer .footerLogo.secure {
  background-position: 0 -297px;
  width: 37px;
}

#biab_footer .footerLogo.exclusion {
  background-position: 0 -334px;
  width: 37px;
}

#biab_footer .footerLogo.amaya {
  background-position: 0 -372px;
  width: 80px;
}

#biab_footer .footerLogo.gamcareCert {
  background-position: 0 -409px;
  width: 48px;
}

#biab_footer .footerLogo.gamcare {
  background-position: 0 -444px;
  width: 113px;
}

#biab_footer .footerLogo.estrellas {
  background-position: 0 -484px;
  width: 91px;
}

#biab_footer .footerLogo.fejar {
  background-position: 0 -521px;
  width: 35px;
}

#biab_footer .footerLogo.malta {
  background-position: 0 -560px;
  width: 110px;
}

#biab_footer .footerLogo.gamingLabs {
  background-position: 0 -596px;
  width: 61px;
}

#biab_footer .footerLogo.juegoSeguro {
  background-position: 0 -632px;
  width: 110px;
}

#biab_footer .footerLogo.iom {
  background-position: 0 -670px;
  width: 39px;
}

#biab_footer .footerLogo.plus21 {
  background-position: 0 -705px;
  width: 35px;
}

#biab_footer .footerLogo.aams {
  background-position: 0 -742px;
  width: 45px;
}

#biab_footer .footerLogo.aamsCert {
  background-position: 0 -780px;
  width: 60px;
}

#biab_footer .footerLogo.bps {
  background-position: 0 -816px;
  width: 75px;
}

#biab_footer .footerLogo.casinoAmur {
  background-position: 0 -853px;
  width: 77px;
}

#biab_footer .footerLogo.bgc {
  background-position: 0 -889px;
  width: 30px;
}

#biab_footer .footerLogo.gg {
  background-position: 0 -925px;
  width: 37px;
}

#biab_footer .footerLogo.resorts {
  background-position: 0 -961px;
  width: 77px;
}

#biab_footer .footerLogo.ccgnj {
  background-position: 0 -999px;
  width: 23px;
}

#biab_footer .footerLogo.aaj {
  background-position: 0 -1037px;
  width: 85px;
}

#biab_footer .footerLogo.gokhulp {
  background-position: 0 -1074px;
  width: 85px;
}

#biab_footer .footerLogo.licensedNJ {
  background-position: 0 -1110px;
  width: 37px;
}

#biab_footer .footerLogo.gamblingCommission {
  background-position: 0 -1144px;
  width: 80px;
}

#biab_footer .footerLogo.mga {
  background-position: 0 -1183px;
  width: 75px;
}

#biab_footer .footerLogo.JugarBIEN {
  background-position: 0 -1259px;
  width: 107px;
}

#biab_footer .footerLogo.xe {
  background-position: 0 -1294px;
  width: 45px;
}

#biab_footer .footerLogo.sh {
  background-position: 0 -1331px;
  width: 35px;
}

#biab_footer .footerLogo.trustIt {
  background-position: 0 -1368px;
  width: 60px;
}

#biab_footer .footerLogo.evalujeu-long {
  background-position: 0 -1407px;
  width: 250px;
}

#biab_footer .footerLogo.evalujeu {
  background-position: 0 -1407px;
  width: 95px;
}

#biab_footer .footerLogo.rg {
  background-position: 0 -1480px;
  width: 35px;
}

#biab_footer .footerLogo.affiliates {
  background-position: 0 -1515px;
  width: 70px;
}

#biab_footer .footerLogo.nj-resorts {
  background-position: 0 -1554px;
  width: 43px;
}

#biab_footer .footerLogo.gambler-800 {
  background-position: 0 -1591px;
  width: 92px;
}

#biab_footer .footerLogo.dge {
  background-position: 0 -1628px;
  width: 68px;
}

#biab_footer .footerLogo.gambleaware {
  background-position: 0 -1664px;
  width: 110px;
}

#biab_footer .footerLogo.srij_1 {
  background-position: 0px -1702px;
  width: 35px;
}

#biab_footer .footerLogo.srij_2 {
  background-position: 0 -1739px;
  width: 35px;
}

#biab_footer .footerLogo.srij_3 {
  background-position: 0 -1776px;
  width: 35px;
}

#biab_footer .footerLogo.gamblingtherapy {
  background-position: 0 -1813px;
  width: 40px;
}

#biab_footer .footerLogo.nog {
  background-position: 0px -1850px;
  width: 38px;
}

#biab_footer .footerLogo.mof {
  background-position: 0 -1888px;
  width: 29px;
}

#biab_footer .footerLogo.ESSA {
  background-position: 0 -1918px;
  width: 145px;
}

#biab_footer .footerLogo.pslive {
  background-position: 0 -1963px;
  width: 112px;
}

#biab_footer .footerLogo.helpDe {
  background-position: 0 -1999px;
  width: 79px;
}

#biab_footer .footerLogo.helpEn {
  background-position: 0 -2035px;
  width: 79px;
}

#biab_footer .footerLogo.playsafe {
  background-position: 0 -2072px;
  width: 52px;
}

#biab_footer .footerLogo.safeIOM {
  background-position: 0 -2108px;
  width: 140px;
}

#biab_footer .footerLogo.safeIT {
  background-position: 0 -2145px;
  width: 146px;
}

#biab_footer .footerLogo.safePT {
  background-position: 0 -2182px;
  width: 132px;
}

#biab_footer .footerLogo.safeRO {
  background-position: 0 -2219px;
  width: 134px;
}

#biab_footer .footerLogo.starsGroup {
  background-position: 0 -2255px;
  width: 75px;
}

#biab_footer .footerLogo.facebook {
  background-position: 0 -2293px;
  width: 35px;
}

#biab_footer .footerLogo.twitter {
  background-position: 0 -2330px;
  width: 40px;
}

#biab_footer .footerLogo.responsibleGaming {
  background-position: 0px -2369px;
  width: 35px;
}

#biab_footer .footerLogo.gamestop {
  background-position: 0 -2401px;
  width: 79px;
}

#biab_footer .footerLogo.sachikoGaming {
  background-position: 0 -2442px;
  width: 79px;
}

#biab_footer .footerLogo.ncpg {
  background-position: 0 -2478px;
  width: 77px;
}

#biab_footer .footerLogo.logico {
  background-position: 0 -2517px;
  width: 77px;
}

#biab_footer .footerLogo.kethea {
  background-position: 0 -2551px;
  width: 77px;
}

#biab_footer .footerLogo.AIGF {
  background-position: 0 -2594px;
  width: 36px;
}

#biab_footer .footerLogo.playsafe-fr {
  background-position: 0 -2628px;
  width: 148px;
}

#biab_footer .footerLogo.playsafe-nl {
  background-position: 0 -2666px;
  width: 148px;
}

#biab_footer .footerLogo.playsafe-en {
  background-position: 0 -2702px;
  width: 148px;
}

#biab_footer .footerLogo.spelin {
  background-position: 0 -2738px;
  width: 47px;
}

#biab_footer .footerLogo.pcgblr {
  background-position: 0px -2776px;
  width: 120px;
}

#biab_footer .footerLogo.pgcb {
  background-position: 0 -2812px;
  width: 35px;
}

#biab_footer .footerLogo.davos {
  background-position: 0 -2850px;
  width: 62px;
}

#biab_footer .footerLogo.adm {
  background-position: 0 -2886px;
  width: 85px;
}

#biab_footer .footerLogo.fournier {
  background-position: 0 -2925px;
  width: 77px;
}

#biab_footer .footerLogo.bgsc {
  background-position: 0 -2961px;
  width: 135px;
}

#biab_footer .footerLogo.sicadpt {
  background-position: 0 -2993px;
  width: 78px;
}

#biab_footer .footerLogo.darmstadt {
  background-position: 0 -3035px;
  width: 38px;
}

#biab_footer .footerLogo.sandhausen {
  background-position: 0 -3072px;
  width: 33px;
}

#biab_footer .footerLogo.kiel {
  background-position: 0 -3109px;
  width: 38px;
}

#biab_footer .footerLogo.hannover {
  background-position: 0 -3146px;
  width: 43px;
}

#biab_footer .footerLogo.grey.rationalGroup {
  background-position: -146px 0;
}

#biab_footer .footerLogo.grey.fps {
  background-position: -146px -38px;
}

#biab_footer .footerLogo.grey.ept {
  background-position: -146px -76px;
}

#biab_footer .footerLogo.grey.arjel {
  background-position: -146px -113px;
}

#biab_footer .footerLogo.grey.plus18 {
  background-position: -146px -150px;
}

#biab_footer .footerLogo.grey.adictel {
  background-position: -146px -187px;
}

#biab_footer .footerLogo.grey.igc {
  background-position: -146px -224px;
}

#biab_footer .footerLogo.grey.cigital {
  background-position: -146px -261px;
}

#biab_footer .footerLogo.grey.secure {
  background-position: -146px -297px;
}

#biab_footer .footerLogo.grey.exclusion {
  background-position: -146px -334px;
}

#biab_footer .footerLogo.grey.amaya {
  background-position: -146px -372px;
}

#biab_footer .footerLogo.grey.gamcareCert {
  background-position: -146px -409px;
}

#biab_footer .footerLogo.grey.gamcare {
  background-position: -146px -444px;
}

#biab_footer .footerLogo.grey.estrellas {
  background-position: -146px -484px;
}

#biab_footer .footerLogo.grey.fejar {
  background-position: -146px -521px;
}

#biab_footer .footerLogo.grey.malta {
  background-position: -146px -560px;
}

#biab_footer .footerLogo.grey.gamingLabs {
  background-position: -146px -596px;
}

#biab_footer .footerLogo.grey.juegoSeguro {
  background-position: -146px -632px;
}

#biab_footer .footerLogo.grey.iom {
  background-position: -146px -670px;
}

#biab_footer .footerLogo.grey.plus21 {
  background-position: -146px -705px;
}

#biab_footer .footerLogo.grey.aams {
  background-position: -146px -742px;
}

#biab_footer .footerLogo.grey.aamsCert {
  background-position: -146px -780px;
}

#biab_footer .footerLogo.grey.bps {
  background-position: -146px -816px;
}

#biab_footer .footerLogo.grey.casinoAmur {
  background-position: -146px -853px;
}

#biab_footer .footerLogo.grey.bgc {
  background-position: -146px -889px;
}

#biab_footer .footerLogo.grey.gg {
  background-position: -146px -925px;
}

#biab_footer .footerLogo.grey.resorts {
  background-position: -146px -961px;
}

#biab_footer .footerLogo.grey.ccgnj {
  background-position: -146px -999px;
}

#biab_footer .footerLogo.grey.aaj {
  background-position: -146px -1037px;
}

#biab_footer .footerLogo.grey.gokhulp {
  background-position: -146px -1074px;
}

#biab_footer .footerLogo.grey.licensedNJ {
  background-position: -146px -1110px;
}

#biab_footer .footerLogo.grey.gamblingCommission {
  background-position: -146px -1144px;
}

#biab_footer .footerLogo.grey.mga {
  background-position: -146px -1183px;
}

#biab_footer .footerLogo.grey.JugarBIEN {
  background-position: -146px -1259px;
}

#biab_footer .footerLogo.grey.xe {
  background-position: -146px -1294px;
}

#biab_footer .footerLogo.grey.sh {
  background-position: -146px -1331px;
}

#biab_footer .footerLogo.grey.trustIt {
  background-position: -146px -1368px;
}

#biab_footer .footerLogo.grey.evalujeu-long {
  background-position: 0 -1443px;
}

#biab_footer .footerLogo.grey.evalujeu {
  background-position: 0 -1443px;
}

#biab_footer .footerLogo.grey.rg {
  background-position: -146px -1480px;
}

#biab_footer .footerLogo.grey.affiliates {
  background-position: -146px -1515px;
}

#biab_footer .footerLogo.grey.nj-resorts {
  background-position: -146px -1554px;
}

#biab_footer .footerLogo.grey.gambler-800 {
  background-position: -146px -1591px;
}

#biab_footer .footerLogo.grey.dge {
  background-position: -146px -1628px;
}

#biab_footer .footerLogo.grey.gambleaware {
  background-position: -146px -1664px;
}

#biab_footer .footerLogo.grey.srij_1 {
  background-position: -146px -1702px;
}

#biab_footer .footerLogo.grey.srij_2 {
  background-position: -146px -1739px;
}

#biab_footer .footerLogo.grey.srij_3 {
  background-position: -146px -1776px;
}

#biab_footer .footerLogo.grey.gamblingtherapy {
  background-position: -146px -1813px;
}

#biab_footer .footerLogo.grey.nog {
  background-position: -146px -1850px;
}

#biab_footer .footerLogo.grey.mof {
  background-position: -146px -1888px;
}

#biab_footer .footerLogo.grey.ESSA {
  background-position: -146px -1918px;
}

#biab_footer .footerLogo.grey.pslive {
  background-position: -146px -1963px;
}

#biab_footer .footerLogo.grey.helpDe {
  background-position: -146px -1999px;
}

#biab_footer .footerLogo.grey.helpEn {
  background-position: -146px -2035px;
}

#biab_footer .footerLogo.grey.playsafe {
  background-position: -146px -2072px;
}

#biab_footer .footerLogo.grey.safeIOM {
  background-position: -146px -2108px;
}

#biab_footer .footerLogo.grey.safeIT {
  background-position: -146px -2145px;
}

#biab_footer .footerLogo.grey.safePT {
  background-position: -146px -2182px;
}

#biab_footer .footerLogo.grey.safeRO {
  background-position: -146px -2219px;
}

#biab_footer .footerLogo.grey.starsGroup {
  background-position: -146px -2255px;
}

#biab_footer .footerLogo.grey.facebook {
  background-position: -146px -2293px;
}

#biab_footer .footerLogo.grey.twitter {
  background-position: -146px -2330px;
}

#biab_footer .footerLogo.grey.responsibleGaming {
  background-position: -146px -2369px;
}

#biab_footer .footerLogo.grey.gamestop {
  background-position: -146px -2401px;
}

#biab_footer .footerLogo.grey.sachikoGaming {
  background-position: -147px -2440px;
}

#biab_footer .footerLogo.grey.ncpg {
  background-position: -147px -2478px;
}

#biab_footer .footerLogo.grey.logico {
  background-position: -147px -2517px;
  width: 77px;
}

#biab_footer .footerLogo.grey.kethea {
  background-position: -147px -2551px;
}

#biab_footer .footerLogo.grey.AIGF {
  background-position: -146px -2594px;
}

#biab_footer .footerLogo.grey.playsafe-fr {
  background-position: -146px -2628px;
}

#biab_footer .footerLogo.grey.playsafe-nl {
  background-position: -146px -2666px;
}

#biab_footer .footerLogo.grey.playsafe-en {
  background-position: -146px -2702px;
}

#biab_footer .footerLogo.grey.spelin {
  background-position: -146px -2738px;
}

#biab_footer .footerLogo.grey.pcgblr {
  background-position: -146px -2776px;
}

#biab_footer .footerLogo.grey.pgcb {
  background-position: -146px -2812px;
}

#biab_footer .footerLogo.grey.davos {
  background-position: -146px -2850px;
}

#biab_footer .footerLogo.grey.adm {
  background-position: -146px -2886px;
}

#biab_footer .footerLogo.grey.fournier {
  background-position: -146px -2925px;
}

#biab_footer .footerLogo.grey.bgsc {
  background-position: -146px -2961px;
}

#biab_footer .footerLogo.grey.sicadpt {
  background-position: -146px -2993px;
}

#biab_footer .footerLogo.darmstadt {
  background-position: -146px -3035px;
}

#biab_footer .footerLogo.sandhausen {
  background-position: -146px -3072px;
}

#biab_footer .footerLogo.kiel {
  background-position: -146px -3109px;
}

#biab_footer .footerLogo.hannover {
  background-position: -146px -3146px;
}

@media (max-width: 767px) {
  #biab_footer .footer-grid .footerDabs .dabsHolder .dabs {
    display: none;
  }

  #biab_footer .footer-grid .footerDabs .dabsHolder .dabContent {
    margin: 10px 0 0;
  }

  #biab_footer .footerLogo {
    float: none;
    display: inline-block;
    height: 38px;
    margin: 0 5px;
  }

  #biab_footer .footerLogos {
    display: table;
    float: none;
    margin: auto;
    text-align: center;
    margin-bottom: 15px;
  }

  #biab_footer .footer-grid .mobile-button:hover {
    color: #4e4e4e;
    background-color: #282828;
    outline: none;
    text-decoration: none;
  }

  #biab_footer .footer-grid .footerLanguageMenu {
    color: #fff !important;
    display: none;
    font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
    font-size: 18px;
    margin: auto auto 20px;
    text-align: center;
  }

  #biab_footer .footer-grid .footerLanguageMenu a {
    color: #fff !important;
  }
}

@media (min-width: 768px) {
  #biab_footer .footerLogos {
    float: none;
    display: table;
    margin: auto;
  }

  #biab_footer .footerLogo {
    height: 38px;
    display: block;
    float: left;
    padding-bottom: 10px;
    margin: 0 5px;
  }
}

@media (min-width: 992px) {
  #biab_footer .footerLogos {
    float: right;
    display: table;
    margin: auto;
    padding-top: 5px;
  }

  #biab_footer .footerLogo {
    height: 38px;
    display: block;
    float: left;
    margin-left: 10px;
  }
}

@media (min-width: 1200px) {
  #biab_footer .footerLogos {
    float: right;
  }

  #biab_footer .footerLogo {
    height: 38px;
    display: block;
    float: left;
    margin-left: 10px;
  }
}

@media (min-width: 999px) {
  #biab_footer .container {
    width: auto;
  }
}

#biab_footer #footer,
#biab_footer .footer-grid {
  color: inherit;
  font-size: inherit;
  background-color: inherit;
}
