.btn {
  all: unset;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  box-sizing: border-box;

  &__withLabel {
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-size: 14px;
    line-height: 20px;
  }

  &__applied {
    color: #FFFFFF !important;
    background-color: #3E3E3E !important;
  }

  i {
    font-size: 24px;
  }
}
