.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.navigationContainer {
  display: flex;
  align-items: center;
}

.iconsContainer {
  display: flex;
  align-items: center;
  height: 30px;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 35px;
  cursor: pointer;
}

.icon {
  font-size: 16px;

  &__divider:before {
    color: #666666 !important;
  }
}

.dropdownIcon {
  position: relative;
}

.rotatedIcon {
  transform: rotate(180deg);
}

.iconLink {
  text-decoration: none;
}

.logoutBtn {
  padding: 5px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logoutIcon, .logoutIcon::before {
  color: inherit !important;
}

.logoutBtnText {
  font-weight: 700;
  font-size: inherit;
  line-height: 20px;
  margin-right: 10px;
}

.dropdownContainer {
  display: flex;
  flex-direction: column;
  width: 120px;
  position: absolute;
  top: 35px;
  left: 0px;
  padding: 5px 0;
  background: #fff;
  z-index: 111;
  box-shadow: 0px 2px 8px 0px #181E5C26;
  border: 1px solid #DDDDDD;
  border-radius: 5px;
}

.navLink {
  padding: 5px 10px;
  font-weight: 400;
  line-height: 20px;
  font-size: 12px;
  text-decoration: none;

  &:hover {
    background-color: #ECECEC;
    transition: 0.3s;
  }
}