.toggleableHeader {
  &__header {
    display: flex;
    align-items: center;
    column-gap: 10px;
    justify-content: center;
    flex-direction: row-reverse;
  }

  &__title {
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    color: #303030;
    margin: 0;
    cursor: pointer;
  }

  &__title {
    &:hover {
      & + .toggleableHeader__toggleSwitch .toggleableHeader__toggleSwitch__switch {
        background-color: #c0c0c0;
      }

      & + .toggleableHeader__toggleSwitch .toggleableHeader__toggleSwitch__input:checked + .toggleableHeader__toggleSwitch__switch {
        background-color: #005F8F;
      }
    }
  }

  &__toggleSwitch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;

    &:hover {
      .toggleableHeader__toggleSwitch__input:not(:checked) + .toggleableHeader__toggleSwitch__switch {
        background-color: #c0c0c0 !important;
      }

      .toggleableHeader__toggleSwitch__input:checked + .toggleableHeader__toggleSwitch__switch {
        background-color: #005F8F;
      }
    }

    &__input {
      display: none;

      &:not(:checked) + .toggleableHeader__toggleSwitch__switch {
        background-color: #ddd !important;
      }

      &:checked + .toggleableHeader__toggleSwitch__switch {
        background-color: #0072ac;

        &:before {
          transform: translateX(16px);
          background-color: #fff;
        }
      }
    }

    &__switch {
      position: absolute;
      cursor: pointer;
      border-radius: 25px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: background-color 0.2s ease;

      &:before {
        position: absolute;
        content: "";
        left: 2px;
        top: 2px;
        width: 16px;
        height: 16px;
        background-color: #fff;
        border-radius: 50%;
        transition: transform 0.3s ease;
      }
    }
  }
}
