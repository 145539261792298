@import 'src/styles/variables/variables';

.loginForm {
  &__mobile {
    display: flex;
    flex-direction: column-reverse;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;

    &__mobile {
      justify-content: center;
      width: 100%;
      max-width: 870px;
      margin: 0 auto;
    }
  }

  &__input {
    width: 75px;
    font-size: $small;
    line-height: 22px;
    border: 1px solid $white;
    margin-top: 0;
    margin-right: 5px;
    padding: 0 10px;
    outline: none;
    -webkit-text-fill-color: black;

    &__orbit {
      -webkit-text-fill-color: white;
      transition: background-color 5000s 0s, color 5000s 0s;
    }

    &__mobile {
      width: 100%;
      line-height: 32px;
      margin-right: 0;
      margin-bottom: 20px;
      background-color: $blue-light;
      border-color: $blue-light;

      &[type='password'],
      &[type='text'] {
        border-radius: 5px;
      }
    }
  }

  &__usernameInputInModal {
    margin-bottom: 0;
    margin-top: 12px;
  }

  &__pwInputInModal {
    margin-bottom: 12px;
    margin-top: 10px;
  }

  &__button {
    height: 24px;
    border: none;
    font-size: $small;
    font-weight: 600;
    border-radius: 0;
    padding: 0 10px;

    &:hover {
      background-color: #b3b3b3;
      border-color: #9a9a9a;
      cursor: pointer;
    }

    &__mobile {
      font-size: 13px;
      margin-left: 0;
      border-radius: 5px;
      background-color: $white;
      text-transform: uppercase;
      height: 32px;
      color: $blue-light;
      border: 1px solid #e6e6e6;
    }
  }

  &__buttonInModal {
    width: calc(100% - 176px);
  }

  &__icon {
    margin-left: 9px;
    vertical-align: baseline;
    font-size: calc(100% - 2px);
  }

  &__error {
    position: absolute;
    right: 9px;
    font-size: $small;
    line-height: 20px;
    padding-top: 5px;
    white-space: nowrap;
    margin: 0;

    &__mobile {
      position: relative;
      white-space: pre-wrap;
      padding: 0 0 10px 0;
      text-align: center;
      font-size: $small;
    }
  }
}

.loginForm__row .loginForm__input {
  /* Chrome/Opera/Safari */
  &::-webkit-input-placeholder {
    color: $black;
  }

  &__mobile::-webkit-input-placeholder {
    color: $white;
  }
}

.loginForm__row .loginForm__input {
  /* Firefox 19+ */
  &::-moz-placeholder {
    color: $black;
  }

  &__mobile::-moz-placeholder {
    color: $white;
  }
}

.loginForm__row .loginForm__input{
  /* IE 10+ */
  &:-ms-input-placeholder {
    color: $black;
  }

  &__mobile:-ms-input-placeholder {
    color: $white;
  }
}

.loginForm__row .loginForm__input {
  /* Firefox 18- */
  &:-moz-placeholder {
    color: $black;
  }

  &__mobile:-moz-placeholder {
    color: $white;
  }
}
