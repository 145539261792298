.openedBetsWrap {
  position: relative;
}

.disableScroll {
  overflow-y: hidden;
  height: 330px;
}

.openedBetsLoading {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  opacity: 0.9;
  z-index: 100;
}

.tabs {
  display: flex;
  margin: 15px 10px;
  padding: 2px;
  gap: 2px;
  border-radius: 4px;
  background-color: #ECECEC;
  height: 30px;
}

.tab {
  all: unset;
  cursor: pointer;
  color: inherit;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  flex: 1;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;

  &__selected {
    background-color: #FFFFFF;
  }

  &__notSelected {
    &:hover {
      background-color: #dddddd;
    }
  }

  &__count {
    height: 15px;
    min-width: 15px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 700;
    color: #FFFFFF;
    padding: 0 4px;
    box-sizing: border-box;

    &__unmatched {
      background-color: #FE3629;
    }

    &__matched {
      background-color: #04A04A;
    }
  }
}