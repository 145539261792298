.main {
  margin-right: 3px;
  .notAvailable {
    width: 30px;
    height: 35px;
    border: 2px solid white;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 2px;
    box-shadow: 1px 1px 2px #7f7f7f;
    display: inline-block;
  }
}

.opacity {
  opacity: 0.6;
}
