@import "../../styles/mixins/breakpoints";

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: auto;
  line-height: 14px;
  min-width: 80px;
  border-bottom: 1px solid #f2f5f5;
  margin: 0;

  @include md {
    min-height: 46px;
  }

  @include xxs {
    white-space: nowrap;
    flex-wrap: nowrap;
  }

  &__title {
    font-weight: 600;
    @media (max-width: $md) {
      font-weight: bold !important;
    }
  }

  .firstBlock {
    display: flex;
    @media (max-width: $md) {
      flex-wrap: unset;
      align-items: end;
    }
  }
}
