.openBet {
  width: 100%;
  padding: 9px 10px 6px 10px;
  border: 1px solid #444;
  background-color: #ececec;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 5px;
  font-size: 10px;

  p {
    margin: 0;
  }

  &__live {
    background-color: #04a04a;
    color: #fff;
    padding: 0 5px;
    border-radius: 2px;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
  &__eventWrapper {
    display: flex;
    align-items: center;
    border-bottom: solid 1px #666;
    padding-bottom: 5px;
    margin-bottom: 3px;
    flex-flow: wrap;
  }
  &__event {
    text-decoration: underline;
    vertical-align: middle;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
  }
  &__competition {
    text-decoration: underline;
    vertical-align: middle;
    font-size: 10px;
    font-style: normal;
    line-height: 15px;
  }
  &__market {
    font-weight: 600;
    margin-bottom: 5px;
  }
  &__odds {
    font-weight: bold;
    &__sign {
      font-weight: normal;
      font-style: normal;
    }
  }
  &__row {
    line-height: 15px;
    display: flex;
    justify-content: space-between;
  }
  &__offerInfo {
    margin-top: 5px;
  }
  &__value {
    font-weight: 600;
  }
  &__offer {
    color: #999999;
  }
  &__date {
    color: #999999;
    &__time {
      margin-right: 10px;
    }
  }
  hr {
    border-top: 1px dashed #666;
    background: none;
    margin: 6px 0 4px;
  }
  &__triggeredByCashOut {
    border-color: #FFB80C;
  }
  &__triggeredByCashOutLabel {
    display: inline-block;
    background-color: #FFB80C;
    color: #303030;
    padding: 0 5px;
    border-radius: 2px;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    vertical-align: middle;
  }
  &__break {
    flex-basis: 100%;
    width: 0;
    height: 5px;
  }

  &__header {
    width: 100%;
    display: flex;
    column-gap: 6px;
    justify-content: space-between;
  }
  &__link {
    color: inherit !important;
  }
}
