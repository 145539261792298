.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 16px 17px 16px;
  background-color: #F5F5F5;
  border-radius: 4px;
  box-sizing: border-box;
  text-decoration: none;

  &__left {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
  }
}

.eventName {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: bold !important;
  line-height: 24px;
  color: #303030;
}

.competitionName {
  margin: 0;
  padding: 0;
}

.icon {
  font-size: 24px;
  color: #0072AC;
}
