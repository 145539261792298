@import "styles/mixins/breakpoints";

.main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 25px;
  padding: 10px 5px 20px;

  strong {
    font-weight: bold !important;
  }

  > span {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5px;
    align-items: center;

    &:nth-child(2) {
      font-weight: bold;

      i {
        font-size: 14px;
      }
    }
  }
  .button {
    padding: 0 10px;
    height: 30px;
    font-size: 12px;
    font-weight: 700;
    border: 0;
    border-radius: 2px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
  }

  .tickIcon:before {
    color: #20a052;
  }

  @include md {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px 5px;
    gap: 10px;
    > span {
      display: flex;
      justify-content: flex-start;
    }
    .status {
      width: 100%;
      height: 30px;
      justify-content: center;
    }
  }
}

.betslip {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  gap: 10px;
}

.coMobPage {
  flex-direction: column;
  padding-bottom: 0;

  .coMobPage__status {
    .coMobPage__removeIcon {
      font-size: 9px;
    }
  }

  &__checkIcon:before {
    font-size: 12px;
  }
}
