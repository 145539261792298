.container {
  position: relative;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background-color: #3F3F3F;
}

.title {
  font-size: 16px;
  line-height: 18px;
  font-weight: 700 !important;
  text-align: center;
  color: inherit;
}

.depositLink {
  color: #FFFFFF !important;
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
}

.empty {
  width: 42px;
  visibility: hidden;
}
