.container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 15px;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #fff;

  &__error {
    border: 1px solid #FE3629;
  }

  &__info {
    border: 1px solid #0072AC;
  }

  &__outline {
    border: none;
  }
}

.description {
  font-size: 12px;
  font-weight: 400;
  color: inherit;
  line-height: 20px;
}

.icon {
  margin-right: 10px;
  font-size: 16px;
  line-height: 16px;
}