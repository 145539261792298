.exportButton {
  all: unset;
  padding: 3px;
  color: #000000;
  border-radius: 50%;
  font-weight: bold;
  border: none;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    background-color: #ECECEC;
  }

  &__disabled {
    color: #C0C0C0;
    cursor: initial;

    &:hover {
      background-color: transparent;
    }
  }

  .icon {
    font-size: 16px;
    color: inherit;

    &__export {
      font-size: 24px;
    }
  }

  &__container {
    display: flex;
    gap: 20px;
  }
}

.error {
  position: absolute;
  right: 0;
  top: -5px;
  display: flex;
  gap: 10px;
  align-items: center;
  min-height: 40px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 20px;
  border-radius: 4px;
  border: 1px solid #FE3629;
  box-sizing: border-box;
  z-index: 2;

  &__icon {
    font-size: 16px;
  }

  &__closeBtn {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 25px;
    min-height: 25px;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: #ECECEC;
    }
  }

  &__closeIcon {
    font-size: 16px;
  }
}
