.periodTab {
  all: unset;
  min-width: 100px;
  height: 100%;
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 2px;
  cursor: pointer;

  &__selected {
    background-color: #FFFFFF;
  }

  &__mobile {
    font-size: 16px;
  }

  &:hover:not(:disabled) {
    background-color: #FFFFFF;
  }

  &:disabled {
    cursor: initial;
  }
}
