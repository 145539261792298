.icon {
  margin-right: 8px;
}

.name {
  display: flex;
  align-items: center;
}

.arrow_icon {
  transform: rotate(90deg);
}


