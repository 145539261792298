.main {
  padding: 4px 6px; // if the top and bottom padding are changed -> need to change TOP_AND_BOTTOM_PADDINGS value in component
  background-color: #303030;
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  line-height: 1.5;
}
