@import "../../styles/variables/variables";
@import "../../styles/mixins/breakpoints";

.searchContainer {
  position: relative;

  &__input {
    width: 100%;
    border: solid 2px $white;
    line-height: 20px;
    padding: 3px 25px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 3px;
    color: #273A47;
    font-size: $small;
    background-color: $white;
    outline: none;

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      background-color: $white;
    }
    @include md {
      line-height: 30px;
      padding: 3px 30px;
    }
  }

  i {
    &.searchContainer__icon {
      position: absolute;
      top: 0;
      left: 2px;
      width: 26px;
      height: 26px;
      font-size: 16px;
      line-height: 28px;
      vertical-align: middle;
      text-align: center;
      @include md {
        line-height: 38px;
        width: 30px;
        height: 30px;
      }
    }
    &.searchContainer__cross__icon {
      left: initial;
      right: 2px;
      cursor: pointer;
    }
  }
}

.searchTitle {
  background-color: #28A7E1;
  color: #fff;
  line-height: 30px;
  font-size: 14px;
  font-weight: 700;
  padding-left: 12px;
}

:global {
  .searchContainer {
    .searchTitle {
      @include md {
        font-weight: 700;
      }
    }
  }
  .biab_search-container .biab_search-input {
    &::placeholder {
      opacity: 1; /* Firefox */
    }
  }
}