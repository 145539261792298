.mobileTableHeader {
  padding: 8px 0 16px;
  border-bottom: 1px solid #dddddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;

  &__left {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }

  &__dateRange {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    color: #303030;
    font-weight: 700 !important;
  }

  &__product {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
  }
}

.noData {
  text-align: center;
  font-size: 14px;
  margin: 0;
  height: 200px;
  line-height: 200px;
}

.mobile {
  &__commissionRange {
    border: 1px solid #ddd;
    color: #303030;
    flex: 1;
    border-radius: 4px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0;
    margin: 0;

    &__wrapper {
      display: flex;
      align-items: center;
      column-gap: 12px;
    }
  }

  &__filterBtn {
    flex: 1;
    width: 100%;
  }
}
