.leftNav {
  min-width: 178px;
  max-width: 12.5%;
  width: 12.5%;
  border-right: 2px solid #dfdfdf;
  &__collapsed {
    min-width: 35px;
    width: 35px;
    border-width: 1px !important;
  }
}

.hideMenuMain {
  all: unset;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 0 4px 0 11px;
  align-items: center;
  height: 20px;
  cursor: pointer;
  background: #ccc;
  color: #666;
}

.collapsedMenuMain {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 4px;
  height: 100%;
  button, a {
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    width: 26px;
    line-height: 26px;
    text-align: center;
    color: #999;
    font-size: 16px;
    border: none;
    outline: 0;
    cursor: pointer;
    &:hover {
      text-decoration: none;
      color: #fff;
      border-radius: 2px;
      background-color: #4d4d4d;
    }
  }
  button {
    i {
      font-size: 85%;
    }
    i.expandIcon {
      font-size: 110%;
    }
  }
}

.collapsedMenuWrapper {
  position: relative;
}