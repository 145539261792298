@import 'styles/mixins/breakpoints';

.headerAccountNavigation {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  margin-left: auto;

  &__content {
    display: flex;
    align-items: center;
    height: 30px;
  }

  &__links {
    display: inline-flex;
    cursor: pointer;
  }
}

.noPadding {
  padding: 0;
}

ul.headerAccountNavigation__links {
  @include min-md1145 {
    display: none !important;
  }
}
