@import "../../styles/variables/variables";
@import "../../styles/mixins/breakpoints";

.market {
  margin-top: 20px;

  &__flex {
    flex: 1 1;

    &__double {
      flex: 2 1;
    }
  }
}

.paddingRight {
  padding-right: 5px;
}

.runnerRow {
  display: flex;
  align-items: stretch;
  min-height: 32px;

  .cellContent {
    height: 100%;
    min-height: 33px;
    padding: 4px 0 3px 0;
    box-sizing: border-box;

    &__mobile {
      border-radius: 3px;
      width: 100%;
    }
  }

  &:first-child .keyLineRunners {
    border-bottom-width: 1px;
    border-style: solid;
  }

  .desktopKeyLine {
    border-left: 1px solid #f2f5f5;
  }
}

.keyLineContainer {
  &:last-child {
    .runnerName {
      border-bottom: none !important;
    }
  }
}

.marketsPart {
  display: flex;
  justify-content: center;
  width: 100%;
}

.nonRunnerWrapper {
  padding-left: 31px;
}

.cellBlock {
  display: flex;
  border-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #f2f5f5;
  position: relative;

  &__cell {
    width: 100%;
    min-height: 33px;

    & > div {
      min-height: inherit !important;
    }

    & div > span {
      min-height: auto;

      &:not([title=makeOffer]) {
        padding: 0 !important;
      }
    }

    &__mobile {
      width: 100%;

      & div > span:first-child {
        padding: 10px 0 2px 0;
      }

      @include md {
        padding: 3px 1px;
        border-left: none;
        background: transparent !important;
      }
    }
  }

  &__depth {
    justify-content: flex-end;

    & > div {
      justify-content: center !important;
    }
  }

  &__depthMobile {
    justify-content: flex-end;
  }

  & > div > div {
    display: contents;
  }

  &__homePageRow {
    border-bottom-width: 2px;
  }

  &__default {
    border-right-width: 0;
  }

  @include md {
    border-right-width: 0;
  }
}

.matched {
  width: 100%;
  color: $light-gray;
  font-size: $smaller;
}

.titleContainer {
  display: flex;
  width: 50%;
}

div.runnerName {
  position: relative;
  font-size: 11px;
  color: #1e1e1e;
  flex-grow: 1;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f5f5;

  &__wrapper, span {
    padding-left: 10px;
    font-weight: 700;
  }

  &__homePageRow {
    border-bottom: 2px solid #f2f5f5;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__titleWrapper {
    display: flex;
    align-items: center;
  }

  .runnerName__timeForm {
    &__expand {
      cursor: pointer;
      z-index: 5;

      i {
        display: inline-block;
      }
    }

    &_active i {
      transform: rotate(180deg);
    }

    &__nonRunner {
      cursor: initial;
      pointer-events: none;
      i {
        pointer-events: none;
        visibility: hidden;
      }
    }
  }

  @include md {
    font-size: 13px;
    font-weight: 700;
  }
}

.markets {
  display: flex;
  width: 100%;
}


.callBlock {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 34px;
  border-left: 1px solid #f2f5f5;
  flex-direction: column;
  text-align: center;
}

.back {
  background-color: #a6d8ff;
}

.lay {
  background-color: #fac9d4;
}

.odds {
  color: $dark-gray;
  font-size: 13px;
  padding-top: 7px;
  line-height: 12px;
  font-weight: 600;
}

.amount {
  color: $dark-gray;
  font-size: $smaller;
  line-height: 12px;
}

.keyLineRunners {
  background-color: #d7d7d7;
  border-color: #dfdfdf;
  border-width: 0;
  border-style: solid;
  border-left-width: 1px;

  @include md {
    border-left-width: 0;

    &:first-child {
      border-top-width: 1px;
    }

    &:last-child {
      border-bottom-width: 1px;
      border-top-width: 0;

      & .runnerName, & .cellBlock {
        border-bottom: 0;
      }
    }
  }
}

:global {
  .isNonRunnerWrapper {
    position: relative;

    .runnerName {
      width: auto;
      padding-right: 20px;
    }
  }
}

.statusLabel {
  display: flex;
  align-items: center;
}

.whatIf {
  &__raice {
    padding-left: 10px;
  }

  &__space {
    padding-left: 10px;
  }

  &__extraSpace {
    padding-left: 36px;
  }
}

.whatIfCashOutRunner {
  padding: 0 !important;
}

.cashOutRunnerWrapper {
  span {
   padding: 0 !important;
  }
}
