.viewDropdownWrap {
  position: relative;
  width: 100%;
}

.viewDropdown {
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  z-index: 20001;
  top: 50px;
  left: 50%;

  &__landscape {
    transform: translate(-50%, 0);
  }

  &__content {
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    overflow: hidden;
  }
}

.subItems {
  padding-left: 20px;
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000000;
  opacity: 0.5;
  z-index: 1000;
}
