@import "../../../../styles/mixins/breakpoints";

.timeFormRunner {
  &__container {
    padding: 15px 10px;
    border-bottom: 1px solid #f2f5f5;
  }

  &__infoWrapper {
    display: flex;
    flex-direction: row;

    &__vertical {
      flex-direction: column;

      .timeFormRunner__comments {
        margin-top: 20px;
      }
    }
  }

  &__info {
    padding-left: 0;
    margin: 0;
    list-style-type: none;

    li {
      line-height: 1.5em;
    }
  }

  &__description {
    flex-basis: 40%;
  }

  &__comments {
    flex-basis: 60%;

    p {
      margin: 0;
    }
  }

  &__descriptionLabel {
    margin-right: 5px;
    font-weight: bold !important;
  }

  &__header {
    display: block;
    font-weight: bold !important;
    margin-top: 0;
    margin-bottom: 10px;
  }

  &__verdict {
    margin-top: 10px;
    padding: 10px;
    color: #ffffff;
    background-color: #384B4C;
    border-radius: 4px;
    border: 1px solid #384B4C;

    p:not(:nth-last-child(1)) {
      margin-bottom: 10px;
    }
  }

  &__history {
    width: 100%;

    tr {
      text-align: left;
    }

    th, td {
      border-top: 1px solid #EEEEEE;
      width: 1%;
    }

    td {
      vertical-align: middle;
      padding: 5px;
      height: 27px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: inherit;
      @include md {
        white-space: normal;

        &:first-child {
          white-space: nowrap !important;
        }
      }

      @media (min-width: $md) {
        &:nth-child(3) {
          white-space: nowrap !important;
        }
      }
    }

    td, th {
      &:nth-last-child(1) {
        @media (min-width: $md) and (max-width: 1366px) {
          display: none;
        }
      }

      &:nth-last-child(2) {
        @media (min-width: $md) and (max-width: 1280px) {
          display: none;
        }
      }

      &:nth-last-child(3) {
        @media (min-width: $md) and (max-width: 1200px) {
          display: none;
        }
      }
    }

    &__wrapper {
      padding: 20px 0;
      overflow: hidden;
    }

    &__column {
      padding: 10px 5px;
      font-weight: bold !important;
    }
  }
}
