.raceContent {
  width: 100%;
  background-color: #FFFFFF;
  height: fit-content;
  border-radius: 0 0 8px 8px;
  padding-top: 10px;

  &__runnersWrapper {
    padding-right: 5px;
  }

  &__runnersStatusWrapper {
    position: relative;
    padding-right: 5px;

    &__greyhoundRacing {
      padding-left: 9px;
    }
  }

  &__timeForm {
    border-radius: 0 0 8px 8px;
  }

  &__arrowScroll {
    &__scrollWrapper {
      padding-top: 0 !important;
    }
  }

  &__gameTitleContainer {
    padding-left: 7px;
  }

  &__gameImage {
    margin-left: 9px;
    margin-right: 0;
  }

  &__gameTitle {
    padding-left: 9px;
  }
}

.runnerRow {
  padding-right: 0;
}

.mobilePlacement {
  margin-right: -5px;

  &__greyhound {
    margin-left: -9px;
  }
}
