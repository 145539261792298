.favorite {
  &__content {
    min-height: initial;
    background-color: #ebebeb;
  }

  &__collapseRow {
    background-color: #ebebeb;
  }
}
