@import "../../styles/variables/variables";
@import "../../styles/mixins/breakpoints";

.sortBy {
  position: relative;
  height: 22px;
  vertical-align: top;
  cursor: pointer;
  color: inherit;
  box-sizing: border-box;
  white-space: nowrap;
  border: none;
  background-color: transparent;

  span {
    padding-right: 10px;
  }

  i {
    height: fit-content;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 8px;

    &:before {
      font-size: inherit;
      font-weight: bold !important;
    }
  }

  &__up {
    margin-top: -5px;
  }

  &__down {
    margin-top: 3px;
  }

  &__active {
    font-weight: bold !important;

    i {
      bottom: 0 !important;
      top: 50%;
      transform: translate(0, -50%);
      font-weight: bold;
    }
  }
}
