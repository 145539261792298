.tabs {
  height: 42px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  background-color: #ECECEC;
  border-radius: 4px;
  box-sizing: border-box;
  flex: 1;
}

.tab {
  all: unset;
  height: 38px;
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-radius: 2px;
  cursor: pointer;

  &__selected {
    background-color: #FFFFFF;
  }
}
