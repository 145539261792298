@import "styles/variables/variables";
@import "styles/mixins/breakpoints";

.rules {
  &__icon {
    font-size: 15px;
    cursor: pointer;
    box-sizing: border-box;

    @include md {
      background-color: $white;
      color: #2aa7e5;
    }
  }

  &__modal {
    font-weight: 100;

    &__text {
      margin-top: 20px;
    }
  }
}
