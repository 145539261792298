.competition {
  &__title {
    box-sizing: border-box;
    height: 38px;
    background-color: #dddddd;
    color: #3E3E3E;
    display: flex;
    position: sticky;
    top: 60px;
    z-index: 2;
    line-height: 38px;
  }
  &__expand {
    cursor: pointer;
  }
  &__actions {
    display: flex;
    white-space: nowrap;
    column-gap: 14px;
    padding-left: 12px;
    padding-right: 8px;
    box-sizing: border-box;
    align-items: center;

    .competition__expand {
      font-size: 15px !important;
      &:before {
        vertical-align: top;
      }
    }
  }

  &__name {
    font-style: normal;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 7px;
    padding-left: 2px;
    position: absolute;
    white-space: nowrap;
    width: 100%;
    box-sizing: border-box;
    gap: 8px;

    &__wrapper {
      position: relative;
      width: 100%;
    }
  }
  &__star {
    margin-right: 18px;
  }
}
