.preferences {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__section {
    min-height: 100px;
  }

  &__title {
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    color: #303030;
    margin: 0;
  }

  &__subtitle {
    font-size: 12px;
    line-height: 20px;
    color: #666;
    margin: 0 0 10px 0;
  }

  &__radio {
    &__wrapper {
      padding: 2.5px 0;

      &:first-of-type {
        margin-bottom: 5px;
      }
    }

    &__label {
      font-size: 12px;
      line-height: 20px;
      color: #303030;
    }
  }

  &__settings {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__checkbox {
    &__wrapper {
      column-gap: 10px;
      padding: 2.5px 0;
    }

    &__label {
      font-size: 12px;
      line-height: 20px;
      color: #303030;
    }
  }
}
