.allMarkets {
  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    font-size: 13px;


    &__wrapper {
      padding-inline: 10px;
      border-bottom: 1px solid #f2f5f5;
      border-top: 1px solid #f2f5f5;
      height: 38px;
      line-height: 38px;
      margin: 0;
      font-weight: 700 !important;
      text-transform: capitalize;
    }

    &__arrow {
      font-size: 15px;
    }
  }
}
