.plMobileCell {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 4px;
  flex-grow: 1;
  font-size: 16px;
  line-height: 24px;
  .eventName {
    display: block;
    font-weight: 700;
    width: 100%;
  }
  .marketName {
    background: none;
    color: #666666;
  }
  td {
    margin-top: -3px;
    padding-bottom: 3px;
  }
  .startTime,
  .settledTime {
    color: #999999;
    font-size: 14px;
    line-height: 20px;
  }
  .timeLabel {
    display: inline-block;
  }
}
