.contentContainer {
  text-align: center;
  margin: 10px 0;
}

.mobileContainer {
  margin: 10px 0;

  &__name {
    font-weight: bold!important;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  &__col {
    padding: 4px 2px;
    vertical-align: middle;
    border: solid 1px #e6e6e6;
  }

  &__colBackground {
    padding: 4px 2px;
    border: solid 1px #e6e6e6;
    vertical-align: middle;
    border-left: solid 1px #fff;
    background-color: #f0f0f0;
  }

  &__normalText {
    vertical-align: bottom;
    font-weight: 400 !important;
  }

  &__mobile {
    &__title {
      padding: 4px 2px;
      vertical-align: middle;
      border: solid 1px #e6e6e6;
      text-align: start;
    }

    &__col {
      padding: 4px 2px;
      vertical-align: middle;
      border: solid 1px #e6e6e6;
      text-align: center;
    }

    &__colBackground {
      padding: 4px 2px;
      border: solid 1px #e6e6e6;
      vertical-align: middle;
      border-left: solid 1px #fff;
      background-color: #f0f0f0;
      text-align: center;
    }
  }
}
