.record {
  all: unset;
  display: flex;
  align-items: center;
  position: relative;
  padding: 16px 0 16px 16px;
  border-bottom: 1px solid #dddddd;
  width: calc(100% - 16px);
  justify-content: space-between;
  column-gap: 12px;
  cursor: pointer;

  &__back, &__lay {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 15px;
      width: 4px;
      height: calc(100% - 30px);
      border-radius: 360px;
    }
  }

  &__back:before {
    background-color: #A6D8FF;
  }

  &__lay:before {
    background-color: #FAC9D1;
  }

  &__balance {
    font-weight: 700 !important;
    font-size: 16px;
    line-height: 1.5;
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin: 0;
  }

  &__rightIcon {
    font-size: 24px;
    color: #666666;
  }

  &__originLabel {
    margin-top: 4px;
    padding: 1px 8px 3px 8px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    background-color: #ececec;
    color: #303030;
    margin-bottom: 0;
    width: fit-content;
  }

  &__selectionName {
    font-weight: 700 !important;
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    color: #303030;
  }

  &__names {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 0;
    margin-top: 8px;
  }

  &__status {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 6px;
    font-size: 16px;
    line-height: 1.5;
    color: #303030;
    margin-bottom: 0;
    margin-top: 8px;

    &__dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #666666;
    }

    &__label {
      &__won {
        color: #04A04A;

        &__dot {
          background-color: #04A04A;
        }
      }

      &__lost {
        color: #FE3629;

        &__dot {
          background-color: #FE3629;
        }
      }
    }
  }

  &__balanceChange {
    margin-left: 6px;

    &__minus {
      color: #FE3629;
    }

    &__plus {
      color: #04A04A;
    }
  }

  &__cashOut {
    background-color: #FFB80C;
    padding: 1px 8px 3px 8px;
    color: #303030;
    font-weight: 700 !important;
    border-radius: 4px;

    &__wrapper {
      display: flex;
      align-items: center;
      column-gap: 8px;
      color: #666666;
      font-size: 14px;
      line-height: 20px;
      margin-top: 8px;
      margin-bottom: 0;
    }
  }
}
