.emptyMessage {
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  color: #212121;
  font-weight: 600 !important;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 46px;
    padding-bottom: 54px;
    row-gap: 8px;
  }

  &__tryAgain {
    color: #444444;
    font-size: 14px;
    line-height: 24px;
    margin: 0;
  }
}