.betDetailWrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.betDetail {
  display: flex;
  flex-direction: column;
}

.unmatchedActions {
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin-top: 10px;

  &__action {
    flex: 1;
    height: 42px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    border-radius: 4px;
    background-color: #ececec;
    align-content: center;
    padding: 9px 8px;
    cursor: pointer;
    color: #303030;
    font-size: 14px;
    line-height: 20px;
  }
}

.loader {
  transform: scale(0.16675);

  & > div {
    background-color: #0072ac !important;
  }

  &__wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    z-index: 10;
  }
}

.profitValue {
  &__negative {
    color: #fe3629;
  }

  &__positive {
    color: #04a04a;
  }
}
