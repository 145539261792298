@import 'styles/variables/variables';
@import 'styles/mixins/breakpoints';

.eventHeader {
  display: flex;
  min-height: 30px;
  overflow: hidden;
  box-sizing: content-box;
  padding: 10px 10px 5px 10px;
  z-index: 4;

  &__favorite {
    padding-left: 0;
  }

  &__mobile {
    padding: 7px 10px 0;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: inherit;
    font-size: inherit;
    margin: 0 6px 0 0;

    a {
      color: inherit !important;
      text-decoration: none;
    }
  }

  &__leftSide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 66px;

    &__search {
      padding-left: 10px;
    }
  }

  &__setContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__scope {
    display: flex;
    border-radius: 3px;
    overflow: hidden;
    margin: 0 3px;
  }

  &__point {
    min-width: 20px;
    min-height: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #444444;
    border-radius: 3px;
    background: #3E3E3E;
    padding: 2px;
    font-size: 14px;
  }

  &__bold {
    font-weight: bold !important;
  }

  &__favouriteIcon {
    flex: none;

    &__right {
      position: initial !important;
      transform: none !important;
    }
  }

  &__leftFavorite {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 10px;
    min-width: 12px;
    @include md{
      margin: 0px 10px 10px 0;
    }
  }

  &__left {
    text-align: end;
  }
}

.set {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 5px;

  &__mobile {
    margin-top: 2px;
  }
}

.basketball {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
  }

  @include md {
    &:before {
      width: 0;
      height: 0;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 4;
  }

  &__name {
    min-height: 46px;
    padding: 2px 0 2px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__names{
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }

  &__highlight {
    height: 12px;
    width: 12px;
    margin-left: 2px;
    margin-right: 5px;
  }

  &__set {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 2px 0 2px;
  }

  &__score {
    padding: 0 5px;
  }

  &__currentPoint {
    padding: 0 5px;
  }

  @include md {
    position: relative;

    li {
      border-bottom: 1px solid #d8d8d8;
      flex-direction: column;
      display: flex;
      height: 45px;
      line-height: 23px;
      padding: 0 10px;
      &:hover {
        text-decoration: none;
      }
    }
    li:last-child {
      border-bottom: none;
    }

    ul {
      list-style-type: none;
      padding-left: 0;
      position: absolute;
      width: calc(100% - 2px);
      margin: 0;
      max-height: 162px;
      overflow-y: auto;
    }
  }

  &__timeWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 46px;
    margin-left: 4px;

    @include md {
      min-height: 10px;
      justify-content: flex-end;

      div {
        padding: 0 6px 0 0;
        text-align: end;
      }
    }
  }

  &__timeLabel {
    align-self: center;
    padding: 6px 12px;
    border-radius: 3px;
  }
}

.rightSide {
  display: flex;
  margin-right: 12px;
  column-gap: 15px;
  align-items: center;
  z-index: 10;
  row-gap: 5px;
  .widgetIconsWrapper {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    &__vertical {
      flex-direction: column;
    }
  }
}
