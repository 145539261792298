.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__mobile {
    flex-direction: column;
    align-items: flex-start;
  }
}

.content {
  display: flex;
  align-items: center;

  &__mobile {
    margin-bottom: 10px;
  }
}

.icon {
  margin-right: 15px;
  font-size: 17px !important;
  line-height: 16px !important;

  &:before {
    color: #000000 !important;
  }
}

.rows {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  align-items: center;
}

.label {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #666;
  margin-right: 10px;
  min-width: 60px;
}

.value {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #303030;

  &__bold {
    font-weight: 700 !important;
  }
}

.controllers {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 40%;

  &__mobile {
    justify-content: space-between;
    width: 100% !important;
  }
}

.infoAlertContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 20px;
}

.infoAlertLabel {
  font-size: 14px;
  color: #666;
  line-height: 20px;
  margin-left: 10px;
}

.cancelBtn {
  width: 100%;
}
