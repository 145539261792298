.closeBtn {
  font-size: 12px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  border-radius: 50%;
  color: inherit;

  i {
    font-size: 16px;
    color: inherit;
  }

  &:hover {
    background-color: #FFFFFF;
  }

  &__dark:hover {
    background-color: #ececec; 
  }

  &:active {
    border: 0;
  }

  &:focus {
    border: 2px solid #3E3E3E;
    border-radius: 4px;
  }
}
