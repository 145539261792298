.inlineSelectedBetWrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
}

.inlineSelectedBet {
  position: relative;
  padding: 10px 15px;
  border: 1px solid #0072AC;
  border-radius: 4px;

  &__inner {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    margin-right: auto;
  }

  &__note {
    margin: 0;
  }
}
