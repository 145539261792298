.sort {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  
  &__disabled {
    cursor: initial;
  }

  &__left {
    justify-content: flex-start;
  }

  .iconWrap {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .iconWrap__desktop {
    width: 25px;
    height: 25px;
    min-width: 25px;
  }

  .iconWrap__mobile {
    width: 42px;
    height: 42px;

    &:active {
      background-color: #DDDDDD;
    }
  }

  .iconWrap__disabled {
    &:active {
      background: none;
    }
  }

  &:hover {
    .iconWrap__desktop {
      background-color: #DDDDDD;
    }

    .iconWrap__disabled {
      background: none;
    }
  }
}

.icon {
  font-size: 16px;

  &__mobile {
    font-size: 24px;
  }

  &__inactive {
    &:before {
      color: #999999;
    }
  }

  &__disabled {
    &:before {
      color: #c0c0c0;
    }
  }
}
