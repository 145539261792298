.perPageOptions {
  height: 38px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  background-color: #ECECEC;
  border-radius: 4px;

  &__option {
    all: unset;
    height: 100%;
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-radius: 2px;
    cursor: pointer;

    &__selected {
      background-color: #FFFFFF;
    }

    &__landscape {
      width: 115px;
    }

    &:active {
      background-color: #DDDDDD;
    }
  }
}
