.betIndicator {
  position: absolute;
  left: 0;
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  color: #FE3629;
  font-size: 14px;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;

  &__up {
    top: 8px;
    font-size: 5px;
  }

  &__down {
    bottom: 8px;
    font-size: 5px;
  }

  &__action {
    cursor: pointer;
  }
}