.container {
  width: 100%;
  height: 100%;
  background: white;
}

.isAbsolute {
  position: absolute;
  width: 100%;
  background: white;
  z-index: 100;
  min-height: 100%;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 8px);
  height: 44px;
  margin: 2px 4px;
}