@import "src/styles/mixins/breakpoints";

.sessionTime {
  &__container {
    display: flex;
    column-gap: 15px;
    line-height: 30px;
    align-items: center;
    flex: 1;
    justify-content: center;
    font-weight: bold !important;

    &__mobile {
      border-top: 1px solid;
      justify-content: center;
      margin-right: 0;
    }
  }

  &__label {
    text-transform: uppercase !important;
    white-space: nowrap;
  }

  &__time {
    font-size: 1.2em;
    white-space: nowrap;
  }

  &__inHeader {
    flex: 1;
    margin-right: 0;
    justify-content: center;
    align-items: flex-start;
  }
}
