.asianViewFavouriteStar {
  min-width: 12px;
  min-height: inherit;
  position: relative;
  text-align: center;
  cursor: pointer;
  font-size: 16px;

  &__active {
    color: #FFB900;
  }
}
