.mobCOList {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 6px;

  &__landscape {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
