.wrapper {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.menu {
  position: relative;
  &__hasMargin {
    margin-left: -80px;
  }
}