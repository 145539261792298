.wrap {
  position: relative;

  &__item {
    padding-left: 20px;
    box-sizing: border-box;

    &:hover {
      background-color: #F5F5F5;
    }
  }

  &__border {
    border-bottom: 1px solid #DDDDDD;
  }

  &__header {
    position: sticky;
    top: 0;
    margin-top: 20px;
    background-color: #FFFFFF;
    z-index: 1;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  gap: 20px;
  min-height: 100px;
  box-sizing: border-box;

  &__succesCancel {
    opacity: 0;
  }

  &__header {
    min-height: 50px;
    color: #666666;
    background-color: #F5F5F5;
    border-radius: 4px;
    border: 0;
    padding-left: 20px;
  }

  &__small {
    padding-right: 20px;
  }

  &__back, &__lay {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 10px;
      width: 5px;
      height: calc(100% - 20px);
      border-radius: 360px;
    }
  }

  &__back:before {
    background-color: #A6D8FF;
  }

  &__lay:before {
    background-color: #FAC9D1;
  }
}

.col {
  flex: 1 0 auto;

  &__xs {
    flex: 0 0 40px;
    width: 40px;
  }

  &__sm {
    flex: 0 0 60px;
    width: 60px;
  }

  &__md {
    flex: 0 0 80px;
    width: 80px;
  }

  &__lg {
    flex: 0 0 110px;
    width: 110px;
  }

  &__right {
    text-align: right;
  }

  &__center {
    text-align: center;
  }
}

.error {
  display: flex;
  align-items: center;
  column-gap: 10px;
  border: 1px solid #FE3629;
  border-radius: 4px;
  padding: 8px 10px 8px 15px;
  min-height: 40px;
  box-sizing: border-box;
  margin-bottom: 14px;
  margin-top: 10px;

  &__msg {
    font-size: 12px;
    line-height: 20px;
    color: #303030;
    margin: 0;
  }

  &__icon {
    font-size: 16px;
  }
}

.loader {
  transform: scale(0.16675);

  & > div {
    background-color: #0072AC !important;
  }

  &__wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    z-index: 100;
  }
}

.success {
  position: absolute;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  column-gap: 10px;
  left: 0;
  top: 5px;
  z-index: 100;
  padding: 10px 15px;
  border-radius: 4px;
  border: 1px solid #0072AC;

  &__icon {
    font-size: 16px;
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
    color: #303030;
    margin: 0;

    &__bold {
      font-weight: 700 !important;
    }
  }
}

.modal {
  &__header {
    text-align: left;
    font-size: initial;
  }

  &__body {
    height: calc(70vh - 100px);
    overflow-y: scroll;
  }

  &__baccaratContent {
    width: fit-content;
  }

  &__hiLoContent {
    width: fit-content;
  }

  &__blackJackContent {
    width: fit-content;
  }
}
