.controllersContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__mobile {
    flex-direction: column-reverse;
  }
}

.controllers {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  &__mobile {
    width: 100% !important;
    justify-content: space-between;
  }

  &__applied {
    width: 50%;
  }
}

.form {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.formLabel {
  font-size: 12px;
  line-height: 20px;
  color: #303030;
}