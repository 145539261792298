.wrapper {
  display: flex;
  justify-content: center;
  gap: 5px;
  line-height: 18px;
  cursor: pointer;
}

.search {
  line-height: initial;
}

.icon {
  font-size: 14px;
}

.infoIcon {
  color: #000 !important;
}