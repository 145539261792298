.message {
  padding: 5px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  border-radius: 4px;

  &__success {
    background-color: #CBECD8;
    border: 1px solid #04A04A;
  }

  &__error {
    background-color: #FAC9D1;
    border: 1px solid #D70022;
  }

  &__icon {
    &__success {
      color: #04A04A;
      font-size: 14px;

      &::after {
        color: #04A04A;
      }
    }

    &__error {
      width: 11px;
      min-width: 11px;
      height: 11px;
      font-size: 12px;
      vertical-align: middle;
    }
  }

  &__text {
    span {
      font-weight: bold;
    }
  }
}
