.market {
  &__marketWrapper {
    display: flex;
    margin: 4px 0 10px;
  }

  &__tabWrapper {
    margin: 0 0 10px;
    gap: 5px;

    &__mobile {
      display: block;
    }
  }

  &__line {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: fit-content;
    margin: 0 3px;
    gap: 4px;
  }

  &__resetMargin {
    margin: 0;
  }
}
