.icon {
  font-size: 16px;

  &__danger {
    color: #FE3629;
  }

  &__info {
    &:before {
      background-color: #0072AC;
    }
  }
}
