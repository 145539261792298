.activeMarkets {
  &__empty {
    margin: 0;
    font-size: 11px;
    line-height: 18px;
    padding: 2px 10px;
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}
