.eventCashOutList {
  &__reloadBtn {
    width: fit-content;
    margin-bottom: 6px;
    font-size: 10px;

    &:hover {
      background: #C0C0C0;
    }

    &__mobile {
      height: auto;
      padding: 8px 12px;
    }

    &__labelMobile {
      font-size: 14px;
    }

    &__iconMobile {
      font-size: 16px;
      width: 16px;
      height: 16px;
    }
  }
}
