.wrap {
  display: flex;
  gap: 5px;
  padding: 5px 10px;
  border-radius: 4px;

  &__error {
    background-color: #FAC9D1;
    border: 1px solid #D70022;
  }
}

.errorIconWrap {
  align-self: center;
}

.errorIcon {
  width: 11px;
  height: 11px;
  font-size: 12px;
  vertical-align: middle;
}

.closeIcon {
  align-self: flex-start;
  margin-left: auto;
  padding-left: 5px;
  cursor: pointer;
}
