@import "../../../../styles/variables/variables";
@import "../../../../styles/mixins/breakpoints";


.searchSportHeader {
  display: flex;
  justify-content: space-between;
  background: #444444;
  color: #fff;
  padding: 0 10px;
  line-height: 30px;
  font-size: 15px;

  .searchSportName,
  .searchResultsFound {
    margin-left: 10px;
  }
  .searchResultsFound {
    font-size: 12px;
  }
  .searchCollapseIcon {
    font-size: 20px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    @include md {
      cursor: initial;
    }
  }
  @include md {
    padding: 5px 10px;
  }
}