.notFound {
  min-height: 440px;
  padding: 32px 56px;
  box-sizing: border-box;

  &__mobile {
    padding: 32px;
    min-height: 326px;
  }
}

.links {
  display: flex;
  align-items: center;
  padding: 5px;
  gap: 10px;
  margin-top: 16px;

  &__mobile {
    flex-direction: column;
  }

  .link {
    border: 2px solid #ffffff;
    color: #ffffff !important;
    flex: 1;
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
    letter-spacing: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    padding: 14px 0;

    &__mobile {
      width: 100%;
    }
  }
}

:global {
  .asian_view-not-found-page {
    .title {
      font-size: 24px !important;
      margin: 0;
      text-align: center;
      line-height: 1;
      font-weight: 400;
    }

    .message {
      margin: 0;
      font-size: 16px !important;
      line-height: 1.5;
      text-transform: uppercase;
    }
  }
}
