@import "../../styles/mixins/breakpoints";
@import "../../styles/variables/variables";

@media (min-width: $md) {
  .popularMarketWrapper {
    display: inline-block;
  }
}

.popularMarketCollapse {
  @include md {
    font-weight: bold;
  }
}

.popularMarketWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 10px;
  padding-right: 5px;

  @include xl {
    padding-bottom: 15px;
  }
  @include md {
    padding-bottom: 0;
  }
}

.popularMarketSport {
  font-size: 12px;
  width: 50%;
  box-sizing: border-box;
  padding: 5px 5px 5px 0;
  @include xl {
    width: 100%;
    padding-right: 0;
    padding-bottom: 0;
  }

  &__infoWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-bottom: 5px;
    padding-left: 10px;
  }

  &__wrapper {
    margin-bottom: 5px;

    &__row {
      display: flex;
      //height: 40px;

      &__totalMatched {
        flex-basis: 50%;

        span {
          white-space: nowrap;
        }
      }

      &__totalMatchedCell {
        flex-basis: 50%;
        display: inline-flex;

        span {
          flex: auto;
          text-align: center;
          padding: 0 10px;
        }
      }

      &__matchOdds {
        display: flex;
        flex-direction: row;
        flex-basis: 50%;

        &_item {
          display: flex;
          flex-direction: row;
          flex-basis: 50%;
          margin-right: 5px;
        }
      }

      &__matchOdd {
        flex-basis: 50%;
        text-align: center;
        border-left: 1px solid #fff;
        font-size: 13px;

        & > div {
          padding: 0 3px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        span {
          display: block;
        }

        &__odd {
          font-weight: 600;
        }
      }

      &__matchNavigation {
        width: 20px;
        line-height: 40px;
        text-align: right;
        font-size: 13px;
        margin-left: auto;
      }
    }

    &__odds {
      border-top: 1px solid #f2f5f5;
    }

    &__header {
      font-size: 11px;
      color: $light-gray;
    }
  }

  &__title {
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-basis: 75%;

    i {
      margin-right: 5px;
    }
  }

  &__cashOutWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    background: #f2d660;
    border: solid 1px #ffa01f;
    border-radius: 12px;
    margin-left: 5px;
    margin-right: 5px;
  }

  &__cashOut {
    width: 4px;
    height: 4px;
    background-color: #ff8624;
    border: solid 1px #ffa01f;

    &:before {
      content: none !important;
    }
  }

  &__time {
    line-height: normal;
    flex: auto;
    white-space: nowrap;
  }
}

.cashOut {
  color: #ffb80c;
  border-radius: 50%;
  border: 1px solid #ffb80c;
  width: 13px;
  height: 12px;
  position: relative;
  margin-right: 4px;
  background-color: #F2D45E;

  &:before {
    color: #ffb80c;
    position: absolute;
    font-size: 8px;
    top: 2px;
    left: 3px;
  }
}

.headerRightSide {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
