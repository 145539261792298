.noEditable {
  flex: 1;
  background-color: #fff;
  height: 42px;
  border-radius: 4px;
  text-align: center;
  position: relative;

  &__value {
    color: #303030;
    font-weight: 700 !important;
    font-size: 14px;
    line-height: 1.42;
    margin: 0;
  }

  &__label {
    font-size: 12px;
    line-height: 1.66;
    color: #999;
    margin: 0;
  }

  &__indicator {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }

  &__landscape {
    flex: 1;
  }

  &__tooltipIcon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}
