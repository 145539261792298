.emptyMarket {
  border-top: solid 1px #c0c0c0;
  padding-bottom: 5px;
  height: 64px;
  &__singleLine {
    height: 22px;
    width: 82px;
    border-top: none;
    border-left: solid 1px #c0c0c0;
    float: right;
    clear: both;
  }
  &__first {
    padding-top: 5px;
  }
}
