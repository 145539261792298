.backLay {
  display: flex;
  gap: 2px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid #C0C0C0;
    min-width: 34px;
    height: 22px;
  }
}

.back {
  background-color: #A6D8FF;
}

.lay {
  background-color: #FAC9D1;
}

.runnerContainer {
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding: 0 10px 0 20px;
  gap: 4px;

  &__mobile {
    height: 54px;
    padding-left: 12px;
    .name {
      width: auto;
      font-weight: bold;
    }
  }
}

.name {
  flex:1;
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.skeleton {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__name {
    height: 20px;
    width: 30%;
    max-width: 400px;
  }

  &__cellWrapper {
    display: flex;
    gap: 4px;
  }

  &__cell {
    height: 20px;
    width: 40px;
  }
}

.odds__small {
  width: 35px;
  font-size: 10px;
}
