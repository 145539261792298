.container {
  width: 100%;
  background: white;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.paddingWrapper {
  width: calc(100% - 32px);
  padding: 16px 16px 0;
}

.topHeader {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.row {
  display: flex;
  justify-content: space-between;

  &__first {
    padding-bottom: 20px;
    border-bottom: 1px solid #DDDDDD;
  }

  &__left {
    display: flex;
    gap: 10px;
  }

  &__right {
    display: flex;
    gap: 10px;
  }
}

.item {
  width: 200px;
  height: 30px;
}

.itemWidth {
  width: 100%;
  height: 20px;
}

.itemIcon {
  width: 30px;
  height: 30px;
}

.subHeaderTitle {
  padding: 10px 0;
}

.table {
  &__col {
    width: 200px;
  }

  &__item {
    width: 100%;
    height: 50px;
    margin-bottom: 4px;
  }

  &__row {
    height: 100px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-bottom: 1px solid #DDDDDD;
    box-sizing: border-box;
  }

  &__col_1 {
    width: 60px;
    height: 20px;
  }

  &__col_2 {
    width: 80px;
    height: 20px;
  }

  &__col_3 {
    width: 200px;
    height: 20px;
  }
}
