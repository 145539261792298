.main {
  background-color: #000000;
  color: #ffffff;
  font-size: 16px;
  padding: 20px;
  text-align: center;
  img {
    max-width: 100%;
  }
}
