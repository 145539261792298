@import "../../../styles/mixins/breakpoints";

.navSkeletonContainer {
  padding-top: 5px;
  .navSkeletonItemWrapper {
    margin-bottom: 2px;
    padding: 2px 10px;

    .navSkeletonItem {
      height: 21px;
      @include md {
        height: 45px;
      }
    }
  }
}