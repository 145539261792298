.marketCashOut {
  flex: 1 1;
  height: fit-content;
  border-radius: 5px;
  background-color: #FFF;
  border: 1px solid #C0C0C0;

  &__title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 30px;
    background-color: #ECECEC;
    border-bottom: 1px solid #C0C0C0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    column-gap: 6px;
    row-gap: 4px;
    padding: 4px 15px;

    &__outright {
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 6px;
      padding-top: 6px;
    }

    &__mobile {
      background: inherit !important;
      border-bottom-style: dashed;
      margin: 0 12px;
      padding: 16px 0 12px;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 10px;
    }
  }

  &__outrightContainer {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }

  &__name {
    font-size: 14px;
    font-weight: 600 !important;
    line-height: 1.5;
  }
}
