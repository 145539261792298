@import 'src/styles/variables/variables';
@import 'src/styles/mixins/breakpoints';

.container {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.modal {
  &__btnGroup {
    display: flex;
    justify-content: flex-end;
  }

  &__btnClose {
    display: flex;
    padding: 6px 12px;
    border-radius: 3px;
    cursor: pointer;
  }
}

.mobileText {
  font-weight: bold !important;

  a {
    height: 36px;
  }
}

.popularLink {
  border-color: #f7f7f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;

  :hover span {
    text-decoration: underline;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    height: 26px;
    text-decoration: none;
    border-bottom-style: none;
    border-bottom-width: 0;
    @include md{
      border-bottom-style: solid;
      border-bottom-width: 1px;
    }
    i {
      display: flex;
      justify-content: center;
      width: 20px;
    }
  }
}
