.halfTimeFullTimeEvent {
  border-bottom: 1px solid #c0c0c0;

  &__runners, &__header, &__statisticsAndCashout, &__header__info, &__header__container, &__runner__prices {
    display: flex;
    align-items: center;
  }

  &__name {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
  }

  &__header {
    border-bottom: 1px solid #c0c0c0;
    min-height: 40px;

    &__inPlay {
      background-color: #cbecd8;
    }

    &__container {
      width: 100%;
      justify-content: space-between;
      padding: 0 10px;
      box-sizing: border-box;
      height: 100%;
    }

    &__info {
      column-gap: 10px;
    }
  }

  &__runner {
    flex: 1;

    &:not(:last-of-type) {
      border-right: 1px solid #c0c0c0;
    }

    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      border-bottom: 1px solid #c0c0c0;
      box-sizing: border-box;
      font-weight: 500;
      background-color: #ececec;
      font-size: 12px;
      line-height: 1.5;
    }

    &__prices {
      flex-direction: column;
      height: 60px;
      justify-content: center;

      &__inPlay {
        background-color: #cbecd8;
      }
    }
  }

  &__inPlayCell {
    min-height: 40px;
    padding: 3px 0;
  }

  &__statisticsAndCashout {
    column-gap: 10px;

    &__leftBlockMargin {
      margin-right: 10px;
    }
  }

  &__time {
    color: #FFFFFF;
    font-size: 14px;
    border-radius: 3px;
    height: 25px;
    padding: 5px;
    box-sizing: border-box;
    background-color: #4db375;
  }

  &__cashout {
    column-gap: 0 !important;

    &__icon {
      cursor: pointer !important;
    }
  }
}
