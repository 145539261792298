.asianViewInPlayCell {
  width: 60px;
  min-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 60px;
  border-right: solid 1px #c0c0c0;
  box-sizing: border-box;

  &__noScore, &__noInPlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 7px;
    font-size: 14px;
  }

  &__noScore {
    &__minimized {
      row-gap: 2px;
    }
  }

  &__liveScore {
    background-color: #4db375;
    color: #fff;
    font-size: 16px;
    &__mobile {
      background-color: #04a04a;
    }
  }

  &__timerIcon {
    color: #DD4F46;
  }

  &__noInPlay {
    &__description {
      display: inline-block;
      padding: 4px 3px;
      background-color: #4DB375;
      width: fit-content;
      color: #FFFFFF;
      font-weight: 500 !important;
      border-radius: 2px;
      max-width: 43px;

      &__mobile {
        &__notStarting {
          font-size: 10px;
          line-height: 1.5;
          font-weight: 500 !important;
          color: #303030;
        }

        &__starting {
          font-size: 12px;
          line-height: 1.5;
          font-weight: 500 !important;
        }
      }
    }
    &__mobile {
      row-gap: 3px;

      &__notToday {
        height: 100%;

        .asianViewInPlayCell__noInPlay__description {
          background: none;
          max-width: 100%;
        }
      }
    }

    &__time {
      font-size: 14px;
      line-height: 1.5;
      color: #303030;
    }
  }

  &__mobileBetList {
    min-height: 52px !important;

    &__noBorder {
      border: none !important;
    }
  }
}
