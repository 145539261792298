.card {
  border-radius: 8px;
  background-color: #FFFFFF;
  border: 1px solid #c0c0c0;
  overflow: hidden;
  padding: 0 11px 11px;
  position: relative;

  &__topLine {
    height: 3px;
    background-color: #3e3e3e;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;

    &__live {
      background-color: #04A04A;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    column-gap: 12px;
    justify-content: space-between;
    padding: 9px 0 7px;

    &__left {
      display: flex;
      align-items: center;
      column-gap: 12px;
    }

    &__title {
      font-size: 14px;
      line-height: 24px;
      padding: 2px 0;
      color: #303030 !important;
      font-weight: 600 !important;
      text-decoration: underline;
      display: block;

      &__outright {
        padding-left: 12px;
        padding-top: 17px;
        padding-bottom: 18px;
      }
    }

    &__plBtn {
      width: 32px;
      height: 32px;
      min-width: 32px;
      background-color: #F5F5F5;
      padding: 0;

      .card__header__plBtn__icon {
        color: #FF8624;
      }
    }
  }

  &__marketsContainers {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 8px;
  }
}

div.inPlayCell {
  height: auto;
  min-height: 46px;
  border: 1px solid #C0C0C0;
  border-radius: 8px;

  &__live {
    border: none;
  }

  &__noInPlay {
    border-radius: 8px;
  }

  &__periodLabel {
    margin-top: 0;
  }
}
