@import 'src/styles/mixins/breakpoints';

.status {
  min-width: 110px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #303030;
  box-sizing: border-box;

  @media (min-width: $md) {
    height: 30px;
  }

  i {
    font-size: 8px;
  }

  &__positive {
    background-color: #cbecd8;

    &.status__mobile {
      color: #04a04a;
    }

    i {
      color: #04a04a;
    }
  }

  &__negative {
    background-color: #faebed;

    &.status__mobile {
      color: #fe3629;
    }

    i {
      color: #fe3629;
    }
  }

  &__neutral {
    background-color: #f5f5f5;

    &.status__mobile {
      color: #666666;
    }

    i {
      color: #666666;
    }
  }

  &__mobile {
    justify-content: flex-start;
    background: none;
  }

  &__dot {
    &__mobile {
      color: inherit !important;
    }
  }
}

.cancelBet {
  background-color: #dddddd;
  font-size: 12px;
  line-height: 20px;
  width: 100%;
  padding: 5px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #c0c0c0;
  }
}
