.wrap {
  display: flex;
  align-items: center;
  column-gap: 3px;
  color: #666666;
}

.cashedOut {
  height: 20px;
  padding: 0 5px;
  font-size: 12px;
  font-weight: bold;
  color: #303030;
  background-color: #FFB80C;
  border-radius: 2px;
  margin-right: 7px;
}

.cancelBet {
  all: unset;
  color: #FE3629;
  text-decoration: underline;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}
