.inlineBetProgress {
  padding: 5px;

  &__wrapper {
    position: relative;
    border-radius: 4px;

    &__back {
      background: #d4ecff;
    }

    &__lay {
      background: #faebed;
    }
  }

  &__inner {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    border: 1px solid #dddddd;
    background-color: inherit !important;
    border-radius: 4px;
    min-height: 90px;
    box-sizing: border-box;
  }

  &__info,
  &__label {
    z-index: 1;
  }

  &__label {
    margin: 0;
  }

  &__progress {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    background: #ffffff;
    transition: 1000ms all;
  }
}
