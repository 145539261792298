.warning {
  height: 30px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__betSlip_coMobPage {
    border-radius: 4px;
  }

  .close {
    cursor: pointer;
  }
}

.containerBetSlip {
  margin-top: 15px;
}

.containerMobCOPage {
  width: calc(100% - 20px);
  margin: 0 10px;
}
