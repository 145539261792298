@import "../../styles/mixins/breakpoints";

.multiMarket {
  &__marketName {
    display: flex;
    align-items: center;
    margin: 0 8px 8px;
    border-bottom: 1px solid #f2f5f5;
    font-weight: bold;
    padding: 8px 0 4px;
  }

  &__otherMarkets {
    padding-top: 10px;

    @include md {
      padding-top: 0;
    }

    &__closed {
      padding-bottom: 24px;
    }

    &__sections {
      padding: 12px 8px;

      @include md {
        padding: 0;
      }

      &__fancy {
        padding-bottom: 0;
      }

      &__contents {
        display: contents !important;
      }

      &__item {
        padding-bottom: 1px;

        &__2columns {
          width: calc(50% - 3px);
        }
      }

      &__2columns {
        display: flex;
        flex-wrap: wrap;
        column-gap: 6px;
      }
    }

    &__viewMarket {
      margin: 0;
      padding: 4px 0;
    }

    &__market {
      margin: 0;
      padding-bottom: 10px;

      &__last {
        padding-bottom: 0;
      }

      @include md {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    &__collapseHeader {
      cursor: pointer;
      font-weight: 700 !important;

      @include md {
        margin-top: 0 !important;
      }
    }
  }

  &__noMarkets {
    margin: 0;
    padding: 25px 15px 35px;
    text-align: center;
  }

  &__paddingRight {
    @include md {
      padding-right: 5px;
    }
  }

  &__allMarketsTab {
    &__link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 38px;
      line-height: 38px;
      padding-inline: 10px;
      font-size: 13px;
      font-weight: 700 !important;
      text-decoration: none;
      border-top: 1px solid #f2f5f5;
      border-bottom: 1px solid #f2f5f5;
    }

    &__icon {
      font-size: 15px !important;
    }
  }

  &__fancyMultiMarketsAndOtherMarketSectionsContainer {
    display: contents;

    &__display {
      display: flex;
      column-gap: 6px;
      flex-wrap: wrap;
      padding: 12px 8px;
    }
  }

  &__favouriteIcon {
    margin-right: 8px;
    i {
      font-size: 16px;
    }
  }
}
