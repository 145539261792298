.container {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
}

.inputField {
  width: 62.5px;
  height: 30px;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
  padding: 0;
  text-align: center;
  box-sizing: border-box;
  outline: none;

  &:focus,
  &:active,
  &:hover {
    border-color: #3E3E3E;
  }

  &:hover {
    border-color: #3E3E3E;
  }

  &:read-only {
    border-color: #FFFFFF;

    &:hover + label {
      color: #999999;
    }
  }

  &:hover + label {
    cursor: pointer;
  }

  &:not(:read-only):focus + label,
  &:not(:placeholder-shown) + label,
  &__filled + label {
    top: 0;
    left: 0;
    font-size: 9px;
    transform: translate(5px, 0);
  }

  &:hover + label {
    color: #303030;
  }

  &:hover:read-only + label {
    cursor: initial;
  }

  &__label {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #999999;
    transition: 300ms all;
    transform: translate(-50%, -50%);
    z-index: 20;
  }
}
