@import 'styles/variables/variables';

.landscapeHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px;
  column-gap: 10px;
  position: sticky;
  top: 0;
  z-index: 100;

  &__dropdown {
    &__container {
      min-width: 156px;
      background-color: $white;
      border-radius: 3px;
    }

    &__plaholder {
      border-width: 0;
      color: $black;
      background-color: $white;
    }

    &__dropdown {
      background-color: $white;
      border-width: 0;
    }

    &__option {
      border-top: 1px solid #f2f2f2;
    }
  }
}
