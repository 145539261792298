.arrow {
  font-size: 6px;

  &__down {
    color: #FE3629;
  }

  &__up {
    color: #04A04A;
  }
}