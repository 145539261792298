.openBets {
  &__byType {
    display: flex;
  }

  &__empty {
    height: 8px;
    background-color: #ececec;
  }
}

.expand {
  cursor: pointer;
  margin-left: auto;
  color: inherit;

  &:before {
    content: '\f078';
  }
  &__opened {
    &:before {
      content: '\f077';
    }
  }
}

.unmatchedHeader, .matchedHeader {
  all: unset;
  cursor: pointer;
  padding: 11px 12px;
  flex: 1;
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: #F5F5F5;

  &__active {
    background-color: #fff;
  }

  &__betCount {
    height: 15px;
    min-width: 15px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 700 !important;
    color: #FFFFFF;
    padding: 0 4px;
    box-sizing: border-box;
  }
}

.unmatchedHeader {
  border-top: 2px solid #fe3629;

  &__betCount {
    background-color: #FE3629;
  }
}

.matchedHeader {
  border-top: 2px solid #04A04A;

  &__betCount {
    background-color: #04A04A;
  }
}

.openBetsList {
  font-size: 12px;
}

.divider {
  width: 1.5px;
  background-color: #ddd;
}