@import "styles/variables/variables";

.headerNavigationLinks {
  height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
  padding: 0;
  list-style: none;
}

.headerNavigationItem {
  font-size: $small;
  line-height: 30px;
  width: fit-content;
  white-space: nowrap;

  a {
    display: flex;
    align-items: center;
    padding-inline: 10px;
    color: #f6f6f6;
    line-height: 30px;
    font-weight: bold;
    text-decoration: none;
    height: 30px;

    &:hover {
      text-decoration: none;
    }

    &:not(:last-child) {
      border-right: 1px solid;
    }
  }

  &.active {
    background-color: #343434;
  }

  &__icon {
    font-size: $regular;
    vertical-align: middle;
  }

  &__home {
    i {
      font-size: 22px;
    }
  }
}

.headerMain {
  display: flex;
  justify-content: space-between;
}
