.tableBody {
  display: flex;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  color: #333333;
  border-bottom: 1px solid #e1e1e1;
  border-collapse: collapse;
  border-spacing: 0;
  min-height: 50px;
  gap: 20px;
  padding: 0 20px;
  &:hover{
    background-color: #f5f5f5;
  }
  .eventCell {
    flex: 5 0 180px;
    text-align: left;
  }
  .marketCell {
    flex: 4 0 180px;
    text-align: left;
  }
  .middleCell {
    width: 140px;
    text-align: left;
  }
  .plCell {
    width: 120px;
    text-align: right;
  }
  .betLost {
    color: #fe3628;
  }
  .betWon {
    color: #190;
  }
  &:first-child {
    border-top: none;
  }
}

.cell {
  padding: 0;
}
