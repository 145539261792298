.wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;

  > * {
    line-height: 20px;
  }
}

.selectionName {
  font-size: 14px;
  font-weight: bold;
}

.link {
  font-size: 12px;

  &:hover {
    text-decoration: none;
  }
}

.marketInfo {
  color: #666666;
  text-transform: capitalize;
}

.gameLink {
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}
