.eventPage {
  &__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 5px 15px 5px;

    .eventPage__markets {
      flex-basis: 100%;
    }
  }
  &__divided {
    .eventPage__markets {
      flex-basis: 50%;
    }
  }
}
