.accountMobileLayout {
    &__container {
        display: flex;
        flex: 1;
        flex-direction: column;
        background-color: #ececec;
        overflow: hidden;
    }

    &__header {
        padding: 10px 0 60px;
        text-align: center;
        background-color: #3e3e3e;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    &__title {
        color: #fff;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700 !important;
    }

    &__bodyWrapper {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    &__body {
        width: 90%;
        position: relative;
        top: -50px;
        border-radius: 5px;
    }

    &__section {
        background-color: #fff;
        padding: 20px;
        margin-bottom: 10px;
        border-radius: 5px;

        &__content {
            height: 320px;  
        }
    }

    &__nav {
        width: 100%;
        display: flex;
        align-items: space-between;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__nav__item {
        background-color: #ececec;
        width: 48%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 15px 0;
        border-radius: 5px;
        text-decoration: none;

        &__bottom__gap {
            margin-bottom: 12px;
        }
    }

    &__nav__icon {
        font-size: 26px !important;
        line-height: 26px !important;
        color: #303030;
        margin-bottom: 5px !important;
    }


    &__nav__title {
        font-size: 14px;
        color: #303030;
        line-height: 20px;
    }

    &__logout__btn {
        background-color: #FAEBED;
        color: #FE3629;
        font-size: 14px;
        line-height: 20px;
        font-weight: 700 !important;
        text-align: center;
        padding: 12px 0;
        border-radius: 5px;
        margin: 0;
    }
}