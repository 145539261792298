.soccerCashOut {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  justify-content: center;
  min-width: 65px;
  min-height: 40px;
  height: 100%;

  tbody {
    display: table;
    width: 100%;
    height: 100%;
  }

  tr {
    border: 0;
    height: 20px;
    vertical-align: middle;
    color: #008000;
  }

  &__time {
    min-width: 20px;
    padding-right: 10px;
    padding-left: 10px;
    text-align: center;
  }

  &__score {
    min-width: 20px;
    text-align: center;
    height: 50%;
  }
}

.soccer {
  display: flex;
  height: 100%;
  width: 100%;

  &__time {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  &__ScoreBlock {
    display: flex;
    flex-direction: column;
    min-width: 22px;
  }

  &__score {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 50%;
  }
}
