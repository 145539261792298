@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

.skeleton {
  animation: 1s fadeIn;
  animation-fill-mode: forwards;

  > * {
    height: 15px;
  }

  &__mobile {
    > * {
      height: 28px;
    }
  }

  &__league {
    width: 100%;
    margin-bottom: 15px;
  }

  &__leagueSm {
    width: 50%;
    margin-bottom: 15px;
  }
}
