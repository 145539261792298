.wrap {
  display: flex;
  gap: 5px;
  padding: 5px 10px 5px 8px;
  border-radius: 4px;
  align-items: center;
  border-width: 1px;
  border-style: solid;

  &__error {
    background-color: #FAC9D1;
    border-color: #D70022;
    gap: 5px;
    padding-left: 10px;
  }

  &__success {
    background-color: #CBECD8;
    border-color: #04A04A;
  }
}

.leftIcon {
  &__success, &__successUp {
    color: #04A04A;
    font-size: 16px;
  }

  &__error {
    width: 12px;
    height: 12px;
    font-size: 12px;
    margin-right: 6px;
  }
}

.closeIcon {
  margin-left: auto;
  cursor: pointer;
  font-size: 12px;
}

.message {
  font-size: inherit;
  margin: 0;

  &__success {
    span {
      font-weight: bold;
    }
  }
}
