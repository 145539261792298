@import 'styles/variables/variables';

.tooltip {
  max-width: 250px !important;
  text-align: start !important;
  color: black !important;
  font-size: $small !important;
  line-height: 1.5 !important;
  white-space: pre-wrap !important;
  word-break: break-word !important;
  padding: 5px 10px !important;
  background-color: white !important;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25) !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 2px !important;
  opacity: 1 !important;
  z-index: 100000 !important;

  &:global(.react-tooltip__place-left) {
    text-align: center !important;
  }
}

.arrow {
  background-color: white !important;
}
