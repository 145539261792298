.wrap {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__landscape {
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
  }
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;

  &__col {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;

    &__inner {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }

  &__1 {
    flex: 1;
  }

  &__2 {
    flex: 2;
  }
}

.minSize,
.betMax {
  font-weight: bold !important;
}

.betMin {
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
}
