.timeForm {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    color: #ffffff;
    background-color: #444444;
    border-top: 1px solid #444444;
    box-sizing: border-box;
  }

  &__expand {
    cursor: pointer;
    transition-duration: 0.5s;
  }

  &__expanded {
    transform: rotate(180deg);
  }
}
