.preferences {
  border-radius: 4px;
  padding: 20px 24px 16px;
  background-color: #fff;

  &__title {
    font-weight: 700 !important;
    color: #303030;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 18px;
    margin-top: 0;
  }

  &__btnsContainer {
    display: flex;
    flex-direction: column;
  }

  &__btn {
    all: unset;
    flex: 1;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #303030;
    line-height: 1.5;
    cursor: pointer;

    &:hover {
      background-color: #F5F5F5;
    }

    &__left {
      display: flex;
      column-gap: 8px;
      align-items: center;

      &__icon {
        font-size: 32px;
        color: #303030;
      }
    }

    &__right {
      display: flex;
      column-gap: 8px;
      align-items: center;
      text-transform: capitalize !important;

      &__icon {
        font-size: 24px;
        color: #303030;
      }
    }
  }
}

.flag {
  object-fit: cover;
  border-radius: 4px;
}

.account {
  &__contextualHelp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    cursor: pointer;
    column-gap: 10px;

    &__left {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }

    &__icon {
      font-size: 32px;
      color: #303030;
    }

    &__label {
      font-size: 16px;
      line-height: 1.5;
      color: #303030;
      margin: 0;
    }

    &__toggle {
      flex-shrink: 0;
    }
  }

  &__changePassword {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    line-height: 1.5;
    color: #303030 !important;
    padding: 15px 0;
    text-decoration: none;

    &__icon {
      font-size: 24px;
      color: #303030;
    }
  }

  &__logout {
    padding: 8px;
    border-radius: 4px;
    background-color: #FAEBED;
    color: #FE3629;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 700 !important;
    text-align: center;
    margin-top: 16px;
    width: 100%;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: #FAC9D1;
    }
  }
}
