.singleViewHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 6px 9px 20px;
  background-color: inherit;
  flex: 1;

  &__withLeftNav {
    margin-left: 80px;
  }

  &__withMenuButton {
    padding-left: 6px;
  }

  &__container {
    display: flex;
  }

  &__wrapper {
    position: sticky;
    top: 0;
    z-index: 1000;
  }

  &__left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;
  }

  &__label {
    font-size: inherit;
    line-height: 1.5;
    color: inherit;
    margin: 0;
  }

  &__icon {
    font-size: 16px;
    color: inherit;
  }

  &__mobileToolbar {
    position: relative;
  }
}
