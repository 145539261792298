.cashOutBetslip {
  display: flex;
  flex-direction: column;
  gap: 5px;

  &__refreshBtn {
    width: fit-content;
  }

  &__card {
    padding: 10px 9px 15px 9px;
    border-radius: 4px;
    border: 1px solid #444;
    background: #ECECEC;

    &__name {
      color: inherit !important;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 1.5;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }

  &__type {
    color: inherit;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    margin: 0;
  }

  &__placed {
    color: inherit;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
  }

  &__divider {
    border-top: solid 1px #666;
    margin: 6px 0 8px;
  }

  &__live {
    background-color: #04a04a;
    color: #fff;
    padding: 0 5px;
    border-radius: 2px;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    display: inline-flex;
    align-items: center;
    margin: 0;
    height: fit-content;

    &__coMobPage {
      font-size: 14px;
      line-height: 1.5;
      padding-top: 1.5px;
      padding-bottom: 1.5px;
    }
  }

  &__nameWrapper {
    display: flex;
    column-gap: 5px;
    align-items: center;
  }

  &__doubleDivider {
    border-top: 1px dashed #666;
    background: none !important;
    margin: 6px 0 4px;
  }

  &__liabilityContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
  }

  &__liabilityText {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0;
    text-align: left;
    margin: 0;
  }

  &__liabilityValue {
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0;
    text-align: left;
    font-weight: bold !important;
    margin: 0;
  }

  &__btnContainer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 5px;
  }

  &__progressBar {
    margin: 12px 15px;
  }
}

.coMobPage {
  &__card {
    color: #303030;
    padding: 10px 0 15px;

    &__landscape {
      width: calc(50% - 3px);
      box-sizing: border-box;
      height: max-content;
    }
  }

  &__name {
    padding: 0 10px;
  }

  &__divider {
    margin-left: 10px;
    margin-right: 10px;
  }

  &__placed {
    font-size: 12px;
    line-height: 1.5;
    padding: 0 10px;
  }

  &__marketName {
    font-weight: bold !important;
    font-size: 14px;
    line-height: 1.5;
    margin: 0 10px;
  }

  &__dashDivider {
    margin-left: 10px;
    margin-right: 10px;
  }

  &__liabCont {
    padding: 0 10px;
  }

  &__liabText, &__liabValue {
    font-size: 14px;
  }

  &__linkName {
    font-size: 14px;
    line-height: 1.5;
    color: inherit !important;
    font-weight: bold !important;
  }
}
