.betsPagesContainer {
  position: relative;
  overflow: hidden;
  min-height: 350px;

  &__mobile {
    padding: 0;
    font-weight: 400;
    display: block;
  }

  &__alert {
    padding: 10px 5px 0;
  }
}
