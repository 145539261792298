.inlineBetInfo {
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 20px;
  gap: 5px;

  &__line {
    display: inline-block;
    padding-right: 25px;

    > *:first-child {
      margin-right: 5px;
    }
  }
}

.selectionName {
  font-weight: bold;
}
