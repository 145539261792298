.infoMsg {
  font-size: 14px;
  line-height: 26px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.noData {
  margin-bottom: 20px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-bottom: 1px solid #DDDDDD;

  &__mobile {
    height: 140px;
    font-size: 16px;
    color: #666666;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
  }
}
