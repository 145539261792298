@import 'styles/variables/variables';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6px;
  background-color: #3E3E3E;
  box-sizing: border-box;
  height: 60px;
  color: #ffffff;

  &__expand {
    padding-right: 14px;
    column-gap: 6px;
  }

  &__left {
    display: flex;
    align-items: center;
    column-gap: 6px;

    &__expand {
      flex: 1;
      border-radius: 4px;
      border: 1px solid #666666;
      column-gap: 8px;
      padding: 9.5px 13px;
    }
  }

  &__title {
    margin: 0;
    color: inherit;
    font-weight: 500;
    font-size: inherit;
  }

  &__listIcon {
    font-size: 16px;
    color: inherit;
  }

  &__betList {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  &__btn {
    outline: none;
    border-radius: 4px;
    border: 1px solid #666666;
    display: flex;
    align-items: center;
    column-gap: 8px;
    background: none;
    color: inherit;
    font-size: 14px;
    line-height: 1.5;
    padding: 8px 12px;
    cursor: pointer;

    &__noLandscape {
      width: 42px;
    }

    &__icon {
      color: inherit;
      font-size: 16px;
      line-height: inherit !important;
    }
  }

  &__closeIcon {
    background: none;
    outline: none;
    padding: 6px;
    border: none;
    color: #ffffff;
    cursor: pointer;
    margin-left: 2px;
  }

  &__search {
    background: none;
    font-size: 14px;
    line-height: 1.5;
    border: none;
    outline: none;
    color: #FFFFFF;
    flex: 1;
    padding: 0;
  }

  &__clear {
    outline: none;
    border: none;
    padding: 0;
    background: none;
    color: inherit;
    cursor: pointer;
    font-size: 12px;
    line-height: 1.5;
  }

  &__noExpandedMiddle {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }
}

.modal {
  &__header, &__body {
    border: none;
  }

  &__dialog {
    padding: 0 19px;

    &__standalone {
      top: 50%;
      transform: translateY(-50%);
      height: auto;
      min-height: auto;
    }

    &__iframe {
      top: 40px;
    }
  }

  &__header {
    padding: 0 20px;
  }

  &__body {
    padding: 0;
    background-color: #FFFFFF !important;

    &__content {
      padding: 32px 20px;
    }
  }

  &__content {
    min-width: auto;
    width: 100%;
    max-width: 600px;
  }

  &__title {
    color: #303030;
    font-size: 20px;
    font-weight: bold !important;
    line-height: 1.5;
    padding-top: 14px;
    padding-bottom: 14px;
  }

  &__sortingTitle {
    color: #303030;
    margin: 0 0 8px 0;
    font-weight: 600 !important;
    font-size: 16px;
    line-height: 1.5;
  }

  &__actions {
    display: flex;
    align-items: center;
    column-gap: 12px;
    padding: 12px 20px;
    background-color: #F5F5F5;
    border-radius: 0 0 4px 4px;

    &__btn {
      flex: 1;
      border: none;
      outline: none;
      border-radius: 4px;
      font-size: 16px;
      line-height: 1.5;
      padding: 12px 0;
      cursor: pointer;

      &__cancel {
        background-color: #DDDDDD;
      }

      &__apply {
        background-color: #FFB80C;
      }

      &__disabled {
        background-color: #FAD06B;
        color: #666666;
        cursor: not-allowed;
      }
    }
  }
}