.marketName {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 25px;
  background-color: #dddddd;
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
}

.runnersColumns {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-bottom: 1px solid #c0c0c0;
}

.runnersType {
  background-color: #ffffff;
  border-right: 1px solid #c0c0c0;

  &:last-child {
    border-right: 0;
  }

  &__inPlay {
    background-color: #cbecd8;
  }

  &__name {
    height: 25px;
    line-height: 25px;
    text-align: center;
    background-color: #ececec;
    border-top: 1px solid #c0c0c0;
    border-bottom: 1px solid #c0c0c0;
    box-sizing: border-box;

    &:first-child {
      border-left: 0;
    }

    &:last-child {
      border-right: 0;
    }
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-color: #c0c0c0;

    &__border {
      gap: 1px;
    }

    &__col1 {
      grid-template-columns: 1fr;
    }

    &__col2 {
      grid-template-columns: repeat(2, 1fr);
    }

    &__col3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__anyOther {
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.runner {
  &__name {
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #f5f5f5;
    border-bottom: 1px solid #c0c0c0;
  }

  &__shortName {
    height: 30px;
    line-height: 30px;
  }

  &__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  &__leftPadding {
    width: 40px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: #ffffff;

    &__inner {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &__fixHeight {
      height: 60px;
    }

    &__inPlay {
      background-color: #cbecd8;
    }

    &__padding {
      padding-top: 10px;
    }
  }
}
