.wrap {
  margin-left: 10px;
  display: flex;
  align-items: center;
  gap: 10px;

  &__shortView {
    margin-left: 0;
  }
}
