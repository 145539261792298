.sports-maintenance {
  background-image: url("../../../assets/images/maintenance-pss.jpg");
}
.sports-maintenance {
  height: auto;
  width: 100%;
  min-height: 100vh;
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.sports-maintenance .logo {
  text-align: center;
}
.sports-maintenance .logo img {
  max-width: 200px;
}

.sports-maintenance .sports {
  width: 100%;
}

.sports-maintenance .message-container {
  padding: 0;
  margin: 0 auto 30px;
}
.sports-maintenance .message-container p span,
#sports-maintenance .message-container p span a {
  text-decoration: underline;
}
.sports-maintenance .message {
  text-align: center;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}
.sports-maintenance h1 {
  font-family: "Druk Text Wide", "Roboto Condensed";
  font-style: italic;
  font-size: 45px;
  margin: 0 0 50px;
  line-height: 1.2em;
  font-weight: 700;
  color: #fff;
  letter-spacing: 5px;
}
.sports-maintenance h2 {
  font-family: "Druk Text Wide", "Roboto Condensed";
  font-style: italic;
  padding: 0 0 5px;
  color: #fff;
  letter-spacing: 2px;
}
.sports-maintenance p {
  font-size: 14px;
  font-weight: 300;
  margin: 15px 0 0;
  color: #fff;
}
.sports-maintenance p a {
  color: #fff;
  text-decoration: underline;
}

@media (min-width: 320px) {
  .sports-maintenance .message-container {
    margin: 0 auto 30px;
  }
  .sports-maintenance h1 {
    font-size: 28px;
  }
  .sports-maintenance h2 {
    font-size: 16px;
  }
  .sports-maintenance .message-container {
    width: 100%;
  }
  .sports-maintenance .logo img {
    max-width: 200px;
  }
  .sports-maintenance p {
    font-size: 14px;
  }
}
@media (min-width: 480px) {
  .sports-maintenance .message-container {
    margin: 0 auto 30px;
  }
  .sports-maintenance h1 {
    font-size: 50px;
  }
  .sports-maintenance h2 {
    font-size: 28px;
  }
  .sports-maintenance .logo img {
    max-width: 200px;
  }
  .sports-maintenance p {
    font-size: 14px;
  }
}
@media (min-width: 768px) {
  .sports-maintenance .message-container {
    margin: 0 auto 60px;
  }
  .sports-maintenance h1 {
    font-size: 70px;
  }
  .sports-maintenance h2 {
    font-size: 30px;
  }
  .sports-maintenance .message-container {
    width: 100%;
  }
  .sports-maintenance .logo img {
    max-width: 275px;
  }
  .sports-maintenance p {
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  .sports-maintenance .button {
    font-size: 22px;
  }
}
