.content {
  &__left {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  &__name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
