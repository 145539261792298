@import '../mixins/breakpoints';

.searchContent {
  .marketRunner {
    @include md {
      grid-template-columns: 5fr 0fr 0fr;
      padding: 4px 10px 4px 0;
    }

    &__betContent {
      height: 100%;
    }
  }

  .betContentContainer {
    .betContentCellMarket {
      .betContent {
        @include md {
          border-radius: 3px;
        }

        .betOdds,
        .betAmount {
          word-wrap: break-word;
          white-space: normal;
        }
      }
    }
  }
}
