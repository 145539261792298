.time {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-right: 4px;
}

.hours {
  font-weight: 700;
}