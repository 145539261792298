.placeholder {
  background-color: #ECECEC;
  border: 1px solid #ECECEC;
  border-radius: 3px;
  font-size: 10px;
  font-style: normal;
  line-height: 15px;
  color: #3E3E3E;

  &::after {
    top: 50%;
  }
}

.placeholder:hover,
.activePlaceholder {
  border-color: #ddd;
  background-color: #ddd;
}

.dropdown {
  margin-top: 4px;
  border-radius: 2px;
  border-color: #c0c0c0;
}

.option {
  box-sizing: border-box;
  height: 30px;
  min-width: 170px;
  line-height: 30px;
  padding: 0 30px 0 10px;
  border-bottom: 1px solid #dddddd;

  &:last-child {
    border: none;
  }
}

.selectedOption {
  font-weight: bold;

  &::after {
    content: '\e91f';
    position: absolute;
    right: 10px;
    font-family: 'fa2', serif !important;
    font-weight: normal !important;
    font-style: normal !important;
  }
}

.icon {
  font-size: 16px;
}

.selectedIcon {
  font-weight: bold !important;
}
