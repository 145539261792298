@import '../../styles/variables/variables';
@import '../../styles/mixins/breakpoints';

.eventHeaderWrapper {
  display: flex;
  flex-direction: row;
}

.eventHeader {
  display: flex;
  align-items: center;
  padding: 2px 12px;
  min-height: 30px;
  overflow: hidden;
  background-color: transparent;
  box-sizing: content-box;
  z-index: 2;

  &__title {
    font-weight: bold !important;
  }

  &__titleBlock{
    display: flex;
    align-items: center;
    padding-top: 2px;
    i{
      position: static;
      margin-left: 5px;
    }
  }

  &__icon {
    margin-right: 10px;
  }

  &__inPlay {
    margin-left: 4px;
    font-weight: bold !important;
  }

  &__date {
    margin: 2px 0;
    font-weight: 100 !important;
  }

  &__statusRow {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  &__favouriteIcon {
    div {
      position: absolute;
      top: 25%;
    }
  }
}

.nameHolder {
  display: flex;
  &__favouriteIcon {
    position: relative;
    div {
      position: absolute;
      top: 45%;
      left: 10px;
    }
  }
}

.marketHeader {
  @include md {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    min-height: 45px;
    z-index: 31;
    .headerRightSide {
      margin: 10px;
      z-index: 1000;
    }
    .dropdownItem {
      border-bottom: 1px solid #666666;
      flex-direction: column;
      justify-content: center;
      display: flex;
      height: 49px;
      line-height: 18px;
      padding: 0 10px;
      &:hover {
        text-decoration: none;
      }
    }
    .dropdownItem:last-child {
      border-bottom: none;
    }
    .marketDropdown {
      list-style-type: none;
      padding: 0;
      position: absolute;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      width: calc(100% - 2px);
      margin: 0;
      overflow-y: auto;
      box-shadow: 2px 5px 4px -1px #858383;
      z-index: 9999;
    }
  }
}
