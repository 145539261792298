.container {
  display: flex;
  flex-direction: column;
}

.content {
  width: 500px;
  padding: 20px;
  border-radius: 5px;
  background-color: #f5f5f5;
  box-sizing: border-box;

  &__mobile {

    max-width: 380px;
  }
}

.lossLimitContainer {
  width: 500px;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #ddd;
  box-sizing: border-box;

  &__mobile {
    max-width: 380px;
    padding: 15px;
  }
}

.validationAlertWrapper {
  width: 100%;

  &__mobile {
    margin-top: 10px;
  }
}

.lossLimitControllsContainer {
  background-color: #F5F5F5;
  padding: 15px;
  border-radius: 5px;

  &__mobile {
    padding: 15px;
  }
}

.divider {
  width: 100%;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

.responsibleBetting {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 60px 0;
  box-sizing: border-box;
}

.formContainer {
  display: flex;
  flex-direction: column;
}

.inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  &__mobile {
    flex-direction: column;
    margin-bottom: 20px;
  }

  &__with_error {
    margin-bottom: 10px;
  }
}

.input {
  position: relative;
  padding: 6px 10px;
  background-color: #fff;
  border: 1px solid #DDD;
  border-radius: 5px;
  font-size: 12px;
  font-style: normal;
  line-height: 15px;
  color: #3E3E3E;
  text-transform: none !important;
  outline: none;
  height: 30px;

  &__mobile {
    text-align: center;
  }

  &::placeholder {
    color: #999;
  }

  &:before {
    content: '';
  }

  &:focus {
    border: 1px solid #000;
  }

  &__with_error {
    border: 1px solid #FE3629;
  }
}

.appliedInputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  &__mobile {
    flex-direction: column;
    margin-bottom: 0;
  }
}

.input {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 48%;
  box-sizing: border-box;

  &__mobile {
    width: 100%;
  }
}

.label {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #303030;
  margin-bottom: 5px;
}

.sectionContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

  &__mobile {
    flex-direction: column;
  }

  &__mobile_reverse {
    flex-direction: column-reverse;
    margin-bottom: 0;
  }

  &__no_margin {
    margin-bottom: 0;
  }
}

.sectionUpdateMessageAlert {
  margin-bottom: 20px;
}

.sectionLabel {
  font-size: 12px;
  line-height: 20px;
  color: #303030;

  &__mobile {
    padding-top: 20px;
  }
}

.sectionControllers {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  &__mobile {
    width: 100% !important;
    justify-content: space-between;
  }

  &__applied {
    width: 50%;
  }
}

.sectionIcon {
  font-size: 16px;

  &__mobile {
    padding: 5px 0;
    transform: rotate(90deg);
  }
}

.controllersContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__mobile {
    flex-direction: column-reverse;
  }
}

.controllers {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  &__mobile {
    width: 100% !important;
  }

  &__applied {
    width: 50%;
  }
}

.limitView {
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  &__wrapper {
    display: flex;
    align-items: center;
  
    &__mobile {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  
  &__label {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #666666;
    margin-right: 5px;
  }
  
  &__divider {
    margin-top: 20px;
  }
  
  &__value {
    font-size: 12px;
    font-weight: 700 !important;
    line-height: 20px;
    text-align: center;
    color: #303030;
  }
  
  &__controls {
    display: flex;
  }
  
  &__controlIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #DDD;
    margin-left: 10px;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    border: none;
    width: 30px;
    height: 30px;
  
    &:hover {
      background-color: #c0c0c0;
    }

    i, i:before {
      color: inherit;
    }
  }
}
