@import "../../styles/mixins/breakpoints";

.tableHeader {
  display: flex;
  padding: 4px 0;
  text-align: center;
  align-items: center;

  .title {
    flex-grow: 1;
    text-align: left;
    padding-inline: 10px;
  }

  .matchedColumn {
    width: 100%;
    max-width: 75px;
    padding-inline: 10px;
  }

  .matchedSort {
    max-width: fit-content;
    padding: 0;

    & > button {
      margin-right: 10px;
    }
  }

  .betContentColumns {
    display: flex;

    &__justifyEnd {
      justify-content: flex-end;
    }

    .betContentColumn {
      box-sizing: border-box;
      flex-grow: 1;

      &__swipe {
        min-width: 40px;

        &__noFlexGrow {
          flex-grow: 0;
        }
      }
    }
  }

  .rulesColumn {
    min-width: 34px;
  }

  &__sortBy {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 4px;
    box-sizing: border-box;
    background-color: #f2f5f5;
    color: #273a47;
    font-size: 12px;

    & > :last-child {
      margin-right: 15px;
    }
  }
}

.hiddenMatched {
  display: none;
}

.matchedTooltip {
  display: flex;
  justify-content: flex-start;
}

.lastRow.firstRow {
  border-top-width: 1px;
}

.lastRow {
  @include md {
    &:last-of-type {
      border-bottom-color: #D8D8D8 !important;
    }
  }

  &.mobileBettingMode {
    height: 48px;
  }
}

.firstRow {
  border-top-width: 0;
}
