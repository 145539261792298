.placeBetsWrap {
  position: relative;
}

.disableScroll {
  overflow-y: hidden;
  max-height: 330px;
}

.emptyMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #303030;
  font-size: 14px;
  margin: 10px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  padding: 19px;
  line-height: 20px;
  text-align: center;
}

.errorMessage {
  padding: 5px 15px;
  font-size: 12px;
  background-color: #fe3629;
  color: #ffffff;

  a {
    color: inherit !important;
  }
}

.loading {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  opacity: 0.9;
  z-index: 100;
}
