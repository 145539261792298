@import "styles/mixins/breakpoints";

.main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 10px 5px 20px;

  strong {
    font-weight: bold !important;
  }

  span {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5px;
    align-items: center;
  }

  .icon {
    font-size: 16px;
    cursor: pointer;
  }

  .button {
    padding: 0 10px;
    height: 30px;
    font-size: 12px;
    font-weight: 700;
    border: 0;
    border-radius: 2px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
  }

  @include md {
    width: 100%;
    flex-direction: column;
    padding: 10px 5px;
    .profitWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;

    }
    button {
      width: 100%;
    }
  }

  &__wrapperBtn {
    display: flex;
    align-items: center;
    column-gap: 7px;
  }

  &__betslip {
    flex-direction: column;
    padding-bottom: 0;
    padding-top: 7px;
    gap: 7px;
  }

  &__betslipBtn {
    height: 30px;
    width: 100px;
  }
}

.betslip {
  display: flex;
  flex-direction: column;
}

.coMobPage {
  flex-direction: column;
  padding-bottom: 0;
  align-items: center;

  &__bottom {
    column-gap: 16px;

    .coMobPage__removeBtn {
      border-radius: 4px;
      font-weight: 400;
      height: 42px;
      padding: 0 20px;
    }
  }
}

