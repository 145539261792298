.selectedMarketNotification {
  display: flex;
  align-items: center;
  gap: 10px;
  min-height: 40px;
  line-height: 1.5em;
  padding: 5px 10px;
  background-color: #FFFFFF;
  border: 1px solid #0072AC;
  box-sizing: border-box;

  &__icon {
    font-size: 16px;
  }

  &__text {
    line-height: 20px;
  }
}
