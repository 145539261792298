.navItem {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid #DDDDDD;
  font-size: 14px;
  color: #303030;
  text-decoration: none;

  &__active {
    background-color: #ECECEC;
    font-weight: bold !important;
  }

  &__subItem {
    padding-left: 40px;
  }

  &__counter {
    padding: 5px;

    &__inPlay {
      background-color: #04A04A;
      color: #FFFFFF;
      border-radius: 2px;
    }
  }
}
