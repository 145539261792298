.timeForm {
  &__verdict {
    margin-left: 10px;
    text-transform: uppercase;
    vertical-align: middle;
  }

  &__logo {
    vertical-align: middle;
    font-family: 'timeform-logo' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
  }
}
