.timeForm123 {
  min-width: 200px;

  &__list {
    list-style-type: none;
    padding-left: 0;
  }

  &__header {
    font-weight: bold !important;
    margin-top: 0;
    margin-bottom: 10px;
  }

  &__rating {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    &__runnerName {
      margin-bottom: 5px;
    }
  }

  &__ratingWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 50px 10px 10px;
    border-bottom: 1px solid #EEEEEE;
  }

  &__fullView {
    margin-left: 30px;
  }
}
