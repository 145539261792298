@import "../../styles/variables/variables";
@import "../../styles/mixins/breakpoints";

.marketTable {
  margin: 12px 11px 0 11px;

  &__content {
    &__header {
      display: flex;
      align-items: center;
      padding: 8px 0;
      border-bottom: 1px solid #eeeeee;
    }
  }

  &__totalMatched {
    flex-grow: 1;
    font-size: $smaller;
    color: $light-gray;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    column-gap: 5px;

    @include md {
      font-size: 11px;
    }
  }

  &__totalMatchedCell {
    display: flex;
    justify-content: center;

    &__content {
      display: flex;
    }

    span {
      flex: 1 1;
      min-width: 40px;
      font-size: $smaller;
      color: $light-gray;
      text-align: center;

      @include md {
        width: auto;
        font-size: 11px;
      }
    }

    &__withoutLay {
      justify-content: flex-end;
    }
  }

  &__runnerName {
    font-size: 11px;
    color: #1e1e1e;
    font-weight: bold;
    padding: 2px 0 2px 10px;
    word-break: break-word;
  }

  #biab_body:not(.biab_mobileView) .biab_cashout-icon i {
    background-color: #ff8624;
    border-color: #ffa01f;
  }
}
