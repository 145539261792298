@import "/src/styles/mixins/breakpoints";

.communitySection {
  display: inline-flex;
  width: fit-content;
  padding: 8px;
  margin: 10px;
  text-align: left;
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  border-radius: 2px;
  box-shadow: 4px 4px 10px -2px #1e1e1e;

  &__unavailableCardItem {
    margin-top: -4px;
  }

  &__result {
    box-shadow: none;
    border: 1px solid #e3e8e8;
  }

  & > div:not(:last-child) {
    margin-right: 3px;
  }

  @include md {
    padding: 5px;
    margin: 5px;
  }
}
