.selectAll {
  label {
    cursor: pointer;
  }

  input {
    vertical-align: middle;
    cursor: pointer;
  }

  span {
    margin-left: 5px;
  }
}
