.notification {
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-size: 10px;
  border-radius: 4px;
  border: solid 1px #0072AC;
  background-color: #ffffff;

  &__animation {
    opacity: 0;
    transform: translateY(-100%);
    animation: slideInNotification 0.5s ease forwards;
  }

  &__market, &__event {
    color: #666666;
  }
  &__content {
    flex-grow: 1;
  }
  &__text {
    margin: 0;
    line-height: 15px;
  }
  &__errorMsg {
    margin: 5px 0 0;
  }
  &__success {
    background-color: #CBECD8;
    border: 1px solid #04A04A;
  }
  &__error {
    background-color: #FAC9D1;
    border: 1px solid #D70022;
  }

  &__icon {
    &__loading {
      font-size: 16px !important;
      color: #0072ac;
    }
    &__success {
      color: #04A04A;
      font-size: 14px;

      &::after {
        color: #04A04A;
      }
    }

    &__error {
      width: 11px;
      min-width: 11px;
      height: 11px;
      font-size: 12px;
      vertical-align: middle;
    }
  }

  &__text {
    span {
      font-weight: bold;
    }
  }
  &__close {
    cursor: pointer;
    font-size: 14px;
  }
}


@keyframes slideInNotification {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}