.won {
  color: #190;
}

.lost {
  color: #fe3628;
}

.capitalise {
  text-transform: lowercase;
}

.capitalise::first-letter {
  text-transform: uppercase;
}

.highlight {
  font-weight: 700 !important;
}

.offerId {
  word-break: break-word;
}
