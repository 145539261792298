.totalLiability {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 10px; // if the top and bottom padding are changed -> need to change TOP_AND_BOTTOM_PADDINGS value in component
  border-top: 1px solid #C0C0C0;  // if the border width is changed -> need to change BORDER_WIDTH value in component
  border-radius: 4px;
  background-color: #ECECEC;
}
