.viewAllLines {
  &__container {
    display: flex;
    justify-content: flex-end;
    padding: 10px 5px;
    column-gap: 5px;
    font-size: 11px;
  }

  &__label {
    font-size: 11px;
    cursor: pointer;
  }

  &__icon {
    font-size: 8px;
    cursor: pointer;
  }
}
