.bet {
  border-top: 1px solid #DDDDDD;
  padding: 20px 0;
  margin: 0 12px;

  &:last-of-type {
    padding-bottom: 0;
  }

  &__triggeredByCashOut {
    background-color: #FFB80C;
    padding: 3px 8px;
    border-radius: 4px;
    margin-bottom: 12px;
    width: fit-content;

    &__text {
      margin: 0;
      font-size: 12px;
      font-weight: bold !important;
      line-height: 1.5;
      color: #303030;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 56px;
    margin-bottom: 16px;

    &__left {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }

    &__title {
      margin: 0;
      font-size: 16px;
      line-height: 1.5;
      font-weight: 600 !important;
      color: #303030;
    }
  }

  &__bottom {
    margin: 0;
    font-size: 12px;
    line-height: 1.5;
    color: #666666;
  }

  &__riskProfit {
    flex: 1;

    &__container {
      display: flex;
      align-items: center;
      column-gap: 10px;
      margin-bottom: 20px;
    }

    &__top {
      margin: 0;
      font-size: 12px;
      line-height: 1.5;
      color: #666666;
    }

    &__bottom {
      margin: 0;
      font-size: 18px;
      line-height: 1.5;
      color: #303030;
    }
  }
}