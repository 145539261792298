@import 'styles/variables/variables';

.nextRacesList {
  &__item {
    display: flex;
    align-items: center;
    padding: 5px;
    box-sizing: border-box;
    border-right: 1px solid #bfbfbf;
    transition: .1s;

    &:hover {
      background-color: #f5f5f5;
    }

    &:last-child {
      border-right: none;
    }

    a {
      text-decoration: none;
      width: 100%;
    }
  }
}

.nextRace {
  display: flex;
  align-items: center;
  font-size: $small;

  &__icon {
    display: inherit;
    flex-basis: content;
    margin-right: 5px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: auto;
  }

  &__title {
    flex: auto;
    font-weight: bold;
    margin-bottom: 3px;
    color: $dark-gray;
  }

  &__totalMatch {
    color: $light-gray;
    font-size: $smaller;
    min-height: 12px;
  }

  &__date {
    display: inline-block;
    max-width: 50px;
    text-align: right;
    color: $light-gray;
    font-size: $smaller;
    margin-right: 5px;
  }

  &__nav {
    flex-basis: 6%;
    text-align: right;

    i {
      display: inherit;
      margin-top: 1px;
      color: $dark-gray;
    }
  }
}
