.cancelAll {
  position: relative;
  width: fit-content;
  margin: 0 auto;

  &__btn {
    all: unset;
    color: #fe3629;
    cursor: pointer;
    text-decoration: underline;
    font-size: 12px;
    line-height: 1.66;
    margin-bottom: 16px;

    &:hover {
      text-decoration: none;
    }

    &:disabled {
      color: #999999;
      text-decoration: none;
      cursor: default;
    }
  }

  &__dialog {
    position: absolute;
    padding: 14px;
    border-radius: 4px;
    border: 1px solid #dddddd;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffffff;
    z-index: 100;
    top: calc(100% - 8px);
    box-shadow: 0 2px 8px rgba(#181e5c, 15%);

    &__arrow {
      position: absolute;
    }

    &:before {
      border-style: solid;
      border-width: 1px 1px 0 0;
      border-color: #dddddd;
      content: '';
      display: inline-block;
      height: 5.5px;
      width: 5.5px;
      left: 50%;
      transform: translateX(-50%) rotate(-45deg);
      position: absolute;
      top: -4px;
      vertical-align: top;
      background-color: #ffffff;
    }

    &__ensureText {
      font-size: 12px;
      line-height: 1.66;
      text-align: center;
      color: #303030;
      margin-top: 0;
    }

    &__buttons {
      display: flex;
      column-gap: 10px;
    }

    &__btn {
      min-width: 80px;
      flex: 1;
    }
  }
}
