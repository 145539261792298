.error {
  display: flex;
  align-items: center;
  column-gap: 10px;
  border-radius: 4px;
  border: 1px solid #FE3629;
  padding: 10px;
  margin-top: 5px;

  &__mobile {
    padding: 13px 12px;
    margin-top: 16px;
  }

  &__icon {
    font-size: 16px;
  }

  &__label {
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    color: inherit;

    &__mobile {
      font-size: 14px;
    }

    span {
      font-weight: bold;
    }
  }

  &__closeBtn {
    all: unset;
    margin-left: auto;
    cursor: pointer;
    padding: 2px;
    line-height: 1;
  }
}
