@import "../../styles/variables/variables";
@import "../../styles/mixins/breakpoints";

.searchText {
  line-height: 14px;
  font-size: $small;
  color: #273a47;
  padding: 15px;
}

