.actions {
  &__wrap {
    padding: 0 10px 10px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  &__btn {
    width: 100%;
    height: 25px;
    border: 0;
    outline: none;
    border-radius: 4px;
    background-color: #FFB80C;
    box-sizing: border-box;
    cursor: pointer;

    &__edit {
      background-color: #DDDDDD;
    }

    &__place {
      background-color: #FFB80C;
    }

    &__confirm, &__lowerPrice {
      border: 2px solid #FF8624;
    }

    &__disabled {
      background-color: #FAD06B;
      cursor: initial;
    }
  }
}
