@import "../../styles/variables/variables";
@import "../../styles/mixins/breakpoints";

.viewBy {
  &__container {
    display: flex;
    align-items: center;
    column-gap: 5px;
    padding: 10px;

    @include md {
      column-gap: 0;
      padding: 4px;
    }
  }

  &__title {
    font-size: 11px;
    font-weight: bold;
    line-height: 30px;
    margin: 0;
  }

  &__dropdown {
    &__container {
      min-width: 112px;

      @include md {
        width: 100%;
      }
    }

    &__placeholder {
      padding: 7px 25px 7px 10px;
      text-transform: none;
      border-color: #dfdfdf;
      background-color: $white;

      @include md {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        font-size: 14px;
        padding: 0 8px;
        color: #7f7f7f;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: normal;
        border-width: 0;

        &:after {
          content: none;
        }
      }
    }

    &__dropdown {
      margin-top: -1px;
      background-color: $white;
      right: 6px;

      @include md {
        border-width: 0;
        border-radius: 0 0 5px 5px;
        width: 100%;
        z-index: 2000;
        left: 0;
      }
    }

    &__option {
      padding: 3px 5px;
      font-size: 11px;
      color: $black;

      &:hover {
        color: $dark-gray;
        background-color: #f6f6f6;
      }

      @include md {
        display: flex !important;
        border-top: 1px solid;
        font-weight: bold !important;
        font-size: 12px;
        background-color: #FFFFFF;
        color: #7F7F7F;
        border-color: #F2F2F2;
        height: 40px;
        justify-content: flex-start;
        span {
          padding: 5px !important;
        }
      }
    }

    &__icon {
      font-size: 18px !important;

      &__rotate {
        transform: rotate(180deg);
      }
    }
  }
}

@media (min-width: $md) {
  .viewBy {
    &__dropdown {
      &__dropdown {
        min-width: 100px;
      }
    }
  }
}
