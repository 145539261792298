.asianMore {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60px;
  justify-content: center;
  gap: 5px;

  &__bottom {
    height: 0;
    justify-content: space-around;
    padding: 4px 0;
    gap: 0;
  }

  i {
    font-size: 4px;
    height: 20px;
    line-height: 20px;
  }

  &__active {
    background-color: #444444;
  }
}
