@import "src/styles/mixins/breakpoints";

.fancyMarket {
  &__sections {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;

    @include md {
      padding-top: 10px;
    }
  }
}
