.totalBlock {
  display: flex;
  border-radius: 5px;
  background-color: #F5F5F5;
  line-height: 30px;
  padding: 0 10px;
  gap: 5px;
  font-size: 12px;
  color: #303030;
  &__mobile {
    flex-grow: 1;
    display: inline-block;
    border: solid 1px #dddddd;
    line-height: 42px;
    text-align: center;
    font-size: 14px;
    background: initial;
  }
}

.pl {
  &__positive {
    color: #04A04A;
  }
  &__negative {
    color: #FE3629;
  }
}
