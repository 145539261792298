@import 'styles/variables/variables';

.mobileLoginFormContainer {
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
}

.container {
  padding: 16px 16px 24px;
  background-color: #ececec;
}

.preferences {
  margin-top: 9px;
}

.header {
  padding: 9px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #3e3e3e;

  &__home {
    margin: 0;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    box-sizing: border-box;

    &__icon {
      font-size: 24px;
      margin: 0;
    }
  }

  &__label {
    margin: 0;
    font-weight: 700 !important;
    font-size: 16px;
    line-height: 1.5;
    color: inherit;
  }

  &__end {
    width: 42px;
  }
}

:global {
  .biab_loginPage {
    #header-resize-container {
      position: sticky;
      top: 0;
      z-index: 100;
    }

    &#biab_body {
      overflow: visible;
    }
  }
}

