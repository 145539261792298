@import 'styles/variables/variables.scss';

.radioButton {
  display: flex;
  align-items: center;
  gap: 10px;

  &:has(> input:not(:disabled)) {
    cursor: pointer;
  }

  &:hover {
    input:not(:checked):not(:disabled) ~ .radioButton__button {
      border-color: $border-black;
    }
  }

  &__button {
    width: 15px;
    height: 15px;
    box-sizing: border-box;
    border: 1px solid $border-dark-gray;
    border-radius: 50%;
    background-color: #fff;
    position: relative;
    transition: border-color 0.1s ease;

    &::before {
      width: 9px;
      height: 9px;
      color: $blue;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.1s ease;
      font-size: 9px !important;
      line-height: 9px;
    }
  }

  input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;

    &:checked + .radioButton__button {
      border: 1px solid $blue;

      &::before {
        opacity: 1;
      }
    }

    &:disabled {
      & ~ span {
        color: $text-gray-light;
      }

      & + .radioButton__button {
        border-color: $border-gray;

        &::before {
          background-color: $icon-black-disabled;
        }
      }
    }

    &:focus-visible + .radioButton__button {
      outline: 2px solid -webkit-focus-ring-color;
      outline-offset: 1px;
    }
  }
}
