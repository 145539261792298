@import "/src/styles/mixins/breakpoints";

.main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 500px;
  margin: auto;

  .rightSide, .pages, .leftSide {
    display: flex;
    align-items: center;
  }

  .leftSide, .rightSide {
    gap: 10px;

    > span {
      display: flex;
      align-items: center;

      &:not(.disabled):hover {
        > div {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  .leftSide {
    i {
      margin-right: 3px;
    }

  }

  .rightSide {
    i {
      margin-left: 3px;
    }
  }

  .pages {
    gap: 10px;
    flex-direction: row-reverse;

    > div {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      @include md {
        &:hover {
          text-decoration: none !important;
        }
      }
    }
  }

  .paginationLink {
    user-select: none;
  }
}
