.skeleton {
  text-align: right;
  margin-bottom: 2px;
  display: flex;
  column-gap: 2px;
  justify-content: flex-end;

  &__odds {
    width: 40px;
    height: 22px;
    display: inline-block;
    &__small {
      width: 35px;
    }
    &__mobile {
      flex: 1;
      height: 42px;
    }
  }
}
