.links {
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 12px;

  &__oneColumn {
    display: flex;
  }

  &__link {
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    background-color: #ececec;
    border-radius: 4px;
    text-decoration: none;
    flex: 1;
    position: relative;
    text-align: center;

    &:hover, &:focus {
      background-color: #dddddd;
    }

    &__icon {
      font-size: 32px;
      color: #303030;

      &__tooltip {
        color: #303030;
        font-size: 24px !important;
        position: absolute;
        right: 6px;
        top: 10px;
      }
    }

    &__label {
      margin: 0;
      font-size: 14px;
      line-height: 20px;
      color: #303030;
      display: inline-flex;
      align-items: center;
      flex: 1;
    }
  }
}

.tooltip {
  text-align: center !important;
  font-size: 16px !important;
  box-sizing: border-box;
}
