.containerEmpty {
  padding: 10px 5px;
  width: 100%;
  box-sizing: border-box;

  &__mobile {
    padding: 0;
  }

  &__text {
    padding: 10px 30px;
    background: #FFFFFF;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.5;
    margin: 0;

    &__mobile {
      padding: 10px;
      font-size: 16px;
      font-weight: 500 !important;
      text-align: center;
      height: 114px;
      vertical-align: middle;
      line-height: 94px;
      box-sizing: border-box;
      background: #f5f5f5;
    }
  }
}

.reloadBtn {
  width: fit-content;
  font-size: 10px;

  &:hover {
    background: #C0C0C0;
  }
}

.cashOut {
  &__mobile {
    background-color: #ffffff;
  }
}

.tabReloadBtn {
  height: 20px;

  &__mobile {
    padding: 8px 12px;
    height: auto;

    &__label {
      font-size: 14px;
    }

    &__icon {
      font-size: 16px;
      width: 16px;
      height: 16px;
    }
  }
}
