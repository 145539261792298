@font-face {
  font-family: Druk Text Wide;
  font-style: normal;
  font-weight: 500;
  src: url("../../../assets/fonts/druk/DrukTextWide-Medium-Cy-Gr-Web.woff2.7eba3843.woff2")
  format("woff2"),
  url("../../../assets/fonts/druk/DrukTextWide-Medium-Cy-Gr-Web.woff.f532c947.woff")
  format("woff");
}

@font-face {
  font-family: Druk Text Wide;
  font-style: italic;
  font-weight: 500;
  src: url("../../../assets/fonts/druk/DrukTextWide-MediumItalic-Cy-Gr-Web.woff2.9028d0d6.woff2")
  format("woff2"),
  url("../../../assets/fonts/druk/DrukTextWide-MediumItalic-Cy-Gr-Web.woff.aae47c39.woff")
  format("woff");
}
