@import '../../styles/mixins/breakpoints';

.main {
  .row1Wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .row2Wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include md {
      justify-content: center;
    }
  }
  @media (min-width: $md) and (max-width: 1100px) {
    .row2Wrapper, .row3Wrapper {
      justify-content: space-between !important;
    }
  }

  .resultsRow2 {
    @include md {
      margin-top: 15px;
    }
  }

  .row3Wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .roundAndLoader {
    @include md {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
    }
  }

  .title {
    @include md {
      position: absolute;
      left: 5px;
    }
  }
}

.myBetsResults {
  padding-top: 20px;
}
