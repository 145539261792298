.link {
  vertical-align: middle;
  line-height: 35px;
  border: solid 1px #666;
  margin: 4px 3px 4px 6px;
  border-radius: 4px;
  padding: 0 13px;
  text-decoration: initial;
  color: #fff;
  font-size: 14px;
  text-align: center;
}

.icon {
  color: inherit !important;
  margin-right: 8px;
}