.skeleton {
  background-color: #FFFFFF;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;

  &__tab {
    flex: 1;
    height: 33px;
    display: block;
    border-left: 1px solid #dddddd;

    &:first-child {
      border-left-width: 0;
    }

    &__container {
      display: flex;
    }
  }

  &__info {
    height: 15px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  &__selections {
    height: 15px;
  }

  &__runner {
    flex-grow: 1;
    margin-top: 5px;
    border-radius: 0 0 8px 8px;
  }
}
