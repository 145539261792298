.main {
  background-color: #3E3E3E;
  padding: 0 6px;
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 6px;
  box-sizing: border-box;
  height: 60px;
  align-items: center;
  &__margin {
    margin-left: 80px;
  }
}
