.betslipHeader {
  display: flex;
  position: relative;
  padding: 0 10px;
  line-height: 35px;
  font-weight: 400;
  align-items: center;
  background-color: #303030;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
}

.betslipTitle {
  flex: 1 1 0;
}

.betslipToggle {
  padding: 0;
  margin-right: 10px;
  border: 0;
  outline: 0;
  font-size: 8px;
  background: none;
  color: inherit;
  cursor: pointer;
  width: 25px;
  height: 25px;
  border-radius: 50%;

  &__active {
    i {
      transform: rotate(180deg);
    }
  }
}

.betslipHeader:hover {
  .betslipToggle {
    background: #666666;
  }
}

.sidebarToggle {
  border: none;
  height: 25px;
  width: 25px;
  background: none;;
  line-height: 25px;
  border-radius: 100%;
  font-size: 10px;
  cursor: pointer;
  margin-right: -5px;
  color: inherit;
  &:hover {
    background: #666666;
  }
}