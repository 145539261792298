.basketball {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  line-height: 1;

  &__label {
    flex: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
  }

  &__games {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    width: 50%;
    height: 100%;
  }

  &__score {
    line-height: 20px;
  }
}

.basketballCashOut {
  display: flex;
  justify-content: space-around;
  min-height: 40px;
  height: 100%;
  min-width: 65px;
  line-height: 1;

  tbody {
    display: table;
    width: 100%;
    height: 100%;
  }

  &__label {
    flex: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    text-align: center;
  }

  &__score {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 20px;
    min-width: 20px;
    height: 50%;
  }
}
