@import 'styles/variables/variables.scss';

.icon {
  font-size: 16px;
  color: $icon-black;

  /* sizes */

  &__xs {
    font-size: 10px;
  }

  &__sm {
    font-size: 12px;
  }

  &__lg {
    font-size: 24px;
  }

  /* colors */

  &__gray {
    color: $icon-gray;
  }

  &__white {
    color: $icon-white;
  }
  &__inherit,
  &__inherit::before {
    color: inherit;
  }
}
