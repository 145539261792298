@import 'src/styles/variables/variables';
@import 'src/styles/mixins/breakpoints';

.accountStmntMobHolderWrapper {
  padding: 12px 5px 10px 5px;
  .mobileAccStatementDatepickerHolder {
    &__mobile {
      padding: 7px 9px 16px;
      background: $white;
    }

    .datepickerForm {
      .datepickerItemHolder {
        &__mobile {
          position: relative;
          margin: 0;
          padding: 0;
          border: none;
          font-size: $small;
        }
        .datepickerItemInner {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }
        .mobileStatementDatepickerBlock {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        .resetDatepickerHolder {
          width: 20%;
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
        }
        .datepickerSettings {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }
        .statementFilter {
          &__mobile {
            display: flex;
            align-items: center;
            &__label {
              white-space: nowrap;
            }
            &__selectHolder {
              flex: 1 1 100%;
              margin-left: 5px;
              .selectWrapper {
                position: relative;
              }
              .listOfValues {
                border: 1px solid;
                width: calc(100% - 10px);
                margin: 0;
                z-index: 100;
                list-style: none;
                padding-inline-start: 0;
                position: absolute;
                left: 5px;
                li {
                  background-color: #e5e5e5;
                  font-size: 14px;
                  line-height: 30px;
                  display: block;
                  text-align: left;
                  padding: 3px 5px;
                  cursor: pointer;
                }
              }
              .selectControl {
                height: 26px;
                cursor: pointer;
                display: flex;
                align-items: center;
                position: relative;
                border: 1px solid #bbbebe;
                border-radius: 2px;
                background-color: #e5e5e5;
                .selectValue {
                  margin-left: 10px;
                }
                i {
                  color: #000000;
                  position: absolute;
                  right: 10px;
                }
              }
              select {
                height: 28px;
                width: 100%;
                min-width: 180px;
                border: 1px solid #bbbebe;
                border-radius: 2px;
                background-color: #e5e5e5;
              }
            }
          }
        }

        .resetDatepicker {
          border: 0;
          outline: 0;
          &__mobile {
            display: inline-block;
            font-size: $small;
            background: #e5e5e5;
            line-height: 25px;
            padding: 0 10px;
            color: #7f7f7f;
            text-decoration: none;
          }
        }

        .datePickerWrapper {
          &__mobile {
            position: relative;
            float: left;
            display: flex;
            align-items: center;
            vertical-align: top;

            input {
              height: 22px;
              min-width: 116px;
              background-color: #e5e5e5;
              background-position: 107px;
              border: none;
            }

            label {
              margin: 0 20px 0 0;
              text-align: right;
            }

            i {
              cursor: pointer;
              font-size: 15px;
              color: $light-gray;
              position: absolute;
              right: 7px;
              top: 4px;
            }
          }
          @include xs {
            margin: 0 10px 10px 0;
            float: none;
          }
          .inputWrapper {
            position: relative;
          }
          input {
            @include xs {
              height: 22px;
              width: 121px;
              background-color: #e5e5e5;
              background-position: 107px;
              border: none;
              box-sizing: border-box;
            }
          }
        }
      }
    }
  }
}
.datepickersAndCancelUnmatched {
  &__accountStatement {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
  }

  &__mobile {
    margin: 7px 5px 10px;
    background: $white;
    position: relative;
    padding: 8px 9px 16px;
  }

  .datepickerForm {
    .periodHolder {
      margin-bottom: 25px;
    }
    .datepickerItemHolder {
      &__mobile {
        margin: 0;
        padding: 0;
        border: none;
        font-size: $small;
      }

      .resetDatepicker {
        border: 0;
        outline: 0;
        font-size: $small;
        line-height: 25px;
        padding: 0 10px;
        margin: 0;
      }

      .datePickerWrapper {
        &__mobile {
          position: relative;
          margin-right: 10px;
          float: left;
          display: flex;
          margin-bottom: 10px;
          align-items: center;
          vertical-align: top;
          .dayClassname {
            background-color: #ffffff;
          }

          input {
            padding-left: 5px;
            height: 24px;
            width: 121px;
            background-color: #e5e5e5;
            background-position: 107px;
            border: none;
            box-sizing: border-box;
          }

          label {
            margin: 0 15px 0 0;
            text-align: right;
            display: block;
          }

          i {
            cursor: pointer;
            font-size: 15px;
            color: $light-gray;
            position: absolute;
            right: 7px;
            top: 4px;
          }
        }
        @include xs {
          margin: 0 10px 10px 0;
        }
      }
    }
  }

  .datepickers {
    display: flex;

    .datepickerLabel {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: $small;
      font-weight: 400;
      padding: 0 4px;
    }

    .datepickerLabel.labelTo {
      padding: 0 8px 0 4px;
    }

    .resetDates {
      margin-left: 10px;
    }

    .from {
      padding-left: 0;
    }

    .datepickerLabel:nth-child(2) {
      margin: 0 4px;
    }

    .datePickerWrapper {
      width: auto;
      position: relative;
      display: flex;

      .dayClassname {
        background-color: #ffffff;
      }

      input {
        background-color: #d5d5d5;
        height: 26px;
        border: 1px solid #d5d5d5;
        border-radius: 2px;
        font-size: $small;
        width: 174px;
        padding: 0 5px;
        outline: none;
      }

      i {
        position: absolute;
        right: 7px;
        top: 7px;
        cursor: pointer;
      }
    }
    .wrapperFrom {
      margin-right: 4px;
    }
    .wrapperTo {
      margin: 0 4px;
    }
  }

  .cancelAllUnmatchedBtnWrapper {
    text-align: center;
  }

  .statementFilter {
    display: flex;
    align-items: center;

    &__label {
      font-size: $small;
    }

    &__selectHolder {
      margin-left: 5px;
      height: 26px;
      border: 1px solid #bbbebe;
      border-radius: 2px;

      div {
        width: 100%;
        height: 100%;
        min-width: 172px;
        .listOfValues {
          margin: 0;
          z-index: 100;
          position: relative;
          list-style: none;
          padding-inline-start: 0;
          li {
            display: block;
            text-align: left;
            padding: 3px 5px;
            cursor: pointer;
          }
        }
        .selectControl {
          cursor: pointer;
          display: flex;
          align-items: center;
          position: relative;
          .selectValue {
            margin-left: 10px;
          }
          i {
            color: #000000;
            position: absolute;
            right: 10px;
          }
        }
        select {
          width: 100%;
          height: 100%;
          font-size: $small;
          border: none;
          background-color: inherit;
          outline: none;

          option {
            background-color: #f6f6f6;
          }
        }
      }
    }
  }
}

.statementFilter__andRefreshButton {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.datepickers {
  display: flex;
  flex-wrap: wrap;
}

.myBetsMobileDatesTop {
  display: flex;
  justify-content: space-between;
}
