.container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.limitControllsContainer {
  background-color: #F5F5F5;
  padding: 20px;
  border-radius: 5px;
}

.limitControllsContainer {
  background-color: #F5F5F5;
  padding: 20px;
  border-radius: 5px;
}

.divider {
  width: 100%;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

.responsibleBetting {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 60px 0;
  box-sizing: border-box;

  &__mobile {
    padding: 20px 15px 0;
  }
}

.validationMessage {
  font-size: 12px;
  line-height: 16px;

  &__value {
    font-weight: 700 !important;
  }
}