.wrap {
  position: fixed;
  top: 20px;
  left: 12px;
  right: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 10000;
}
