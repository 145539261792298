.selectControl {
  height: 26px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid #bbbebe;
  border-radius: 2px;
  background-color: #e5e5e5;

  .selectValue {
    margin-left: 10px;
  }

  i {
    color: #000000;
    position: absolute;
    right: 10px;
  }
}

.listOfValues {
  margin: 0;
  z-index: 100;
  position: relative;
  list-style: none;
  padding-inline-start: 0;
  li {
    display: block;
    text-align: left;
    padding: 3px 5px;
    cursor: pointer;
  }

  &__mobile {
    border: 1px solid;
    width: calc(100% - 10px);
    margin: 0;
    z-index: 100;
    list-style: none;
    padding-inline-start: 0;
    position: absolute;
    left: 5px;
    li {
      background-color: #e5e5e5;
      font-size: 14px;
      line-height: 30px;
      display: block;
      text-align: left;
      padding: 3px 5px;
      cursor: pointer;
    }
  }
}