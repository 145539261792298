@import "../../styles/variables/variables";

.eventMarket {
  margin: 12px 6px 0 6px;

  &__content {
    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid;
      border-color: #f2f5f5;
    }

    &__header {
      height: 22px;
      padding: 2px 0 2px 10px;
      align-items: center;
      font-size: 11px;
      color: #7f7f7f;
    }
  }

  &__totalMatched {
    flex-basis: 60%;

    span {
      white-space: nowrap;
    }
  }

  &__totalMatchedCell {
    flex-basis: 40%;
    display: inline-flex;

    span {
      flex: auto;
      text-align: center;
      padding: 0 10px;
    }
  }

  &__runnerName {
    flex-grow: 1;
    font-size: 11px;
    color: #1e1e1e;
    font-weight: bold !important;
    padding: 2px 0 2px 10px;
    word-break: break-word;
    margin: 0;
  }

  &__whatIf {
    width: fit-content;
    padding-left: 0;
  }

  #biab_body:not(.biab_mobileView) .biab_cashout-icon i {
    background-color: #ff8624;
    border-color: #ffa01f;
  }

  &__runnersContainer {
    position: relative;
  }

  &__starIcon {
    margin-right: 5px;
    i {
      font-size: 16px;
    }
  }

  &__collapseArrow {
    font-size: 8px;
  }
}
