.container {
  display: flex;
  padding: 10px 5px;
}

.link {
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 30px;
  text-decoration: none;
  border-left: 2px solid;
  font-weight: 700;
  line-height: 21px;
}