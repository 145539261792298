@import "src/styles/mixins/breakpoints";

.betCellTooltip {
  position: absolute;
  z-index: 11;

  &__market {
    display: none !important;
    top: 0;
    left: calc(33.3333% - 12px);

    @include md {
      top: 3px;
      left: -17px;
    }
  }

  &__cell {
    top: 2px;
    left: 4px;
  }

  &__fancy {
    top: 2px;
    left: -14px;
  }

  &__icon {
    margin-left: 0;
  }

}

.isLayColumnEnabled {
  left: calc(66.6666% - 12px);
}
