@import "src/styles/mixins/breakpoints";

.secondSection {
  min-width: 520px;
  padding: 8px;
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  border-radius: 2px;
  box-shadow: 4px 4px 10px -2px #1e1e1e;
  width: 611px;
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  .carts {
    display: flex;
    flex-direction: row;
  }
  @include md {
    flex-direction: column;
    gap: 5px;
    width: auto;
    min-width: auto;
    .carts {
      justify-content: center;
    }
  }
}
