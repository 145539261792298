.message {
  display: flex;
  min-height: 40px;
  align-items: center;
  padding: 0 12px;
  border-radius: 4px;
  margin-top: 7px;
  box-sizing: border-box;

  &__text {
    margin: 0;
    font-size: 10px;
    line-height: 1.5;

    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__closeIcon {
    font-size: 10px;
    cursor: pointer;
  }

  &__mobileTab {
    margin: 15px 12px;

    &__left {
      display: flex;
      align-items: center;
      column-gap: 12px;

      i.tickIcon:before {
        color: #04A04A;
      }

      &__text {
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }
  &__error.message__mobileTab {
    border: solid 1px #D70022;
  }
  &__success.message__mobileTab {
    border: solid 1px #04A04A;
  }
}
