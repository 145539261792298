.outRightMarket {
  margin-bottom: 4px;
  background-color: #FFFFFF;

  &__border {
    border-bottom: solid 1px #c0c0c0;
  }
}

.outRightMarketMobile {
  border-radius: 8px;
  overflow: hidden;
  border: solid 1px #c0c0c0;
  margin-bottom: 6px;
}