@import 'styles/variables/variables';

.competition {
  &__title {
    box-sizing: border-box;
    height: 30px;
    border-top: solid 1px #c0c0c0;
    border-bottom: solid 1px #c0c0c0;
    background-color: #ececec;
    color: #3e3e3e;
    display: flex;
    position: sticky;
    top: 40px;
    z-index: 10;
    font-size: 12px;
  }
  &__expand {
    cursor: pointer;
  }
  &__withOutBorder {
    border-bottom-width: 0;
  }
  &__tab {
    border-top-width: 0;
    position: initial;
    top: 0;
    z-index: 0;
    border-radius: 4px;
    &__open {
      border-radius: 4px 4px 0 0;
    }
  }
  &__mobileTab {
    padding: 4px 8px 4px 5px;
    height: 38px;
    background-color: #dddddd;
    .competition__tabName {
      font-weight: bold;
    }
  }
  &__actions {
    width: 60px;
    display: flex;
    white-space: nowrap;
    column-gap: 14px;
    padding: 0 7px;
    box-sizing: border-box;
    min-width: 60px;
    align-items: center;

    .competition__expand {
      line-height: 30px;
      color: #3e3e3e !important;
      font-size: 15px !important;
      &:before {
        vertical-align: top;
      }
    }
  }

  &__actionsTab {
    width: auto;
    min-width: auto;
    display: flex;
    vertical-align: top;
    white-space: nowrap;
    column-gap: 14px;
    padding: 0 7px;
    box-sizing: border-box;
  }

  &__name {
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 7px;
    padding-left: 2px;
    position: absolute;
    white-space: nowrap;
    width: 100%;
    box-sizing: border-box;
    gap: 8px;

    &__wrapper {
      position: relative;
      width: 100%;
    }
  }

  &__nameOdds {
    font-weight: bold;
  }

  &__tabName{
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  &__colWrapper {
    display: flex;
  }
  &__col {
    border-left: solid 1px #c0c0c0;
    display: inline-block;
    min-height: 28px;
    line-height: 28px;
    vertical-align: top;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    box-sizing: border-box;
  }
  &__market {
    width: 103px;
    &__big {
      width: 130px;
    }
  }
  &__goal {
    width: 56px;
    background-color: #fff;
    & > div {
      padding: 0;
    }
  }
  &__home,
  &__over {
    width: 76px;
  }
  &__under,
  &__away {
    width: 83px;
  }
  &__streaming {
    width: 50px;
  }

  &__odds,
  &__even {
    width: 100px;
  }

  &__matchOddsHT {
    &__cols {
      display: flex;
      align-items: center;
      height: 100%;

      &__container {
        display: flex;
        align-items: center;
        flex: 1;
        height: 100%;
      }
    }

    &__col {
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #c0c0c0;
      height: 100%;
      width: 102px;
    }
  }

  &__rules {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-size: 14px;
    gap: 10px;
  }

  &__outRight {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-right: 10px;
  }

  &__cashOutIcon {
    &:hover {
      cursor: pointer !important;
    }
  }
}

.modal {
  &__dialog {
    z-index: 1000000;
  }

  &__header, &__body {
    border: none;
  }

  &__content {
    min-width: auto;

    &__landscape {
      width: $asian-view-pop-up-max-width;
    }
  }

  &__title {
    color: #303030;
  }
}
