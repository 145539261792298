@import 'styles/mixins/breakpoints';

.main {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .link {
    display: flex;
    align-items: center;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }

    i {
      margin-left: 5px;
    }
  }

  &__chevronDown {
    margin-left: 4px;
  }

  &____chevronDownClose {
    transform: rotate(180deg);
  }
}

.markets {
  padding: 15px 0;
  .runners {
    padding: 0 20px 0 10px;
    > div:first-child {
      border-top: 1px solid #eee;
    }
  }
}

.matched {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px 5px 10px;
  .tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 32%;
    span {
      width: 50%;
      text-align: center;
    }
  }
}

.cashOutMarket {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  padding: 0  15px;
  margin-bottom: 10px;

  @include xl {
    grid-template-columns: repeat(1, 1fr);
  }

  &__runnerName {
    max-width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
  }

  &__runner {
    min-height: 30px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;

    &:nth-child(1) {
      border: 1px solid #C0C0C0;
    }

    &:nth-child(2) {
      border-top: 1px solid #C0C0C0;
      border-right: 1px solid #C0C0C0;
      border-bottom: 1px solid #C0C0C0;

      @include xl {
        border-right: 1px solid #C0C0C0;
        border-left: 1px solid #C0C0C0;
        border-bottom: 1px solid #C0C0C0;
        border-top: none;
      }
    }

    &:nth-child(odd) {
      border-right: 1px solid #C0C0C0;
      border-left: 1px solid #C0C0C0;
      border-bottom: 1px solid #C0C0C0;
    }

    &:nth-child(even) {
      border-right: 1px solid #C0C0C0;
      border-bottom: 1px solid #C0C0C0;

      @include xl {
        border-left: 1px solid #C0C0C0;
      }
    }
  }
}
