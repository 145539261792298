@import 'src/styles/mixins/breakpoints';
@import 'src/styles/variables/variables';

div {
  .tourModalContainer:not(#biab_body_modal) {
    .tourModalDialog {
      @include md {
        padding: 47px 0;
        height: auto;
        bottom: 0;
      }

      .tourModalFadeout {
        animation: fadeOut 0.5s;
      }
      .tourModalFadein {
        animation: fadeIn 0.5s;
      }
      .tourModal {
        max-width: 995px;
        min-width: 700px;

        @include md {
          width: calc(100% - 10px);
          min-width: 180px;
        }

        .tourModalHeader {
          column-gap: normal;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          min-height: 48px;
          z-index: 99;
          position: relative;
          justify-content: space-between;
          border-bottom: 1px solid #c0c0c0;
          .tourModalHeaderContent {
            width: calc(100% - 32px);
            text-align: center;
            margin-left: 16px;
            h4 {
              text-align: center;
            }
          }
          @include md {
            line-height: 30px;
            h4 {
              line-height: 22px;
            }
          }
        }
      }
    }
  }
}

.tourModalNoScroll {
  @include md {
    width: calc(100% - 24px);
    max-width: 400px;
    min-width: 180px;
  }
}

.tourModalBody {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: $white !important;
}

@keyframes modalFadeOut {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0;
  }
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.rightMenuOpen {
  z-index: 100;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  top: -30px;
  left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include md {
    left: 10px;
    top: -38px;
    line-height: 30px;
  }
  &__icon {
    pointer-events: none;
    padding-right: 6px;
    color: #000000;
  }
  &__text {
    font-size: 16px;
    font-weight: 600 !important;
    color: #000000;
    cursor: pointer;
  }
}
.rightMenu {
  border-bottom-left-radius: 10px;
  z-index: 99;
  visibility: hidden;
  overflow: hidden;
  top: 0;
  background: $white;
  position: absolute;
  left: 0;
  height: 100%;
  width: 0px;
  &__item {
    line-height: 18px;
    cursor: pointer;
    width: 232px;
    background: #dddddd;
    min-height: 31px;
    padding-left: 8px;
    color: #212121;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #c0c0c0;
    &__name {
      max-width: 200px;
    }
    &__arrow {
      width: 24px;
    }
    &.active {
      background: #c0c0c0;
    }
  }
}

.roundBottomBorder {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.rightMenu.slide {
  visibility: visible;
  width: 232px;
  transition: width 0.8s;
}

.rightMenu.slideRight {
  visibility: visible;
  width: 0;
  transition: width 0.8s;
}

.openModalAnimation {
  animation: modalFadeIn 1.5s;
}

.mobileMenu {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  overflow: hidden;
  z-index: 99;
  width: 100%;
  &__item {
    height: 35px;
    padding: 0 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #c0c0c0;
    justify-content: space-between;
    background: #dddddd;
    cursor: pointer;
    color: #212121;
    font-size: 14px;
    width: 100%;
    &__arrow {
      padding-right: 16px;
      width: 24px;
      text-align: center;
    }
    &.active {
      background: #c0c0c0;
    }
  }
}

.mobileMenu.slide {
  visibility: visible;
  transition: height 0.5s;
}
.mobileMenu.slideRight {
  visibility: visible;
  transition: height 0.5s;
}

.howToOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000000;
  opacity: 0.5;
  z-index: 98;
}

.fadeIn {
  animation: modalFadeIn 1.5s;
}

.fadeOut {
  animation: modalFadeOut 1.5s;
}

.landscapeView {
  color: #212121;
  font-weight: 500;
  font-size: 12px;
}

.webToursLoading {
  height: 670px;
  width: 995px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include md {
    width: calc(100% - 24px);
    max-width: 400px;
    min-width: 180px;
    height: 500px;
  }
}

.landscapeWarning {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__text {
    font-weight: 400;
    font-size: 14px;
    color: #212121;
    margin-bottom: 8px;
  }
}
