.refresh {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 26px;
  min-width: 16px;
  padding: 0 5px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.5;
  background: #DDDDDD;
  color: #3E3E3E;
  cursor: pointer;
  transition: transform 0.5s ease;
  border: none;

  &__icon {
    width: 11px;
    height: 11px;
  }

  &:hover {
    background: #C0C0C0 !important;
  }

  &:active {
    transform: scale(0.98);
  }
}
