@import 'styles/variables/variables';

.betMaxWrap {
  display: flex;
  align-items: center;
  gap: 5px;
}

.betMax {
  display: flex;
  color: inherit !important;
  text-decoration: underline;
  font-size: inherit;
  line-height: 1.5;

  &__col {
    flex-direction: column;
    align-items: flex-end;
  }

  &__label, &__value {
    line-height: 15px;
  }

  &__value {
    font-weight: 700;
  }

  &:hover {
    color: #000000;
  }
}

.infoIcon {
  font-size: 12px;
  cursor: pointer;
}

.modal {
  &__dialog {
    z-index: 1000000;
  }

  &__header, &__body {
    border: none;
  }

  &__content {
    min-width: auto;

    &__landscape {
      width: $asian-view-pop-up-max-width;
    }

    &__desktop {
      max-width: $pop-up-max-width;
    }
  }

  &__title {
    color: #303030;
  }
}
