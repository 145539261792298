@import 'styles/mixins/breakpoints';

.main {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 5px 20px;
  gap: 15px;
  @include md {
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
    padding: 10px 5px 20px;
    .cashOutBtnWrapper {
      width: 100%;
      button {
        width: 100%;
        font-weight: 700;
      }
    }
  }

  i {
    font-size: 16px;
  }

  button {
    padding: 0 10px;
    height: 30px;
    font-size: 12px;
    font-weight: 700;
    border: 0;
    border-radius: 2px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    &.disabled {
      cursor: initial;
    }
  }

  input {
    width: 100px;
    height: 30px;
    padding: 0 10px;
    font-size: 12px;
    border: 1px solid #e5e5e5;
    outline: 0;
    border-radius: 2px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
  }

  .questionIcon {
    cursor: pointer;
  }

  @include md {
    width: 100%;
    justify-content: space-between;
    padding: 10px 0;
    .inputWrapper {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
    }
  }
}

.cashoutFormWrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 15px;

  &__mobile {
    width: 100%;
    justify-content: space-between;
    padding: 0 20px;
    gap: 0;
    > div {
      padding: 0 20px;
    }

    &__av {
      width: 100%;
      justify-content: space-between;
      padding: 0 12px;
      column-gap: 16px;
    }
  }
}

.betslip {
  &__contentWrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &__ifProfit {
    width: 100%;
  }
}
