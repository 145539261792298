@import 'styles/variables/variables.scss';

.betLabel {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  font-size: 12px;
  padding: 5px 10px;
  border-bottom: 1px solid transparent;

  &:last-child {
    border-bottom: none;
  }

  &__back {
    border-color: #a6d8ff;
  }

  &__lay {
    border-color: #fac9d1;
  }

  &__positive {
    color: $green;
  }

  &__negative {
    color: $red;
  }

  &__value {
    white-space: nowrap;
  }
}
