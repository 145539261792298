@import "styles/variables/variables";
@import "styles/mixins/breakpoints";

.betContentContainer {
  position: relative;
  display: flex;
  justify-content: flex-end;

  &__deepBackNoLay {
    width: calc(100% - 100% / 3) !important;
  }

  &__defaultNoLay {
    width: calc(100% / 3) !important;
  }

  @include md {
    justify-content: flex-end;
  }
}
