@import 'src/styles/mixins/breakpoints';
@import 'src/styles/variables/variables';

.listView {
  display: flex;
  align-items: center;
  min-width: 100%;
  width: fit-content;
  height: 29px;
  white-space: nowrap;
  padding-left: 0;
  margin: 0;

  &__slider {
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    & {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  &__wrapper {
    margin-bottom: 10px;
  }

  &_hidden {
    width: auto;
    height: auto;
    flex-direction: column;
    position: absolute;
    top: 29px;
    right: 0;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 30%);
    background: #fff;
    min-width: 100%;
    max-height: 318px;
    overflow: auto;
    z-index: 13;
  }

  &__tab {
    background-color: #efefef;
    cursor: pointer;
    color: #1e1e1e;
    border-right: 1px solid #dfdfdf;
    display: inline-block;
    vertical-align: top;
    font-weight: 700;
    text-decoration: none;
    white-space: nowrap;
    height: 29px;
    width: 120px;
    max-width: 140px;
    padding: 0;
    text-align: center;
    position: relative;
    box-sizing: border-box;

    &__links {
      display: block;
      height: 100%;
      line-height: 29px;
      padding: 0 16px;
      text-decoration: none;
      color: inherit !important;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @include md {
        line-height: 35px;
      }
    }

    &__active {
      border-top: 2px solid;
      border-right-color: #dfdfdf !important;

      a {
        line-height: 25px;
      }
    }

    &__moreLink {
      padding: 0;
    }

    &__moreLink:after {
      display: inline-block;
      font-family: "fa2", serif;
      font-weight: normal !important;
      font-style: normal !important;
      content: "\e94d";
      padding: 0;
      vertical-align: middle;
      font-size: 6px;
      margin-left: 4px;
    }

    &__more {
      position: relative;

      &_active {
        border-top: 2px solid #ffb80c;
        border-right-color: #dfdfdf !important;
      }
    }

    &_hidden {
      width: 100%;
      text-align: left;
      background-color: #ffffff;
      border-color: #e0e6e6;
      color: #1e1e1e;

      &:hover {
        background-color: #efefef;
      }
    }
  }

  &__fromStart {
    display: flex;
    justify-content: flex-start !important;
  }
}

.mobile {
  &__listViewWrapper {
    margin-bottom: 15px;
    position: relative;
  }

  &__scroll__listViewWrapper {
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__listView {
    min-width: auto;
    overflow: auto;
    height: auto;

    &__tab {
      width: auto;
      height: 35px;
      line-height: 35px;
      border-right-color: transparent;

      &__active {
        background-color: #ffffff;
        border-top: 2px solid #303030;
        border-right: none;

        .listView__tab__links {
          line-height: 30px;
        }
      }

      &:after {
        content: unset;
      }

      i {
        position: absolute;
        top: 50%;
        right: 15px;
        font-size: 15px;
        transform: translate(-50%, -50%);
      }
    }

    &__hidden {
      width: 100%;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 11;
      overflow: hidden;

      .mobile__listView__tab {
        width: 100%;
        border-bottom: solid 1px #f7f7f7;
        font-size: 14px;
        text-align: left;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;

        span {
          background-color: #ffffff !important;
        }
      }
    }
  }
}
