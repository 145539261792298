.wrap {
  padding: 0 0 8px 0;
  background-color: #ECECEC;
}

.message {
  padding: 13px 12px;
  background-color: #FFFFFF;
  border-top: 1px solid #DDDDDD;
  border-bottom: 1px solid #DDDDDD;
  display: flex;
  align-items: center;
  column-gap: 12px;

  p {
    margin: 0;
  }

  i {
    font-size: 16px;
  }

  &__text {
    font-size: 14px;
    line-height: 1.42;
    color: inherit;

    span {
      font-weight: 700 !important;
    }
  }

  &__closeBtn {
    all: unset;
    padding: 3px;
    margin-left: auto;
    cursor: pointer;

    i {
      font-size: 16px !important;

      &:before {
        font-weight: 700 !important;
      }
    }
  }
}
