@import "/src/styles/mixins/breakpoints";

.notAvailable {
  border: 2px solid white;
  box-shadow: 1px 1px 2px #7f7f7f;
  margin-top: -2px;
  margin-bottom: 2px;
}

.cartItem {
  float: left;
  width: 45px;
  height: 75px;
  margin: 2px;

  @include md {
    width: 32px;
    height: 50px;
  }

  img, .notAvailable {
    box-sizing: border-box;
    border-radius: 2px;
    -moz-box-sizing: border-box;
    width: 45px;
    height: 61px;
    @include md {
      width: 30px;
      height: 35px;
    }
  }

  img {
    display: flex;
  }

  .index {
    font-weight: bold;
    text-align: center;
    font-size: 11px;
  }
}

.margin51 {
  margin-left: 51px;
  @include md {
    margin-left: 0;
  }
}
