.calculator {
  position: absolute;
  z-index: 20;
  right: 0;
  top: calc(100% - 1px);
  width: 200px;
  box-shadow: 0 2px 8px 0 rgba(24, 30, 92, 0.15);
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 15px;
  box-sizing: border-box;
  cursor: initial;

  &:before {
    content: '';
    position: absolute;
    top: -5px;
    right: 35px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
  }

  &__lay {
    &:before {
      right: 40px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    &__left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 4px;
    }
  }

  &__closeBtn {
    all: unset;
    padding: 6px;
    cursor: pointer;
    border-radius: 50%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #ececec;
    }

    i {
      font-size: 16px;
    }
  }

  &__title {
    font-size: 14px;
    line-height: 20px;
    color: #303030;
    font-weight: 700;
  }

  &__infoBtn {
    all: unset;
    cursor: pointer;
    display: flex;

    i {
      font-size: 12px;

      &:before {
        background-color: #303030;
      }

      &:hover {
        &:before {
          background-color: #666;
        }
      }
    }
  }

  &__middle {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 10px 0;
  }

  &__stakeLabel {
    font-size: 12px;
    line-height: 20px;
    color: #303030;
  }

  &__input {
    all: unset;
    border: 1px solid #3e3e3e;
    flex: 1;
    border-radius: 4px;
    padding: 2.25px 0;
    text-align: center;
    font-size: 12px;
    min-width: 0;
  }

  &__distribute {
    all: unset;
    display: block;
    background-color: #ffb80c;
    color: #303030;
    border-radius: 4px;
    padding: 4px 10px;
    height: 30px;
    text-align: center;
    width: 100%;
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    cursor: pointer;
    box-sizing: border-box;
    overflow: hidden;

    &:disabled {
      background-color: #fad06b;
      color: #666;
    }
  }

  &__modal {
    &__dialog {
      height: auto;
    }

    &__body {
      max-width: 476px;
    }

    &__text {
      font-size: 12px;
      line-height: 20px;
      color: #303030;
      margin: 0;
    }
  }
}
