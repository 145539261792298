.markets {
  position: absolute;
}
.totalLiability {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__label, &__value {
    line-height: 1.5;
    color: inherit;
    margin: 0;
    column-gap: 8px;
  }

  &__value {
    font-weight: 500;
  }
}
