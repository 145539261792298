.wrap {
  display: flex;
  gap: 10px;
  padding: 12px 13px;
  border-radius: 4px;
  align-items: center;
  border-width: 1px;
  border-style: solid;

  &__error {
    background-color: #FAC9D1;
    border-color: #D70022;
    gap: 10px;

    &__container {
      position: relative;
    }
  }

  &__info {
    background-color: #A6D8FF;
    border-color: #0072AC;
  }
}

.leftIcon {
  &__info {
    font-size: 22px;
    vertical-align: middle;
  }

  &__error {
    font-size: 24px;

    &__line {
      position: absolute;
      left: 0;
    }
  }
}

.closeIcon {
  margin-left: auto;
  cursor: pointer;
}

.lockIcon {
  color: #0072AC !important;
}
