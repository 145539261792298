.quickBets {
  &__header {
    padding: 9px 16px;
    background-color: #3e3e3e;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__goBack {
      all: unset;
      width: 42px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      cursor: pointer;
      border: 1px solid #666;
      color: #fff;
      font-size: 24px;
      box-sizing: border-box;
    }

    &__label {
      font-size: 16px;
      line-height: 1.5;
      font-weight: 700 !important;
      color: #fff;
      margin: 0;
    }

    &__empty {
      width: 42px;
    }
  }

  &__content {
    padding: 24px 16px 48px 16px;
    background-color: #fff;
  }
}
