.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 120px 0 170px;
  box-sizing: border-box;
  overflow: hidden;

  &__mobile {
    padding: 120px 20px 170px;
  }

  &__content {
    padding: 0;
  }
}

.iconWrapper {
  margin-bottom: 15px;
}

.icon {
  font-size: 64px;

  &__locked {
    color: #0072AC;
  }

  &__unlocked {
    color: #04A04A;
  }
}

.content {
  width: 548px;
  display: flex;
  align-items: center;
  padding: 0 24px;;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
}

.title {
  font-weight: 700 !important;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  margin: 0 0 10px;

  &__mobile {
    font-size: 22px !important;
  }
}

.descriptionContainer {
  width: 500px;
  padding: 10px 20px;
  background-color: #F5F5F5;
  border-radius: 5px;
  text-align: center;
  margin: 10px 0 10px;
  box-sizing: border-box;

  &__mobile {
    width: 100%;
    padding: 10px 30px;
  }
}

.description {
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  padding: 0;
  margin: 0;

  &__mobile {
    font-size: 16px;
    line-height: 24px;
  }

  &__date {
    font-size: 14px;
    line-height: 20px;
  }
}

strong {
  font-weight: 700 !important;
  font-size: inherit;
}