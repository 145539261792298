.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.limitContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.limitItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.badge {
  width: 6px;
  height: 6px;
  margin-right: 7px;
  border-radius: 3px;

  &__available {
    background-color: #81D8A3;
  }

  &__spent {
    background-color: #FAADBA;
  }

  &__disabled {
    background-color: #f5f5f5;
  }
}

.limit {
  font-size: 14px;
  font-weight: 700 !important;
  line-height: 20px;
  text-align: left;
  color: #303030;
  margin-right: 7px;


  &__spent {
    font-weight: 400 !important;
  }
}

.limitLabel {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #666;
}

.scaleContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.available {
  background-color: #81D8A3;
  border-radius: 5px;
  height: 20px;

  &__countdown {
    background-color: #FAADBA;
  }
}

.spent {
  background-color: #FAADBA;
  border-radius: 5px;
  height: 20px;

  &__disabled {
    background-color: #f5f5f5;
  }
}

.periodContainer {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
}

.period {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #999;
  margin-right: 5px;
}