@import 'styles/variables/variables';

.more {
  all: unset;
  border-right: 1px solid;
  display: flex;
  line-height: 1;
  height: 30px;
  padding: 0 12px;
  font-weight: 700;
  align-items: center;
  column-gap: 6px;
  cursor: pointer;
  i {
    margin-top: -2px;
  }
}

.main {
  position: relative;

  .list {
    position: absolute;
    overflow: hidden;
    z-index: 16;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    right: 0;
    max-width: 200px;
    width: 200px;
    margin-top: 4px;
    padding: 5px 0;
    box-shadow: 0 2px 8px rgba(#181e5c, 15%);

    &__item {
      color: $text-black;
      border: none;

      &:hover {
        background-color: $bg-gray;
      }
    }
  }
}
