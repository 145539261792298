.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 35px;
  padding: 5px 10px;
  background-color: #fe3629;
  column-gap: 10px;
  color: #ffffff;
  box-sizing: border-box;

  &__matched {
    background-color: #3e3e3e;
  }

  &__title {
    font-size: 14px;
    color: inherit;
    line-height: 20px;
    margin: 0;

    .eventName,
    .marketName {
      color: inherit;
    }

    a:hover {
      text-decoration: none;
    }
  }

  &__canBeRemoved {
    background-color: #3e3e3e;
  }

  &__left {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  &__live {
    font-size: 12px;
    color: #ffffff;
    line-height: 20px;
    background-color: #04a04a;
    border-radius: 2px;
    padding: 0 5px;
    font-weight: 700;
    margin: 0;
  }

  &__closeWrapper {
    all: unset;
    cursor: pointer;
  }

  &__closeIcon {
    color: inherit;
  }
}
