.listItem {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  a {
    position: relative;
    cursor: pointer;
    display: block;
    padding: 0 10px;
    text-decoration: none;
    word-break: break-all;
  }
}
