@import 'styles/variables/variables';

.wrapper {
  position: relative;
}

.content {
  position: absolute;
  top: 30px;
  right: 0;
  width: 600px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  color: #303030;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #C0C0C0;
  z-index: 1000;

  &__mobile {
    height: auto;
    width: 100%;
    right: 0;
    left: 0;
    max-height: 70dvh;
    top: 0;
    font-size: 14px;
    max-width: $asian-view-pop-up-max-width;
    margin: 0 auto;
    border: none;
  }
}

.header {
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #C0C0C0;
  border-radius: 4px 4px 0 0;
  font-size: 14px;

  &__title {
    font-weight: bold;

    &__mobile {
      font-weight: bold !important;
    }
  }

  &__mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background-color: #FFB80C;
  }
}

.inner {
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 15px 20px;
  font-size: 12px;
  overflow-y: auto;

  &__column {
    width: 100%;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }
  }

  &__mobile {
    font-size: 14px;
  }
}
