.rbSessionModal {
  &__alert {
    display: flex;
    align-items: center;
  }

  &__icon {
    color: #fe3629 !important;
    font-size: 24px !important;
  }

  &__messageContainer {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
  }

  &__message {
    color: #303030;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
  }

  &__time {
    color: #fe3629;
    font-size: 14px;
    font-weight: 700 !important;
    line-height: 20px;
  }
}
