@import 'styles/variables/variables.scss';

.wrapper {
  max-width: 500px;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  border-radius: 20px;
  border-bottom: 12px solid #a6d8ff;
  &__noBorder {
    border: none;
    border-radius: 0;
  }
}

.border {
  padding: 20px;
  border-radius: 20px 20px 0 0;
  border: 1px solid #dddddd;
  border-bottom: none;
  &__noBorder {
    border: none;
    border-radius: 0;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.inputColumns {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-end;
}

.inputLabel {
  line-height: 20px;
  font-size: 14px;
  font-weight: 700 !important;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.fractionalWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.symbol {
  line-height: 40px;
}

.input {
  outline: 0;
  line-height: 38px;
  padding: 0 15px;
  border: solid 1px #ddd;
  border-radius: 4px;
  font-size: 14px;
  color: #303030;
  width: 108px;
  box-sizing: border-box;
  text-align: center;
  &__odds {
    width: 160px;
  }
  &:focus {
    border-color: #3e3e3e;
  }
}

.info {
  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 20px;
    font-size: 14px;
  }
  &__label {
    color: #666666;
  }
  &__value {
    color: #999999;
  }
}

@media (max-width: 767px) {
  .embeded {
    .inputColumns {
      flex-direction: column;
      align-items: center;
    }
    .inputWrapper {
      width: 100%;
    }
    .symbol {
      &__equal {
        display: none;
      }
    }
    .input {
      width: 100%;
      &__odds {
        width: 100%;
      }
    }
  }
}
