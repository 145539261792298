.tableWrapper {
  padding: 0;
}

.table {
  table-layout: fixed;
  width: 100%;
  margin-top: 0;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #fff;

  .headerRow th {
    line-height: 17px;
  }

  th {
    position: relative;
    padding: 0 10px;
    text-align: left;
    font-weight: normal;
    color: #1e1e1e;
    font-size: 10px;
    border-left: 1px solid #ececec;
    background-color: #f5f5f5;
  }
  th,
  td {
    height: 25px;
    line-height: 20px;
  }

  tr:last-of-type {
    & > td {
      border-bottom: 1px solid #666 !important;
    }
  }

  tr:last-child td:not(.noRightBorder),
  tr td:last-child {
    border-right: 1px solid #ececec;
    &.noRightBorder {
      border-right: none !important;
    }
  }
  tr:first-child td {
    border-top: 1px solid #ececec;
  }
  td {
    padding: 0;
    border: 1px solid #ececec;
  }
  tbody {
    th,
    td {
      text-align: center;
    }
  }
}

.activeTable {
  border: 1px solid #ececec;
  thead th {
    padding-left: 10px;
    font-weight: bold;
  }
}

.checkbox {
  position: relative;
  display: inline-block;
  margin: 0px 2px 2px 0px;
  vertical-align: middle;
  font-size: 15px;
  color: #28abdc;
  cursor: pointer;
  background: none;
  padding: 0;
  border: none;
}

.activeCheckbox {
  color: #fff;
}

.noCheckIcon {
  font-size: 12px !important;
  margin-left: -3px;
  margin-right: 3px;
}

.noCheckIconBorder:before {
  color: inherit !important;
}

.checkIcon {
  display: block;
  position: absolute;
  z-index: 1;
  color: #008000;
  top: 2px;
  left: 0px;
  font-size: 12px !important;
}

.eventName {
  display: inline-block;
  font-weight: normal;
}

.activeTable tbody {
  .textLeft {
    border-right: none !important;
    text-align: left;
    padding-left: 10px;
  }

  .textRight {
    border-left: none !important;
    text-align: right;
    padding-right: 10px;
  }
}

.commission {
  background-color: #f5f5f5;
}
