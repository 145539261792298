@import "src/styles/mixins/breakpoints";

.game {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-weight: bold !important;

  @include md {
    flex-direction: row;
    align-items: end;
    margin-top: -10px;
  }

  .section1 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @include md {
      flex-direction: row;
      align-items: center;
      margin-bottom: -5px;
    }
  }
}
