.settings {
  position: relative;
  cursor: initial;

  &__title {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    padding: 0 10px;
    cursor: pointer;
    color: #f6f6f6;
    line-height: 30px;
    white-space: nowrap;
    border-left-width: 1px;
    border-left-style: solid;

    i {
      color: inherit !important;
    }

    i:first-of-type {
      margin-right: 5px;
    }

    i:last-of-type {
      margin-left: 10px;
      font-size: 6px;
      position: relative;
    }
  }

  &__window {
    position: absolute;
    border-radius: 4px;
    right: 10px;
    top: calc(100% + 4px);
    z-index: 1000;
    box-shadow: 0 2px 8px 0 rgba(#0c0c0d, 0.2);

    &__content {
      background-color: #ffffff;
      border: 1px solid #cbcbca;
      display: flex;
      border-radius: 4px;
    }

    &__menu {
      display: flex;
      flex-direction: column;
      min-width: 150px;
      padding: 20px 0;
      overflow: hidden;
      background-color: #f5f5f5;
      border-radius: 4px 0 0 4px;

      &__item {
        cursor: pointer;
        padding: 6px 0 6px 20px;
        line-height: 28px;
        color: #1e1e1e !important;

        &__active {
          font-weight: 700;
          background-color: #ffffff !important;
          border-left: 4px solid #ffb80c;
          padding-left: 16px;
        }

        &:not(.settings__window__menu__item__active):hover {
          background-color: #dddddd;
        }
      }
    }

    &__displayedContent {
      width: 350px;
      padding: 15px 16px 20px;
      min-height: 340px;
      border-radius: 0 4px 4px 0;
      overflow-y: auto;
      box-sizing: border-box;
    }
  }
}
