.content {
  display: flex;
  width: 100%;
  overflow-x: hidden;

  &__mobile {
    overflow-x: initial;
  }
}
.middleSectionWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background-color: #F5F5F5;
  > div {
    width: 100%;
    box-sizing: border-box;
  }

  &__desktop {
    overflow-y: auto;
  }
  &__hasMenu {
    margin-left: 80px;
  }

  &__mobile {
    background-color: #ffffff;
  }
}
