.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #FFFFFF;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__row {
    display: flex;
    gap: 20px;
  }
}

.item {
  background-color: #F5F5F5;
  border-radius: 4px;

  &__lg {
    width: 100%;
    height: 42px;
  }

  &__md {
    width: 200px;
    height: 16px;
  }

  &__sm {
    width: 140px;
    height: 16px;
  }

  &__xs {
    width: 70px;
    height: 16px;
  }

  &__xxs {
    width: 60px;
    height: 16px;
  }

  &__indicator {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 4px;
    height: 88px;
    border-radius: 4px;
  }
}

.table {
  display: flex;
  flex-direction: column;

  &__row {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 16px 0 16px 16px;
    border-bottom: 1px solid #DDDDDD;

    &:first-child {
      border-top: 1px solid #DDDDDD;
    }

    &__left {
      padding: 4px 0;
      display: flex;
      flex-direction: column;
      gap: 16px
    }

    &__right {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
