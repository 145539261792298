@import '../../styles/mixins/breakpoints';
@import '../../styles/variables/variables';

.main {
  padding: 20px 10px;

  .rulesAndMatched {
    @include md {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
    }
  }

  .rulesAndNetCommission {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 16px;

    & > div:not(:last-child) {
      margin-right: 10px;
    }
  }

  .marketTable {
    position: relative;

    .marketRow {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 33px;
      @include md {
        border-top: 1px solid #f2f5f5;
        padding: 3px 0;
        height: 40px;
      }

      .name {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 50%;
        border-top: 1px solid #f2f5f5;
        font-size: 11px;
        font-weight: bold;
        @include md {
          border: none;
        }
      }

      .rowData {
        width: 50%;
        display: flex;
        .backPrices {
          width: 50%;
          display: flex;
          flex-direction: row-reverse;

          &.withoutLay {
            width: 100%;
          }
        }

        .layPrices {
          width: 50%;
          display: flex;

          .box:last-child {
            border-right: 1px solid #f2f5f5;
          }
        }

        .box {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px 0 3px 0;
          width: 33.3%;
          border-top: 1px solid #f2f5f5;
          border-left: 1px solid #f2f5f5;
          cursor: pointer;
          @include md {
            border-left: 2px solid $white;
            border-radius: 3px;
          }

          .boxContent {
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .value {
            margin-bottom: 2px;
            font-weight: 600;
          }

          .unmatched {

          }
        }

        .statusWrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          border-top: 1px solid #f2f5f5;
          @include md {
            border: none;
          }

          .status {
            font-size: 14px;
            font-weight: bold;
            color: #828282;
            text-align: center;
          }
        }
      }
    }

    .marketStatusOverlay {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 24px;
      color: red;
      text-transform: uppercase;
      z-index: 2;
    }
  }

  .selectionsCount {
    font-weight: normal;
    margin-bottom: 2px;
    color: rgb(39, 58, 71);
    font-size: 10px;
    width: 100%;
    display: flex;
  }

  .matched {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
      border-radius: 2px;
      margin-left: 5px;
      padding: 0 10px;
      cursor: pointer;
    }
  }

  .rules {
    display: flex;

    div {
      cursor: pointer;

      i {
        border-radius: 2px 0 0 2px;
        border-right: 1px solid;
        font-style: normal;
        float: left;
        width: 16px;
        height: 16px;
        text-align: center;
        font-weight: bold;
      }

      span {
        padding: 1px 5px;
        border-radius: 0 2px 2px 0;
        float: left;
      }
    }
  }

  .bestBetIcon {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 3;
  }

  .rulesModalDialog{
    padding-top: 15vh;
    overflow-y: hidden;
  }

  .rulesModalBody{
    overflow-y: auto;
    height: 50vh
  }
}

.mobilePlacement {
  margin-left: -10px;
  margin-right: -10px;
}