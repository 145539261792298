/* RESET start */
/* & stands for biab_body */
html {
  height: 100%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  background: #fff;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

button {
  font-family: inherit;
}

a {
  color: inherit;
}

// Reset lib datepicker style for icon
.react-datepicker__navigation-icon::before {
  display: inline-block;
  font-family: 'fa2', serif !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 6px;
  content: '\e94d';
  transform: rotate(90deg);
  border-color: initial;
  border-style: none;
  border-width: 0;
}

// Avoid zoom in for mobile devices
@supports (-webkit-overflow-scrolling: touch) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}
