.main {
  box-shadow: none;
  border: 1px solid #e3e8e8;
  padding: 8px;
  background-color: #f7f7f7;
  .name {
    .leftName {
      font-weight: bold;
    }
  }
  .points {
    text-align: center;
  }
  .winner {
    font-weight: bold;
  }
}
