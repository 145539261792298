.search {
  &__gameCounter {
    display: inline-block;
    background: url('../../assets/icons/saddlecloths-sprite.gif') no-repeat 0 0;
    width: 25px;
    height: 20px;
    font-size: 0;

    &.search__gameNumber__1 {
      background-position-y: 0;
    }

    &.search__gameNumber__1A {
      background-position-y: -20px
    }

    &.search__gameNumber__1B {
      background-position-y: -40px
    }

    &.search__gameNumber__1C {
      background-position-y: -60px
    }

    &.search__gameNumber__1D {
      background-position-y: -80px
    }

    &.search__gameNumber__1X {
      background-position-y: -100px
    }

    &.search__gameNumber__1Y {
      background-position-y: -120px
    }

    &.search__gameNumber__2 {
      background-position-y: -140px
    }

    &.search__gameNumber__2B {
      background-position-y: -160px
    }

    &.search__gameNumber__2C {
      background-position-y: -180px
    }

    &.search__gameNumber__2X {
      background-position-y: -200px
    }

    &.search__gameNumber__3 {
      background-position-y: -220px
    }

    &.search__gameNumber__3C {
      background-position-y: -240px
    }

    &.search__gameNumber__3D {
      background-position-y: -260px
    }

    &.search__gameNumber__3E {
      background-position-y: -280px
    }

    &.search__gameNumber__3X {
      background-position-y: -300px
    }

    &.search__gameNumber__4 {
      background-position-y: -320px
    }

    &.search__gameNumber__4D {
      background-position-y: -340px
    }

    &.search__gameNumber__4X {
      background-position-y: -360px
    }

    &.search__gameNumber__5 {
      background-position-y: -380px
    }

    &.search__gameNumber__6 {
      background-position-y: -400px
    }

    &.search__gameNumber__7 {
      background-position-y: -420px
    }

    &.search__gameNumber__8 {
      background-position-y: -440px
    }

    &.search__gameNumber__9 {
      background-position-y: -460px
    }

    &.search__gameNumber__10 {
      background-position-y: -480px
    }

    &.search__gameNumber__11 {
      background-position-y: -500px
    }

    &.search__gameNumber__11F {
      background-position-y: -520px
    }

    &.search__gameNumber__12 {
      background-position-y: -540px
    }

    &.search__gameNumber__12F {
      background-position-y: -560px
    }

    &.search__gameNumber__13 {
      background-position-y: -580px
    }

    &.search__gameNumber__13F {
      background-position-y: -600px
    }

    &.search__gameNumber__14 {
      background-position-y: -620px
    }

    &.search__gameNumber__14F {
      background-position-y: -640px
    }

    &.search__gameNumber__15 {
      background-position-y: -660px
    }

    &.search__gameNumber__15F {
      background-position-y: -680px
    }

    &.search__gameNumber__16 {
      background-position-y: -700px
    }

    &.search__gameNumber__16F {
      background-position-y: -720px
    }

    &.search__gameNumber__17 {
      background-position-y: -740px
    }

    &.search__gameNumber__17F {
      background-position-y: -760px
    }

    &.search__gameNumber__18 {
      background-position-y: -780px
    }

    &.search__gameNumber__18F {
      background-position-y: -800px
    }

    &.search__gameNumber__19 {
      background-position-y: -820px
    }

    &.search__gameNumber__19F {
      background-position-y: -840px
    }

    &.search__gameNumber__20 {
      background-position-y: -860px
    }

    &.search__gameNumber__20F {
      background-position-y: -880px
    }

    &.search__gameNumber__21 {
      background-position-y: -900px
    }

    &.search__gameNumber__21F {
      background-position-y: -920px
    }

    &.search__gameNumber__22 {
      background-position-y: -940px
    }

    &.search__gameNumber__22F {
      background-position-y: -960px
    }

    &.search__gameNumber__23 {
      background-position-y: -980px
    }

    &.search__gameNumber__23F {
      background-position-y: -1000px
    }

    &.search__gameNumber__24 {
      background-position-y: -1020px
    }

    &.search__gameNumber__24F {
      background-position-y: -1040px
    }

    &.search__gameNumber__25 {
      background-position-y: -1060px
    }

    &.search__gameNumber__25F {
      background-position-y: -1080px
    }

    &.search__gameNumber__26 {
      background-position-y: -1100px
    }

    &.search__gameNumber__26F {
      background-position-y: -1120px
    }

    &.search__gameNumber__27 {
      background-position-y: -1140px
    }

    &.search__gameNumber__27F {
      background-position-y: -1160px
    }

    &.search__gameNumber__28 {
      background-position-y: -1180px
    }

    &.search__gameNumber__28F {
      background-position-y: -1200px
    }

    &.search__gameNumber__29 {
      background-position-y: -1220px
    }

    &.search__gameNumber__29F {
      background-position-y: -1240px
    }

    &.search__gameNumber__30 {
      background-position-y: -1260px
    }

    &.search__gameNumber__30F {
      background-position-y: -1280px
    }
  }
}
