.row {
  display: flex;
  align-items: center;
  gap: 20px;
  background: #fff;
  color: #303030;
  padding: 15px 0 15px 20px;
  border-bottom: 1px solid #dddddd;
  position: relative;

  &:hover {
    background-color: #F5F5F5;
  }

  &__less1200 {
    padding-right: 20px;
  }

  &__back, &__lay {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 10px;
      width: 5px;
      height: calc(100% - 20px);
      border-radius: 360px;
    }
  }

  &__back:before {
    background-color: #A6D8FF;
  }

  &__lay:before {
    background-color: #FAC9D1;
  }

  &__cell {
    margin: 0;
    font-size: 12px;
    line-height: 20px;

    &__longRefId {
      font-size: 10px;
      line-height: 1.5;
      word-break: break-word;
    }

    &__right {
      text-align: right;
    }

    &__debit {
      color: #FE3629;
    }

    &__credit {
      color: #04A04A;
    }

    &__status {
      display: flex;
      align-items: center;
      column-gap: 10px;
      justify-content: center;
      width: 100%;
      border-radius: 4px;
      height: 30px;

      &__dot {
        font-size: 8px;
      }

      &__won {
        background-color: #CBECD8;

        &__dot {
          background-color: #04A04A;
        }
      }

      &__lost {
        background-color: #FAEBED;

        &__dot {
          background-color: #FE3629;
        }
      }

      &__grey {
        background-color: #F5F5F5;

        &__dot {
          background-color: #666666;
        }
      }
    }

    &__date {
      white-space: pre;
    }

    &__description {
      display: flex;
      flex-direction: column;
      gap: 5px;

      &__bottom {
        font-size: 12px;
        line-height: 20px;
        color: #666666;
        margin: 0;

        &__less1200 {
          word-break: break-word;
        }
      }
    }

    &__selection {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #303030;
      margin: 0;

      &__wrapper {
        display: flex;
        align-items: center;
        column-gap: 10px;
        flex-wrap: wrap;
      }

      &__label {
        font-size: 12px;
        line-height: 20px;
        padding: 0 5px;
        background-color: #ececec;
        color: #303030;
        border-radius: 4px;
        margin: 0;
        white-space: nowrap;
      }
    }

    &__names {
      font-size: 12px;
      line-height: 20px;
      color: #303030;
      margin: 0;
    }

    &__cashOut {
      font-size: 12px;
      line-height: 20px;
      padding: 0 5px;
      font-weight: 700;
      background-color: #FFB80C;
      color: #303030;
      border-radius: 4px;
      margin-right: 10px;
      display: inline-block;
    }
  }
}
