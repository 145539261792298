.runnersContainer {
  display: grid;
}

.emptyRunner {
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}

.runnerBorder {
  &__right {
    border-right: solid 1px #C0C0C0;
  }
  &__bottom {
    border-bottom: solid 1px #C0C0C0;
  }
}