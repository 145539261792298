.container {
  display: grid;
}

.itemContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 26px;
  gap: 2px;
  padding: 0 10px 0 20px;
  background-color: #ffffff;

  &__mobile {
    height: 24px;
    background-color: inherit;
  }

  &__hasBorder {
    border-right: solid 1px #C0C0C0;
  }
}

.item {
  display: flex;
  width: 34px;
  height: 18px;
  justify-content: center;
  align-items: center;
  border-radius: 2px 2px 0 0;
  border: 1px solid #C0C0C0;
  background: #ECECEC;
}

.backLayLabels {
  margin-bottom: 0;
  column-gap: 4px;
}
