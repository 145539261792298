.main {
  background-color: #FFFFFF;
  border-radius: 4px;
  padding: 10px 15px;
  font-size: inherit;
  margin: 10px 8px;
}

.mobile {
  padding-top: 40px;
  background-color: initial;
  .mobileEmpty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
  .header {
    font-weight: 600;
  }
  .description {
    font-size: 14px;
    font-weight: 400;
    color: #444444;
  }
}