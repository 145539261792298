.eventHeader {
  display: flex;
  gap: 10px;
  justify-content: left;
  align-items: center;
  border-bottom: 1px solid #c0c0c0;

  &__inPlayCell {
    height: 40px;
    min-height: 40px;
  }

  &__inPlay {
    background-color: #cbecd8;
  }

  &__name {
    font-size: 14px;
    font-weight: 500;
  }

  &__statistics {
    display: flex;
    margin-left: auto;
    padding: 10px;
    gap: 10px;
    align-items: center;

    &__leftBlockMargin {
      margin-right: 10px;
    }
  }

  &__cashout {
    column-gap: 0 !important;
  }

  &__cashoutIcon {
    &:hover {
      cursor: pointer !important;
    }
  }
}
