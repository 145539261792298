@import 'styles/variables/variables';

.header {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 7px 7px 9px;
  box-sizing: border-box;
  background-color: #3e3e3e;
  color: #ffffff;

  &__wrapper {
    position: sticky;
    top: 0;
    z-index: 1000;
  }

  &__menu {
    position: relative;
  }

  &__mobile {
    padding: 0 6px;
    height: 60px;
  }

  &__left {
    display: flex;
    align-items: center;
    column-gap: 6px;

    &__mobile {
      margin-right: auto;
      padding-left: 14px;
    }
  }

  &__menuBtn {
    margin-right: 6px;
  }

  &__label {
    font-size: inherit;
    font-weight: 400;
    margin: 0;
  }

  &__reload {
    font-size: 10px;
  }

  &__reloadMobile {
    background-color: #3e3e3e;
    border-width: 1px;
    border-style: solid;
    border-color: #666666;
    padding: 9px 10px;
    color: inherit;
    height: auto;
    font-size: 14px;
    line-height: 1.5;

    &__landscape {
      padding-right: 13px;
      padding-left: 13px;
    }

    &:hover {
      background-color: #3e3e3e !important;
    }

    &__icon {
      fill: #ffffff;
      width: 16px;
      height: 16px;
      order: -1;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    column-gap: 6px;

    .header__rules__icon {
      font-size: 16px;

      .path1:before {
        color: inherit;
      }

      .path2:before {
        color: #3e3e3e;
      }
    }
  }

  &__rules {
    display: flex;
    align-items: center;
    column-gap: 9px;
    background-color: #3e3e3e;
    border-width: 1px;
    border-style: solid;
    border-color: #666666;
    padding: 9px 10px;
    color: inherit;
    font-size: 14px;
    line-height: 1.5;
    cursor: pointer;
    border-radius: 4px;

    &__landscape {
      padding-right: 13px;
      padding-left: 13px;
    }
  }

  .cashOutIcon {
    cursor: pointer;
  }
}

.modal {
  &__header, &__body {
    border: none;
  }

  &__content {
    min-width: auto;

    &__landscape {
      width: $asian-view-pop-up-max-width;
    }
  }

  &__title {
    color: #303030;
  }

  &__closeIcon {
    color: #303030 !important; // TODO !important should be removed when branding will be implemented for this part
  }
}