@import "src/styles/mixins/breakpoints";

.player {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-width: 100%;
  margin-top: 10px;

  @include md {
    margin-top: 5px;
  }

  &__hand {
    display: inline-block;
    width: 120px;
    min-height: 120px;
    padding: 8px;
    margin: 0 2px;
    background-color: #f7f7f7;
    border: 1px solid #f7f7f7;
    border-radius: 2px;
    box-shadow: 4px 4px 10px -2px #1e1e1e;

    &__result {
      box-shadow: none;
      border: 1px solid #e3e8e8;
    }

    @include md {
      padding: 5px;
      width: 100%;
      min-width: 95px;
      min-height: 135px;
      max-width: 140px;
    }

    @include xs {
      width: 100px;
    }

    @media screen and (max-width: 550px) {
      width: 100px;
      min-height: 115px;
    }
  }

  &__wrapper {
    text-align: center;

    @include md {
      margin: 5px 0;
    }
  }

  &__status {
    min-height: 12px;
    color: #7f7f7f;
    font-size: 12px;
    margin-bottom: 10px;

    @include md {
      margin-bottom: 0;
    }
  }

  &__cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 10px 0;

    @include md {
      margin: 5px 0;
    }
  }

  &__cardItem {
    &:nth-child(2n) {
      .player__unavailableCard {
        margin-right: auto;
      }

      @include md {
        text-align: left;
      }
    }

    &:nth-child(2n+1) {
      margin-right: 3px;

      .player__unavailableCard {
        margin-left: auto;
        margin-bottom: 4px;
      }

      @include md {
        text-align: right;
      }
    }

    @include md {
      flex-basis: 48%;
    }
  }

  &__name {
    color: #000000;
    font-weight: bold;
    margin: 10px 0;
    text-align: center;

    @include md {
      margin: 5px 0;
    }
  }

  &__description {
    margin-top: 10px;
    min-height: 3em;
    font-size: 11px;
    line-height: 11px;
    text-align: center;

    @include md {
      margin-top: 0;
      min-height: 2em;
    }
  }

  &__biab_poker {
    @include md {
      min-height: 105px;
      width: 120px;
      margin: 0;
    }
  }
}
