@import 'styles/variables/variables';

.event {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px 5px 5px;
    line-height: 20px;
    font-size: 14px;

    &__info {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &__collapseBtn {
      all: unset;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      border-radius: 50%;

      &:hover {
        background-color: #ececec;
      }
    }

    &__liveLabel {
      padding: 0 5px;
      line-height: 20px;
      font-size: 12px;
      font-weight: 700;
      background-color: #04a04a;
      border-radius: $radius-secondary;
    }

    &__icon {
      transition: transform 0.2s;

      &::before {
        color: $icon-black !important;
      }

      &__collapsed {
        transform: rotate(180deg);
      }
    }
  }

  .event__link {
    color: $text-black;

    &:hover {
      text-decoration: none;
    }
  }
}
