.container {
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 20px;
  background-color: #fff;
  border: 1px solid #DDD;
  border-radius: 5px;
  margin-bottom: 10px;

  &__active {
    &:hover {
      border-color: #3e3e3e;
      cursor: pointer;

      .downloadIconBtn {
        background-color: #ddd;
      }
    }
  }

  &__with_error {
    border-color: #FE3629;
  }
}

.field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.fieldInfo {
  display: flex;
  flex-grow: 1;
  padding-right: 10px;
  justify-content: space-between;
  align-items: center;

  &__mobile {
    flex-grow: 0;
    flex-direction: column;
    align-items: flex-start;
  }
}

.fieldInfoLabel {
  font-size: 12px;
  line-height: 20px;
  color: #303030;
}

.fieldInfoDescription {
  font-size: 10px;
  line-height: 15px;
  color: #999;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 18px;
  outline: none;
  border: none;
  background-color: transparent;

  &__active {
    cursor: pointer;
  }

  &:hover {
    background-color: #DDDDDD;
  }

  &:disabled {
    &:hover {
      background-color: transparent;
    }
  }
}

.icon {
  font-size: 16px;
}

.downloadIcon {
  font-size: 18px !important;
}
 
.warningWrapper {
  display: flex;
  align-items: center;
}

.warningIcon {
  margin-right: 10px;
}

.warningLabel {
  font-size: 12px;
  line-height: 20px;
  color: #666;
}

.divider {
  margin: 0;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.loaderIcon {
  font-size: 16px;
  color: #0072AC;
  animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.loaderIcon {
  font-size: 16px;
  color: #0072AC;
  animation: rotate 1.5s linear infinite;
}
