.navWrap {
  white-space: nowrap;
}

.navBtn {
  padding: 0;
  background: none;
  border: 0;
  outline: 0;
  color: #000000;
  cursor: pointer;

  &__disabled {
    color: #C0C0C0;
    cursor: initial;
  }
}
