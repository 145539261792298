.container {
  margin-right: 4px;
}

.iconContainer {
  display: inline-block;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  text-decoration: none;
  font-weight: 600;
  line-height: 22px;
}

.icon {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-top: 1px;
  padding-left: 1px;
  font-size: 22px;
  width: 22px;
  height: 22px;
}

