@import "../../styles/variables/variables";

.marketWrapper {
  padding-bottom: 10px;
}

.runnersStatusWrapper {
  position: relative;
}

:global {
  .runnersStatusWrapper {
    .runnersWrapper {
      padding-right: 0;
    }
  }

  .marketWrapper {
    .runnerRow {
      padding-left: 0;
    }

    .matchedTitle {
      padding-left: 10px;
    }
  }

  .biab_market-loading {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    text-align: center;
    background: #fff7;
    z-index: 1000;
    padding-top: 0;
    i {
      color: #28aae3;
      font-size: 30px;
      font-style: normal;
      margin: -15px auto 0 -15px;
      left: 50%;
      position: absolute;
      top: 50%;
    }
  }
}
