.selections {
  padding-top: 1px;
  padding-bottom: 4px;

  &__layBetting {
    padding-top: 29px;
  }
}

.selection__name {
  padding: 6px 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selection__active {
  color: #D70022;
}
