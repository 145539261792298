.container {
  display: flex;
  justify-content: space-between;
}

.headerNavigationLinks {
  height: 30px;
  display: inline-flex;
  flex-grow: 1;
}
