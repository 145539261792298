.item {
  display: flex;

  &:hover {
    background-color: #ececec;
  }

  > span {
    margin-right: 5px;
  }
}
