@import "../../../../styles/variables/variables";
@import "../../../../styles/mixins/breakpoints";

.searchItem {
  background: #303030;
  color: #fff;
  padding: 10px 10px;

  a {
    color: inherit !important;
    text-decoration: none;
  }

  &__startTime {
    margin-top: 3px;
  }

  &__headerWrapper {
    display: flex;
    flex-direction: row;
  }

  &__favouriteIcon {
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__link {
    &:hover {
      text-decoration: underline;
    }

    @include md {
      font-weight: bold;
    }
  }
}
