.marketCell {
  &__selection {
    display: flex;
    margin-bottom: 5px;
    justify-content: flex-end;
  }
  &__odds__name {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-right: 10px;
  }
  &__odds__space {
    width: 35px;
  }
}
