.betForm {
  display: flex;
  align-items: center;
  font-size: 12px;
  box-sizing: border-box;

  &__readOnly {
    text-align: center;
  }

  &__price {
    font-weight: 700;
  }

  &__select {
    column-gap: 5px;
  }

  &__readonly {
    column-gap: 5px;
  }

  &__confirm {
    height: 30px;
  }
}

.betPriceWrap {
  position: relative;
  text-align: center !important;
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 5px;

  &__center {
    justify-content: center;
  }
}

.size,
.profit {
  flex: 1;
  text-align: center;
}

.priceIndicatorWrap {
  position: relative;
  width: 10px;
}
