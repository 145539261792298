.settings {
  padding: 24px 16px 12px 20px;
  background-color: #fff;
  border-radius: 4px;

  &__title {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 700 !important;
    color: #303030;
    padding: 2px 0;
    margin-bottom: 16px;
    margin-top: 0;
  }

  &__item {
    padding: 14px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    column-gap: 10px;

    &__label {
      font-size: 16px;
      line-height: 1.5;
      margin: 0;
    }

    &__toggle {
      flex-shrink: 0;
    }
  }
}
