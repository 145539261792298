@import '../../variables/variables';

@if($isIosBundle) {
  @font-face {
    font-family: 'FiraGo Regular';
    font-weight: 400;
    src: url('../../../assets/fonts/FiraGo-Panton/woff/FiraGO-Regular.WOFF') format('woff2');
  }
} @else {
  @font-face {
    font-family: 'FiraGo Regular';
    font-weight: 400;
    src: url('../../../assets/fonts/FiraGo-Panton/woff/FiraGO-Regular.WOFF') format('woff2'),
    url('../../../assets/fonts/FiraGo-Panton/ttf/FiraGO-Regular.ttf') format('ttf'),
    url('../../../assets/fonts/FiraGo-Panton/eot/FiraGO-Regular.eot') format('eot');
  }
}

@if($isIosBundle) {
  @font-face {
    font-family: 'FiraGo Meduim';
    font-weight: 500;
    src: url('../../../assets/fonts/FiraGo-Panton/woff/FiraGO-Medium.WOFF') format('woff2');
  }
} @else {
  @font-face {
    font-family: 'FiraGo Meduim';
    font-weight: 500;
    src: url('../../../assets/fonts/FiraGo-Panton/woff/FiraGO-Medium.WOFF') format('woff2'),
    url('../../../assets/fonts/FiraGo-Panton/ttf/FiraGO-Medium.ttf') format('ttf'),
    url('../../../assets/fonts/FiraGo-Panton/eot/FiraGO-Medium.eot') format('eot');
  }
}

@if($isIosBundle) {
  @font-face {
    font-family: 'FiraGo SemiBold';
    font-weight: 600;
    src: url('../../../assets/fonts/FiraGo-Panton/woff/FiraGO-SemiBold.WOFF') format('woff2');
  }
} @else {
  @font-face {
    font-family: 'FiraGo SemiBold';
    font-weight: 600;
    src: url('../../../assets/fonts/FiraGo-Panton/woff/FiraGO-SemiBold.WOFF') format('woff2'),
    url('../../../assets/fonts/FiraGo-Panton/ttf/FiraGO-SemiBold.ttf') format('ttf'),
    url('../../../assets/fonts/FiraGo-Panton/eot/FiraGO-SemiBold.eot') format('eot');
  }
}

@if($isIosBundle) {
  @font-face {
    font-family: 'FiraGo Bold';
    font-weight: 700;
    src: url('../../../assets/fonts/FiraGo-Panton/woff/FiraGO-Bold.WOFF') format('woff2');
  }
} @else {
  @font-face {
    font-family: 'FiraGo Bold';
    font-weight: 700;
    src: url('../../../assets/fonts/FiraGo-Panton/woff/FiraGO-Bold.WOFF') format('woff2'),
    url('../../../assets/fonts/FiraGo-Panton/ttf/FiraGO-Bold.ttf') format('ttf'),
    url('../../../assets/fonts/FiraGo-Panton/eot/FiraGO-Bold.eot') format('eot');
  }
}

@if($isIosBundle) {
  @font-face {
    font-family: 'PantonAM Regular';
    font-weight: 400;
    src: url('../../../assets/fonts/FiraGo-Panton/woff/PantonAM-Regular.WOFF') format('woff2');
  }
} @else {
  @font-face {
    font-family: 'PantonAM Regular';
    font-weight: 400;
    src: url('../../../assets/fonts/FiraGo-Panton/woff/PantonAM-Regular.WOFF') format('woff2'),
    url('../../../assets/fonts/FiraGo-Panton/ttf/PantonAM-Regular.ttf') format('ttf'),
    url('../../../assets/fonts/FiraGo-Panton/eot/PantonAM-Regular.eot') format('eot');
  }
}

@if($isIosBundle) {
  @font-face {
    font-family: 'PantonAM Bold';
    font-weight: 600;
    src: url('../../../assets/fonts/FiraGo-Panton/woff/PantonAM-Bold.WOFF') format('woff2');
  }
} @else {
  @font-face {
    font-family: 'PantonAM Bold';
    font-weight: 600;
    src: url('../../../assets/fonts/FiraGo-Panton/woff/PantonAM-Bold.WOFF') format('woff2'),
    url('../../../assets/fonts/FiraGo-Panton/ttf/PantonAM-Bold.ttf') format('ttf'),
    url('../../../assets/fonts/FiraGo-Panton/eot/PantonAM-Bold.eot') format('eot');
  }
}

@if($isIosBundle) {
  @font-face {
    font-family: 'PantonAM ExtraBold';
    font-weight: 800;
    src: url('../../../assets/fonts/FiraGo-Panton/woff/PantonAM-ExtraBold.WOFF') format('woff2');
  }
} @else {
  @font-face {
    font-family: 'PantonAM ExtraBold';
    font-weight: 800;
    src: url('../../../assets/fonts/FiraGo-Panton/woff/PantonAM-ExtraBold.WOFF') format('woff2'),
    url('../../../assets/fonts/FiraGo-Panton/ttf/PantonAM-ExtraBold.ttf') format('ttf'),
    url('../../../assets/fonts/FiraGo-Panton/eot/PantonAM-ExtraBold.eot') format('eot');
  }
}

@font-face {
  font-family: 'Druk Text Medium';
  src: url('../../../assets/fonts/DS-Test-Only-Druk-Text-Medium.otf') format("opentype");
}

[class*='biab_toolbar-icon-']:before {
  display: inline-block;
  font-family: 'Exchange';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.biab_toolbar-icon-exchange-stroke:before {
  content: '\0041';
}

.biab_toolbar-icon-favorites:before {
  content: '\0042';
}

.biab_toolbar-icon-in-play:before {
  content: '\0043';
}

.biab_toolbar-icon-my-bets:before {
  content: '\0044';
}

.biab_toolbar-icon-profile-stroke:before {
  content: '\0045';
}

.biab_toolbar-icon-search:before {
  content: '\0046';
}

.biab_toolbar-icon-sports:before {
  content: '\0047';
}
