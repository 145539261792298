.seoSettings {
  background-color: #FFFFFF !important;
  text-align: center;

  &__header {
    line-height: 30px;
    border-width: 1px;
    border-style: solid;
    text-align: center;
    margin: 0 !important;
    font-weight: inherit;
  }

  &__content {
    line-height: 1.7em;
    text-align: initial;
    position: relative;
    padding-bottom: 1px;
    box-sizing: content-box !important;

    &__hidden {
      overflow: hidden;
      max-height: calc(5em * 1.7);
    }

    &__visible {
      max-height: fit-content;
      overflow: auto;
    }

    &__wrapper {
      padding: 10px;
    }

    &__fade {
      &:after {
        content: "";
        width: 100%;
        height: 2em;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        background-image: -webkit-gradient(linear, left bottom, left top, from(#fff), to(rgba(255,255,255,0)));
        background-image: -webkit-linear-gradient(90deg, #fff 0, rgba(255,255,255,0) 100%);
        background-image: linear-gradient(0deg, #fff 0, rgba(255,255,255,0) 100%);
        opacity: 0.7;
      }
    }
  }

  &__toggle {
    text-align: center;
    border: none;
    background: none;
    padding: 0;
    margin-top: 15px !important;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
