@import "src/styles/mixins/breakpoints";

.statusOverlay {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -1px;
  height: 100%;
  width: 100%;
  background-color: transparent !important;
  opacity: 1;
  line-height: 40px;
  font-weight: 700 !important;
  border: 2px solid;
  text-align: center;
  box-sizing: border-box;
  z-index: 30;
  @include xl {
    font-size: 11px;
  }
  &:after {
    content: "";
    background-color: transparent;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    opacity: 0.5;
  }

  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  &__container {
    position: relative;
    height: 36px;
    width: 50%;

    &__full {
      height: 100%;
      width: 100%;
    }
  }
}
