@import '../../styles/variables/variables';

.connection {
  width: 12px;
  height: 12px;
  border-radius: 100px;
  display: flex;
  align-self: center;
  cursor: help;

  &.good {
    background-color: #32e32e;
  }

  &.normal {
    background-color: $yellow;
  }

  &.bad {
    background-color: $red;
  }
}
