.favouritesHeader {
  &__tooltip {
    display: flex;
    align-items: center;
    column-gap: 4px;
  }

  &__inactiveIcon {
    color: inherit !important;
  }

  &__asianViewIcon {
    font-size: 16px;

    &__active {
      color: #FFB900;
    }
  }
}
