@import 'styles/variables/variables';

.mobileTopMenu {
  .mobileTopMenuLink {
    display: flex;
    flex-direction: column;
    padding: 4px;
    align-items: center;
    justify-content: center;
    border: 1px solid $gray-bg;
    border-radius: 2px;
    height: 26px;
    text-decoration: none;
    min-width: 40px;
    background-color: $gray-bg;
    font-size: $smaller;
    color: $white;
  }
}
