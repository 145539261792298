.progressBar {
  background-color: #ffffff;
  padding: 13px 12px;
  position: relative;
  border-radius: 4px;

  &__fill {
    background-color: #DDDDDD;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    border-radius: 4px 0 0 4px;
    transition: width 2s;
  }

  &__text {
    font-size: 10px;
    line-height: 1.5;
    color: #212121;
    margin: 0;
    position: relative;
    z-index: 2;
  }
}
