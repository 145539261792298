.oddView {
  background-color: #F5F5F5;
  margin-bottom: 4px;

  &__event {
    margin-bottom: 4px;
  }

  &__emptyContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  &__loading {
    background-color: #ffffff;
  }
}

.noDate {
  text-align: center;
}

