@import "../../styles/mixins/breakpoints";

.roundsList {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 10px;
  padding-left: 0;
  font-size: 16px;
  list-style-type: none;

  @include md {
    margin: 0;
  }

  li {
    margin: 0 5px;
  }

  @include md {
    margin: 0 10px 0 0;
  }
}
