@import '../../styles/variables/variables';

.netCommission {
  display: inline-flex;
  align-items: center;

  &__container {
    display: flex;
    align-items: center;
  }

  &__label {
    line-height: 13px;
  }

  &__icon {
    font-size: 16px;
  }
}
