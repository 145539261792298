.marketsCell {
  position: relative;
  height: 100%;
  &__expanded {
    height: initial;
  }
}

.marketCell__empty {
  min-height: 91px;
}
