@import 'src/styles/mixins/breakpoints';

.resultsMain {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px 0;
  @include md {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
  }

  .noData {
    display: flex;
    width: 100% !important;
    justify-content: center;
    padding-top: 10vh;
    @include md{
      padding-bottom: 10vh;
    }
  }

  > div:nth-child(2) {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 48px;

    @include md {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .hr {
    margin: 27px 0 25px 0;
    border: none;
    background-color: #e3e8e8;
    height: 1px;
  }
}

.pokerContainer {
  display: flex;
  align-items: center;
  flex-direction: column;

  &__popup {
    width: 80%;
    margin: 0 auto;
    padding: 20px 0;
  }
}

hr {
  border: none;
  background-color: #e3e8e8;
  height: 1px;
  margin: 20px 0 28px 0;
}

.resultPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}
