.inputWrap {
  position: relative;

  &__label {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #999999;
    transition: 300ms all;
    transform: translate(-50%, -50%);
    z-index: 20;
  }

  &__validationMessage {
    position: absolute;
    bottom: 0;
    background-color: #D70022;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: 1.5;
    padding: 5px 5px 1px;
    color: #ffffff;
    width: calc(100% - 10px);
    margin: 0;
    transform: translateY(calc(100% - 3px));
    z-index: 9;
    border-radius: 0 0 4px 4px;
    flex-wrap: wrap;
  }
}

.input {
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding: 5px 5px 2px 5px;
  box-sizing: border-box;
  font-size: 12px;
  text-align: right;
  border: 1px solid #C0C0C0;
  border-radius: 4px;
  outline: 0;
  cursor: pointer;
  position: relative;
  z-index: 10;

  &:hover + label {
    cursor: pointer;
  }

  &:focus + label,
  &:not(:placeholder-shown) + label,
  &__filled + label {
    top: 0;
    left: 0;
    font-size: 9px;
    transform: translate(5px, 0);
  }
  &__hover + label {
    color: #303030;
  }
  &__error {
    border-color: #D70022;
  }
  &__back:read-only {
    background-color: #D4ECFF !important;
  }
  &__lay:read-only {
    background-color: #FAEBED !important;
  }
  &:read-only {
    border-width: 0;
  }
  &:disabled {
    background-color: #F5F5F5 !important;
    cursor: initial;
    & + label {
      cursor: initial;
      color: #999999;
    }
  }
}
