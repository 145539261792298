.auth {
  &__content {
    text-align: center;
  }

  &__input {
    border-radius: 4px;
    margin: 10px 0;
    border: 1px solid #ddd;
    text-align: center;
    font-size: 14px;
    outline: none;
    box-shadow: none;
    width: 100%;
    max-width: 350px;
    height: 40px;
    box-sizing: border-box;

    &__mobile {
      max-width: unset;
      height: 60px;
      border-color: #c0c0c0;
      font-size: 16px;
      margin: 16px 0;
    }

    &__error {
      border-color: #FE3629 !important;
    }
  }

  &__message {
    text-align: center;
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400 !important;

    &__mobile {
      text-align: left;
      font-size: 16px;
      line-height: 1.5;
    }
  }

  &__errMessage {
    color: #303030;
    line-height: initial;
    background: none;
    margin: 0;

    &__container {
      border: 1px solid #FE3629;
      max-width: 200px;
      width: 100%;
      display: flex;
      align-items: center;
      column-gap: 12px;
      height: 40px;
      padding: 0 10px;
      border-radius: 4px;
      margin: 0 auto 10px;
      box-sizing: border-box;

      &__mobile {
        max-width: unset;
        height: 48px;
        padding: 0 12px;
        margin-bottom: 16px;
      }
    }

    &__icon {
      font-size: 16px;
    }
  }

  &__timeoutError {
    font-size: 12px;
    line-height: 20px;
    color: #FE3629;
    margin: 8px 0;

    &__mobile {
      font-size: 16px;
      line-height: 1.5;
    }
  }

  &__verify {
    border: none;
    font-weight: bold !important;
    border-radius: 3px;
    cursor: pointer;
    height: 40px;
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    font-size: 12px;
    line-height: 20px;
    background-color: #FFB80C;
    color: #303030;

    &__mobile {
      max-width: unset;
      font-size: 18px;
      line-height: 28px;
      padding-top: 12px;
      padding-bottom: 14px;
      height: auto;
      min-height: 40px;
    }

    &__disabled {
      background-color: #FAD06B;
      cursor: initial;
      color: #666666;
    }
  }
}
