@import 'styles/variables/variables';
@import 'styles/mixins/breakpoints';

.row {
  display: flex;
  min-height: 40px;
  border-top: 2px solid;
  align-items: stretch;
  position: relative;
  width: 100%;
  box-sizing: border-box;

  @include md {
    border-top: 1px solid;
    background-color: #fff;
    border-top-color: #d8d8d8 !important;
  }

  &.mobileBettingMode {
    height: 46px;

    .participantsNames p {
      line-height: 23px !important;
    }
  }

  .participantsNamesCell {
    padding-inline: 10px;
    cursor: pointer;
    flex-grow: 1;
    min-width: 0;
    background: $white;

    @include xxs2 {
      padding-inline: 2px;
    }

    &__swipeMode {
      position: relative;
      z-index: 20;
    }

    .participantsNames {
      width: fit-content;
      height: calc(100% - 2px);
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 700;
        margin: 0;
      }
    }
  }

  .placementLabelsCell {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 5px;
    width: 100%;
    max-width: 70px;
    padding-left: 5px;
    box-sizing: border-box;
    align-content: center;

    div {
      display: flex;
      .placementLabel {
        display: flex;
        align-items: center;
        cursor: pointer;
        height: fit-content;
        font-weight: 700;
        padding: 0 10px;
        border-radius: 2px;
        line-height: 14px;
        font-size: 10px;
        margin: 0;
      }
    }
  }

  .matchedCell {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    max-width: 100px; // If you need to change this value, you also need to change MATCHED_CELL_WIDTH value in src/constants/marketsTable -> MATCHED_CELL_WIDTH value should be equal max-width + left and right paddings and only in px, e.g. max-width: 100px; padding: 0 10px; -> MATCHED_CELL_WIDTH = 120 (100 + 20)
    padding: 0 10px; // the same as above about max-width
    background: $white;
    column-gap: 5px;

    @include md {
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      width: fit-content;
    }

    &__swipeMode {
      position: relative;
      z-index: 20;
    }

    &__hide {
      @include xxs2 {
        display: none;
      }
    }
  }

  .rulesIconWrapper {
    display: flex;
    align-items: center;
    padding: 0 10px;

    @include md {
      padding: 0;
    }

    .rulesIcon {
      font-size: 15px;
      cursor: pointer;
      box-sizing: border-box;
    }

    .lockIcon {
      padding-inline: 10px;
    }
  }

  &__videoStreamAndMatchStats {
    display: flex;
    flex-direction: column;
    row-gap: 1px;

    @include md {
      flex-direction: row;
      align-items: center;
      column-gap: 5px;
    }
  }
}

.rowLargeView,
.rowMediumView {
  .placementLabelsCell {
    max-width: 40px;
    div {
      flex-direction: column;
    }
  }

  .matchedCell {
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    &__row {
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.rowMediumView {
  .matchedCell {
    display: none;
  }
}

.rowSmallView,
.rowExtraSmallView {
  .placementLabelsCell {
    display: none;
  }

  .matchedCell {
    display: none;
  }
}

.rowExtraSmallView,
.rowMobileView {
  .participantsNames {
    max-width: 100%;
  }
}

.mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 10px;

  &__label {
    flex: auto;
    display: flex;
    align-items: center;
    font-size: 17px;
  }

  &__inPlayInfo {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding-right: 10px;
    line-height: 20px;

    &__match {
      text-align: right;
    }
  }

  &__matchNavigation {
    width: 20px;
    line-height: 40px;
    text-align: right;
    font-size: 15px;
    margin-left: auto;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #d8d8d8;
  }
}

.sportIconMobileWrapper {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.matchedAndVideoIcon {
  display: flex;
  align-items: center;
  gap: 5px;
}
