.container {
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__mobile {
    width: 100%;
    margin-bottom: 10px;
  }
}

.value {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 10px;
  margin: 0;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #fff;
  font-size: 12px;
  font-weight: 700 !important;
  color: #303030;
  line-height: 20px;
  text-transform: capitalize !important;
  box-sizing: border-box;

  &__mobile {
    width: 100%;
    margin-bottom: 10px;
  }

  &__centered {
    justify-content: center;
  }
}

.label {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #303030;
  margin-bottom: 5px;
}