@import 'src/styles/variables/variables';
@import 'src/styles/mixins/breakpoints';

.cashOut {
  display: flex;
  align-items: center;

  &__container {
    color: $black;
  }

  &__desktop {
    display: flex;
  }

  &__icon {
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: #ff8624;
    border: solid 1px #ffa01f;
    border-radius: 2px;

    &__wrapper {
      display: flex;
      align-items: center;
      column-gap: 5px;
    }

    &__container {
      width: 12px;
      height: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f2d660;
      vertical-align: bottom;
      border: solid 1px #ffa01f;
      border-radius: 12px;
      position: relative;
      text-align: center;
      line-height: 12px;
    }
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 18px;
  }

  &__pointer {
    cursor: pointer;
  }

  &__disabled {
    border: 1px solid #999999;
    background: #ffffff;

    i {
      border-color: #444444;
      background-color: #444444;
    }
  }
}

.modalHeader {
  h4 {
    text-align: left;
    font-weight: bold !important;
  }
  i {
    font-size: 18px;
    @include md {
      font-size: 22px;
    }
  }
}

.modalDialog {
  @include xs {
    overflow: hidden;
  }

  &__aVplTable {
    z-index: 1000000;
  }
}

.modalBody {
  @include xs {
    height: 50vh;
    overflow-y: auto;
  }
}
