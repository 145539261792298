@import "src/styles/mixins/breakpoints";

.main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 3px 0;
  align-items: center;

  .carts {
    display: flex;
    flex-direction: row;
  }

  .icon {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
  }

  .signSide {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    flex: 1;
    @include md {
      font-weight: inherit;
    }
    .suitInfo {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      padding-right: 5px;
    }
  }
}
