@import 'styles/variables/variables';

a.link {
  display: flex;
  align-items: center;
  padding-inline: 10px;
  border-right: 1px solid;
  color: #f6f6f6;
  line-height: 30px;
  text-decoration: none;
  column-gap: 5px;
  white-space: nowrap;
  font-size: $small;

  &__bold {
    font-weight: bold;
  }

  &:hover {
    text-decoration: none;
  }
}

.active {
  background-color: #343434;
}
