.main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-direction: column;
  .placeButton {
    width: 100%;
    background-color: #FFB80C;
    padding: 8px;
    border-radius: 4px;
    font-size: 12px;
    color: #212121;
    border: none;
    cursor: pointer;
    box-sizing: border-box;

    &:disabled {
      background-color: #FAD06B;
      color: #666666;
      cursor: not-allowed;
    }
  }

  .confirmBtn, .placeButton__lowerPrices {
    padding: 6px 8px;
    border: 2px solid #FF8624;
  }

  .buttonGray {
    width: 100%;
    font-size: 12px;
    color: #212121;
    background-color: #DDDDDD;
    padding: 8px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
  }
  .confirmRemoving {
    border: 2px solid #999;
    padding: 6px 0;
    min-width: 110px;
  }
}
