@import "src/styles/variables/variables";
@import "src/styles/mixins/breakpoints";

.loginMessage {
  font-size: 14px;
  text-align: left;
  line-height: 21px;
}

.closePopup {
  text-align: right;

  button {
    padding: 6px 12px;
    border: 2px solid #289fd3;
    border-radius: 3px;
    color: #289fd3;
    cursor: pointer;
  }
}

.loginPopupContent {
  max-width: 300px;

  @include md {
    max-width: 100% !important;
    width: 100%;
  }
}

.loginToPlaceBet {
  @include md {
    width: 90%;
  }
}

.postLoginPopup {
  max-width: 440px;
}

.mobileLoginPopUp {
  &__modal {
    &__dialog {
      padding: 0 16px;
      min-height: auto;
      height: auto;
      top: 50%;
      transform: translateY(-50%);
    }

    &__body {
      padding: 16px;
    }

    &__header {
      height: 60px;
      padding: 0 16px;

      &__desktop {
        height: 40px;
      }
    }

    &__content {
      border: none;
    }

    &__title {
      font-weight: 700 !important;
    }
  }

  &__topLabel {
    font-size: 16px;
    line-height: 1.5;
    color: #303030;
    margin: 0;

    &__mb {
      margin-bottom: 16px;
    }
  }
}
