@import 'styles/variables/variables';

.content {
  color: #303030;
  border-radius: 4px;
  width: calc(100dvw - 24px);
  max-width:$asian-view-pop-up-max-width;

  .header {
    background-color: #FFB80C;
    padding: 15px 20px;
    border-radius: 4px 4px 0 0;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    line-height: 1.5;
  }

  .body {
    max-height: calc(70dvh - 132px);
    padding: 35px 0 15px;
    font-size: 12px;
    overflow-y: auto;
    background-color: #ffffff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 35px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    box-sizing: border-box;
    font-size: 12px;
    padding: 12px 20px;
    height: auto;
    border-radius: 0 0 4px 4px;
    gap: 10px;
  }

  .button {
    border: 0;
    border-radius: 4px;
    color: #212121;
    cursor: pointer;
    flex: 1;
    height: 48px;
    font-size: 16px;
    line-height: 1.5;

    &__disabled {
      opacity: 0.5;
    }
    &__apply {
      background-color: #FFB80C;
      font-weight: 500;
    }
    &__cancel {
      background-color: #dddddd;
    }
  }
}

.listItem {
  border: 1px solid #C0C0C0;
  padding: 0 12px;
  height: 42px;
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  background: none;
  width: 100%;
  border-radius: 4px;

  &:not(:last-of-type) {
    margin-bottom: 5px;
  }

  .leftIcon {
    margin-right: 15px;
    font-size: 24px;
  }

  &.selectedLIstItem {
    border-color: #FFB80C;
    border-width: 2px;

    span {
      font-weight: 600;
    }
  }
  .selectedIcon {
    margin-left: auto;
    font-size: 24px;
  }
}

.title {
  font-weight: 600 !important;
  font-size: 16px;
  display: flex;
  align-items: center;
  line-height: 1.5;
  margin: 0 0 10px;
  column-gap: 10px;
}

.layBetting {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  i {
    font-size: 22px;
    cursor: pointer;
  }

  .info {
    font-size: 14px;
    color: #666666;
    max-width: 179px;
    line-height: 1.5;
    margin: 0;
  }
}

.switch {
  position: relative;
  width: 80px;
  height: 32px;
  left: 4px;
  label {
    height: 32px;
    background-color: #1D1D1B;
    span:nth-child(1) {
      &:before {
        color: #FFFFFF !important;
        width: 24px;
        height: 24px;
        font-size: 16px;
        line-height: 34px;
      }
      &:not(.checked):after {
        margin-left: 55px;
        font-size: 16px;
        color: #fff;
        margin-top: 9px;
      }
    }
  }
  span:nth-child(2) {
    background-color: #FFFFFF;
    width: 24px;
    height: 24px;
    top: 4px;
    right: 52px !important;
  }
}

.checked {
  span:nth-child(2) {
    right: 4px !important;
  }
  label {
    background-color: #FFB80C;
    span:first-child {
      &::before {
        color: #3E3E3E !important;
      }
    }
    span:nth-child(2) {
      background-color: #3E3E3E;
    }
  }
}

.section, .betSlipTitle {
  padding: 0 20px;
}
