.bet {
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 4px;
  font-size: 11px;

  > * {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &__back {
    background-color: #A6D8FF;
  }

  &__lay {
    background-color: #FAC9D1;
  }

  &__disabled {
    background-color: #DDD;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__link {
    color: inherit !important;
  }

  &__live {
    padding: 2px 8px;
    font-weight: bold;
    color: #FFFFFF;
    background-color: #04A04A;
    border-radius: 2px;
  }

  &__marketName {
    font-weight: 600;
  }

  &__odds {
    padding: 2px 5px;
    border-radius: 2px;
    span {
      font-size: 10px;
    }
    i {
      margin-left: 5px;
      font-size: 6px;
    }
    &__up {
      border: 1px solid #04A04A;
      background: #CBECD8;
      i {
        color: #04A04A;
      }
    }
    &__down {
      border: 1px solid #D70022;
      background: #FAC9D1;
      i {
        color: #D70022;
      }
    }
    &__bold {
      font-weight: bold;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: end;
    a {
      text-decoration: underline;
      cursor: pointer;
    }
    &__icon {
      vertical-align: text-top;
      margin-left: 5px;
      font-size: 13px;
      cursor: pointer;
    }
  }

  &__form {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    > * {
      width: 50%;
    }
  }

  &__payout {
    padding-top: 5px;
    border-top: 1px dashed #666666;
  }

  &__border {
    margin: 8px 0 5px 0;
    padding: 0;
    border-top: 1px solid #666666;
  }

  &__closeIcon {
    align-self: flex-start;
    margin-left: auto;
    padding-left: 5px;
    cursor: pointer;
  }

  &__loader {
    transform: scale(0.2);
    margin-bottom: 10px !important;
    height: 28px;
  }

  &__betMax {
    color: #303030;

    &:hover {
      color: #000000;
    }
  }
}
