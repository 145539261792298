.notFoundPage-wrapper {
  width: 100%;
  padding: 40px 20px;
  box-sizing: border-box;
  @include md {
    padding: 30px 10px;
  }

  .content {
    width: 50%;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    text-align: center;
    color: #ffffff;
    height: auto;
    margin: 0 auto;
    @include md {
      width: 90%;
    }

    .title {
      font-size: 46px;
      line-height: 1;
      font-weight: inherit;
      margin: 0;
      @include md {
        font-size: 24px;
      }
    }

    .message {
      font-size: 20px;
      text-transform: uppercase;
      line-height: 1.5em;
      margin: 0;
      text-align: left;
      @include md {
        font-size: 16px;
      }
    }
  }

  .notFoundPage-linksWrapper {
    display: flex;
    justify-content: center;
  }

  .notFoundPage-links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 50%;
    padding: 0;
    list-style-type: none;
    text-align: center;
    @include md {
      width: 90%;
    }

    &__item {
      width: 31%;
      padding: 5px;
      @include md {
        width: 48%;
      }
      @include sm {
        width: 100%;
        overflow: hidden;
      }

      a {
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        display: inline-block;
        width: 100%;
        padding: 15px;
        color: #ffffff;
        letter-spacing: 1px;
        box-sizing: border-box;
        border: 2px solid #ffffff;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover {
          color: #000000;
          border-color: #ffffff;
          background-color: #ffffff;
        }
      }
    }
  }
}

.content-page {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  position: relative;
  overflow: hidden;

  &__title {
    width: 100%;
    padding-bottom: 20px;
    margin: 0;
    background-color: #ffffff;
    color: #298bd1;
    font-size: 20px;
    border-bottom: 2px solid #e3e8e8;
    line-height: 40px;
    font-weight: 400;
    @include md {
      margin: 0;
      color: $white;
      font-size: 20px;
      font-weight: bold;
      background-color: transparent;
      border-bottom: none;
      padding-bottom: 0;
      line-height: 20px;
    }

    &-wrapper {
      padding: 20px 20px 0;
      background: #ffffff;
      box-sizing: border-box;
      @include md {
        padding: 10px;
        box-sizing: border-box;
        background-color: $dark-gray;
        color: $white;
        font-size: 20px;
      }
    }
  }

  &__content {
    margin: 0 0 50px 0;
    padding: 20px;
    font-size: 12px;
    @include md {
      min-height: 290px;
      padding: 30px 12px 12px 12px;
    }
  }
}

.eventPage__markets__rightSide {
  .biab_promoted-tooltip-inner {
    visibility: hidden;
  }
}
