.marketCell {
  &__odds__back,
  &__odds__lay {
    cursor: pointer;
    width: 40px;
    text-align: center;
    border-radius: 4px;
    background: #A6D8FF;
    border: solid 1px #C0C0C0;
    box-sizing: border-box;
    padding: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    height: 22px;
    vertical-align: middle;
    position: relative;

    &.selected {
      color: #fff;
      background-color: #0072AC;
      border-color: #0072AC;
    }

    &.locked {
      cursor: initial;
      color: #666;
      background-color: #F5F5F5;
      border-color: #C0C0C0;

      .lockIcon {
        font-size: 12px !important;
      }
    }
  }
  &__odds__small {
    width: 35px;
    font-size: 10px;
  }
  &__odds__lay {
    background-color: #FAC9D1;
    margin-left: 2px;

    &.selected {
      background-color: #FF7289;
      border-color: #FF7289;
    }
  }
}

@-webkit-keyframes highlightUp {
  100% {
    border-width: 1px;
  }
}
@keyframes highlightUp {
  100% {
    border-width: 1px;
  }
}

.highlighted.goingUp {
  border-width: 1.5px;
  animation: highlightUp 1000ms;
  animation-fill-mode: forwards;
}

@-webkit-keyframes highlightDown {
  100% {
    border-width: 1px;
  }
}
@keyframes highlightDown {
  100% {
    border-width: 1px;
  }
}

.highlighted.goingDown {
  border-width: 1.5px;
  animation: highlightDown 1000ms;
  animation-fill-mode: forwards;
}

.indicator {
  font-size: 3px;
  position: absolute;
  right: 1px;
  top: 1px;

  &__down {
    top: initial;
    bottom: 1px;
  }
}

.mobileSize {
  min-height: 42px;
  min-width: 46px;
  .indicator {
    font-size: 5px;
    right: 2px;
    top: 2px;
  
    &__down {
      top: 2px;
      bottom: initial;
    }
  }
  .oddsValue {
    font-weight: bold;
  }
  &.locked .lockIcon {
    font-size: 21px !important;
  }
}

.selected .oddsLabel {
  color: #fff;
}

.oddsLabel {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  color: #444;
  line-height: 15px;
}

.loaderWrap {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 50%;
  right: 0;
  width: 24px;
  height: 24px;
  transform: translate(-50%, -50%);
}

.loader {
  position: absolute;
  transform: scale(0.2);
  width: auto;
  height: auto;
}
