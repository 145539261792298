@import 'styles/variables/variables';
@import 'styles/mixins/breakpoints';

.keyboard {
  margin-top: 12px;

  &__landscape {
    margin-bottom: 0;
  }
}

:global {
  @include md {
    .biab_bet-matched {
      width: 100%;
      &.biab_fixed {
        position: fixed;
        top: 100px;
        z-index: 9999;
        left: 0;
      }
      .biab_matching-bet-msg {
        background-color: #638a00;
        padding: 9px;
        min-height: 39px;
        &.biab_type-bet {
          background-color: #0075b2;
        }
        &.biab_cancelled-all-bets-message {
          margin: 5px;
          min-height: auto;
          background-color: #00599e;
          color: #ffffff;
          font-size: 14px;
        }
        .biab_matched-label {
          font-weight: 700;
          display: block;
          color: #fff;
          width: 100%;
          font-size: 14px;
          line-height: 22px;
        }
        .biab_matched-details {
          display: block;
          color: #fff;
          width: 100%;
          font-size: 14px;
          line-height: 22px;
          margin: 0;
          a {
            color: #fff;
            text-decoration: underline;
          }
          .biab_cashout-icon {
            width: 12px;
            height: 12px;
            display: inline-block;
            background: #f2d660;
            border: solid 1px #ffa01f;
            border-radius: 12px;
            position: relative;
            text-align: center;
            line-height: 12px;
            vertical-align: middle;
          }
        }
      }
      .biab_matching-bet-msg.biab_fixed {
        position: absolute;
        right: 10px;
        left: 10px;
        z-index: 1020;
      }
    }
    .biab_betslip {
      .biab_open-bet-details {
        .biab_line-odds-info {
          color: #333333;
          font-size: 13px;
        }
        .biab_mobile-betslip-header {
          margin-bottom: 10px;
          font-size: 12px;
        }
        .biab_custom-number-holder {
          &.biab_pnc-price-holder .biab_odds-arrow {
            color: #fe3629;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.betDetails {
  padding: 12px;
}

.header {
  padding: 10px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  &__title {
    font-size: 14px;
    line-height: 20px;
    margin: 0;

    &__selection {
      margin-left: 5px;
      font-weight: bold !important;
    }
  }

  &__close {
    font-size: 16px;

    :before {
      font-weight: 700 !important;
    }

    &__unmatched {
      color: inherit;
    }

    &__btn {
      all: unset;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__edit {
    font-size: 16px;
  }
}

.inputsWrapper {
  display: flex;
  column-gap: 12px;

  &__landscape {
    display: contents;
  }
}

.payoutAndLiability {
  display: flex;
  column-gap: 12px;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 12px;

  &__landscape {
    display: contents;
  }
}

.messageContainer {
  i {
    display: inline-block;
    width: 4px;
    height: 4px;
    position: absolute;
    left: 3px;
    top: 3px;
    border: solid 1px #ffa01f;
    border-radius: 1px;
  }
}

.placeBtn {
  all: unset;
  background-color: #ffb80c;
  color: #303030;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.42;
  font-weight: 700 !important;
  text-align: center;
  display: block;
  width: 100%;
  height: 42px;
  cursor: pointer;
  box-sizing: border-box;
  margin-top: 20px;

  &__confirm {
    border: 2px solid #ff8624;
  }

  &:disabled {
    background-color: #fad06b;
    border-color: #fad06b;
    color: #666;
    cursor: initial;
  }
}

.currencyChangedWrapper {
  background-color: #d4ecff;
  padding: 12px;
}

.quickBets {
  margin-top: 12px;
}

.quickBet {
  line-height: 20px;
  font-size: 14px;
  font-weight: 400 !important;
}

.landscape {
  &__top {
    display: flex;
    column-gap: 12px;
    align-items: center;
  }

  &__placeBtn {
    flex: 1;
    padding: 0;
    font-size: 12px;
    margin-top: 0;
  }

  &__validationMsg {
    margin-top: 12px;
  }
}

.payout {
  font-size: 14px;
  line-height: 1.6666;
  margin: 0;
  flex: 1;
  padding: 12px 0;

  &__value {
    font-weight: 700 !important;

    &__landscape {
      font-weight: 400 !important;
    }
  }

  &__landscape {
    padding: 0;
    font-size: 12px;
    font-weight: 400 !important;
  }
}

.oddsChangedNotification {
  border: 1px solid #0072ac;
  border-radius: 4px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;

  &__landscape {
    margin-top: 12px;
  }

  &__icon {
    font-size: 16px;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    min-height: 40px;
    line-height: 1.5em;
    box-sizing: border-box;
  }

  &__marginTop {
    margin-top: 12px;
  }
}

.emptyQuickBets {
  margin-top: 12px;
}

.marginTop {
  margin-top: 12px;
}
