.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentContainer {
  text-align: center;
  margin: 10px 0;
}

.PLTableTitle {
  text-align: start;
  font-weight: bold !important;
}

.closePopupIcon {
  font-weight: 600 !important;
  color: #fff;
}
