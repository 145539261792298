@import '../../../../styles/mixins/breakpoints';
@import '../../../../styles/variables/variables';

:global {
  .slick-arrow {
    display: none !important;
  }
  .slick-next {
    display: none !important;
  }
  .slick-slider {
    height: 100%;
    border: 1px solid #c0c0c0;
    border-radius: 10px;
    overflow: hidden;
  }
  .slick-list {
    height: 100%;
  }
  .slick-track {
    height: 100%;
  }

  .slick-current {
    height: 100%;
  }
}

.sliderContainer {
  @include md {
    transition: height 0s;
  }
}

.sliderWrapper {
  outline: none;
}
.pagination {
  margin-bottom: 16px;
  margin-top: 13px;
  display: flex;
  justify-content: center;
  &__slide {
    cursor: pointer;
    width: 26px;
    height: 26px;
    font-size: $regular;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-right: 8px;
    color: $black;
    &__selected {
      border: 1px solid #3e3e3e;
      border-radius: 5px;
      cursor: default;
      pointer-events: none;
    }
    @include md {
      font-weight: bold !important;
    }
  }

  &__mobileSlidesWrapper {
    max-width: 205px;
    display: flex;
    flex-direction: row;
  }

  &__blurLast {
    &:after {
      height: 26px;
      width: 14px;
      position: absolute;
      content: '';
      top: 0;
      right: 13px;
      bottom: 0;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 33%, #ffffff 53%);
      transform: rotate(-90deg);
    }
  }

  &__blurFirst {
    &::before {
      height: 26px;
      width: 14px;
      position: absolute;
      content: '';
      top: 0;
      left: 5px;
      bottom: 0;
      transform: rotate(-90deg);
      background: linear-gradient(180deg, #ffffff 45%, rgba(255, 255, 255, 0) 66%);
    }
  }

  &__slideWrapper {
    display: flex;
    flex-direction: row;
    position: relative;
    width: fit-content;
  }

  &__leftArr {
    margin-right: 8px;
  }
  &__leftArr {
    left: -24px;
  }
  &__rightArr {
    right: -16px;
  }
  &__leftArr,
  &__rightArr {
    position: absolute;
    cursor: pointer;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    animation: fadeIn 0.5s;
  }
}

.arrowFadeIn {
  opacity: 1;
  animation: fadeIn 0.5s;
}

.arrowFadeOut {
  opacity: 0;
  animation: fadeOut 0.5s;
}

.stepDescription {
  padding: 0 35px;
  margin-top: 16px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @include md {
    margin-top: 8px;
    line-height: 18px;
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    color: #212121;
    margin-bottom: 47px;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
