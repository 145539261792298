.matchStatistics {
  &__button {
    display: flex;
    border-width: 0;
    background: none;
    column-gap: 5px;
    align-items: center;
    cursor: pointer;
    padding: 0;
    text-decoration: none !important;
    color: inherit;

    &__leftSpace {
      margin-left: 10px;
    }

    &__mobileHeader {
      min-height: 30px;
      width: 100%;
    }
  }

  &__iconAndLabel {
    display: flex !important;
    align-items: center;
    column-gap: 5px;
  }

  &__text {
    text-transform: uppercase !important;
  }

  &__icon {
    &__leftSpace {
      margin-left: 5px;
    }

    &__clickableWithoutTooltip {
      cursor: pointer;
    }
  }

  &__tooltip {
    text-decoration: none !important;
  }

  &__modal {
    position: absolute;

    &__content {
      text-align: center;
      font-size: 12px;
    }
  }

  &__tooltip {
    padding: 0 !important;
  }

  &__arror {
    display: flex;
    min-height: 22px;
  }
}

.leftSpace {
  margin-left: 10px;
}
