.skeletonContainer {
  position: relative;
  clear: both;

  &__active {
    height: 300px;
    overflow-y: hidden;
  }
}

.infoMsg {
  padding-top: 15px;
  line-height: 20px;
  color: #666666;
}
