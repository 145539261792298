.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 5px 20px;
  gap: 15px;

  i {
    font-size: 16px;
  }
}

.slider {
  position: relative;
}

.disabled {
  position: absolute;
  top: -4px;
  left: 0;
  height: 140%;
  width: 100%;
  background-color: #ffffff;
  opacity: 0.5;
  z-index: 999;
}

.input {
  width: 200px;
  height: 10px;
  padding: 0;
  -webkit-appearance: none;

  &__disabled {

  }
}

input[type="range"] {
  -webkit-appearance: none;
  height: 10px;
  border-radius: 5px;
  background: #f0f1f5 linear-gradient(#ffb80c, #ffb80c) no-repeat;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ffb80c;
  cursor: pointer;
  box-shadow: 0 0 2px 0 #f0f1f5;
  transition: background 0.3s ease-in-out;
  border: solid 1px #ecaa0c;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.questionIcon{
  cursor: pointer;
}

.betslip {
  width: 100%;

  &__labelLine{
    width: 180px;
    display: flex;
    justify-content: space-between;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 15px 0 5px 0;
  }

  &__controlLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 18px;
    gap: 10px;
  }

  &__input {
    width: 180px;
  }

  &__questionIcon {
    font-size: 16px !important;
    cursor: pointer;
  }

  input[type="range"] {
    background-color: #C0C0C0;
  }
}

.coMobPage {
  width: 100%;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;

  &__range {
    width: 100%;

    &__container {
      flex: 1;
    }
  }

  &__min, &__max {
    font-size: 14px;
  }
}

input[type="range"].coMobPage__range {
  background: #C0C0C0 linear-gradient(#ffb80c, #ffb80c) no-repeat;
}