.settings {
  padding: 0 10px;
  border: 1px solid #C0C0C0;
  border-radius: 4px;
  box-sizing: border-box;

  &__toggle {
    position: relative;
    padding: 6px 0;
    cursor: pointer;
    background: none;
    border: none;
    width: 100%;
    text-align: left;
    font-size: 12px;
    line-height: 1.5;
    color: inherit;

    &__expanded {
      border-bottom: 1px solid #C0C0C0;
    }

    &:after {
      font-family: "fa2", serif !important;
      font-weight: normal !important;
      font-style: normal !important;
      content: '\e94d';
      width: 7px;
      font-size: 6px !important;
      transform: translateY(-50%);
      position: absolute;
      right: 10px;
      top: 50%;
    }

    &__expanded {
      &:after {
        transform: translateY(-50%) rotateX(180deg);
      }
    }
  }

  &__content {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
