@import "src/styles/mixins/breakpoints";

.main {
  display: flex;
  flex-direction: row;

  .segment {
    width: 10px;
    margin: 2px;
    height: 6px;
    background-color: #e1e1e1;
    border-radius: 2px;
    @include md {
      width: 12px;
    }
  }
  .pastSegment {
    opacity: 0.5;
  }
}
