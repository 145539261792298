.minHeight {
  min-height: 300px;
}

.wrapper {
  padding: 5px 10px;
  display: flex;
  flex: 1;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    display: none;
  }

  &__desktop {
    border: solid 1px #dddddd;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 #181e5c26;
    max-height: 300px;
    overflow: auto;
    width: 450px;
    border-radius: 4px;
    position: absolute;
    z-index: 2;
    right: 0;
    top: 35px;
  }
}

.results {
  width: 100%;
}

.noResults {
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  &__title {
    font-size: 16px;
    font-weight: 700 !important;
    line-height: 25px;

    &__mobile {
      font-size: 18px;
      line-height: 28px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 20px;

    &__mobile {
      font-size: 16px;
      line-height: 1.5;
    }
  }
}

.loading {
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;

  &__spinner {
    font-size: 20px !important;
  }
}

.startTyping {
  font-size: 18px;
  font-weight: 700 !important;
  line-height: 28px;
  align-content: center;
  text-align: center;
  margin: 0;
  flex: 1;
}
