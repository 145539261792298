@import "../../styles/variables/variables";

.nextRacesWrapper {
  padding: 3px 5px;

  &__title {
    padding: 0 10px;
    margin-bottom: 3px;
    line-height: 30px;
    color: $white;
    overflow: hidden;
    font-size: 11px;

    i {
      margin-right: 5px;
    }
  }
}

.nextRacesList {
  display: flex;
  align-items: center;
}
