.betMessage {
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 15px;
  background-color: #ffffff;
  column-gap: 10px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  margin-top: 10px;

  &__icon {
    font-size: 16px;
  }

  &__success {
    border-color: #04A04A;
  }

  &__warning {
    border-color: #FE3629;
  }

  &__info {
    border-color: #0072AC;
  }

  &__text {
    font-size: 12px;
    line-height: 1.66;
    margin: 0;
  }

  &__right {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
  }

  p {
    margin: 0;
  }

  &__closeBtn {
    all: unset;
    padding: 3px;
    cursor: pointer;
    margin-left: auto;

    i {
      font-weight: bold !important;
      font-size: 16px;
    }
  }
}

p.moreInfo a {
  color: #0072AC;
  font-size: 12px;
  line-height: 1.66;
}
