@import 'src/styles/mixins/breakpoints';

.cancelAll {
  position: relative;
  width: fit-content;
  margin: 0 auto;
  float: right;
  line-height: 30px;

  &__mobile {
    position: initial;
    float: initial;
    margin: 0;
  }
  &__btn {
    all: unset;
    color: #fe3629;
    cursor: pointer;
    text-decoration: underline;
    font-size: 12px;
    line-height: 1.66;

    &:hover {
      text-decoration: none;
    }

    &:disabled {
      color: #999999;
      text-decoration: none;
      cursor: default;
    }

    &__mobile {
      font-size: 14px;
    }
  }

  &__dialog {
    position: absolute;
    padding: 14px;
    border-radius: 4px;
    border: 1px solid #dddddd;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffffff;
    z-index: 100;
    top: calc(100% - 0px);
    box-shadow: 0 2px 8px rgba(#181e5c, 15%);

    &__arrow {
      position: absolute;
    }

    &:before {
      border-style: solid;
      border-width: 1px 1px 0 0;
      border-color: #dddddd;
      content: '';
      display: inline-block;
      height: 5.5px;
      width: 5.5px;
      left: 50%;
      transform: translateX(-50%) rotate(-45deg);
      position: absolute;
      top: -4px;
      vertical-align: top;
      background-color: #ffffff;
    }

    &__mobile {
      left: 0;
      right: 0;
      transform: none;

      &:before {
        left: 24px;
      }
    }

    &__ensureText {
      font-size: 12px;
      line-height: 1.66;
      text-align: center;
      color: #303030;
      margin-top: 0;

      @media (max-width: $md) {
        font-size: 16px;
      }
    }

    &__buttons {
      display: flex;
      column-gap: 10px;
    }

    &__btn {
      min-width: 80px;
      flex: 1;

      @media (max-width: $md) {
        height: 42px;
        font-size: 14px;
      }
    }
  }
}

.notification {
  float: right;
  border: solid 1px #0072ac;
  border-radius: 4px;
  font-size: 12px;
  overflow: hidden;
  line-height: 20px;
  height: 40px;
  background-color: #fff;
  display: flex;
  padding: 0px 10px;
  &__success {
    border-color: #04a04a;
  }
  &__error {
    border-color: #fe3629;
  }
  &__icon {
    font-size: 16px;
    &__loading {
      color: #0072ac;
    }
    &__unmatched:before {
      color: #fe3629;
    }
  }
  &__close {
    margin-left: auto;
    display: flex;
    min-width: 25px;
    min-height: 25px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    &__icon {
      cursor: pointer;
      font-size: initial;
      color: inherit;
    }
  }
  &__mobile {
    flex-grow: 1;
  }
  &__body {
    display: flex;
    align-items: center;
    gap: 10px;
    &__mobile {
      flex-grow: 1;
    }
    .close:hover {
      background: #ececec;
    }
  }

  &__desktop {
    position: absolute;
    top: -5px;
    right: 0;
  }
}
