.marketRow {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #f7f7f7;
  background-color: #eee;

  &__link {
    text-decoration: none;
    line-height: 20px;
    padding: 2px 10px;

    &:hover {
      text-decoration: underline;
    }
  }
  &__title {
    text-decoration: none;
    line-height: 20px;
    padding: 2px 10px;
  }
  &__inactive {
    border-bottom: 0;
  }
}
