.progress {
  &__container {
    background-color: #fff !important;
    position: relative;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
  }

  &__bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: #ececec;
    transition: width 1s;
  }

  &__label {
    font-size: 12px;
    line-height: 1.66;
    color: inherit;
    margin: 0;
    padding: 7px 10px;
    position: relative;
    z-index: 10;
  }
}