@import '../../styles/variables/variables';
@import '../../styles/mixins/breakpoints';

.marketDetailInfo {
  display: flex;
  justify-content: space-between;
  padding: 0 7px;
  margin-bottom: 12px;
  flex-wrap: wrap;
  column-gap: 5px;

  &__left {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 16px;
    gap: 5px;
  }

  &__rules {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-inline: 10px;

    i {
      border-radius: 2px 0 0 2px;
      border-right: 1px solid;
      border-color: #56b5de;
      font-size: $small;
      font-style: normal;
      width: 16px;
      height: 16px;
      text-align: center;
      line-height: 16px;
      font-weight: bold;
    }

    span {
      line-height: 16px;
      font-size: 10px;
      padding: 0 5px;
      background-color: #289fd3;
      border-radius: 0 2px 2px 0;
    }
  }

  &__marketModal {
    font-size: small;
  }

  &__modalText {
    margin-top: 20px;
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__matched {
    font-size: 11px;
    color: #273a47;
    line-height: 16px;
  }

  &__matchedTotal {
    margin-left: 3px;
    font-weight: 700;
  }

  &__refresh {
    border: 0;
    margin-left: 5px;
    display: inline-block;
    line-height: 16px;
    font-size: $smaller;
    padding: 0 10px;
    background-color: #289fd3;
    color: $white;
    border-radius: 2px;
    vertical-align: top;
    cursor: pointer;
  }
}

.plTable {
  margin-right: 8px;
}

.favouriteIcon {
  width: 20px;
  margin-right: 6px;
}
