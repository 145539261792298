@import "../../../../styles/mixins/breakpoints";

.marketsTabs {
  width: 100%;
  max-height: 32px;

  @include md {
    overflow-x: scroll;
    white-space: nowrap;
    background-color: #efefef;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__tab {
    font-weight: bold !important;
    border-right: 1px solid #dfdfdf;
    width: 16.1%;
    max-width: 130px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    display: inline-block;

    @include md {
      border-right: none;
      max-width: initial;
      width: initial;
      min-width: 16.1%;
    }

    span {
      display: block;
      padding: 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-top: 2px solid transparent;

      @include md {
        overflow: initial;
        text-overflow: initial;
      }
    }
  }
}
