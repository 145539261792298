@import "/src/styles/mixins/breakpoints";

.cardsSection {
  width: 60%;
  margin: 0 auto;

  @include md {
    width: 100%;
  }
}

.gamesWrapper {
  padding: 20px 0;
  @include md {
    padding: 5px 0;
  }
}

.autoWidth {
  width: 610px;
  margin: 0 auto;
}

.derbyMobileWrapper {
  @include md {
    display: flex;
    overflow-x: scroll;
    width: auto !important;
    padding: 10px;
  }
}
