@import 'styles/variables/variables';

.checkbox {
  display: flex;
  align-items: center;

  &__asianView {
    gap: 5px;
  }

  &__mobile {
    margin-bottom: 10px;
    gap: 4px;

    &__popUp {
      margin-bottom: 0;
      padding: 12px 0;
      column-gap: 16px;
    }
  }

  &__label {
    &__asianView {
      font-size: 10px;
    }

    &__mobile {
      font-size: $small;
      line-height: $regular;
    }

    &__popUp {
      font-size: 10px;

      &__mobile {
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }
}
