.skeleton {
  position: relative;
  padding: 16px 0 16px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 12px;
  border-bottom: 1px solid #dddddd;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 16px;
    width: 4px;
    height: calc(100% - 32px);
    border-radius: 360px;
    background-color: #F5F5F5;
  }

  &__left {
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 7px 0;
    width: 100%;
  }

  &__item {
    width: 100%;
    max-width: 140px;
    height: 16px;
    background-color: #F5F5F5;
    border-radius: 4px;

    &__top {
      max-width: 200px;
    }

    &__right {
      max-width: 70px;
      height: 16px;
      width: 100%;
      background-color: #F5F5F5;
    }
  }
}
