@import 'src/styles/mixins/breakpoints';
.radioBlock {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @include md{
    padding-top: 10px;
  }
  div {
    width: 150px;
    display: flex;
    margin-right: 5px;
    label {
      display: flex;
      align-items: center;
      justify-content: center;
      input[type='radio'] {
        margin: 0 4px 0 0;
        appearance: none;
        width: 15px;
        height: 15px;
        border: 2px solid #d1d5db;
        border-radius: 50%;
        background-clip: content-box;
      }
      input[type='radio']:checked {
        border: 4px solid #ff8624;
      }
      &:first-child {
        margin-right: 18px;
      }
    }
  }
}
