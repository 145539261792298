@import 'styles/variables/variables';
@import 'styles/mixins/breakpoints';

.fullRules {
  display: flex;
  align-items: center;
  cursor: pointer;

  i {
    border-radius: 2px 0 0 2px;
    border-right: 1px solid;
    border-color: #56b5de;
    font-size: $small;
    font-style: normal;
    width: 16px;
    height: 16px;
    text-align: center;
    line-height: 16px;
    font-weight: bold;
  }

  span {
    line-height: 16px;
    font-size: 10px;
    padding: 0 5px;
    background-color: #289fd3;
    border-radius: 0 2px 2px 0;
  }
}

.marketTable {
  margin: 12px 11px 0 11px;

  &__linkWrapper {
    border-bottom: 1px solid #eeeeee;
  }

  &__content {
    &__header {
      display: flex;
      align-items: center;
      padding: 8px 0;
      border-bottom: 1px solid #eeeeee;
      @include md {
        grid-template-columns: 5fr 0fr 0fr;
        padding-right: 10px;
      }

      &__withoutLay {
        justify-content: flex-end;
      }
    }
  }

  &__headerInfo {
    display: flex;
    align-items: center;
    padding: 7px 5px 0;
    flex-wrap: wrap;
    gap: 5px;

    &__inPlayIcon {
      margin-right: 0;
    }
  }

  &__totalMatched {
    flex-grow: 1;
    font-size: $smaller;
    color: $light-gray;
    min-height: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    float: right;
    line-height: 30px;
    margin-right: 10px;
    gap: 0;

    @include md {
      font-size: $small;
      line-height: initial;
      float: none;
      margin: 0 0 0 10px;
      gap: 5px;
    }
  }

  &__totalMatchedCell {
    display: flex;
    justify-content: center;

    span {
      min-width: 40px;
      width: 50%;
      font-size: $smaller;
      color: $light-gray;
      text-align: center;

      @include md {
        width: auto;
        font-size: $small;
      }
    }

    &__withoutLay {
      justify-content: flex-end;

      span {
        width: 100%;
      }
    }
  }

  &__runnerName {
    font-size: 11px;
    color: #1e1e1e;
    font-weight: bold;
    padding: 2px 0 2px 10px;
    word-break: break-word;
  }
}

.rulesIcon {
  font-size: 15px;
  cursor: pointer;
  box-sizing: border-box;
  @include md {
    background-color: $white;
    color: #2aa7e5;
  }
}

.cashOutContainer {
  margin-left: 3px;
  cursor: pointer;
}

.marketLink {
  font-size: 11px;
  margin-bottom: 3px;
  text-decoration: none;
  transition: 0.1s;
  line-height: 30px;
  margin-left: 10px;
  font-weight: bold;
  @media (max-width: $md) {
  }

  &:hover {
    text-decoration: underline;
  }

  &:last-child {
    margin-bottom: 0;
    @include md {
      font-weight: bold;
      font-size: 12px;
    }
  }

  i {
    margin-left: 2px;
    color: $light-gray;
    font-size: $smaller;
    line-height: 30px;
    @media (max-width: $md) {
    }
  }
}
