.predictionMain {
  display: flex;

  img {
    margin: 0 10px;
  }
  .status {
    span {
      font-weight: bold;
    }
  }
}
