.message {
  display: flex;
  gap: 5px;
  margin: 0;

  &__line {
    flex: 1;
    display: flex;
  }
}

.icon {
  margin-right: 5px;
  font-size: 16px;

  &__unmatched {
    color: #FE3629;
  }
}
