.mobileBetList {
  &__tabs {
    display: flex;
    align-items: center;
    border-top: 1px solid #666666;
    width: 100%;
  }

  &__tab {
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    border: none;
    border-bottom: 2px solid #3E3E3E;
    flex: 1;
    padding: 10px 0;
    font-weight: 600;
    background-color: #3E3E3E;
    cursor: pointer;

    &__selected {
      color: #FFB80C;
      border-color: #FFB80C;
    }
  }

  &__cards {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding: 8px 6px 0;
    width: 100%;
    box-sizing: border-box;

    &__landscape {
      padding-bottom: 12px;
      padding-left: 0;
      padding-right: 0;
      background-color: #DDDDDD;
    }
  }

  &__viewSettledBets {
    padding: 41px 0 49px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 12px;

    &__text {
      font-size: 14px;
      line-height: 24px;
      color: #444444;
      margin: 0;
    }

    &__link {
      font-size: 14px;
      line-height: 22px;
      font-weight: 600 !important;
      color: #0072AC;
      text-decoration: underline;
    }
  }
}