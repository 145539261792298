.btn {
  padding: 2px 18px;
  border-radius: 5px;
  border: none;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #303030;
  cursor: pointer;

  &:disabled {
    background: #FAD06B;
    color: #666666;
    cursor: default;

    &:hover {
      background: #FAD06B;
    }
  }

  &__primary {
    background: #FFB80C;
    font-weight: 700 !important;
    border: 2px solid transparent;
    margin-left: 10px;

    &:hover {
      background-color: #ECA803;
    }
  }

  &__secondary {
    font-weight: 400 !important;
    background-color: #DDD;
    border: 2px solid transparent;

    &:hover {
      background-color: #c0c0c0;
    }
  }

  &__applied {
    background: #FFB80C;
    border: 2px solid #FF8624;
    font-weight: 700 !important;
    margin-left: 10px;

    &:hover {
      background: #ECA803;
    }
  }

  &__outlined {
    background-color: #ECECEC;
    font-weight: 400 !important;
    border: 1px solid #DDDDDD;

    &:hover {
      background-color: #c0c0c0;
    }
  }

  &__mobile {
    width: 48%;
    padding: 8px 20px;
  }
}