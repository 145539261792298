.playerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  &__error {
    text-align: center;
    padding: 20px 10px;
    margin: 0;
  }

  &__frame {
    border-width: 0;
  }
}
