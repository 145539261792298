.main {
  height: 30px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 30px 10px 10px;
  overflow: hidden;
  .progress {
    left: 0;
    height: 30px;
    background-color: #eeeeee;
    position: absolute;
    transition: width 2s;
    z-index: 0;
    padding: 10px 30px 10px 10px;
  }
  .text {
    position: relative;
    z-index: 1;
    padding: 10px 30px 10px 0;
  }
}

.divider {
  margin-top: 13px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}
