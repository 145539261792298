@import "src/styles/mixins/breakpoints";

.fancyMarketSection {
  padding-inline: 10px;
  margin-bottom: 20px;
  width: 50%;
  box-sizing: border-box;

  &__fullWidth {
    width: 100%;
    padding-inline: 0;
  }

  @include md {
    width: 100%;
    padding-inline: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  &__rulesIcon {
    cursor: pointer;
    font-size: 15px;
  }

  &__icons {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }

  &__arrow {
    font-size: 8px;
    cursor: pointer;
  }

  &__mobileInfoIcon {
    font-size: 16px !important;
    cursor: pointer;
  }
}
