@import "../../styles/variables/variables";
@import "../../styles/mixins/breakpoints";

// Desktop
.tabs {
  display: flex;
  column-gap: 1px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  &__tab {
    flex: 1 1 0;
    background-color: $dark-gray;
    font-size: $small;

    a {
      display: block;
      line-height: 30px;
      padding: 0 10px;
      text-align: center;
      font-weight: bold;
      text-decoration: none;
      transition: .1s;
      color: $white;
      white-space: nowrap;
      @include md {
        font-weight: normal;
      }

      &.active {
        background-color: $light-gray;
      }

      &:hover {
        background-color: $light-gray;
      }
    }
  }

  @include md {
    column-gap: 0;
  }
}


