.promotionBannersWrapper {
  display: flex;
  flex-direction: column;
  .promotionBannersHeader {
    display: flex;
    position: relative;
    padding: 0 10px;
    line-height: 35px;
    font-weight: 400;
    background-color: #303030;
    color: #ffffff;
    font-size: 14px;
  }
  ul {
    margin: 0;
    list-style: none;
    padding: 0;
    &:not(.opened) {
      .promotionBanner {
        &:nth-child(n + 5) {
          display: none;
        }
      }
    }
    .promotionBanner {
      position: relative;
      float: left;
      width: 50%;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border-bottom: 1px solid #ffffff;

      &:nth-child(odd) {
        border-right: 1px solid #ffffff;
      }
      .promotionBannerOverlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #ffffff;
        opacity: 0.5;
        z-index: 1;
      }
      .bannerLabel {
        position: absolute;
        top: 0;
        right: 0;
        color: #fff;
        z-index: 1;
        padding: 5px 10px;
        font-weight: bold;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          background: #000;
          opacity: 0.5;
          z-index: -1;
        }
      }
      img {
        width: 100%;
        vertical-align: top;
      }
      a {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        background-color: #ffb80c;
        padding: 5px 15px;
        border-radius: 4px;
        font-weight: bold;
        font-size: 11px;
        text-decoration: none;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        z-index: 2;
      }
    }
  }
  .bannersShowMore {
    padding: 5px 10px;
    text-align: right;
    .showMoreBtn {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.rotate {
  transform: rotate(180deg);
}
