@import 'styles/variables/variables';
@import 'styles/mixins/breakpoints';

.eventHeader {
  display: flex;
  min-height: 30px;
  overflow: hidden;
  box-sizing: content-box;
  padding: 10px 10px 4px 10px;
  max-width: 60%;
  @include md {
    max-width: 80%;
  }


  .scoreWrapper {
    display: flex;
    flex-direction: row;
  }

  &__showFavorite {
    padding-left: 0;
  }

  &__mobile {
    padding: 0;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: inherit;
    font-size: inherit;
    margin: 0;

    a {
      color: inherit !important;
      text-decoration: none;
    }
  }

  &__leftSide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 66px;
    overflow: hidden;
    &__search {
      padding-left: 10px;
    }
  }

  &__highlight {
    height: 12px;
    width: 12px;
    margin-left: 2px;
    margin-right: 3px;
  }

  &__setContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__line {
    font-size: $small;
    line-height: 12px;
    margin: 0 4px;
  }

  &__scope {
    display: flex;
  }

  &__point {
    min-width: 20px;
    min-height: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #444444;
    border-radius: 3px;
    background: #3e3e3e;
    padding: 2px;
    font-size: 14px;
  }

  &__mainScore {
    padding: 2px 3px;
  }

  &__futureSet {
    min-width: 16px;
  }

  &__favouriteIcon {
    flex: none;

    &__right {
      position: initial !important;
      transform: none !important;
    }
  }

  &__leftFavorite {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 10px;
    min-width: 12px;
    @include md {
      margin: 0 10px 15px 1px;
    }
  }
}

.pointMobileSize {
  display: flex;
  width: 22px;
  height: 22px;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: bold !important;
}

.set {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 4px;
}

.tennis {
  &__mobile {
    max-height: 72px;
    padding: 7px 7px 2px 10px;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__name {
    min-height: 46px;
    padding: 2px 0 2px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  &__highlight {
    height: 12px;
    width: 12px;
    margin-left: 1px;
    margin-right: 2px;
  }

  &__set {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 2px 0 2px;
  }

  &__setPoint {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 4px;
  }

  &__ball {
    margin-top: -6px;
  }

  &__point {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include md {
    position: relative;

    li {
      border-bottom: 1px solid #d8d8d8;
      flex-direction: column;
      display: flex;
      height: 45px;
      line-height: 23px;
      padding: 0 10px;
      &:hover {
        text-decoration: none;
      }
    }
    li:last-child {
      border-bottom: none;
    }

    ul {
      list-style-type: none;
      padding-left: 0;
      position: absolute;
      width: calc(100% - 2px);
      margin: 0;
      max-height: 162px;
      overflow-y: auto;
    }
  }
}

.rightSide {
  display: flex;
  align-items: center;
  margin-right: 12px;
  column-gap: 15px;
  justify-content: flex-end;
  row-gap: 5px;
  .widgetIconsWrapper {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    &__vertical {
      flex-direction: column;
    }
  }
  @include md {
    margin-left: 10px;
  }
}

.teamNameHolder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: initial;
}
