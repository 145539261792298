.notification {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 13px 12px;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #FFFFFF;
  border: 1px solid #0072AC;

  i {
    font-size: 16px;
  }
}

.text {
  font-size: 14px;
  line-height: 20px;
}
