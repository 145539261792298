.notification {
  border: 1px solid #0072AC;
  border-radius: 4px;
  background-color: #FFFFFF;

  &__icon {
    font-size: 16px;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 5px 10px;
    min-height: 40px;
    line-height: 1.5em;
    box-sizing: border-box;
    max-width: 450px;
  }
}