.tabsContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);

  &__single  {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__mobile {
    width: 100%;
  }

  &__tab {
    display: inline-block;
    font-weight: 700;
    margin: 0 auto;
    font-size: 12px;
    text-align: center;
    padding: 10px 20px;
    box-sizing: border-box;
    cursor: pointer;
    border-width: 0;
    background-color: initial;

    &__active {
      border-bottom-width: 2px;

      &__avMobile {
        font-weight: 600 !important;
      }
    }

    &__mobile {
      font-weight: bold !important;
    }
  }
}

.cashOutBetslip {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 5px;
  margin-bottom: 15px;

  &__tabs {
    width: 100%;
    margin-top: 15px;
  }

  &__tabsWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__tabsContainer__tab {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    padding: 0;
    width: 100%;
    background-color: initial !important;
  }
}

.betslip {
  width: 100%;
}

.tabContent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 0;
}

.coMobPage {
  &__container {
    margin-top: 16px;
  }

  &__tab {
    width: auto;
    font-weight: normal !important;
    background-color: initial !important;

    &__active {
      font-weight: bold !important;
    }
  }
}
