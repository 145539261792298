.skeletonBetslip {
  padding: 6px 10px 15px 10px;

  &__firstLine {
    width: 100%;
    height: 25px;
    margin-bottom: 10px;
  }

  &__secondLine {
    width: 80%;
    height: 25px;
    margin-bottom: 44px;
  }

  &__lineWrapper {
    display: flex;
    gap: 10px
  }

  &__thirsLine {
    flex: 1;
    height: 40px;
  }

  &__squery {
    width: 40px;
    height: 40px;
  }
}