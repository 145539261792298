.favorite__manage {
  width: 100%;
  padding: 2px 20px 2px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
  border-bottom: 1px solid #f7f7f7;
  text-decoration: none;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;

  i {
    position: absolute;
    right: 4px;
  }

  &:hover {
    text-decoration: underline;
  }
}

.modal {
  &__body {
    min-width: 100%;
    padding: 0;
    overflow: auto;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */

    &__empty {
      padding: 5px 10px;

      div {
        line-height: 50px;
      }
    }
  }

  &__dialog {
    height: 75vh;
  }
  &__header {
    text-align: left;
  }

  &__content {
    width: 100%;
    max-width: 600px;
    min-width: auto;
  }
}
