.howToSectionWrapper {
  z-index: 32;
  position: relative;
}

.navList {
  line-height: 20px;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  position: relative;
  z-index: 2;
}

@keyframes modalFadeOut {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0;
  }
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  animation: modalFadeOut 1.5s;
  opacity: 0.5;
  z-index: 99;
}
