@import '/src/styles/mixins/breakpoints';

.card {
  &__item {
    display: inline-block;
    vertical-align: top;

    img {
      width: 46px;
      height: 62px;

      @include md {
        width: 30px;
        height: 35px;
      }
    }
  }

  &__notAvailable__item {
    width: 45px;
    height: 61px;
    background-color: #b2c8d9;
    border: 2px solid white;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 2px;
    box-shadow: 1px 1px 2px #7f7f7f;

    @include md {
      width: 30px;
      height: 35px;
    }
  }
}

.blackjack-card {
  .card__notAvailable__item,
  img {
    width: 27px;
    height: 31.5px;
  }
}

.baccarat-card {
  display: inline-block;
  margin-right: 3px;
  vertical-align: top;
}

.baccaratUnavalaible{
  margin-top: 0;
}
