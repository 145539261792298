@import "styles/mixins/breakpoints";

.title {
  border-bottom: 2px solid #e3e8e8;

  h1 {
    margin: 0 !important;
    padding: 20px ;

    @include md {
      padding: 10px ;
    }
  }
}

.content {
  padding: 20px 20px 0 20px;

  @include md {
    padding: 10px 10px 0 10px;
  }
}
