.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 28px;

  &__checkbox {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .switch__slider {
      background-color: #0072AC;

      &:before {
        -webkit-transform: translateX(28px);
        -ms-transform: translateX(28px);
        transform: translateX(28px);
      }
    }

    &:focus-within + .switch__slider {
      background-color: #c0c0c0;
    }
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ddd;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 360px;

    &:active {
      background-color: #c0c0c0;
    }

    &:before {
      position: absolute;
      content: "";
      height: 24px;
      width: 24px;
      left: 2px;
      top: 2px;
      background-color: #fff;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }
  }
}
