// Desktop
.marketsTableContainer {
  padding-left: 1px;

  .tableBottom {
    display: flex;
    justify-content: space-between;
    padding: 0 10px 15px 9px;
    height: 28px;
    align-items: center;

    a {
      text-decoration: none;
      font-size: 12px;

      &:hover {
        text-decoration: underline;
      }

      .chevronRight {
        margin-left: 5px;
      }
    }
  }
}

//Mobile
@media (max-width: 999px) {
  .marketsTableContainer {
    padding-left: 0;

    .tableBottom {
      padding-bottom: 0;
    }
  }
}
