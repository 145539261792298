.allMarkets {
  height: 38px;
  line-height: 38px;
  padding-inline: 10px;
  border-bottom: 1px solid #f2f5f5;
  border-top: 1px solid #f2f5f5;
  overflow: hidden;

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    font-size: 13px;
    text-transform: capitalize;
    font-weight: 700 !important;

    i {
      font-size: 15px;
    }
  }
}
