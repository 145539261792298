.actions {
  margin-top: 8px;
  display: flex;
  column-gap: 8px;

  button {
    flex: 1;
    min-height: 42px;
    border-radius: 4px;
    cursor: pointer;

    span {
      display: block;
    }
  }

  &__cancel {
    background-color: #ececec;
    border: 1px solid #DDDDDD;
    color: #303030;
    font-size: 14px;

    &:disabled {
      color: #999;
    }
  }

  &__take {
    font-size: 12px;

    &__label {
      font-weight: 700 !important;
      margin-bottom: 3px;
    }

    &:disabled {
      cursor: none;
    }
  }

  &__take, &__edit {
    border: 0;
    background-color: #FFB80C;

    &:disabled {
      background-color: #FAD06B;
    }
  }

  &__edit {
    font-size: 14px;
    font-weight: 700 !important;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  transform: scale(0.2);
}
