@import "src/styles/variables/variables";

.fancyMarketInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__container {
    padding: 0 8px 10px;
  }

  &__divider {
    border: 0;
    border-top: 1px solid #f2f5f5;
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__refresh {
    border: 0;
    line-height: 16px;
    font-size: $smaller;
    padding: 0 10px;
    background-color: #289fd3;
    color: $white;
    border-radius: 2px;
    cursor: pointer;
  }
}
