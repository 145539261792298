.contentContainer {
  text-align: center;
  margin: 10px 0;
}

.mobileContainer {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 96%;
}

.mobileTable {
  border-collapse: collapse;
  border-spacing: 0;
}

.table {
  width: 98%;
  border-collapse: collapse;
  border-spacing: 0;

  &__title {
    width: 1%;
    border-top: none;
    border-bottom: none;
    border-left: none;
    font-weight: normal;
    text-align: left;
    padding-left: 10px;
  }

  &__coll {
    width: 1%;
    padding: 4px 2px;
    font-weight: normal;
    border: solid 1px #e6e6e6;
    vertical-align: middle;
  }

  &__topCol {
    border-bottom: none;
  }

  &__botCol {
    border-top: none;
  }
}
