@import 'styles/mixins/breakpoints';

.teamNameHolder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: initial;

  @include md{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__criket {
    max-width: 200px;
    @include md{
      max-width: 140px;
    }
  }

  &__tennis {
    @include md{
      max-width: 300px;
    }
  }
}

.bigName{
  display: flex;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.truncateName {
  overflow: hidden;
  text-overflow: ellipsis;
}

.doubleMatch{
  display: flex;
  flex-direction: row;

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
