.matchStatisticsWidgetContent {
  &__frame {
    border-width: 0;
  }

  &__error {
    text-align: center;
    padding: 20px 10px;
    margin: 0;
  }
}
