.raceCardContent {
  &__header {
    background-color: #303030;
    color: #FFFFFF;
    padding: 8px;
    border-radius: 8px 8px 0 0;
    position: sticky;
    top: 0;
    z-index: 100;

    &__swipeMenu {
      top: 57px;
    }
  }

  &__tabs {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    width: fit-content;

    &__wrapper {
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__item {
      line-height: 30px;
      padding: 0 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      min-width: 76px;
      text-align: center;

      &:not(:last-of-type) {
        border-right: 1px solid #dddddd;
      }

      &__active {
        border-top-width: 2px;
        border-top-style: solid;
        border-right: none !important;
      }
    }
  }
}
