.minNav {
  display: flex;
  gap: 20px;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  height: 100%;
  align-items: center;
  padding-top: 7px;
}

.button {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  line-height: 25px;
  border-radius: 50%;
  cursor: pointer;
}

.sidebarToggle {
  color: inherit;

  &:hover {
    background: #666666;
  }
}

.betlist {
  position: relative;

  &__counter {
    position: absolute;
    background: #ffb80c;
    height: 16px;
    min-width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    text-align: center;
    font-size: 10px;
    line-height: 1.5;
    color: #3e3e3e;
    top: -4px;
    left: -3px;

    &__moreThanDoubleDigitNumber {
      border-radius: 16px;
      padding: 0 4px;
    }
  }
}

.linkIcon {
  font-size: 22px;
}
