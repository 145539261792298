@import 'src/styles/variables/variables';

.keyboard {
  display: flex;
  width: 100%;
  gap: 3px;
  background-color: #d8d8d8;
  border: 3px solid #d8d8d8;
  box-sizing: border-box;

  &__wideBorder {
    border-width: 5px;
    gap: 5px;
  }

  &__couponSettings {
    margin-top: 16px;
  }

  &__smallBtnsWrapper {
    width: 84%;
    display: flex;
    flex-direction: column;
    gap: 3px;
    background-color: #d8d8d8;

    &__wideBorder {
      gap: 5px;
    }

    &__row {
      display: flex;
      gap: 3px;

      &__wideBorder {
        gap: 5px;
      }

      button.cell {
        width: 100%;
        height: 35px;
        border: none;
        background: $white;
        font-weight: bold !important;
        font-size: 14px;
        padding: 0;

        &__couponSettings {
          border-radius: 2px;
        }

        &.avCell {
          font-weight: 400 !important;
        }
      }
    }
  }
}

button.clearBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16%;
  background: $white;
  height: auto !important;
  padding: 0;
  border-radius: 0;
  border: none;

  &__wideBorder {
    font-size: 20px;
  }

  &__couponSettings {
    border-radius: 2px;
  }
}
