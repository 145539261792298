.periodTabs {
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2px;
  padding: 2px;
  background-color: #ECECEC;
  border-radius: 4px;
  box-sizing: border-box;

  &__mobile {
    height: 42px;
    flex: 1;
  }

  &__disabled {
    opacity: 0.5;
  }
}
