.fancyMarketItem {
  display: flex;
  align-items: center;
  border-top: 1px solid #f2f5f5;
  font-size: 10px;
  position: relative;

  &__last {
    border-bottom: 1px solid #f2f5f5;
  }

  &__cell {
    padding: 2px 10px;
  }

  &__name {
    font-weight: bold;
    flex-grow: 1;
    min-width: 0;
  }

  &__matchedAmount {
    text-align: right;
    color: #7f7f7f;
    width: 16%;
    box-sizing: border-box;
  }

  &__betContent {
    display: flex;
  }
}

.header {
  display: flex;
  justify-content: flex-end;

  &__content {
    display: flex;
    justify-content: center;

    &__withoutLay {
      justify-content: flex-end;
    }

    &__container {
      display: flex;
      justify-content: center;

      & > span {
        padding: 2px;
        height: 14px;
        text-align: center;
        font-size: 10px;
        line-height: 16px;
        width: 50%;
      }
    }
  }
}

.mobileFancyCell {
  min-width: 53px !important;
  width: 53px !important;

  &__withoutLay {
    min-width: 106px !important;
    width: 106px !important;
  }

  &__container {
    border-left-width: 0;
  }
}
