@import 'styles/mixins/breakpoints';

.goBack {
  &__button {
    border-width: 0;
    padding-right: 12.5px;
    padding-left: 0;
    background-color: transparent;
    cursor: pointer;
  }

  &__icon {
    font-size: 20px !important;
    color: #424242;

    &:before {
      font-size: 10px !important;
    }
  }
}
