@import 'src/styles/mixins/breakpoints';

.rowWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.roundAndLoader {
  @include md {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
}

.baccaratWrapper.resultsBaccarat:not(.biab_modal) {
  width: auto;
  .title {
    padding-left: 8px;
  }
  @include md {
    width: 100%;
    margin: unset;
  }
}

.baccaratWrapper.myBetsBaccarat:not(.biab_modal) {
  width: fit-content;
  margin: 0 auto;
  .title {
    padding-left: 8px;
  }
  @include md {
    width: 100%;
    margin: unset;
  }
}
