@import "../../../../styles/variables/variables";
@import "../../../../styles/mixins/breakpoints";

.filters {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  padding: 7px 10px;

  &__title {
    font-size: 14px;
    font-weight: bold;
    color: $white;
    line-height: 30px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__filter {
    padding: 0 15px;
    border-radius: 2px;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    height: 30px;
    line-height: 30px;
    min-width: 40px;
    white-space: nowrap;
    cursor: pointer;
    @include xl {
      padding: 0 9px;
    }
  }

  &__typeWrapper {
    display: flex;
    flex-direction: row;
  }

  &__favouriteIcon {
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
}
