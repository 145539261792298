.liveScore {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.time {
  font-size: 12px;
}

.score {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
  display: flex;
  column-gap: 1px;

  &__mobile {
    font-weight: 500 !important;
  }

  &__goalLabel {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500 !important;
  }

  &__changed {
    font-size: 14px;
  }
}

.timeContainer {
  &__mobile {
    display: flex;
    column-gap: 3px;
  }
}

.label {
  margin: 0;
  font-size: 12px;
  line-height: 1.5;
}

.periodLabel {
  margin-top: 4px;
}

.highlightedGoal {
  -moz-animation: blink .6s 25;
  -webkit-animation: blink .6s 25;
  -o-animation: blink .6s 25;
  animation: blink .6s 25;
}

@keyframes blink {
  0%, 100% {
    color: #FFFFFF;
  }

  50% {
    color: #F3D55F;
  }
}

@-webkit-keyframes blink {
  0%, 100% {
    color: #FFFFFF;
  }

  50% {
    color: #F3D55F;
  }
}

@-moz-keyframes blink {
  0%, 100% {
    color: #FFFFFF;
  }

  50% {
    color: #F3D55F;
  }
}

@-ms-keyframes blink {
  0%, 100% {
    color: #FFFFFF;
  }

  50% {
    color: #F3D55F;
  }
}

@-o-keyframes blink {
  0%, 100% {
    color: #FFFFFF;
  }

  50% {
    color: #F3D55F;
  }
}
