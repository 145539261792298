.container {
  background-color: #F5F5F5;
  border-bottom: 1px solid #C0C0C0;
}

.content {
  padding: 0 4px;
}

.tabContainer {
  display: flex;
  margin-bottom: 10px;

  &:hover {
    cursor: default;
  }
}

.tab {
  flex: 1;
  height: 25px;
  background-color: #3e3e3e;
  color: #ffffff;
  border-right: 1px solid #c0c0c0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    background: #666666;
  }

  &__active {
    color: #212121;
    background-color: #F5F5F5;
    border-top: solid 2px #ffb80c;
    border-right: 0;

    & + .tab {
      border-left: 1px solid #c0c0c0;
    }

    &:hover {
      background: #F5F5F5;
      cursor: default;
    }
  }

  &__firstItem {
    border-left: none;
  }

  &__lastItem {
    border-right: none;
  }

  &__mobile {
    height: 42px;
    background-color: #3e3e3e;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;

    &:not(:last-of-type) {
      border-right: solid 1px #c0c0c0;
    }

    &.tab__active {
      border-top: solid 2px #ffb80c;
      box-sizing: border-box;
      background-color: #f5f5f5;
      border-right: none;
    }
  }
}

.emptyContainer {
  height: 40px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.cashOutCounter {
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14px;
  padding: 0 2px;
  background: #ffb80c;
  border-radius: 2px;
  color: black;
}
