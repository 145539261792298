.container {
  display: flex;
  gap: 10px;
  margin: 0 0 3px;
}

.btn {
  display: flex;
  color: #3e3e3e;
  font-size: 10px;
  height: 16px;
  padding: 2px 10px;
  align-items: center;
  justify-content: center;
  background-color: #dddddd;
  border-radius: 4px;
  cursor: pointer;
  &__mobile {
    height: 38px;
    margin-top: 6px;
    margin-bottom: 7px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding: 2px 12px;
    box-sizing: border-box;
  }

  i {
    margin-left: 6px;
  }

  svg {
    margin-left: 5px;
    width: 10px;
    height: 10px;
  }

  &__chevronDown {
    transform: rotate(180deg);
  }

  &:hover {
    background: #C0C0C0;
  }
}
