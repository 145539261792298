@import '../../styles/mixins/breakpoints';

.cardTableMain {
  position: relative;
  border: 1px solid #e3e8e8;
  padding: 8px;
  background-color: #f7f7f7;
  border-radius: 2px;
  width: max-content;
  min-width: 180px;
  margin: 10px;
  @include md {
    margin: 5px;
    min-width: 150px;
    padding: 5px;
    padding-bottom: 3px;
  }

  .playerName {
    font-weight: bold;
  }

  .cards {
    display: flex;
    margin: 5px 0;
  }

  .playerStatus {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .points {
    text-align: center;
  }
}

.withShadow {
  box-shadow: 4px 4px 10px -2px #1e1e1e;
}

.baccarat {
  min-width: 147px;
  @include md {
    min-width: 120px;
  }
}
