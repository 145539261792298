@import "styles/variables/variables";
@import "styles/mixins/breakpoints";

.header {
  width: 100%;
  background-color: $yellow-bg;

  &__self__excluded {
    height: 135px;
  }

  &__no__navigation {
    height: 125px;
  }

  &__logoLink {
    padding-bottom: 0;
    max-height: 46.5px;
    text-align: center;

    &__container__mobile {
      align-self: center;
    }
  }

  &__logoImage {
    max-width: 150px;
    max-height: 46.5px;
  }

  &__logoLink__container, &__logoEmptySpace, &__timezoneLocaleLogin {
    flex: 1;

    &__mobile {
      flex: none;
    }
  }

  &__topWrap {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    @include xl {
      padding-left: 0;
    }

    &__mobile {
      align-items: center;
      padding: 5px 5px 5px 12.5px;

      &__login {
        justify-content: center;
      }
    }
  }

  &__timezoneLocaleLogin {
    display: flex;
    justify-content: flex-end;

    &__mobile {
      justify-content: flex-end;
    }
  }

  &__localizationContainer {
    margin-right: 5px;
  }

  &__navigation {
    display: flex;
    padding-left: 10px;
    @include xl {
      padding-left: 0;
    }
  }

  &__navigationItemLink {
    display: block;
    color: $black-light;
    font-size: $small;
    padding: 0 6px;
    border-radius: 2px 2px 0 0;
    text-decoration: none;
    line-height: 30px;
    font-weight: bold;
    transition: 0.2s;

    &:hover {
      background-color: rgba(229, 165, 11, 0.8);
      text-decoration: none;
    }

    &.active {
      color: $white;
      background-color: $black-middle-bg;
    }
  }

  &__headsUpOver {
    padding: 10px;
    text-align: center;
    line-height: initial;
    font-weight: bold !important;

    * {
      margin: 0;
      font-weight: bold;
    }
  }

  &__timezoneAndLocalization {
    display: flex;
    align-items: center;
    column-gap: 5px;
    height: fit-content;
  }
}

