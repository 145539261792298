.betInfoContent {
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 10px;
    line-height: 2;
    color: inherit;
    margin: 0;
  }

  &__right {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  &__unmatched {
    padding-top: 15px;
  }
}

.cashOutIcon {
  &:before {
    font-size: 16px;
    background-color: #FAD06B;
    color: #FF8624;
  }
}

.cashOutLink {
  display: flex;
  text-decoration: none;
}
