.viewAllContainer {
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  margin-top: 4px;
  margin-bottom: 14px;

  a {
    text-decoration: none;
    font-size: 12px;

    &:hover {
      text-decoration: underline;
    }
  }

  i {
    color: #7f7f7f;
    font-size: 10px;
  }
}
