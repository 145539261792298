.heading {
  height: 40px;
  box-sizing: border-box;
  width: 100%;
  color: #fff;
  background-color: #3e3e3e;
  text-align: left;
  padding: 7px 8px 7px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 15;

  &__title {
    line-height: 26px;
    display: flex;
    align-items: center;
    column-gap: 6px;

    h2 {
      text-transform: capitalize;
      display: inline-block;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
    }

    &__icon {
      font-size: 16px;
    }
  }

  &__name {
    text-transform: capitalize;
  }

  .results {
    font-size: 10px;
    line-height: 1.5;
    margin: 0;
  }

  &__searchContainer {
    display: flex;
    align-items: center;
    column-gap: 12px;
  }

  .search {
    font-size: 14px;
    line-height: 1.5;
    margin: 0;
  }
}
