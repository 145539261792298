@import "styles/mixins/breakpoints";

.betContent {
  &__wrapper {
    position: relative;
    display: flex;
    column-gap: 4px;

    @include md {
      column-gap: 0;
    }
  }

  &__swipeMode {
    overflow-x: scroll;
    position: relative;
    z-index: 10;
    overflow-y: hidden;

    &__disabledLay {
      padding-right: 15px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__swipeWrapper {
    display: flex;
  }
}
