@import 'src/styles/mixins/breakpoints';

.favorite {
  min-width: 12px;
  min-height: inherit;
  position: relative;
  text-align: center;
  cursor: pointer;

  &__colored {
    vertical-align: middle;
  }

  &__loginMessage {
    font-size: 14px;
    text-align: center;
  }

  i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: inherit;
    @include md {
      font-style: initial !important;
      font-size: 15px !important;
      font-weight: 100 !important;
    }
  }
}
