@import "../../../../styles/mixins/breakpoints";

.cancelAllBtnWrap {
  &__btn {
    height: 28px;
    padding: 5px 10px;
    font-weight: bold;
    border-radius: 2px;
    border: 0;
    box-sizing: border-box;
    cursor: pointer;
    @include md {
      font-weight: bold !important;
    }
  }
}

