.alertWrapper {
  width: 100%;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 12px;
  color: #303030;
  line-height: 20px;
  font-weight: 700 !important;

  &__mobile {
    margin-bottom: 0;
  }
}

.exportDataControllers {
  padding: 10px 0;
}

.form {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  &__mobile {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 0px;
  }
}

.formLabel {
  font-size: 12px;
  line-height: 20px;
  color: #303030;

  &__mobile {
    margin-bottom: 5px;
  }
}