.marketContainer {
  border-radius: 8px;
  background-color: #F2F3F5;
  padding: 12px 0;

  &__header {
    display: flex;
    align-items: center;
    min-height: 56px;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 0 12px;
    column-gap: 12px;

    &__clickable {
      cursor: pointer;
    }

    &__left {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }
  }

  &__marketName {
    margin: 0;
    font-weight: 600 !important;
    font-size: 16px;
    line-height: 1.5;
    color: #303030;
  }

  &__betsCounter {
    display: flex;
    align-items: center;
    column-gap: 12px;

    &__left {
      display: flex;
      flex-direction: column;
      row-gap: 2px;
      align-items: flex-end;

      &__top {
        margin: 0;
        font-size: 12px;
        line-height: 1.5;
        color: #666666;
      }

      &__bottom {
        margin: 0;
        font-size: 16px;
        line-height: 1.5;
        color: #303030;
        font-weight: 600 !important;
      }
    }

    &__arrow {
      font-size: 14px;
      font-weight: 600 !important;
    }
  }

  &__total {
    flex: 1;

    &__container {
      border-top: 1px solid #DDDDDD;
      display: flex;
      align-items: center;
      padding-top: 20px;
      column-gap: 10px;
      margin: 0 12px;

      &__withCashOut {
        padding-bottom: 20px;
      }
    }

    &__top {
      margin: 0;
      font-size: 12px;
      line-height: 1.5;
      color: #666666;
    }

    &__bottom {
      margin: 0;
      font-size: 18px;
      line-height: 1.5;
      color: #303030;
    }
  }
}

.cashOut {
  &__btn {
    background-color: #DDDDDD;

    &__wrapper {
      margin-top: 0;
      gap: 8px;
      padding: 0 12px;
    }

    &__cashOutText {
      font-weight: 400 !important;
    }
  }

  &__settingsBtn {
    background-color: #DDDDDD;

    &__active {
      background-color: #3E3E3E;
    }
  }

  &__tabs {
    &__container {
      margin-top: 12px;
    }
  }
}