@import 'src/styles/mixins/breakpoints';
@import 'src/styles/variables/variables';

.imageWrapper {
  @include md {
    img {
      width: 100%;
      height: auto;
      display: inline-block;
    }
  }
}
