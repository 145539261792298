@import "styles/mixins/breakpoints";

.container, .container div {
  display: inline-block;
}

.wrapBlock {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
}

.arrow {
  padding-right: 2px !important;
  padding-left: 1px !important;
  font-size: 12px;
}

.itemContainer {
  display: flex;

  span {
    padding: 0!important;
  }
}

.item {
  padding-right: 2px !important;
  font-size: 12px !important;
}

.positive {
  color: #060;
  font-weight: 400 !important;
}

.negative {
  color: #b30000;
  font-weight: 400 !important;
}

