@import "src/styles/variables/variables";

.fancyMarketMobileInfo {
  display: flex;
  align-items: center;
  column-gap: 15px;
  padding: 10px 8px;

  &__refresh {
    border: 0;
    line-height: 16px;
    font-size: $smaller;
    padding: 0 10px;
    background-color: #289fd3;
    color: $white;
    border-radius: 2px;
    cursor: pointer;
  }

  &__icon {
    font-size: 20px !important;
    cursor: pointer;
  }

  &__openBets {
    &__container {
      display: flex;
      align-items: center;
      column-gap: 5px;
    }

    &__checkbox {
      margin: 0;
    }

    &__label {
      font-size: 10px;
    }
  }
}
