.time {
  color: #FFFFFF;
  font-size: 14px;
  border-radius: 3px;
  height: 25px;
  padding: 5px;
  box-sizing: border-box;
  background-color: #4db375;
  margin: 0;
}
