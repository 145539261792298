.rbModalTemplate {
    &__body {
      background-color: #fff;
      padding: 15px;
      border-top: 0 !important;
    }
  
    &__alert {
      display: flex;
      align-items: center;
      padding: 15px;
      background-color: #f5f5f5 !important;
      border-radius: 5px;
    }
  
    &__confirmation {
      font-size: 12px;
      line-height: 20px;
      color: #303030;
      text-align: center;
    }
  
    &__actionsContainer {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
    }

    &__dialog {
      height: inherit;
      max-height: 70vh;
      &__mobile {
        padding: 0;
        margin: 10px;
        left: auto;
        right: auto;
        min-height: auto;
      }
    }
  
    &__content {
      width: 500px !important;
      border: 0;

      &__mobile {
        width: 360px !important;
      }
    }
  
    &__header {
      background-color: #FFB80C;
      padding: 20px;
      border-bottom: 0 !important;
    }
  
    &__title {
      font-weight: 700 !important;
      color: inherit;
      font-size: inherit;
      line-height: 20px;
    }
  
    &__action {
      width: 49%;
      margin: 0 !important;
      padding: 8px 0;
    }

    &__body, &__header {
      border: 1px solid #3e3e3e;
    }
  }