@import "styles/variables/variables";

.racing {
  &__tabsContainer {
    display: flex;
    border-bottom: 1px solid #dfdfdf;
  }

  &__tab {
    padding: 8px 5px;
    border: 0;
    color: #273a47;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
    background-color: #efefef;
    border-right: 1px solid #dfdfdf;
    width: 85px;

    &.active {
      background-color: $white;
      border-top: 2px solid $yellow-bg;
    }
  }

  &__svgIcon {
    width: 20px;
    height: 12px;
  }

  &__contentContainer {
    border-bottom: 1px solid #eeeeee;
  }

  &__containerItem {
    display: flex;
    border-bottom: 1px solid #eeeeee;
    padding: 8px;

    &:last-child {
      border-bottom: none;
    }
  }

  &__itemName {
    width: 25%;
    color: #273a47;
    font-size: 12px;
    font-weight: bold;
    margin-top: 8px;
    display: flex;
    align-items: center;
  }

  &__itemTimeContainer {
    flex-wrap: wrap;
    width: 75%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  }

  &__itemTime {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px;
    font-weight: bold;
    background-color: #dfdfdf;
    border-radius: 2px;
    cursor: pointer;
    padding: 8px 16px;
    color: #1e1e1e;
    font-size: 11px;

    &:hover {
      background-color: #c2c2c2;
      color: #1e1e1e;
    }
  }

  &__inPlay {
    border-radius: 50%;
    height: 8px;
    min-width: 8px;
    background-color: #009000;
    margin-right: 4px;
  }
}
