.placeholder {
  border-radius: 3px;
  border-color: #ececec;
  padding: 5px 20px 5px 5px;
  background-color: #ececec;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  color: #3e3e3e;
  &::after {
    top: 50%;
  }
}

.placeholder:hover,
.activePlaceholder {
  border-color: #ddd;
  background-color: #ddd;
}

.dropdown {
  border-radius: 2px;
  margin-top: 4px;
  border-color: #c0c0c0;
}

.option {
  box-sizing: border-box;
  height: 30px;
  line-height: 30px;
  padding: 0px 26px 0px 10px;
  min-width: 170px;
  border-bottom: solid 1px #ddd;
  &:last-child {
    border: none;
  }
}

.selectedOption {
  font-weight: bold;
  &::after {
    font-family: 'fa2', serif !important;
    font-weight: normal !important;
    font-style: normal !important;
    content: '\e91f';
    position: absolute;
    right: 10px;
  }
}
