$ps-icon-football: "\e900";
$ps-icon-alpine-skiing: "\e901";
$ps-icon-american-football: "\e902";
$ps-icon-archery: "\e903";
$ps-icon-athletics: "\e904";
$ps-icon-australian-rules: "\e905";
$ps-icon-badminton: "\e906";
$ps-icon-baseball: "\e907";
$ps-icon-basketball: "\e908";
$ps-icon-beach-volleyball: "\e909";
$ps-icon-biathlon: "\e90a";
$ps-icon-bobsleigh: "\e90b";
$ps-icon-bowls: "\e90c";
$ps-icon-boxing: "\e90d";
$ps-icon-canoeing: "\e90e";
$ps-icon-cricket: "\e90f";
$ps-icon-cross-country-ski: "\e910";
$ps-icon-curling: "\e911";
$ps-icon-cycling: "\e912";
$ps-icon-darts: "\e913";
$ps-icon-diving: "\e914";
$ps-icon-equestrian: "\e915";
$ps-icon-esports: "\e916";
$ps-icon-fencing: "\e917";
$ps-icon-figure-skating: "\e918";
$ps-icon-soccer: "\e919";
$ps-icon-freestyle-skiing: "\e91a";
$ps-icon-gaa-football: "\e91b";
$ps-icon-gaa-hurling: "\e91c";
$ps-icon-golf: "\e91d";
$ps-icon-gymnastics: "\e91e";
$ps-icon-handball: "\e91f";
$ps-icon-hockey: "\e920";
$ps-icon-horse: "\e921";
$ps-icon-ice-hockey: "\e922";
$ps-icon-judo: "\e923";
$ps-icon-karate: "\e924";
$ps-icon-kickboxing: "\e925";
$ps-icon-luge: "\e926";
$ps-icon-mixed-martial-arts: "\e927";
$ps-icon-motor-sports: "\e928";
$ps-icon-netball: "\e929";
$ps-icon-olympics: "\e92a";
$ps-icon-pentathlon: "\e92b";
$ps-icon-rowing: "\e92c";
$ps-icon-rugby-league: "\e92d";
$ps-icon-rugby-union: "\e92e";
$ps-icon-sailing: "\e92f";
$ps-icon-shooting: "\e930";
$ps-icon-skeleton: "\e931";
$ps-icon-ski-jumping: "\e932";
$ps-icon-snooker: "\e933";
$ps-icon-snowboarding: "\e934";
$ps-icon-surfing: "\e935";
$ps-icon-swimming: "\e936";
$ps-icon-table-tennis: "\e937";
$ps-icon-taekwondo: "\e938";
$ps-icon-tennis: "\e939";
$ps-icon-triathlon: "\e93a";
$ps-icon-volleyball: "\e93b";
$ps-icon-water-polo: "\e93c";
$ps-icon-weightlifting: "\e93d";
$ps-icon-winter-olympics: "\e93e";
$ps-icon-winter-sports: "\e93f";
$ps-icon-wrestling: "\e940";
$ps-icon-up-chevron: "\e900";
$ps-icon-down-chevron: "\e901";
$ps-icon-left: "\e902";
$ps-icon-home: "\e903";
$ps-icon-rg: "\e904";
$ps-icon-a-z: "\e905";
$ps-icon-cashout: "\e906";
$ps-icon-faq: "\e907";
$ps-icon-guides: "\e908";
$ps-icon-in-play: "\e909";
$ps-icon-account: "\e90a";
$ps-icon-my-bets: "\e90b";
$ps-icon-rules: "\e90c";
$ps-icon-search: "\e90d";
$ps-icon-settings: "\e90e";

$ps-icon-up-chevron: "\e900";
$ps-icon-down-chevron: "\e901";
$ps-icon-left: "\e902";
$ps-icon-home: "\e903";
$ps-icon-rg: "\e904";
$ps-icon-a-z: "\e905";
$ps-icon-cashout: "\e906";
$ps-icon-faq: "\e907";
$ps-icon-guides: "\e908";
$ps-icon-in-play: "\e909";
$ps-icon-account: "\e90a";
$ps-icon-my-bets: "\e90b";
$ps-icon-rules: "\e90c";
$ps-icon-search: "\e90d";
$ps-icon-settings: "\e90e";
