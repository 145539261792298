.biab_datepicker-input {
  &:focus-visible {
    outline: #86ba00 auto 1px;
  }
}

.biab_cash-out-navigation-icon {
  font-family: 'FontAwesome';
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid;
  box-sizing: border-box;
  &:before {
    content: '\f0c8';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 8px;
    width: 6.5px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  background-color: inherit !important;
  color: inherit !important;
}