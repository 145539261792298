.eventWidgets {
  display: flex;

  &__container {
    border-width: 1px;
    border-style: solid;
    position: relative;

    &__selectedWidget {
      border-left-width: 0;
      border-right-width: 0;
    }
  }

  &__widget {
    flex: 1;
    padding: 0 5px;

    &__selected {
      border-right-width: 1px;
      border-left-width: 1px;
      border-right-style: solid;
      border-left-style: solid;
    }
  }
}
