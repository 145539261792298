.main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
  .cartCells {
    display: flex;
    flex-direction: row;
    .cartCell {
      width: 33px;
      text-align: center;
    }
  }
}
