@import '../../styles/variables/variables';
@import '../../styles/mixins/breakpoints';

.marketHeader {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ebebeb;
    color: #1e1e1e;
    padding: 4px 6px;

    @include md {
      align-items: flex-start;
      background-color: #303030;
      color: $white;
      margin-top: 10px;
      padding: 6.5px 10px;
    }

    &__starIcon {
      cursor: pointer;
      font-size: 14px;
      margin-right: 2px;
    }

    &__icons {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 5px;
    }

    &__collapse {
      margin-right: 0;

      i {
        margin-right: 5px;
      }

      a {
        font-size: 13px;
        font-weight: bold;
        text-decoration: none;
        line-height: 1.5em;

        &:hover {
          text-decoration: underline;
        }

        @include md {
          color: $white;
        }
      }

      i {
        display: inline-block;
        font-size: 10px;
        color: inherit;
      }
    }
  }

  &__status {
    width: 15px;
    height: 15px;
  }
}
