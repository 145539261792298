.item {
  background-color: #F5F5F5;
  border-radius: 4px;

  &__md {
    width: 200px;
    height: 12px;
  }

  &__sm {
    width: 140px;
    height: 12px;
  }

  &__xs {
    width: 70px;
    height: 12px;
  }
}

.table {
  display: flex;
  flex-direction: column;

  &__row {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: 1px solid #DDDDDD;

    &__left {
      padding: 4px 0;
      display: flex;
      flex-direction: column;
      gap: 16px
    }

    &__right {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.skeleton {
  width: 220px;
  height: 20px;
  &__market {
    width: 180px
  }
}