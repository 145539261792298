@import "src/styles/mixins/breakpoints";

.main {
  display: flex;
  flex-direction: row;

  .number {
    font-size: 10px;
    font-weight: 700;
    opacity: 0.5;
    width: 14px;
    height: 14px;
    text-align: center;
    @include md {
      width: 16px;
    }
  }
  .activeNumber {
    opacity: 1;
  }
}
