.toggleSwitch {
  position: absolute;
  top: 3px;
  right: 2px;
  width: 47px;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  &__checkbox {
    display: none;
  }
  &__label {
    position: relative;
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 15px;
    margin: 0;
  }
  &__inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 15px;
      padding: 0;
      font-size: 10px;
      font-weight: normal;
      line-height:15px;
      box-sizing: border-box;
    }
    &:before {
      content: attr(data-yes);
      text-transform: uppercase;
      padding-left: 10px;
      background-color: inherit;
      color: inherit;
    }
  }
  &__inner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background-color: inherit;
    color: inherit;
    text-align: right;
  }
  &__switch {
    display: block;
    width: 11px;
    height: 11px;
    position: absolute;
    top: 2px;
    right: 32px;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  &__checkbox:checked + &__label {
    .toggleSwitch__inner {
      margin-left: 0;
    }
    .toggleSwitch__switch {
      right: 3px;
    }
  }
}
