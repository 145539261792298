.skeletonBetslip {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;

  &__firstLine {
    width: 30%;
    height: 25px;
    margin-bottom: 20px;
  }

  &__secondLine {
    width: 100%;
    height: 15px;
    margin-bottom: 10px;
  }

  &__thirdLine {
    width: 66%;
    height: 15px;
    margin-bottom: 10px;
  }

  &__fourthLine {
    width: 50%;
    height: 15px;
    margin-bottom: 30px;
  }

  &__fifthLine {
    width: 100%;
    height: 40px;
    margin-bottom: 40px;
  }
}