.item {
  padding: 3px 0;

  label {
    cursor: pointer;
  }

  input {
    vertical-align: middle;
    cursor: pointer;
  }

  span {
    margin-left: 5px;
  }

  &__mobile {
    padding: 10px 0;
  }
}
