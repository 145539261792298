@import "src/styles/mixins/breakpoints";

.timeForm__details {
  padding-top: 5px;
  font-weight: initial !important;

  @include md {
    padding-top: 0;
    white-space: nowrap;
  }

  i {
    margin: 0 4px;
    font-size: 2px !important;
    vertical-align: middle;
  }
}
