@import 'styles/mixins/breakpoints';

.container {
  display: flex;
  gap: 7px;
  justify-content: space-between;

  &__byCenter {
    justify-content: center;
  }

  @include md {
    justify-content: center;
    flex-flow: row wrap;
  }
}

.stake {
  display: inline-block;
  padding: 0;
  line-height: 28px;
  background-color: #ececec;
  border: 1px solid #dddddd;
  color: #303030;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  width: 10%;
  flex-grow: 1;
  font-size: 12px;
  transition: 0.3s all ease;

  &:disabled {
    color: #999999 !important;
    cursor: initial !important;
    border-color: #ececec;
  }

  @include md {
    padding: 8px 0;
    font-weight: bold !important;
  }

  &__byCenter {
    margin: 4px 8px 0;
  }

  &:hover {
    cursor: pointer;
  }

  &__mobile {
    cursor: none;
  }
}
