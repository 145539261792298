.container {
  margin: 10px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  padding: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  &__text {
    text-align: center;
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    color: #303030;
    padding: 0 10px;
  }
}
