.promotableTooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  &__icon {
    font-size: 12px;

    &__large {
      font-size: 16px;
    }

    &__reversed {
      order: -1;
    }
  }
}
