.modal {
  &__btnGroup {
    display: flex;
    justify-content: flex-end;
  }

  &__btnClose {
    display: flex;
    padding: 6px 12px;
    border-radius: 3px;
    cursor: pointer;
  }
}

.popularLinks {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  height: 26px;
  color: #1e1e1e;
  border: none;
  border-bottom: 1px solid #dfdfdf;
  text-decoration: none;
  background-color: #FFFFFF;
  width: calc(100% - 10px);
  cursor: pointer;

  &:hover {
    background-color: #f6f6f6;

    span {
      text-decoration: underline;
    }
  }
}
