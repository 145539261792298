.betslip {
  padding: 0;
  width: 100%;
  display: block;

  &__title {
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  &__inputWrapper {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  &__input {
    width: 64px !important;
    font-size: 12px !important;
  }

  &__button {
    font-size: 12px !important;
    font-weight: normal !important;
    padding: 0 5px !important;
  }
}
// Asian view mobile cash out page
.coMobPage {
  padding-bottom: 0;
  flex-direction: column;
  width: 100%;
  padding-left: 0;
  padding-right: 0;

  &__form {
    padding: 0 10px;
    gap: 15px !important;
    justify-content: space-between !important;
    width: 100%;
  }

  &__ifProfit, &__inputWrap {
    padding: 0 !important;
  }

  &__ifProfit {
    font-size: 14px;
  }

  &__inputWrap {
    display: flex;
    column-gap: 16px;
    align-items: center;
  }

  &__btn__container {
    display: flex;
    justify-content: center;

    .coMobPage__btn {
      width: auto !important;
      font-weight: 400 !important;
      padding: 8px 20px;
      font-size: 16px;
      background-color: #C0C0C0;
      color: #303030;
      height: auto;
      line-height: 1.5;
      border-radius: 4px;

      &__disabled {
        background-color: #DDDDDD;
        color: #999999;
      }
    }
  }
}

.avContainer {
  .av__btn__container {
    display: flex;
    justify-content: center;
  }

  button.autoCOBtn__avMobile {
    font-size: 16px !important;
    line-height: 1.5;
    width: fit-content;
    font-weight: 400;
    color: #303030 !important;
    padding: 9px 20px;
    height: fit-content;
    border-radius: 4px;
  }

  .profitInput {
    &__avMobile {
      flex-grow: 1;
    }
  }

  .inputWrapper {
    &__avMobile {
      flex-grow: 1;
    }
  }
}
