.matchOddsHeader {
  display: flex;

  &__lastCol {
    width: 50px;
    border-left: 1px solid #c0c0c0;
    box-sizing: border-box;
  }

  &__firstCol {
    flex-grow: 1;
  }

  &__middleCol {
    width: 309px;
    border-left: 1px solid #c0c0c0;
    padding: 3.5px 0;
    line-height: 18px;
    font-size: 12px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    column-gap: 5px;
  }
}
