.contentContainerSingle {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4px;
  padding: 8px;
}

.contentContainerDouble {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 9px;
  padding: 12px 11px 0 11px;

  .market {
    margin-top: 0;
  }

  .featureMarket__headerTable {
    width: 48%;
  }
}

.market {
  margin-top: 4px;
}

.blurLastMarker {
  z-index: 2;
  position: relative;
  top: -25px;
  width: 100%;
  height: 25px;
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #fff 50%, #fff 100%);
}

.marketStatus__container {
  position: relative;
}

:global {
  .contentContainerDouble {
    .cellBlock {
      width: 50%;
    }
  }

  .blurLastMarker {
    .viewAllContainer {
      position: absolute;
      right: 0;
      top: 12px;
    }
  }
}
