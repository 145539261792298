.placeBetsActions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dashLine {
  all: unset;
  position: relative;
  height: 1px;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    border: 4px dashed #dddddd;
    box-sizing: border-box;
  }
}

.buttonsWrap {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.button {
  flex: 1;
  display: inline-block;
  padding: 5px;
  min-height: 40px;
  line-height: 20px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  vertical-align: top;
  cursor: pointer;

  &:focus {
    border: solid 2px #FF8624;
  }
}

.primaryBtn {
  background-color: #ffb900;
  color: #303030;
  box-sizing: border-box;
  border: none;

  &__disabled {
    background-color: #f7d478;
    color: #7f7f7f;
    cursor: initial;
  }

  &__highlighted {
    border: 2px solid #ff8624;
  }
}

.secondaryBtn {
  background-color: #cbcbcb;
  color: #303030;
  border: none;
  font-weight: 400;
  &:focus {
    border: solid 2px #C0C0C0;
  }
}

.confirmRemovalBtn {
  border: 2px solid #c0c0c0;
}

.hr {
  margin: 10px 0;
  border: none;
  border-top: 1px solid #e7e7e7;
}
