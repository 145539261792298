.info {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: #A6D8FF;
  border: 1px solid #0072AC;
}

.icon {
  width: 11px;
  height: 11px;
  font-size: 12px;
  vertical-align: middle;
}

.text {
  font-size: 10px;
}
