@import '../base/media.scss';

@mixin xxs {
  @media (max-width: $xxs) {
    @content;
  }
}

@mixin xxs2 {
  @media (max-width: $xxs2) {
    @content;
  }
}

@mixin xs {
  @media (max-width: $xs) {
    @content;
  }
}

@mixin sm {
  @media (max-width: $sm) {
    @content;
  }
}

@mixin md {
  @media (max-width: $md) {
    @content;
  }
}

@mixin lg {
  @media (max-width: $lg) {
    @content;
  }
}

@mixin xl {
  @media (max-width: $xl) {
    @content;
  }
}

@mixin xxl {
  @media (max-width: $xxl) {
    @content;
  }
}

@mixin min-md {
  @media (min-width: $md) {
    @content;
  }
}

@mixin min-xs {
  @media (min-width: $xs) {
    @content;
  }
}

@mixin min-sm740 {
  @media (min-width: $sm740) {
    @content;
  }
}

@mixin min-md1145 {
  @media (max-width: $md1145) {
    @content;
  }
}

@mixin min-sm768 {
  @media (min-width: $sm768) {
    @content;
  }
}
