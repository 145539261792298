.header {
    padding: 10px 15px;
}

.wallets {
    display: flex;
    justify-content: center;
    align-items: center;
}

.walletWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-left: 10px;

    &__with_border {
        padding-right: 10px;
    }

    &__with_border:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        height: 32px;
        border-right: 1px solid #666;
    }
}

.label {
    font-size: 14px;
    margin-bottom: 5px;
    color: inherit;
}

.balance {
    font-size: 16px;
    color: inherit;
    font-weight: 700 !important;
}

.singleWalletWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #666;
}

.iconWrapper {
    margin-left: 8px;
}

.icon {
    font-size: 15px;
}