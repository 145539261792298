.timeForm {
  &__section {
    margin-bottom: 20px;
  }

  &__content {
    padding: 20px 10px;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;

    &__mobile {
      flex-direction: column;
    }
  }

  &__header {
    font-weight: bold !important;
    margin-top: 0;
    margin-bottom: 10px;
  }

  &__runnerName {
    display: inline-block;
    margin-bottom: 5px;
    font-weight: bold !important;
  }

  &__commentText {
    margin: 0;
  }

  &__comment {
    &:last-child {
      margin-bottom: 0;
    }
    margin-bottom: 20px;
    box-sizing: border-box;
  }

  &__commentsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__rightSide, &__leftSide {
    flex-basis: 49%;
  }
}
