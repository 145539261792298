.swipeMessage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #A6D8FF;
  border: 1px solid #0072AC;
  border-radius: 4px;
  padding: 11px 10px;
  box-sizing: border-box;
  margin: -10px 0 10px;
  color: #212121;

  &__left {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  &__text {
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
  }

  &__icon {
    color: #0072AC;
  }

  &__closeIcon {
    font-size: 13px;
    color: #303030;

    &__wrapper {
      cursor: pointer;
      border: none;
      padding: 7px;
      background: none;
      outline: none;
    }
  }
}
