.field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  height: 56px;
  font-size: 16px;

  &__borderTop {
    border-top: 1px solid #DDDDDD;
  }
}

.fieldName {
  color: #666666;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.fieldValue {
  display: flex;
  align-items: center;
  color: #303030;
}

.fieldValue.capitalise {
  text-transform: lowercase;
}

.fieldValue.capitalise::first-letter {
  text-transform: uppercase;
}

.icon {
  color: #303030;
  font-size: 24px;

  .iconBg:before {
    color: inherit;
  }

  &__info {
    margin-left: 9px;
  }

  &__copy {
    padding: 9px;

    &:active {
      background-color: #ececec;
      border-radius: 50%;
    }
  }
}

.tooltip {
  font-size: 16px !important;
}
