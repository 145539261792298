@import 'src/styles/variables/variables';

.quickBets {
  &__tabs {
    display: flex;
    border-radius: 4px;
    gap: 2px;
    padding: 2px;
    background-color: #ececec;
    margin-top: 15px;

    &__mobile {
      margin-top: 0;
      margin-bottom: 24px;
    }

    &__tab {
      all: unset;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: inherit;
      padding: 3px;
      border-radius: 2px;
      flex: 1;
      margin: 0;
      cursor: pointer;

      &:hover {
        background-color: #ddd;
      }

      &__active {
        background-color: #fff;

        &:hover {
          background-color: #fff;
        }
      }

      &__mobile {
        font-size: 16px;
        line-height: 1.5;
        padding: 7px;
      }
    }
  }

  &__bets {
    &__header {
      display: flex;
      margin-top: 15px;
      padding: 5px 0;
      margin-bottom: 20px;

      &__mobile {
        margin-top: 0;
        margin-bottom: 24px;
        padding: 12px 0;
      }

      &__col {
        flex: 1;
        position: relative;

        &__divider {
          position: absolute;
          width: 25px;
          rotate: 90deg;
          height: 1px;
          background-color: #ddd;
          right: -12.5px;
          top: 50%;
          margin: 0;

          &__mobile {
            width: 42px;
          }
        }
      }

      &__title {
        margin: 0;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: $text-gray-dark;

        &__mobile {
          font-size: 16px;
          line-height: 1.5;
        }
      }

      &__value {
        margin: 0;
        font-weight: 700 !important;
        font-size: 14px;
        line-height: 20px;
        text-align: center;

        &__mobile {
          font-size: 16px;
          line-height: 1.5;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    column-gap: 20px;
    margin-top: 5px;

    &__mobile {
      margin-top: 12px;
      column-gap: 12px;
    }

    &__col {
      flex: 1;
    }
  }

  &__button {
    &__input {
      min-width: 90px;
      height: 30px;
      font-size: 12px;
      line-height: 20px;
      color: #303030;
      padding: 0 10px;
      border: 1px solid #ddd;
      border-radius: 4px;
      flex-grow: 1;
      max-width: 68px;
      outline: none;
      box-sizing: border-box;

      &:hover {
        border-color: #3e3e3e;
      }

      &:disabled {
        border-color: transparent !important;
        background-color: #f5f5f5;

        &:hover {
          border-color: #ddd;
        }
      }

      &__error {
        border-color: #fe3629;

        &:hover {
          border-color: #fe3629;
        }
      }
    }

    &__wrapper {
      display: flex;
      align-items: center;
      gap: 15px;
      height: fit-content;

      &__mobile {
        flex: 1;
      }
    }

    &__label {
      font-size: 12px;
      line-height: 20px;
      color: #666;
      margin: 0;
      white-space: nowrap;
    }
  }

  &__notSavedError {
    margin-top: 15px;
  }

  &__floatingInput {
    &__disabled {
      background-color: #f5f5f5;
      border-color: #f5f5f5;
    }

    &__error {
      border-color: #FE3629;
    }

    &__input {
      text-align: center;
      width: 0;

      &:disabled {
        background-color: #f5f5f5;
      }
    }

    &__label {
      left: 50%;
      transform: translate(-50%, -50%);

      &.quickBets__floatingInput__label__active {
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }

  &__actions {
    margin-top: 24px;
  }
}
