.message {
  padding: 11px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background-color: #FFFFFF;
  animation: moveFromTop 0.5s forwards;
  transition: opacity 0.5s ease-in-out;

  &__fadeOut {
    opacity: 0;
  }

  &__title {
    margin: 0;
    font-weight: bold !important;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__marketName,
  &__score {
    margin-left: 5px;
  }

  &__score {
    font-weight: bold;
  }

  &__text {
    span {
      font-weight: bold !important;
    }
  }

  &__success {
    border: 1px solid #04A04A;
  }

  &__error {
    border: 1px solid #D70022;
  }

  &__icon {
    &__success {
      font-size: 20px;
    }

    &__error {
      min-width: 1em;
      min-height: 1em;
      font-size: 20px;
      vertical-align: middle;
    }
  }

  &__close {
    margin-left: auto;
    padding-left: 5px;
    cursor: pointer;
  }
}

@keyframes moveFromTop {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0px);
  }
}
