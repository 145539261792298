@import "../../styles/variables/variables";
@import "../../styles/mixins/breakpoints";

.eventHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 30px;
  padding: 10px 15px 11px 15px;
  overflow: hidden;
  background-color: $dark-gray;
  box-sizing: content-box;
  position: relative;
  @include md {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding: 4px 12px;
  }

  &__title {
    font-size: inherit;
    margin: 0;
    font-weight: 400;
    line-height: 120%;

    &__wrapper {
      display: flex;
      flex-direction: column;
    }

    &__name {
      display: flex;
      align-items: flex-end;
    }

    @include md {
      font-size: 13px;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  &__subTitle {
    margin-top: 8px;
  }

  &__rightSide, &__leftSide {
    display: flex;
    align-items: center;
    position: relative;
    @include md {
      padding-left: 30px;
    }

    &__withBackButton {
      @include md {
        padding-left: 25px;
      }
    }

    i {
      font-size: 20px;
      color: $white;
      margin-right: 5px;

      @include md {
        position: absolute;
        top: 3px;
        left: 0;
        font-size: 23px;
      }
    }
  }

  &__leftSide {
    &__backButtonWrapper {
      position: absolute;
      top: 7px;
      left: 0;
    }

    &__withBackButton {
      i {
        @include md {
          position: static;
          margin-right: 0;
        }
      }
    }
  }

  &__date {
    width: 80px;
    text-align: center;
    line-height: 15px;
    @include md {
      width: 100%;
      font-size: 11px;
      color: $white;
    }
  }

  &__inPlay {
    position: inherit !important;
  }

  &__favouriteIcon {
    cursor: pointer;
    margin-left: 15px;
    @include md {
      display: none;
    }
  }

  &__desktop {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background: #32abe5;
    }
  }
}
