@import "src/styles/mixins/breakpoints";

.main {
  min-width: 520px;
  padding: 8px;
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  border-radius: 2px;
  box-shadow: 4px 4px 10px -2px #1e1e1e;
  width: 611px;
  @include md {
    min-width: 528px;
    width: 528px;
  }
}
