.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  box-sizing: border-box;
  background-color: #FFFFFF;

  &__mobile {
    border-width: 1px;
    border-style: solid;
    border-color: #C0C0C0;
    background-color: #F5F5F5;
    border-radius: 4px;
    padding: 8px 24px; // if the left and right paddings are changed -> need to change MOBILE_LEFT_AND_RIGHT_PADDINGS value in component

    &__wrapper {
      padding: 16px 6px; // if the left and right paddings are changed -> need to change MOBILE_LEFT_AND_RIGHT_EXTERNAL_PADDINGS value in component
      background-color: #ffffff;
    }
  }

  &__page, &__action {
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
    color: inherit;
  }

  &__page {
    width: 30px;
    height: 30px;
    min-width: 30px;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 4px;
    color: inherit;
    font-size: 14px;
    line-height: 1.5;

    &:hover {
      border: 1px solid #999999; // if the left and right borders are changed -> need to change MOBILE_LEFT_AND_RIGHT_BORDER_WIDTHS value in component
    }

    &__active, &__active:hover {
      border: 1px solid #303030; // if the left and right borders are changed -> need to change MOBILE_LEFT_AND_RIGHT_BORDER_WIDTHS value in component
    }

    &__mobile {
      height: 42px;
      width: 42px; // if width is changed -> need to change MOBILE_PAGE_NUMBER_WIDTH value in component
      font-size: 16px;
      font-weight: 600 !important;
    }
  }

  &__action, &__pagesWrapper, &__leftActions, &__rightActions, &__page {
    display: flex;
    align-items: center;
  }

  &__pagesContainer {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  &__pagesWrapper {
    max-width: 380px;
    overflow: hidden;
    position: relative;
    column-gap: 5px; // if gap is changed -> need to change SPACE_BETWEEN_PAGES_NUMBERS value in component
    width: fit-content;

    &__lastFade:after, &__firstFade:before {
      content: '';
      width: 30px;
      height: 30px;
      position: absolute;
      top: 0;
      pointer-events: none;
    }

    &__lastFade__mobile:after, &__firstFade__mobile:before {
      width: 42px; // should be the same as width for pagination__page__mobile
      height: 42px; // should be the same as width for pagination__page__mobile
    }

    &__lastFade {
      &:after {
        right: -4px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 55%);
      }

      &__mobile {
        &:after {
          background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #f5f5f5 55%);
        }
      }
    }

    &__firstFade {
      &:before {
        left: -3px;
        background: linear-gradient(90deg, #ffffff 45%, rgba(255, 255, 255, 0) 100%);
      }

      &__mobile {
        &:before {
          background: linear-gradient(90deg, #f5f5f5 45%, rgba(255, 255, 255, 0) 100%);
        }
      }
    }

    &__mobile {
      max-width: 512px; // Max pages to display = 11. Width of page number button = 42. 11 * 42 = 462px. Gap between pages = 5; (11 - 1) * 10 = 50. 462 + 50 = 512px.
    }
  }

  &__action {
    column-gap: 4px;

    &:disabled {
      cursor: initial;

      .pagination__action__icon, .pagination__action__text {
        color: inherit;
      }
    }

    &__icon {
      line-height: initial !important;
    }

    &__first, &__last {
      column-gap: 8px;
    }
  }

  &__leftActions, &__rightActions {
    column-gap: 20px;
  }

  &__padding {
    background-color: #f5f5f5;
    height: 5px;
  }

  &__emptySpace {
    background-color: #f5f5f5;
  }
}

.pagination .pagination__action__icon__mobile:before {
  font-size: 10px;
}
