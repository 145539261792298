@import 'styles/variables/variables';
@import 'styles/mixins/breakpoints';

.eventHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 30px;
  overflow: hidden;
  box-sizing: content-box;

  &__hasBackButton {
    margin-left: 12px;
  }

  &__favoriteLeft {
    margin-left: 2px;
    i {
      position: relative;
    }
  }

  &__title {
    font-weight: inherit;
    margin: 0 6px;

    &__search {
      margin-left: 0;
    }

    a {
      color: inherit !important;
      text-decoration: none;
    }
  }

  &__leftSide {
    display: flex;
    line-height: 30px;
    padding: 10px 0 11px 10px;
  }

  &__rightSide {
    display: flex;
    align-items: center;
  }

  &__widgetsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__widgets {
    margin-right: 15px;
    display: flex;
    align-items: center;
    column-gap: 15px;
    &__vertical {
      flex-direction: column;
      row-gap: 9px;
    }
  }

  &__scope {
    display: inline;
    color: #00cc00;
    font-size: $middle;
    margin: 0 5px;
  }

  &__point {
    width: 30px;
    min-height: 91px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__favouriteIcon {
    position: initial !important;
    transform: none !important;
  }

  &__favouriteLeft {
    display: inline-flex;
    margin: 0 4px;
  }

  &__minSide {
    display: flex;
    justify-content: flex-start;
    z-index: 1;
    flex: 1;

    &__right {
      flex: 1;
      align-items: center;
      justify-content: flex-end;
      @include xl {
        row-gap: 5px;
        align-items: flex-end;
      }

      @include md {
        justify-content: flex-end;
        position: initial;
        align-items: center;
      }
    }

    li {
      border-bottom: 1px solid #d8d8d8;
      flex-direction: column;
      display: flex;
      height: 45px;
      line-height: 23px;
      padding: 0 10px;
      &:hover {
        text-decoration: none;
      }
    }
    li:last-child {
      border-bottom: none;
    }

    ul {
      list-style-type: none;
      padding-left: 0;
      padding-bottom: 1px;
      position: absolute;
      width: calc(100% - 2px);
      margin: 0;
      max-height: 162px;
      overflow-y: auto;
    }
  }
}

.mobileEventHeader {
  display: flex;
  align-items: center;
  padding: 4px 10px;
  min-height: 30px;

  &__title {
    display: flex;
    flex-direction: column;
    padding: 0 4px;

    &__search {
      padding: 0;
    }

    a {
      color: inherit !important;
      text-decoration: none;
    }
  }

  &__label {
    margin: 0 4px;
  }

  p {
    margin: 0;

    span {
      margin-right: 4px;
    }
  }
}

.matchContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  min-height: 44px;
  overflow: hidden;
  box-sizing: content-box;
  border-top: 1px solid;
  padding: 0 8px;
  &__penalty {
    min-height: 34px;
    justify-content: center;
  }
}

.match {
  position: relative;
  height: 4px;
  flex-grow: 3;
  border-radius: 4px;
  &__extra {
    flex-grow: 1;
  }
}

.empty {
  position: absolute;
  top: 0;
  height: 4px;
  max-width: 100%;
  width: 100%;
  border-radius: 4px;

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 4px;
    z-index: 2;
  }
}

.line {
  position: absolute;
  top: 0;
  height: 4px;
  width: 100%;
  max-width: 100%;
  border-radius: 4px;

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 4px;
  }
}

.event {
  position: absolute;
  top: -2px;
  height: 12px;
  width: 12px;
  left: 2px;
  cursor: help;
}

.home {
  top: -10px;
}

.away {
  top: 5px;
}

.YellowCard {
  background: url('../../assets/icons/running-ball.png') no-repeat 0 -96px;
}

.RedCard {
  background: url('../../assets/icons/running-ball.png') no-repeat 0 -111px;
}

.goalWhite {
  color: white;
}

.leftSide {
  display: flex;
  align-items: center;
}

.soccer {
  display: flex;
  padding: 10px;
  z-index: 5;
  align-items: center;
  flex: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &__hideSecondName {
    height: 0;
    opacity: 0;
    padding: 0 !important;
    margin: 0 !important;
  }

  &__scoreWrap {
    box-sizing: border-box;
    padding: 5px;
    text-align: center;
    background-color: #3e3e3e;
    border: 1px solid #444444;
    border-radius: 4px;
  }

  &__score {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: bold !important;
  }

  &__time {
    margin-top: 8px;
  }

  &__diver {
    padding: 0 5px;
  }

  &__teamNameWrapper {
    display: flex;
    flex-direction: column;
    align-self: baseline;
    flex: 1;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include md {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__first {
    justify-content: flex-end;
  }

  &__teamName {
    text-align: end;
    padding: 5px 5px 0 5px;
    line-height: 15px;

    &__away {
      text-align: start;
    }

    a {
      color: inherit !important;
      text-decoration: none;
    }

    @include md {
      padding: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__homeScore {
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: flex-end;
  }

  &__awayScore {
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: flex-start;
  }

  &__homeScore,
  &__awayScore {
    i {
      margin-right: 4px;
    }
  }

  &__container {
    position: relative;
    min-height: 30px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
    }

    @include md {
      &:before {
        width: 0;
        height: 0;
      }
    }
  }

  &__headerContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index: 2;

    @include md {
      justify-content: space-between;
    }
  }

  &__tooltip {
    display: flex;
    flex-direction: column;
    color: black;
    text-align: start;

    &__time {
      color: #00cc00;
    }

    &__name {
      display: flex;
      align-items: center;
    }

    &__card {
      height: 12px;
      width: 12px;
      margin-right: 4px;
    }
  }
}

.marketDropdown {
  padding-right: 12px;
  @include md {
    padding-right: 9px;
  }
}

.penalty {
  &__score {
    display: inline-block;
    width: 17px;
    height: 17px;
    margin: 0 3px;
    background: url('../../assets/icons/running-ball.png') no-repeat 0 -78px;
    &__scored {
      background: url('../../assets/icons/running-ball.png') no-repeat 0 -40px;
    }
    &__missed {
      background: url('../../assets/icons/running-ball.png') no-repeat 0 -59px;
    }
  }
  &__scores {
    color: #fff;
    font-size: 14px;
  }
}
