@import "../../../../styles/variables/variables";

.userInfo {
  margin: 0 10px;

  .usernameAndStatus {
    display: flex;
    column-gap: 7px;
    margin-bottom: 15px;
    &__singleBalance {
      margin-bottom: 10px;
    }
  }

  p {
    font-size: $small;
    font-weight: bold;
    margin: 5px 0 0;
  }
}

.balance {
  position: relative;
  &__label {
    position: absolute;
    right: 100%;
    margin-right: 10px;
    white-space: nowrap;
  }
  &__single {
    margin-right: 5px;
  }
}