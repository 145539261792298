@import "src/styles/mixins/breakpoints";

.firstSection {
  min-width: 520px;
  padding: 8px;
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  border-radius: 2px;
  box-shadow: 4px 4px 10px -2px #1e1e1e;
  width: 611px;
  @include md {
    width: auto;
    min-width: auto;
  }

  > p {
    font-weight: bold;
    text-align: center;
    margin-top: 0;
    margin-bottom: 5px;
  }

  .main {
    display: flex;
    flex-direction: row;
    gap: 18px;
    justify-content: flex-start;
    @include md {
      gap: 0;
    }

    .section {
      display: flex;
      flex-direction: row;
      align-items: center;

      .iconWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        margin-right: 5px;
        margin-bottom: 5px;
        border-radius: 12px;
      }
    }
  }
}

.resultsWrapper {
  box-shadow: none;
  border: none;
  background: transparent;
  margin: 0 auto;
   > p {
     font-weight: bold !important;
   }
  .main {
    gap: 5px;
    justify-content: center;
  }
  .iconWrapper {
    display: none !important;
  }
}
