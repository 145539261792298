.reminderView {
    &__wrapper {
        width: 500px;
        box-sizing: border-box;

        &__mobile {
            width: 380px;
            padding: 12px 16px;
        }
    }

    &__column {
        flex-direction: column;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center; 
        flex-grow: 1;
        width: 100%;

        &__bottom__gap {
            margin-bottom: 10px;
        }
    }

    &__input__wrapper {
        display: flex; 
        justify-content: space-between; 
        align-items: center;
        gap: 10px;
        flex-grow: 1;
    }

    &__input {
        max-width: 220px;
        box-sizing: border-box;
    }
    &__input__mobile {
        width: 170px !important;
        margin: 0;
    }

    &__label {
        font-size: 12px;
        line-height: 20px;
        color: #303030;

        &__remindMe {
            flex-grow: 1;
        }
        &__remindEvery {
            margin-right: 5px;
        }
    }
}