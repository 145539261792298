@import "src/styles/mixins/breakpoints";

.eventPage__wrapper {
  .marketTable__betContent {
    height: 100%;
  }

  .betContentContainer {
    .betContentCellMarket {
      border-left: 2px solid $white;
    }
  }

  .marketHeader__header {
    cursor: pointer;
    margin-top: 0;
  }

  .marketHeader__header__collapse {
    display: flex;
    align-items: center;
    font-weight: bold;
  }
}
