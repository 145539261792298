.mobileTodayCard {
  &__headerItems {
    display: flex;
    overflow-x: scroll;
    column-gap: 8px;
    row-gap: 6px;
    background-color: #303030;
    padding: 4px 9px;
    margin-bottom: 8px;

    &::-webkit-scrollbar {
      display: none;
    }

    &__container {
      display: grid;

      &__landscape {
        grid-template-columns: 180px 1fr;
      }
    }

    &__landscape {
      flex-direction: column;
      overflow-y: scroll;
      margin-bottom: 0;
    }

    &__tile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2px 6px;
      border-radius: 5px;
      border-width: 1px;
      border-style: solid;
      background-color: #3E3E3E;
      border-color: #3E3E3E;
      cursor: pointer;
      text-decoration: none;

      &__landscape {
        justify-content: flex-start;
        padding-left: 9px;
      }

      &__active {
        border-color: #FFFFFF;
      }

      &__time {
        font-size: 14px;
        font-weight: 500 !important;
        line-height: 1.5;
        text-align: center;
        margin: 0;
        width: 100%;

        &__landscape {
          text-align: left;
        }
      }

      &__countryFlag {
        width: 14px;
        height: 9.33px;
      }

      &__venue {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        justify-content: center;
        column-gap: 5px;
        align-items: center;

        &__landscape {
          justify-content: flex-start;
        }

        &__name {
          line-height: 1.5;
          font-size: 12px;
          white-space: nowrap;
        }
      }
    }
  }

  &__raceCardContainer {
    background-color: #f5f5f5;
  }

  &__landscapeSkeletonContainer {
    width: calc(100% - 40px);
    padding-inline: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding-top: 12px;
  }

  &__skeleton {
    flex: 1;
  }
}
