@import 'styles/variables/variables';

.footer {
  width: 100%;
  background-color: $black-light;
  text-align: center;
  color: $white;

  &__asianView {
    &__mobile {
      background-color: #F5F5F5 !important; // TODO remove !important once branding has separate selector for AV footer
    }
  }

  &__nav, &__icons {
    margin: 0;
    padding: 20px;
    font-size: $small;

    &__mobile {
      padding: 20px 0;
    }

    li {
      display: inline-block;
      vertical-align: top;
      font-size: $small;
      line-height: 14px;

      span {
        display: inline-block;
        vertical-align: middle;
        height: 10px;
        border-left: 1px solid $white;
        background-color: $white;
        margin: 0 9px 0 13px;
      }

      a {
        color: $white;
        font-size: $small;
      }
    }
  }

  &__content {
    &__mobile {
      padding: 0 20px;
    }

    p, ul {
      @extend .footer__nav;
    }
  }

  &__logo {
    width: 120px;
  }
}

