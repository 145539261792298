@import 'styles/variables/variables.scss';
@import 'src/styles/mixins/breakpoints';

.content {
  box-sizing: content-box;
  max-width: 1320px;
  margin: auto;
  padding: 40px 60px;

  @media (max-width: $md) {
    padding: 24px 16px;
  }
}

.guideContent {
  display: flex;
  flex-direction: column;
  gap: 70px;
  max-width: 800px;
  margin: auto;
}

.backLink {
  display: inline-flex;
  align-items: center;
  margin-left: -15px;
  gap: 5px;
  text-decoration: underline;
  color: $blue;

  &:hover {
    text-decoration: none;
  }

  i {
    color: currentColor;
  }
}

.viewAllSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 4px;

  h2 {
    margin: 0;
    line-height: 28px;
    font-size: 18px;
    font-weight: 700 !important;
  }

  button {
    padding: 8px 20px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
}

.contentSection {
  &__desktop {
    margin-top: -50px;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
}

.articlesList {
  padding-top: 20px;
}
