.container {
  display: flex;
  flex: 1;
}

.increaseBtn, .decreaseBtn {
  all: unset;
  box-sizing: border-box;
  width: 42px;
  height: 42px;
  border: 1px solid #c0c0c0;
  background-color: #ECECEC;
  text-align: center;

  &__focused {
    border-color: #3E3E3E;
  }

  i {
    font-size: 20px;

    &:before {
      color: #303030;
    }
  }
}

.decreaseBtn {
  border-radius: 4px 0 0 4px;
  border-right-width: 0;
}

.increaseBtn {
  border-radius: 0 4px 4px 0;
  border-left-width: 0;
}

.input {
  border: none;
  flex: 1;
  padding: 0;
  text-align: center;
  color: #303030;
  font-size: 14px;
  font-weight: 700 !important;
  line-height: 20px;

  &:focus-visible {
    outline: none;
  }

  &__wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #c0c0c0;
    border-bottom: 1px solid #c0c0c0;
    height: 42px;
    background-color: #fff;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    &__focused {
      border-color: #3E3E3E;
    }
  }

  &__label {
    color: #999;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
  }

  &__topMargin {
    margin-top: -1px;
  }
}

.input__wrapper, .decreaseBtn, .increaseBtn {
  &__warning {
    border-color: #FE3629;
  }
}
