@import 'styles/variables/variables';

.stake {
  &__container {
    display: flex;
    align-items: flex-start;
    padding-left: 20px;
    column-gap: 8px;

    &__asianView {
      padding-left: 0;
      column-gap: 5px;
      justify-content: space-between;

      &__edit {
        flex-direction: column;
      }

      &__mobile {
        padding-top: 8px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  &__inner {
    width: 100%;
    display: flex;
    gap: 5px;
    justify-content: space-between;

    &__asianViewMobile {
      width: auto;
      flex: 3;
      gap: 8px;
    }
  }

  &__actions {
    width: 100%;
    display: flex;
    column-gap: 8px;

    &__edit {
      margin-top: 5px;
    }

    &__noEditAsianViewMobile {
      width: auto;
      flex: 1;
    }

    &__editAsianViewMobile {
      margin-top: 15px;
    }
  }

  &__button {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    border-radius: 2px;
    line-height: 18px;
    padding: 0 10px;

    &:focus {
      border: solid 2px #FF8624;
    }

    &__mobile {
      border-radius: 0;
      line-height: 20px;

      &__asianView {
        flex: 1;
        font-size: 16px;
        line-height: 40px;
        color: #303030;
        height: 40px;
        border-radius: 4px;
        background-color: #c0c0c0;

        &__disabled {
          opacity: 0.5;
        }

        &__save {
          background-color: #FFB80C;
        }
      }
    }

    &__asianView {
      width: 100%;
      line-height: 20px;
      border-radius: 4px;
      order: 2;
    }

    &__cancel {
      order: 0;
    }
  }

  &__stakeWrapper {
    line-height: 18px;
    border-radius: 2px;

    &__asianViewMobile {
      flex: 1;
      border-radius: 4px;
    }
  }

  &__item {
    cursor: pointer;
    width: 66px;
    border-radius: 2px;
    text-align: center;
    vertical-align: middle;
    height: 18px;
    display: inline-block;
    line-height: 18px;

    &__mobile {
      height: 20px;
      line-height: 20px;
    }

    &__asianView {
      width: 50px;

      &__mobile {
        width: 100%;
        font-size: 16px;
        line-height: 40px;
        height: 40px;
        border-radius: 4px;
        box-sizing: border-box;

        &__noEdit {
          background-color: #dddddd;
        }

        &__active {
          color: #ffffff;
          background-color: #3E3E3E;
        }

        &__error {
          border: 1px solid #D70022 !important;
        }
      }
    }
  }

  &__stakeInput {
    width: 50px;
    height: 16px;
    border: 1px solid #dfdfdf;
    outline: none;
    cursor: text;
    line-height: 18px;
    border-radius: 2px;
    vertical-align: middle;
    padding: 0;
    text-align: center;

    &__mobile {
      height: 18px;
      line-height: 20px;
    }

    &__asianView {
      width: 100%;

      &__mobile {
        height: auto;
        font-size: 16px;
        line-height: 1.5;
        border-radius: 4px;
        padding: 7px 0;
        background-color: #f5f5f5;
        border-color: #c0c0c0;
      }

      &__couponSettings {
        &__focused {
          border-color: #303030 !important;
        }

        &__error {
          border: 1px solid #D70022 !important;
        }
      }
    }
  }

  &__error {
    color: red;
    width: 52px;
    font-size: $smaller;
    line-height: 13px;
    text-transform: uppercase !important;
    text-align: center;
    margin: 5px 0 0 0;
  }

  &__disable {
    cursor: not-allowed;
  }
}
