@import 'styles/variables/variables';

.emptyMatchStatistic {
  &__container {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: $black;
  }

  &__text {
    margin: 0;
    color: $white;
    font-size: 24px;
  }
}
