.wrap {
  display: flex;
  flex-direction: column;
  gap: 18px;
  font-size: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 26px;
}

.title {
  padding: 0;
  margin: 0;
  font-weight: bold !important;
}

.list {
  all: unset;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.item {
  all: unset;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  cursor: pointer;

  &__selected {
    font-weight: 700 !important;
  }

  &:focus {
    background-color: #F5F5F5;
  }
}

.icon {
  font-size: 24px;
}

.iconColor {
  &:before {
    color: inherit !important;
  }
}

.label {
  display: flex;
  gap: 4px;
  align-items: center;
}
