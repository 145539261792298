@import 'styles/variables/variables.scss';

.selectedMarketHeader {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 5px 5px 10px;
  min-height: 35px;
  font-size: 14px !important;
  line-height: 20px;
  background-color: $bg-dark;
  color: #fff;
  box-sizing: border-box;

  &__closed {
    &:hover {
      cursor: pointer;

      .closeBtn {
        background-color: $bg-dark-light;
      }
    }
  }
  &__disabled:hover {
    cursor: initial;

    .closeBtn {
      background: none;
    }

    .closeBtn:hover {
      background-color: $bg-dark-light;
    }
  }
}

.headerTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.eachWayLabel {
  font-size: 12px;
  color: #c0c0c0;
}

.liveLabel {
  display: flex;
  align-items: center;
  min-height: 20px;
  padding: 0 5px;
  font-size: 12px;
  font-weight: bold;
  background-color: #04a04a;
  color: #ffffff;
  border-radius: 2px;
}

.closeBtn {
  all: unset;
  margin-left: auto;
  border-radius: 50%;
  cursor: pointer;
  min-width: 25px;
  min-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
