@import 'src/styles/mixins/breakpoints';
@import 'src/styles/variables/variables';

.modal {
  display: flex;
  justify-content: center;

  &__dialog {
    overflow-x: hidden;
    overflow-y: auto;
    height: 70vh;
    position: fixed;
    z-index: 1000001;
    top: 100px;
    margin: 0 auto;
    box-sizing: border-box;

    &__mobile {
      min-height: calc(70vh - 130px);
      padding: 0 10px;
      left: 0;
      right: 0;
    }
  }

  &__content {
    border: 1px solid $border-black;
    border-radius: 4px;
    width: fit-content;
    margin: 0 auto;
    z-index: 999999;
    position: relative;
    box-sizing: border-box;

    @include min-xs {
      min-width: 350px;
      max-width: 750px;
    }

    @include min-md {
      min-width: 500px;
    }

    &__mobile {
      max-width: 100%;
      min-width: 100%;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 10px 15px;
    font-weight: bold;
    column-gap: 32px;
    position: relative;
    box-sizing: border-box;
    text-align: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    &__mobile {
      padding: 0 12px;
      min-height: 45px;
    }
  }

  &__headerNoTitle {
    justify-content: flex-end !important;
  }

  &__title {
    line-height: 20px;
    margin: 0;
    text-align: left;
  }

  &__subTitle {
    width: 100%;
    margin: 0;
    padding-bottom: 10px;
    font-size: 12px;
    font-weight: 400;
  }

  &__closeIcon {
    color: #999999;
    cursor: pointer;
    font-size: 12px;
    min-width: 16px;
  }

  &__body {
    padding: 15px;
    background-color: $white;
    position: relative;
    line-height: 20px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    &__noTitle {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }

  &__backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $black;
    opacity: 0.5;
    z-index: 1000000;
  }
}
