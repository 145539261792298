.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: 5px 15px 5px 10px;
  margin-top: 10px;

  &__back {
    background-color: #A6D8FF;
  }

  &__lay {
    background-color: #FAC9D1;
  }

  &__disabled {
    background-color: #ececec;
  }

  &__right {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }
}

.persistenceRadio {
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;

  input {
    margin: 0;
    cursor: pointer;
  }

  &__disabled {
    cursor: initial;

    input {
      cursor: initial;
    }
  }
}

.atInPlay {
  font-size: 12px;
  line-height: 20px;
  margin: 0;
}
