@import '../../../../styles/variables/variables';
@import '../../../../styles/mixins/breakpoints';

.betIndicatorWrapper {
  float: right;
  line-height: 10px;
  padding-right: 5px;

  &__matchedBets,
  &__unmatchedBets {
    min-width: 20px;
    display: inline-block;
    text-align: center;
    color: #fff !important;
    text-decoration: none;
    font-size: 11px;
    padding: 2px;
    border-radius: 2px;
  }
  &__matchedBets {
    background-color: #01a04a;
  }
  &__unmatchedBets {
    background-color: #d70022;
  }

  .placementLabel {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: fit-content;
    font-weight: 700;
    padding: 0 10px;
    border-radius: 2px;
    line-height: 14px;
    font-size: 10px;
    margin: 0;
    text-decoration: none;
  }
  .tooltip {
    position: relative;
    border-radius: 2px;
    margin: 2px;
    .tooltipHolder {
      position: absolute;
      left: 50%;
      bottom: 100%;
      margin-bottom: 8px;
      z-index: 9999;
      opacity: 0;
      .tooltipContent {
        position: relative;
        left: -50%;
        display: table;
        width: auto;
        cursor: default;
        pointer-events: none;
        border-radius: 3px;
        text-align: center;
        white-space: nowrap;
        &::after {
          position: absolute;
          content: '';
          top: 100%;
          left: 50%;
          height: 0;
          width: 0;
          border: 6px solid;
          pointer-events: none;
          margin-left: -6px;
          border-left-color: transparent !important;
          border-right-color: transparent !important;
          border-bottom-color: transparent !important;
          border-top-color: inherit;
        }
        .tooltipString {
          display: block;
          font-weight: normal;
          font-size: 11px;
          line-height: 11px;
          padding: 3px 8px;
          color: #fff;
        }
      }
    }

    &:hover {
      .tooltipHolder{
        opacity: 1;
      }
    }
  }
}
