.search {
  display: flex;
  align-items: center;
  width: auto;
  border-right: 1px solid #303030;
  padding-left: 10px;
  height: 30px;
  white-space: nowrap;
  &__wrapper {
    display: flex;
    align-items: center;
    &__mobile {
      flex: 1;
      color: #ffffff;
      font-size: 14px;
    }
  }
  &:not(.expandedMobileView) {
    &:hover {
      background-color: #666666;
    }
  }
  input {
    background-color: transparent;
    border: none;
    font-size: inherit;
    max-width: 46px;
    color: inherit;
    padding-left: 10px;
    &::placeholder {
      color: inherit;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      color: inherit;
    }

    &::-ms-input-placeholder {
      color: inherit;
    }
    &:active,
    &:focus-visible {
      border: none;
      outline: none;
    }
  }
  .searchIcon {
    line-height: 1;
    position: absolute;
    font-size: 12px;
  }
  &.expanded {
    max-width: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    background-color: #666666;
    padding-left: 0;
    z-index: 10;
    &:not(.expandedMobileView) {
      border-right: none;
    }
    i {
      left: 10px;
    }
    input {
      max-width: 100%;
      width: 100%;
    }
  }
  .actions {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    z-index: 11;
    padding-right: 14px;
    .closeIcon {
      font-size: 12px;
      cursor: pointer;
    }
    .clear {
      cursor: pointer;
      white-space: nowrap;
    }
  }
}

.mobileView {
  min-width: 42px;
  height: auto;
  max-height: 42px;
  border-radius: 4px;
  border: 1px solid #666666;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  width: auto;
  box-sizing: border-box;
  i {
    position: relative !important;
    &::before {
      font-size: 16px;
    }
  }
  & > span {
    margin-left: 10px;
  }
  input {
    width: 0;
    padding: 0;
    font-size: 14px;
    line-height: 1.5;

    &::placeholder {
      color: #999999;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      color: #999999;
    }

    &::-ms-input-placeholder {
      color: #999999;
    }
  }
}

.expandedMobileView {
  height: auto;
  max-height: 42px;
  position: initial !important;
  .actions {
    padding: 0 !important;
    justify-content: space-between !important;
  }
  input {
    padding-left: 18px !important;
  }
}

.expandedMobileEmpty {
  .actions {
    justify-content: flex-end !important;
  }
}

i.closeIcon__mobile {
  position: initial !important;
  color: #ffffff;
  padding: 0 14px;
  cursor: pointer;

  &:before {
    font-size: 16px;
  }
}

.input__hidden {
  width: 0;
  position: absolute;
}
.input__withIcon {
  padding-left: 27px !important;
}

.placeholder {
  margin-right: 10px;
  white-space: nowrap;

  &__withIcon {
    margin-left: 17px;
  }
}
