.container {
  position: relative;
  min-height: 63px;
  .statusMessage {
    padding: 10px 30px 10px 10px;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .close {
    display: flex;
    margin-right: -20px;
    cursor: pointer;
    color: #ffffff;
  }

  a {
    color: inherit !important;
  }
}
