.btn {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  min-height: 48px;
  border-radius: 4px;
  border: none !important;
  outline: none;
  font-size: 16px;
  line-height: 1.5;
  color: #303030;
  background-color: #C0C0C0;

  &__landscape {
    min-height: 42px;
  }
}
