.contentWrapWithoutScroll {
  display: flex;
  flex-direction: column;
  overflow: auto;
  justify-content: space-between;

  &__mobile {
    overflow: initial;
  }

  .scrollableContentWrapper {
    max-width: 1320px;
    margin: 0 auto;
    padding: 0 60px;
    width: calc(100% - 120px);

    &__mobile {
      max-width: initial;
      padding: 24px 16px 48px;
      margin: 0;
      width: calc(100% - 32px);
    }
  }

  &__content {
    flex: 1 0 auto;
  }

  &__footer {
    flex-shrink: 0;
  }
}
