.contentContainer {
  text-align: center;
  margin: 10px 0;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  &__col {
    padding: 4px 2px;
    border: solid 1px #e6e6e6;
    vertical-align: middle;
    border-left: solid 1px #fff;
  }

  &__title {
    padding: 4px 2px;
    border: solid 1px #e6e6e6;
    text-align: left;
    border-left: solid 1px #fff;
  }

  &__colBackground {
    padding: 4px 2px;
    border: solid 1px #e6e6e6;
    vertical-align: middle;
    border-left: solid 1px #fff;
    background-color: #f0f0f0;
  }
}
