.showMore {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  font-size: 12px;

  &__wrapper {
    padding: 10px 15px;
  }
}
