.arrow {
  all: unset;
  overflow: hidden;
  position: absolute;
  width: 14px;
  height: 14px;
  padding: 0;
  line-height: 8px;
  text-align: center;
  right: 0;
  color: #303030;
  cursor: pointer;
  font-size: 4.5px;

  &:hover {
    background-color: #ececec;
  }

  &__up {
    top: 1px;
    right: 1px;
    border-top-right-radius: 3px;

    i {
      margin-left: 1px;
    }
  }

  &__down {
    bottom: 1px;
    right: 1px;
    border-bottom-right-radius: 3px;

    i {
      margin-left: 1px;
      transform: rotate(180deg);
    }
  }

  &__disabled {
    color: #8e9ba2;
    cursor: initial;

    &:hover {
      background-color: initial;
    }
  }

  i {
    line-height: 12px;
  }
}
