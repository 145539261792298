.event {
  display: flex;
  border-bottom: solid 1px #c0c0c0;
  background-color: #fff;

  &__inPlay {
    background-color: #cbecd8;
  }

  > div {
    box-sizing: border-box;
  }

  &__selections {
    flex-grow: 1;
    position: relative;
    &__expand {
      position: absolute;
      right: 8px;
      bottom: 5px;
      background-color: #0072AC;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      line-height: 16px;
      font-size: 10px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      padding: 0;
      border: none;
      outline: none;
      i {
        line-height: 16px;
      }
    }
  }

  &__rightSection {
    display: flex;
    justify-content: space-between;
  }

  &__actionsWrapper {
    display: flex;
    column-gap: 10px;
    padding: 5px 8px;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  &__cashout {
    column-gap: 0 !important;
  }

  &__cashoutIcon {
    cursor: pointer !important;
  }

  &__scoreWrapper {
    width: 60px;
    border-right: solid 1px #c0c0c0;
    text-align: center;
    display: flex;
    & > div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
    }
  }
  &__col {
    width: 103px;
    border-left: solid 1px #c0c0c0;
  }
  &__market {
    position: relative;
    &__big {
      width: 130px;
    }
    &__twoSelections {
      width: 215px;
    }
  }
  &__streaming {
    width: 50px;
    text-align: center;
    position: relative;
    button {
      display: inline-block;
      margin: 5px 5px 0 5px;
    }
  }
  &__moreMarketsWrapper {
    position: absolute;
    bottom: 5px;
    width: 100%;
    text-align: center;
  }
  &__moreMarkets {
    display: inline-block;
    border-radius: 2px;
    background-color: #0072AC;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    height: 15px;
    color: #fff;
    min-width: 35px;
    cursor: pointer;
    &:hover,
    &__opened {
      background-color: #006091;
    }
    i {
      font-size: 5px;
      vertical-align: top;
      margin-left: 4px;
    }
  }
}
