@import "styles/variables/variables";

.racing {
  &__headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
  }

  &__headerTitle {
    font-weight: bold;
    margin-right: 5px;
  }

  &__periodsBtnContainer {
    display: flex;
  }

  &__headerBtn {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #bfbfbf;
    color: $black-light;
    margin-left: 3px;
    border: 0;
    border-radius: 2px;
    font-weight: bold;
    font-size: 11px;
    box-sizing: border-box;
    padding: 8px 16px;
    transition: .1s;

    &:hover {
      background-color: #dfdfdf;
    }
  }

  &__typeWrapper {
    display: flex;
    flex-direction: row;
  }

  &__favouriteIcon {
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 20px;
  }

  &_active {
    background-color: $white;
  }
}

