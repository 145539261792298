@import 'styles/variables/variables';
@import 'styles/mixins/breakpoints';

.eventHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 30px;
  box-sizing: content-box;

  &__name {
    display: flex;
    justify-content: flex-end;
    text-align: end;

    a,
    span {
      line-height: 21px;
      color: inherit !important;
      text-decoration: none;
    }
  }

  &__highlightTeam {
    display: flex;
    justify-content: flex-end;
    margin-left: 3px;
    width: 12px;

    i {
      margin-top: -1px;
    }

    @include md {
      margin: 0 2px;

      i {
        margin-top: 0;
      }
    }
  }

  &__leftSide {
    display: flex;
    justify-content: flex-start;
    flex: 1;

    @include md {
      flex: unset;
      max-width: 50px;
      width: auto;
    }

    i:not(.eventHeader__leftFavorite i) {
      margin: 10px 8px 10px 10px;
    }

    &__wrapper {
      display: flex;
      justify-content: flex-start;
    }
  }

  &__rightSide {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 12px;
    flex: 1;

    @include md {
      flex: unset;
      padding-right: 0;
    }

    &__rightBlock {
      display: flex;
      align-items: center;
      column-gap: 15px;
      margin-right: 12px;
      margin-left: 12px;
      flex-wrap: wrap-reverse;
      row-gap: 5px;
      justify-content: flex-end;
    }

    &__widgets {
      display: flex;
      align-items: center;
      column-gap: 15px;
      row-gap: 5px;
      flex-wrap: wrap;
      justify-content: flex-end;
      flex-direction: row;
      &__vertical {
        flex-direction: column;
      }
    }
  }

  &__centerContentWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;
    flex: auto;
    flex-direction: row;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include md {
    }
  }

  &__nameContainer {
    display: flex;
    font-size: 18px;
    margin: 0 5px;
    padding-top: 12px;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    @include md {
      margin: 0 3px;
    }
  }

  &__scope {
    display: flex;
    font-size: $middle;
    margin: 0 5px;
    width: 95px;
    padding-top: 12px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    min-width: 84px;

    @include md {
      margin: 0 3px;
      width: 70px;
    }
  }

  &__points {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    background: #3e3e3e;
    border: 1px solid #444444;
    border-radius: 3px;
    font-size: 14px;
    min-width: 83px;
    white-space: nowrap;
  }

  &__setPoint {
    line-height: 21px;
  }

  &__favouriteIcon {
    &__right {
      position: initial !important;
      transform: none !important;
    }
  }

  &__leftFavorite {
    display: flex;
    align-items: center;
    margin: 0 0 0 12px;
    @include md {
      margin: 0 6px 8px 6px;
    }
  }

  &__inning {
    color: #9d9d9d;
    font-size: $smaller;
    margin-top: 5px;
  }

  &__days {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
  }

  &__inningsWrapper {
    display: flex;
  }
}

.tabs {
  display: flex;

  > * {
    flex: 1;
  }
}

.biab_winner {
  color: #999999;
}

.biab_loser {
  color: #ffffff;
}

.marketDropdown {
  margin-top: -10px;
}
