@import "src/styles/mixins/breakpoints";

.top5View {
  &__marketName {
    display: flex;
    align-items: center;
    margin: 0 8px 8px;
    border-bottom: 1px solid #f2f5f5;
    font-weight: bold;
    padding: 8px 0 4px;
  }

  &__favouriteIcon {
    margin-right: 8px;
    font-size: 15px;
  }

  &__otherMarkets {
    padding-top: 10px;

    &__mobile {
      padding-top: 0;
    }

    &__sections {
      &__item__2columns {
        width: calc(50% - 10px);
      }

      &__2columns {
        display: flex;
        flex-wrap: wrap;
        padding: 8px 10px 10px;
        column-gap: 20px;
      }

      &__mobile {
        display: flex;
        flex-direction: column;
        row-gap: 1px;
      }
    }

    &__market {
      margin: 0;
      padding-top: 10px;

      &__mobile {
        padding-top: 0;
      }
    }

    &__viewMarket {
      margin: 0;
      padding: 4px 0;
    }

    &__collapseHeader {
      cursor: pointer;
      font-weight: 700 !important;

      &__mobile {
        margin-top: 0 !important;
      }
    }

    &__marketTableHeader {
      @include md {
        padding-right: 5px;
      }
    }
  }

  &__noMarkets {
    margin: 0;
    padding: 25px 15px 35px;
    text-align: center;
  }

  &__wrapper {
    flex: 1 1 auto;
  }

  &__paddingRight {
    @include md {
      padding-right: 5px;
    }
  }
}
