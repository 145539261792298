.placeholder {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 5px;
  background-color: #ECECEC;
  border: 1px solid #ECECEC;
  border-radius: 3px;
  font-size: 10px;
  font-style: normal;
  line-height: 15px;
  color: #3E3E3E;
  box-sizing: border-box;

  &::after {
    top: 50%;
  }
}

.placeholder:hover,
.activePlaceholder {
  border-color: #DDDDDD;
  background-color: #DDDDDD;
}

input {
  margin: 0;
}

.label {
  cursor: pointer;

  input, span {
    margin: 0;
    vertical-align: middle;
    cursor: pointer;
  }
}

.infoIcon {
  font-size: 14px;
  cursor: pointer;
}

.modal {
  &__dialog {
    z-index: 1000000;
  }
}
