@import 'styles/variables/variables';

.headerNavigationItem {
  font-size: $small;
  white-space: nowrap;

  .rightIcon {
    display: inline;
  }

  > * {
    display: flex;
    align-items: center;
    color: #f6f6f6;
    line-height: 30px;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &.active {
    background-color: #343434;
  }

  &__icon {
    margin-left: 6px;
    font-size: 6px;
    vertical-align: middle;
    position: relative;
  }
}

.title {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  padding: 0 10px;
  cursor: pointer;
  color: #f6f6f6;
  line-height: 30px;
  white-space: nowrap;
  border-left-width: 1px;
  border-left-style: solid;

  i {
    color: inherit !important;
  }

  i:first-of-type {
    margin: 0 5px;
  }
}

.submenu {
  position: absolute;
  z-index: 111;
  width: 200px;
  border-radius: 4px;
  background-color: #fff;
  min-width: 100%;
  right: 0;
  top: 0;
  padding: 5px 0;
  margin-top: 4px;
  list-style: none;
  border: 1px solid #ddd;
  box-shadow: 0 2px 8px rgba(#181e5c, 15%);

  &__noSettings {
    right: 10px;
  }

  div {
    a {
      display: flex;
      justify-content: flex-start;
      padding: 0 10px;
      text-decoration: none;
      white-space: nowrap;
      color: #303030;
    }
  }
}

.itemContainer {
  position: relative;
}

.logout {
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    background-color: #ececec;
  }

  i {
    color: inherit;
  }
}
