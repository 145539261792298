.competitionList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #C0C0C0;
  border-top: 1px solid #C0C0C0;
  min-height: 40px;
  background: #FFFFFF;

  &__inPlayCell {
    width: 60px;
    min-width: 60px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-right: solid 1px #c0c0c0;
    box-sizing: border-box;
  }

  &__firstSection {
    display: flex;
    align-items: center;
    gap: 10px
  }

  &__inPlay {
    background-color: #cbecd8;
  }

  &__statistics {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-right: 10px;
  }

  &__marketWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 6px;
    margin: 4px 6px 10px;
  }

  &__marketCashout {
    flex: 1 1;
    max-width: 50%;
    height: fit-content;
    border-radius: 5px;
    background-color: #FFF;
    border: 1px solid #C0C0C0;

    &__withOutBorder {
      border: none;
      background-color: inherit;
    }
  }

  &__marketTitle {
    display: flex;
    align-items: center;
    height: 30px;
    padding-left: 15px;
    background-color: #ECECEC;
    border-bottom: 1px solid #C0C0C0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    gap: 6px
  }

  &__name {
    margin: 0;
    font-weight: bold;
  }

  &__outrights {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
    padding: 0 15px;
  }
}

.containerEmpty {
  padding: 10px 6px;
  width: calc(100% - 16px);
}
