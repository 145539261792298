@import 'styles/variables/variables';

.mobileEvent {
  margin: 6px 6px 0 6px;
  border: solid 1px #c0c0c0;
  border-radius: 4px;
  background: #fff;
  &__header {
    display: flex;
    border-top: solid 4px #3e3e3e;
    border-radius: 3px 3px 0 0;
    &__icons {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row-reverse;
      height: 100%;
      box-sizing: border-box;
      float: right;
      padding: 0 11px;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }
  &__lastItemMargin:last-child {
    margin-bottom: 16px;
  }
  &__selections {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__names {
      font-size: inherit;
      font-weight: bold !important;
      padding: 14px;
      line-height: 21px;
      .active {
        color: #d70022;
      }
    }
  }
  &__headers {
    display: flex;
    background-color: #ececec;

    & > div {
      height: 42px;
      text-align: center;
      line-height: 42px;
      font-size: 12px;
      font-style: normal;
      border-right: solid 1px #c0c0c0;
    }
    & > div:last-child {
      border-right: none;
    }
    &__fullTime {
      border-top: solid 1px #c0c0c0;
      flex: 3;
    }
    &__firstHalf {
      border-top: solid 1px #c0c0c0;
      flex: 2;
    }
    &__noTab {
      font-weight: bold !important;
    }
    &__tab {
      background-color: #dddddd;
      font-size: 12px;
      font-style: normal;
      font-weight: normal !important;
      cursor: pointer;
      box-sizing: border-box;
      flex: 1;
      &__active {
        font-weight: bold !important;
        background-color: #f5f5f5;
        border-top: solid 2px #ffb80c;
        border-right: none !important;
      }
      &__leftBorder {
        border-left: solid 1px #c0c0c0;
      }
    }
  }
  &__markets {
    &__columns {
      display: flex;
      & > div {
        flex: 1;
        flex-shrink: 0;
        background-color: #f5f5f5;
        text-align: center;
        border-top: solid 1px #c0c0c0;
        border-bottom: solid 1px #c0c0c0;
        border-right: solid 1px #c0c0c0;
        height: 38px;
        line-height: 38px;
        width: 0;
        font-weight: bold !important;
      }
      & > div:last-child {
        border-right: none;
      }
    }
  }
  &__inPlay &__header,
  &__startingSoon &__header {
    border-top: solid 4px #04a04a;
  }
  &__inPlay {
    background-color: #cbecd8;
  }
  &__bottom {
    padding: 16px 12px 0;
    min-height: 48px;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__streaming {
    display: flex;
    gap: 10px;
    align-items: center;
    min-width: 84px;

    &__button {
      width: 40px;
      display: flex;
      justify-content: center;
      background-color: #0072AC;
      border-radius: 4px;
      font-size: 10px;
      color: #FFFFFF;
    }

    &__tooltip {
      display: flex;
    }

    &__bg {
      background-color: #0072AC;

      &::before {
        color: #0072AC;
      }
    }

    &__bgActive {
      background-color: #3E3E3E;

      &::before {
        color: #3E3E3E;
      }
    }

    &__button,
    &__icon {
      vertical-align: middle;
    }
  }
  &__statistics__button {
    font-size: 12px;
  }
  &__expand {
    line-height: 32px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border: none;
    background: none;

    i {
      margin-left: 6px;
      line-height: 25px;
      display: inline-block;
      height: 32px;
      vertical-align: middle;
    }
  }
  &__moreMarketsWrapper {
    display: flex;
    width: 84px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    height: 32px;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
    border-radius: 4px;
    color: #fff;
    background-color: #0072ac;
    box-sizing: border-box;
    i {
      margin-left: 6px;
      font-size: 6px;
      vertical-align: middle;
    }

    &__compact {
      width: 62px;
    }
  }
}

.score {
  min-height: auto;
}

.widgetHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  padding: 10px;
  background-color: #3E3E3E;
  color: #FFFFFF;
  box-sizing: border-box;
  font-size: 16px;
  border: 1px solid;

  &__icon {
    font-size: 16px;
    cursor: pointer;;
  }
}

.modal {
  &__header, &__body {
    border: none;
  }

  &__content {
    min-width: auto;

    &__landscape {
      width: $asian-view-pop-up-max-width;
    }
  }

  &__title {
    color: #303030;
  }
}

.eventSelectionsWrapper {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}