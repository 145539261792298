.container {
  width: 48%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px dashed #c0c0c0;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;

  &__mobile {
    width: 100%;
  }
}

.title {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #666;
  margin-right: 5px;
}

.value {
  font-size: 12px;
  font-weight: 700 !important;
  line-height: 20px;
  color: #303030;
}