.wrap {
  display: flex;
  flex-direction: column;
  gap: 18px;
  font-size: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 26px;
}

.title {
  padding: 0;
  margin: 0;
  font-weight: bold !important;
}

.selectAllToggle {
  all: unset;
  cursor: pointer;
  color: #0072AC;
}

.list {
  all: unset;
  display: flex;
  flex-direction: column;
  gap: 5px;

  li {
    all: unset;
    height: 56px;
    display: flex;
    font-size: 16px;
  }
}

.checkboxRow {
  flex-direction: row-reverse;
  justify-content: space-between;
  flex: 1;

  &:focus {
    background-color: #F5F5F5;
  }
}
