.totalLiability {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: 20px;

  &__label {
    flex: 1 1 auto;
  }

  &__tooltip {
    justify-content: flex-start;
  }
}
