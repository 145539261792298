.betslipWrap {
  margin-bottom: 1px;
}

.tabs {
  display: flex;
  background-color: #e3e8e8;
}

.tab {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
  flex: 1;
  padding: 7px 20px 9px;
  line-height: 20px;
  font-size: 12px;
  text-align: center;
  color: #303030;
  background-color: #e3e8e8;
  border-top: 2px solid transparent;
  cursor: pointer;

  &__active {
    background-color: #ffffff;
    border-color: #ffb80c;
    font-weight: 700;
  }

  &__counter {
    height: 15px;
    min-width: 15px;
    background-color: #c0c0c0;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 12px;
    font-weight: 700;
    color: #303030;
    padding: 0 4px;
    line-height: 1;
    box-sizing: border-box;

    &__active {
      background-color: #ffb80c;
    }

    &__badge {
      width: 5px;
      height: 5px;
      position: absolute;
      top: -2px;
      right: -1px;
      background-color: #fe3629;
      border-radius: 50%;
    }
  }
}

.hidden {
  visibility: hidden;
  height: 0;
}

.panel {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background-color: #f5f5f5;
}

.settings {
  border-color: inherit;
}
