.input {
  width: 60px;
  height: 30px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
  padding: 0;
  text-align: center;
  box-sizing: border-box;
  outline: none;

  &__highlighted {
    border-color: #3e3e3e !important;
  }

  &:focus,
  &:active,
  &:hover {
    border-color: #3e3e3e;
  }

  &:read-only {
    border-color: #ffffff;

    &:hover + label {
      color: #999999;
    }
  }

  &:hover + label {
    cursor: pointer;
  }

  &:not(:read-only):focus + label,
  &:not(:placeholder-shown) + label,
  &__filled + label {
    top: 0;
    left: 0;
    font-size: 9px;
    transform: translate(5px, 0);
  }

  &:hover + label {
    color: #303030;
  }

  &:hover:read-only + label {
    cursor: initial;
  }

  &__size {
    width: 62.5px;
  }

  &__error {
    border-color: #fe3629;
  }

  &__errorLabel {
    position: absolute;
    display: block;
    width: 100%;
    padding: 2px 5px;
    background: #fe3629;
    color: #ffffff;
    font-size: 10px;
    text-align: center;
    box-sizing: border-box;
    z-index: 1;
  }

  &__price {
    padding: 0 15px 0 5px;
    font-weight: 700;
  }

  &__disabled {
    padding: 0;
    background: transparent;
    border: 0;
    text-align: center;
  }

  &__label {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #999999;
    transition: 300ms all;
    transform: translate(-50%, -50%);
    z-index: 20;
  }
}
