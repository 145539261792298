.main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #eee;

  .link {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    i {
      margin-left: 5px;
    }
  }
}
