.text {
  margin: 0;
}
.moreInfo {
  color: #0072AC;

  a {
    color: #0072AC;
  }
}

.bold {
  font-weight: 700 !important;
}