.raceHeaderInfo {
  position: sticky;
  top: 0;
  z-index: 100;
  border-radius: 8px 8px 0 0;
  padding: 8px;
  background-color: #303030;
  color: #FFFFFF;

  &__swipeMenu {
    top: 57px;
  }

  &__backButon {
    padding-left: 12.5px;
    padding-right: 0;
  }

  &__title {
    margin: 0 0 6px 0;
    font-weight: 700 !important;
  }

  &__time {
    margin: 0;
  }

  &__content {
    display: flex;
    align-items: center;
    height: 34px;
    column-gap: 8px;
  }
}
