.main {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 15px 10px;

  .left, .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    &:not(.disabled) {
      span {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .dots {
    div {
      display: inline-block;
      width: 4px;
      height: 4px;
      margin: 0 2px;
      border-radius: 4px;
      background-color: #dfdfdf;
      cursor: pointer;
      &.active {
        background-color: #303030;
      }
    }
  }
}
