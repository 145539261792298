@import "../../../../styles/variables/variables";
@import "../../../../styles/mixins/breakpoints";

.marketSelections {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px 0 0;
  box-sizing: border-box;

  @include md {
    flex-direction: column;
    width: 100%;
  }

  &__closeBlock {
    min-height: 18px;
    flex-direction: column;
    align-items: start;
  }

  &__closed {
    // minus 10px (padding)
    width: calc(100% - 10px);
    padding: 2px 0 2px 10px;
    margin: 5px 0;
    white-space: nowrap;
    vertical-align: middle;
    background: #fff;
    font-weight: normal;
  }

  &__depthDisabled {
    padding-right: 6.5px;
  }

  &__matchedRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    &__matchedAmount {
      color: #7f7f7f;
      font-size: 11px;
      white-space: nowrap;
      padding-left: 10px;
    }
  }
}
