.inlineBetForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 305px;

  &__fullWidth {
    width: 100%;
  }
}

.form {
  gap: 10px;
}

.price {
  width: 90px;
  padding: 0;

  &:disabled, &:read-only {
    background-color: #FFFFFF;
  }
}

.size {
  width: 100%;
}

.profit {
  width: 100%;
}

.priceWrap {
  max-width: none;
}

.betPriceWrap {
  position: relative;
  flex: none;
}

.size, .profit {
  padding: 10px 5px 2px 5px;
  line-height: 30px;
  text-align: right;
}

.quickBets {
  margin: 0;
}

.priceIndicator {
  position: absolute;
  left: 5px;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.pncIndicator {
  position: absolute;
  left: 5px;
  z-index: 1;
}
