@import 'styles/variables/variables';
@import 'styles/mixins/breakpoints';

.search {
  &__gameContainerTitle {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 2px 0 2px 10px;
  }

  &__gameNumberContainer {
    width: 20px;
    text-align: center;
  }

  &__numberContainer {
    width: 20px;
  }

  &__gameNumber {
    font-weight: 400;
    font-size: 11px;
    color: #273a47;
    text-align: center;
  }

  &__gameImg {
    max-height: 25px;
    max-width: 29px;
    margin: 0 7px 0 18px;
  }

  &__gameRunnerName {
    font-weight: 700 !important;
    font-size: 11px;
    color: #273a47;
    overflow: hidden;
    text-overflow: ellipsis;
    @include xxl {
      white-space: nowrap;
      display: inline-block;
      max-width: calc(100vw - 238px);
    }

    &__tooltip {
      display: flex;
      padding-left: 0 !important;
      justify-content: flex-start;
    }
  }

  &__gameJockeyName {
    font-size: 11px;
    color: #273a47;
    font-weight: 400 !important;
    @include xxs {
      white-space: nowrap;
      display: inline-block;
      max-width: calc(100vw - 236px);
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.matchedTitle {
  display: flex;
  align-items: center;
  flex: auto;
  height: 100%;
  font-size: 11px;
  color: #1e1e1e;

  @include md {
    font-size: 13px;
    font-weight: 700 !important;
  }
}

.matchedTitleGame {
  display: block;
  padding-left: 15px;
}

.matchedTitleRunner {
  font-weight: 700;
  margin-left: 10px;
}
