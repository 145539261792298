@import "/src/styles/variables/variables";
@import "/src/styles/mixins/breakpoints";

.marketTitleRow {
  &__name {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: $small;
    margin: 8px;
    //padding-bottom: 8px;
    color: #273a47;
    font-weight: 700;
    border-bottom: 1px solid #f2f5f5;
  }

  &__dropdown {
    max-height: 250px;
    overflow: auto;
  }

  &__dropdownIcon {
    font-size: 20px !important;
  }

  &__wrapper {
    margin-right: 5px;
    font-size: 15px;
  }
}
