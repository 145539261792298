.competitionMobile {
  display: flex;
  background-color: #DDD;
  color: #3e3e3e;
  align-items: center;
  min-height: 40px;
  padding: 7px 18px 7px 12px;

  &__expand {
    cursor: pointer;
    margin-right: 8px;
  }

  &__actions {
    width: 60px;
    display: flex;
    white-space: nowrap;
    column-gap: 14px;
    box-sizing: border-box;
    min-width: 60px;
    align-items: center;
    justify-content: center;

    .competition__expand {
      line-height: 30px;
      color: #3e3e3e !important;
      font-size: 15px !important;
      &:before {
        vertical-align: top;
      }
    }
  }

  &__actionsTab {
    width: auto;
    min-width: auto;
    display: flex;
    vertical-align: top;
    white-space: nowrap;
    column-gap: 14px;
    box-sizing: border-box;
  }

  &__openTab {
    border-bottom: solid 1px #c0c0c0;
  }

  &__name {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    word-break: break-word;
    width: 100%;
    line-height: 140%;
    box-sizing: border-box;
    gap: 8px;

    display: flex;
    flex-direction: column;

    &__wrapper {
      position: relative;
      width: 100%;
    }
  }

  &__outRight {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 8px;
    gap: 8px;
  }
}

.cashoutIcon {
  &:hover {
    cursor: pointer !important;
  }
}
