@import "/src/styles/mixins/breakpoints";

.gameBoxItem {
  width: 33%;
  @include min-sm740 {
    width: 25%;
  }
  display: inline-block;
  margin: 0;
  padding: 10px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  vertical-align: top;

  .gameTitle {
    position: relative;
    width: 14em;
    height: 20em;
    padding: 10px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 12%), 0 2px 4px 0 rgb(0 0 0 / 8%);
    vertical-align: top;
    z-index: 1;

    > span {
      line-height: 1;
      vertical-align: middle;
    }

    > div {
      font-weight: bold;
      text-transform: uppercase;
      height: 2em;
    }

    .image {
      display: block;
      margin: 8px auto 15px;
      width: 132px;
      height: 88px;
      background-image: url('../../assets/images/xgames-sprite.png');
      background-repeat: no-repeat;
    }

    .gameBtn {
      display: block;
      width: 132px;
      margin: 5px auto 0;
      padding: 10px 0;
      border-radius: 4px;
      font-weight: bold;
      cursor: pointer;
      text-decoration: none;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #fff;
      opacity: 0.8;
      width: 100%;
      height: 100%;
      border-radius: 2px;
    }
  }
}

.gamesContainer {
  width: 1000px;
  margin: 0 auto;
  @include md {
    width: 100%;
  }
}
