.rightNav {
  border-left: 1px solid #dfdfdf;
  overflow: auto;
  flex: 1 0 25%;
  min-width: 350px;
  scrollbar-width: none;
  max-width: 25%;

  &:hover {
    .hoveredIconClass {
      background: #666666;
    }
  }

  &__collapsed {
    background: #3e3e3e;
    min-width: 40px;
    max-width: 40px;
    cursor: pointer;
  }
}

.noBetslip {
  display: none;
}
