.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 2px;
  white-space: nowrap;

  &__side {
    margin: 0;
    font-size: 12px;
    line-height: 1.5;
    color: #666666;
  }

  &__priceAndOddsType {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    color: #303030;
    font-weight: 600 !important;
  }
}