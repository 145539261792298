@import "../../styles/mixins/breakpoints";

.header {
  padding: 11px 9px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include md {
    padding: 0 10px;
    min-height: 27px;
  }

  .button {
    padding: 0 15px;
    font-size: 11px;
    border-radius: 2px;
    display: inline-block;
    line-height: 30px;
    font-weight: bold;
    min-width: 40px;
    height: 30px;
    text-align: center;
    cursor: pointer;
    margin-left: 5px;

    @include md {
      height: 20px;
      line-height: 20px;
    }
  }
}
