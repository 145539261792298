.view {
  &__header {
    padding: 9px 16px;
    background-color: #f5f5f5;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__label {
      font-weight: 700 !important;
      font-size: 16px;
      line-height: 1.5;
      margin: 0;
    }

    &__btn {
      width: 42px;
      height: 42px;
      border-radius: 4px;
      border: 1px solid #DDDDDD;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &__active {
        border-color: #0072AC;
      }

      i {
        font-size: 24px;
      }
    }
  }

  &__icon {
    font-size: 24px;
    color: #303030;
    font-weight: 400 !important;

    &__disabled {
      color: #c0c0c0;
    }

    &__active {
      color: #0072AC;
    }
  }

  &__option {
    all: unset;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #DDDDDD;
    padding: 15px 0 14px;
    font-size: 16px;
    line-height: 1.5;
    text-transform: capitalize !important;
    flex: 1;

    &__notSelected {
      &:hover {
        background-color: #dddddd;
      }
    }

    &__selected {
      font-weight: 700 !important;
    }

    &__left {
      display: flex;
      column-gap: 9px;
      align-items: center;
    }

    &__container {
      background-color: #fff;
      padding: 24px 16px 124px;
      display: flex;
      flex-direction: column;
    }
  }
}
