@import "../../styles/variables/variables";

.overlay {
  background: $white;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
