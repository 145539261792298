@import "./sportMaintenance.scss";
@import "./fonts.scss";

.promotionBanner {
  width: 100vw;
  max-width: 100%;
  padding: 0;
  grid-gap: 1.25rem;
  color: #fff;
  display: grid;
  gap: 1.25rem;
  grid-auto-flow: row;
  position: relative;
}

.container {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI,
  Helvetica Neue, Arial, sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.125rem;

  background:#000;
  color: #000;
}

.wrapperTextSetting {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  overflow: auto;
  position: relative;
  z-index: 101;

  background-color: #000;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100%;
  display: flex;
  height: auto;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  word-break: break-word;

  a {
    color: #02bd9c;
    font-weight: bold;
    text-decoration: underline;
  }

  a:hover {
    color: #05d6ae;
    text-decoration: underline;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  p, a, img {
    vertical-align: middle;
  }
}

@media (min-width: 45.3125rem) {
  .wrapperTextSetting {
    background-position: 50%;
    background-size: cover;
  }
}
