.title {
  list-style-type: none;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  li {
    font-weight: normal !important;
  }
}
