@import "../../../../styles/variables/variables";

.rulesView {
  padding-bottom: 20px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 10px;
    font-weight: bold;
    border-top: 1px solid $black-light;
    background-color: $dark-gray;

    &__button {
      padding: 0;
      background: none;
      border: none;

      &__icon {
        font-size: 16px;
      }
    }
  }

  &__collapse {
    &__title {
      display: flex;
      column-gap: 10px;
      padding: 8px 10px;
      font-size: 12px;
      color: $white;
      border-top: 1px solid $dark-gray;
    }

    &__content {
      padding: 10px;
    }
  }
}
